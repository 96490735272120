import CatalogsRoutes from './Catalogs'
import ClientPortalUsersRoutes from './ClientPortalUsers'
import HelpFormRequestsRoutes from './HelpFormRequests'
import InCommDebugPage from './InCommDebug'
import OfferRoutes from './Offers'
import OfferPartnerRoutes from './OfferPartners'
import RetailersRoutes from './Retailers'
import SendEmails from './SendEmails'
import Domains from './Domains'
import EventsRoutes from './Events'
import EmailContainment from './EmailContainment'

const VelocityRoutes = {
    path: 'velocity',
    children: [
        CatalogsRoutes,
        ClientPortalUsersRoutes,
        HelpFormRequestsRoutes,
        OfferRoutes,
        OfferPartnerRoutes,
        RetailersRoutes,
        EventsRoutes,
        {
            path: 'domains',
            element: <Domains />,
        },
        {
            path: 'email_containment',
            element: <EmailContainment />,
        },
        {
            path: 'send_emails',
            element: <SendEmails />,
        },
        {
            path: 'debug',
            element: <InCommDebugPage />,
        },
    ],
}

export default VelocityRoutes
