import { Box, Button, TextField, Typography, CardMedia } from '@mui/material'
import { checkImageNotNull, isEmpty } from 'helpers'
import { AutocompleteStyled, FormColumnStyled, FormStyled, SubmitBlockStyled, TextFieldStyled } from 'theme/forms'
import { PageStyled } from 'theme/page'
import { STATUS_OPTIONS } from 'features/status'
import BackButton from 'components/BackButton'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import useStyles from './styles'
import ImageFieldWithTooltip from 'components/ImageFieldWithTooltip'

export const OfferPartnerForm = ({
    edit, brand, loadSuccess, connectionError, errors, isLoadingState,
    isLoadError, disabled, brands, form,
    updateField, updateStatusField, updateBrandField, updateImageField, onSubmit,
}) => {
    const styles = useStyles()

    const image = checkImageNotNull(form.image)
    const formImage = checkImageNotNull(form.logo_original)

    if (brand && !form.brand_id) {
        updateBrandField(null, brand)
    }

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isLoadError) return <ConnectionError err={errors} />

    if (!loadSuccess) return <Loading />

    return (
        <PageStyled>
            <Typography variant='h1'>{`${edit ? 'Edit' : 'New'} Offer Partner`}</Typography>

            <IsLoading
                isLoadingState={isLoadingState}
            />

            <FormStyled
                noValidate
                autoComplete='off'
                style={styles.form}
                onSubmit={(e) => e.preventDefault()}
                data-testid='offer-form'
            >
                <FormColumnStyled>
                    <TextFieldStyled
                        label='Name'
                        type='text'
                        data-cy='enter_offer_partner_name'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.name || ''}
                        onChange={updateField('name')}
                    />
                    {isEmpty(brand) && !edit &&
                        <AutocompleteStyled
                            autoComplete
                            disableClearable
                            data-cy='enter_brand'
                            options={brands}
                            getOptionLabel={(op) => op.name || 'None'}
                            disabled={disabled}
                            value={brands.find((op) => op.id === form.brand_id) || ''}
                            onChange={updateBrandField}
                            renderInput={(params) => (
                                <TextField {...params} label='Brand' />
                            )}
                        />
                    }
                    <AutocompleteStyled
                        autoComplete
                        disableClearable
                        data-cy='status'
                        options={STATUS_OPTIONS}
                        getOptionLabel={(value) => value.name || ' '}
                        disabled={disabled}
                        value={STATUS_OPTIONS.find((type) => type.value === form?.status) || ''}
                        onChange={updateStatusField}
                        renderInput={(params) => (
                            <TextField {...params} label='Status' />
                        )}
                    />
                </FormColumnStyled>
                <FormColumnStyled>
                    <ImageFieldWithTooltip>
                        <TextFieldStyled
                            label='Image'
                            type='file'
                            inputProps={{ accept: 'image/png' }}
                            disabled={disabled}
                            data-cy='image'
                            InputLabelProps={{ shrink: true }}
                            onChange={updateImageField}
                        />
                    </ImageFieldWithTooltip>

                    {(formImage || image) &&
                        <>
                            <Box sx={styles.subtitle}>
                                <Typography variant='h3'>{`Current Image:`}</Typography>
                            </Box>
                            <CardMedia
                                sx={styles.cover}
                                component='img'
                                alt='thumbnail_image'
                                src={!formImage ? `data:image/png;base64,${form.image.data}` : form.logo_original}
                                title={`${form.name}'s Current Image`}
                                data-cy='current_image'
                            />
                        </>
                    }
                    {formImage && image &&
                        <>
                            <Box sx={styles.subtitle}>
                                <Typography variant='h3'>{`New Image:`}</Typography>
                            </Box>
                            <CardMedia
                                sx={styles.base64_cover}
                                component='img'
                                alt='thumbnail_image'
                                src={`data:image/png;base64,${form.image.data}`}
                                title={`${form.name}'s New Image`}
                                data-cy='new_image'
                            />
                        </>
                    }
                </FormColumnStyled>
                <SubmitBlockStyled>
                    <Button
                        type='submit'
                        data-cy='submit_offer_partner'
                        variant='contained'
                        color='secondary'
                        sx={styles.btn}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>

                    <BackButton />
                </SubmitBlockStyled>
            </FormStyled>
        </PageStyled>
    )
}

export default OfferPartnerForm
