import OfferPartnersList from './List'
import OfferPartnerShow from './Show'
import OfferPartnerEdit from './Edit'
import OfferPartnersNew from './New'

const OfferPartnerRoutes = {
    path: 'offer_partners',
    children: [
        {
            path: '',
            element: <OfferPartnersList />,
        },
        {
            path: 'new',
            element: <OfferPartnersNew />,
        },
        {
            path: ':id/edit',
            element: <OfferPartnerEdit />,
        },
        {
            path: ':id',
            element: <OfferPartnerShow />,
        },
    ],
}

export default OfferPartnerRoutes
