import OffersList from './List'
import OfferShow from './Show'
import OfferEdit from './Edit'
import OfferNew from './New'
import OfferRules from './Rules'
import OfferRetailerShow from './OfferRetailerShow'

const OfferRoutes = {
    path: 'offers',
    children: [
        {
            path: '',
            element: <OffersList />,
        },
        {
            path: 'new',
            element: <OfferNew />,
        },
        {
            path: ':id/edit',
            element: <OfferEdit />,
        },
        {
            path: ':id',
            element: <OfferShow />,
        },
        {
            path: 'rules',
            element: <OfferRules />,
        },
        {
            path: ':offerID/retailers/:retailerID',
            element: <OfferRetailerShow />,
        },
    ],
}

export default OfferRoutes
