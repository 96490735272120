import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useQueryDefaults } from 'queryHelpers'
import {
    fetchBannerRetailers,
    deleteBannerRetailer,
} from '../services'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'

export const useQueryBannerRetailers = (parentID, queryArgs = {}) => {
    const queryKey = ['bannerRetailers', 'list', parentID]

    return useQuery({
        queryKey,
        queryFn: () => fetchBannerRetailers(parentID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useDeleteBannerRetailerCmd = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (params) => deleteBannerRetailer(params),
        onSuccess: (data, variables) => {
            if (data?.success) {
                const { notices, warnings } = data
                const parentID = variables?.parentID

                queryClient.invalidateQueries({ queryKey: ['bannerRetailers', 'list', parentID] })

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `bannerRetailer-${parentID}-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `bannerRetailer-${parentID}-${warn}` }))

                navigate(`/velocity/retailers/${parentID}`, { replace: true })
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `bannerRetailerDelete-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `bannerRetailerDelete-${data.toString()}` })
        },
    })
}
