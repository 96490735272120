import { squashArr, reduceLoadingState } from 'helpers'
import { useOfferForm } from 'features/velocity/offers/hooks/form'
import { useCreateOfferCmd } from 'features/velocity/offers/queries'
import { useOfferPartners } from 'features/velocity/offer_partners/hooks/filterless_list'
import { useRetailersForOffer } from 'features/velocity/retailers/hooks/retailersForOffer'

export const useCreateOffer = () => {
    const offerPartnersResult = useOfferPartners()
    const retailersResult = useRetailersForOffer()

    const isLoadError = offerPartnersResult?.isError || retailersResult?.isError
    const loadSuccess = offerPartnersResult?.success && retailersResult?.success

    const createResult = useCreateOfferCmd()
    const onSubmit = () => createResult.mutate({ strategy: offerFormReturn.strategy, ...offerFormReturn.form })

    const connectionError = offerPartnersResult?.connectionError || retailersResult?.connectionError
    const warnings = squashArr([offerPartnersResult.warnings, retailersResult?.warnings, createResult.data?.warnings])
    const notices = squashArr([offerPartnersResult.notices, retailersResult?.notices, createResult.data?.notices])
    const errors = squashArr([offerPartnersResult.errors, retailersResult?.errors, createResult.error, createResult.data?.errors])

    const offerFormReturn = useOfferForm({ strategy: 'incomm' })

    const isLoadingState = reduceLoadingState([offerPartnersResult, retailersResult]) || createResult.isLoading
    const isDisabled = offerPartnersResult.isLoading || retailersResult.isLoading || createResult.isLoading

    const offerPartners = loadSuccess ? offerPartnersResult?.offerPartners : []
    const retailers = loadSuccess ? retailersResult?.retailers : []
    const other_retailers = retailers[retailers.length - 1]

    if (other_retailers?.banner_stores <= 0) {
        retailers.pop()
    }

    return {
        loadSuccess,
        connectionError,
        errors,
        isLoadingState,
        warnings,
        notices,
        isLoadError,
        disabled: isDisabled,
        offerPartners,
        retailers,
        ...offerFormReturn,
        onSubmit,
    }
}
