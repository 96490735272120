import { Button } from '@mui/material'
import { FormColumnStyled, FormStyled, SubmitBlockStyled, TextFieldStyled } from 'theme/forms'
import { PageStyled } from 'theme/page'
import IsLoading from 'components/IsLoading'
import { useCreateCatalogItem } from 'features/velocity/catalogs/hooks/create_catalog_item'
import useStyles from './styles'

export const UPCForm = ({ catalogID }) => {
    const styles = useStyles()

    const {
        form,
        disabled,
        isLoadingState,
        updateField,
        onSubmit,
    } = useCreateCatalogItem(catalogID)

    return (
        <PageStyled>
            <IsLoading
                isLoadingState={isLoadingState}
            />

            <FormStyled
                noValidate
                autoComplete='off'
                onSubmit={(e) => e.preventDefault()}
                data-testid='url-form'
            >
                <FormColumnStyled>
                    <TextFieldStyled
                        label='Description'
                        type='text'
                        data-cy='enter_description'
                        sx={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.description || ''}
                        onChange={updateField('description')}
                    />
                    <TextFieldStyled
                        label='UPC'
                        type='text'
                        data-cy='enter_upc'
                        sx={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.upc || ''}
                        onChange={updateField('upc')}
                    />
                </FormColumnStyled>

                <SubmitBlockStyled>
                    <Button
                        type='submit'
                        data-cy='submit_url'
                        variant='contained'
                        color='secondary'
                        sx={styles.btn}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </SubmitBlockStyled>
            </FormStyled>
        </PageStyled>
    )
}

export default UPCForm
