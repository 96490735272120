import { colors } from 'theme'

const useStyles = () => ({
    graphDisplayWrapper: {
        position: 'relative',
        margin: '10px',
        padding: '10px',
    },
    graphNoDisplayOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: (theme) => theme.palette.overlay.main,
        borderRadius: '10px',
        top: 0,
        left: 0,
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    graphTitle: {
        '&.MuiTypography-root': {
            fontSize: '1.928rem',
            fontWeight: 700,
        },
    },
    graphDataWrapper: {
        height: { xs: 200, xl: 350 },
        width: '100%',
        marginTop: '20px',
        position: 'relative',
    },
    graphButton: {
        position: 'absolute',
        right: 20,
        top: '5%',
        height: 40,
        width: 40,
        backgroundColor: colors.vizerIceGray.main,
        color: colors.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        cursor: 'pointer',
    },
})

export default useStyles
