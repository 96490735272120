import RetailerForm from 'features/velocity/retailers/components/Form'
import { useCreateRetailer } from 'features/velocity/retailers/hooks/create'
import { useLocation } from 'react-router-dom'

export const RetailerNewForm = () => {
    const createRetailerResult = useCreateRetailer()
    const location = useLocation()
    const parentRetailerID = location?.state?.parentRetailerID

    return (
        <RetailerForm
            edit={false}
            parentRetailerID={parentRetailerID}
            {...createRetailerResult}
        />
    )
}

export default RetailerNewForm
