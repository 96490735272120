import { UPDATE_FIELD } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const graphFiltersInitialState = (state = {}) => {
    return {
        offer_strategy: null,
        start_date: null,
        end_date: null,
        shortlink_id: null,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
        utm_term: null,
        ...state,
    }
}

export const graphFiltersReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state[`${action.field}`] = action.value

            break
        }
        case 'RESET_LINK_FILTER_VALUES': {
            state.shortlink_id = null
            state.utm_source = null
            state.utm_medium = null
            state.utm_campaign = null
            state.utm_content = null
            state.utm_term = null

            break
        }
        case 'RESET_FILTER_VALUES': {
            return graphFiltersInitialState()
        }
        default: {
            return state
        }
    }
}

export const useGraphFiltersReducer = (state = {}) => {
    return useReducer(graphFiltersReducer, graphFiltersInitialState(state))
}
