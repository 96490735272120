import {
    Autocomplete,
    Box,
    Button,
    TextField,
    InputAdornment,
} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import SearchIcon from '@mui/icons-material/Search'
import { capitalize, isPresent } from 'helpers'
import { PER_PAGE_DEFAULT } from 'features/status'
import useStyles from './styles'

const Filter = ({
    pageState, filters, updateFilter, search, setSearch, resetFilters,
    setPage, disabled, resetFiltersDisabled, refresh, children,
}) => {
    const styles = useStyles()

    return (
        <Box sx={styles.bar}>
            {isPresent(filters) && Object.entries(filters).map(([key, value]) => (
                <Autocomplete
                    autoComplete
                    key={key}
                    sx={styles.input}
                    data-cy={key}
                    options={value}
                    getOptionLabel={(value) => capitalize(value.replaceAll('_', ' '))}
                    isOptionEqualToValue={(f) => f === pageState.filters[key]}
                    disabled={disabled}
                    value={value.find((f) => f === pageState.filters[key]) || null}
                    onChange={(e, value) => updateFilter(e, value, key)}
                    renderInput={(params) => (
                        <TextField {...params} label={key.replaceAll('_', ' ')} />
                    )}
                />
            ))}

            {children}

            {isPresent(setSearch) && typeof setSearch === 'function' &&
                <TextField
                    id="search"
                    sx={styles.input}
                    label="Search"
                    type="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            }
            <Box sx={styles.btnContainer}>
                {isPresent(filters) &&
                    <Button
                        sx={styles.btn}
                        variant='contained'
                        color='secondary'
                        data-cy='reset-btn'
                        disabled={resetFiltersDisabled}
                        onClick={resetFilters}
                    >
                        Reset Filters
                    </Button>
                }

                <Button
                    sx={styles.refresh}
                    variant='contained'
                    color='secondary'
                    data-cy='refresh-btn'
                    onClick={refresh}
                >
                    <RefreshIcon />
                </Button>
            </Box>
            <Autocomplete
                autoComplete
                freeSolo
                clearOnBlur
                selectOnFocus
                sx={styles.perPage}
                disableClearable
                data-cy='per-page'
                options={PER_PAGE_DEFAULT}
                getOptionLabel={(value) => value}
                isOptionEqualToValue={(p) => p == pageState?.per_page} // eslint-disable-line eqeqeq
                disabled={disabled}
                value={PER_PAGE_DEFAULT.find((p) => p == pageState?.per_page) || pageState?.per_page || null} // eslint-disable-line eqeqeq
                onChange={(e, value) => setPage(e, value, 'per_page')}
                renderInput={(params) => (
                    <TextField {...params} label='Results per page' />
                )}
            />
        </Box>
    )
}

export default Filter
