import { UPDATE_FIELD, RESET_FORM } from 'features/status'
import { useDomainFormReducer } from 'features/velocity/domains/reducers'
import { emptyToNull } from 'helpers'

export const useDomainForm = (state) => {
    const [form, dispatch] = useDomainFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })
    const updateStatusField = (_, status) => dispatch({ type: UPDATE_FIELD, field: 'status', value: status.value })
    const resetForm = () => dispatch({ type: RESET_FORM })

    return {
        form,
        updateField,
        updateStatusField,
        resetForm,
    }
}
