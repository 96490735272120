import { useEffect } from 'react'
import { reduceQueries, useInvalidateQuery } from 'helpers'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'
import { useQueryBrands, useQueryOfferPartnerShow } from 'features/velocity/offer_partners/queries'

export const useOfferPartnerShow = (offerPartnerID) => {
    const debounceSkipKeys = ['offer_type', 'offer_status', 'incomm_status', 'currently_live']

    const {
        setPage,
        resetPage,
        updateFilter,
        resetFilters,
        pageState,
        paginationQueryArgs,
    } = usePaginationFilters({
        state: { filters: { offer_type: 'incomm' } },
        debounceSkipKeys,
    })

    const {
        payloads: [offerPartnerPayload, brandsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [
            useQueryOfferPartnerShow(offerPartnerID, pageState, paginationQueryArgs),
            useQueryBrands(paginationQueryArgs),
        ],
    )

    const invalidateShowQueries = useInvalidateQuery(['offer_partners', 'show'])

    const offerPartner = offerPartnerPayload?.offer_partner
    const offerPartnerOffers = offerPartnerPayload?.offers
    const pagination = offerPartnerPayload?.pagination || {}
    const filters = offerPartnerPayload?.filters || {}
    const offerPartnerBrand = brandsPayload?.brands.find((brand) => brand.id === offerPartnerPayload?.offer_partner.brand_id)

    offerPartnerOffers?.map((offer) => {
        offer.offer_partner = offerPartner
        offer.brand = offerPartnerBrand
    })

    delete filters.offer_type

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    useEffect(() => {
        updateFilter(null, 'incomm', 'offer_type')
    }, [filters])

    return {
        connectionError,
        success,
        offerPartner,
        offerPartnerOffers,
        offerPartnerBrand,
        pagination,
        filters,
        setPage,
        updateFilter,
        resetFilters,
        pageState,
        errors,
        warnings,
        notices,
        invalidateShowQueries,
        ...isResults,
    }
}
