import { RESET_FILTERS, RESET_STATE, UPDATE_FILTER, UPDATE_STATE } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const paginationFilterInitialState = (state = {}) => {
    return {
        page: 1,
        per_page: 10,
        filters: {},
        ...state,
    }
}

export const paginationFilterReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_STATE: {
            state[action.field] = action.value

            break
        }
        case UPDATE_FILTER: {
            if (action.value) {
                state.filters[action.field] = action.value
            } else {
                delete state.filters[action.field]
            }

            break
        }
        case RESET_FILTERS: {
            state.filters = {}

            break
        }
        case RESET_STATE: {
            return paginationFilterInitialState(state)
        }
        default: {
            return state
        }
    }
}

export const usePaginationFilterReducer = (state = {}) => {
    return useReducer(paginationFilterReducer, paginationFilterInitialState(state))
}
