import { colors } from 'theme'

const useStyles = () => ({
    pageHeader: {
        marginBottom: '30px',
    },
    btn: {
        fontWeight: 500,
        '& svg': {
            marginLeft: '10px',
        },
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '20px',
        '& h1': {
            margin: 0,
        },
        flexWrap: { xs: 'wrap', md: 'nowrap' },
    },
    headerButtons: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        '& > button': {
            marginRight: '10px',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    link: {
        textDecoration: 'none',
        color: colors.black,
    },
    pageRow: {
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
    },
    pageColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 15px',
        '&:nth-of-type(1)': {
            paddingLeft: 0,
        },
        '&:nth-of-type(4)': {
            flexGrow: 1,
            paddingRight: 0,
        },
    },
    divider: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: colors.vizerCharcoal.light,
        margin: '15px',
    },
})

export default useStyles
