import { useNavigate } from 'react-router-dom'
import { Typography, Button, Modal, useTheme } from '@mui/material'
import Monkey from '../../../../components/Monkey'
import { useShowErrorModal } from '../../hooks/error_modal'
import { classesFn } from './styles'

export const ErrorModal = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    const classes = classesFn(theme)
    const {
        title, userMessage, buttonText,
        devMessage, visible, dismissError,
    } = useShowErrorModal(navigate)

    // TODO(drewbrad4): https://www.pivotaltracker.com/story/show/187895671
    // we need to hook this up correctly when user roles are working
    const isAdmin = true

    return (
        <Modal open={visible} sx={classes.modal}>
            <div style={classes.page}>
                <div style={classes.container}>
                    <div>
                        <Typography sx={classes.title}>{title}</Typography>
                    </div>
                    <div>
                        <Monkey />
                    </div>
                    {isAdmin &&
                        <Typography sx={classes.messageText(true)}>
                            {devMessage}
                        </Typography>
                    }
                    <Typography sx={classes.messageText()}>
                        {userMessage}
                    </Typography>
                    <Button
                        variant='contained'
                        color='secondary'
                        sx={classes.btn}
                        onClick={dismissError}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ErrorModal
