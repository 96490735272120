import { useParams, Link } from 'react-router-dom'
import {
    Box,
    Button,
    Card,
    CardContent,
    Link as Anchor,
    Tab,
    Tabs,
    AppBar,
    Typography,
} from '@mui/material'
import { sanitizeDate } from 'helpers'
import { DescriptionListStyled } from 'theme/styled_components'
import ConnectionError from 'components/ConnectionError'
import ImageWithModal from 'components/ImageWithModal'
import IsLoading from 'components/IsLoading'
import Loading from 'components/Loading'
import TabPanel from 'components/TabPanel'
import ImageModal from 'features/modals/components'
import OfferPartnerButton from 'features/velocity/offer_partners/components/OfferPartnerButton'
import { useBrandShow } from 'features/brands/hooks/show'
import useStyles from './styles'
import ClientPortalContent from '../ClientPortalContent'

export const BrandShow = () => {
    const styles = useStyles()
    const { id } = useParams()

    const {
        connectionError,
        brand,
        errors,
        tabs,
        activeTab,
        setActiveTab,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
    } = useBrandShow(parseInt(id))

    const {
        name,
        about,
        website,
        facebook,
        instagram,
        twitter,
        reward_cover_image_url,
        app_link,
        redemption_instructions,
        client_portal_resource_content,
        client_portal_success_center_content,
        offer_partner_id,
        created_at,
        updated_at,
    } = brand || {}

    if (isInitialLoading) return <Loading fullscreen />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <ImageModal />

            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
            />

            <Card sx={styles.pageHeader}>
                <CardContent>
                    <Box sx={styles.titleBar}>
                        <Typography variant='h1'>{name}</Typography>
                        <div style={styles.headerButtons}>
                            <Link style={styles.link} to={`/brands/${id}/edit`}>
                                <Button
                                    sx={styles.btn}
                                    variant='contained'
                                    color='secondary'
                                    data-cy='edit-btn'
                                >
                                    Edit
                                </Button>
                            </Link>
                        </div>
                    </Box>

                    <Box sx={styles.pageRow}>
                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>ID</p>
                                </dt>
                                <dd>
                                    <p data-cy='id'>{id}</p>
                                </dd>
                                <dt>
                                    <p>Website:</p>
                                </dt>
                                <dd>
                                    {website ? (
                                        <Anchor target='_blank' rel='noreferrer' href={website} data-cy='website'>
                                            {website}
                                        </Anchor>
                                    ) : (
                                        'None'
                                    )}
                                </dd>
                                <dt>
                                    <p>Facebook:</p>
                                </dt>
                                <dd>
                                    {facebook ? (
                                        <Anchor target='_blank' rel='noreferrer' href={facebook} data-cy='facebook'>
                                            {facebook}
                                        </Anchor>
                                    ) : (
                                        'None'
                                    )}
                                </dd>
                                <dt>
                                    <p>Instagram:</p>
                                </dt>
                                <dd>
                                    {instagram ? (
                                        <Anchor target='_blank' rel='noreferrer' href={instagram} data-cy='instagram'>
                                            {instagram}
                                        </Anchor>
                                    ) : (
                                        'None'
                                    )}
                                </dd>
                                <dt>
                                    <p>Twitter:</p>
                                </dt>
                                <dd>
                                    {twitter ? (
                                        <Anchor target='_blank' rel='noreferrer' href={twitter} data-cy='twitter'>
                                            {twitter}
                                        </Anchor>
                                    ) : (
                                        'None'
                                    )}
                                </dd>
                                <dt>
                                    <p>About:</p>
                                </dt>
                                <dd>
                                    <p>{about || 'None'}</p>
                                </dd>
                            </DescriptionListStyled>
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>App Link:</p>
                                </dt>
                                <dd>
                                    <p data-cy='app-link'>{app_link || 'None'}</p>
                                </dd>
                                <dt>
                                    <p>Redemption Instructions:</p>
                                </dt>
                                <dd>
                                    <p data-cy='redemption-instructions'>{redemption_instructions || 'None'}</p>
                                </dd>
                                <dt>
                                    <p>Created</p>
                                </dt>
                                <dd>
                                    <p data-cy='created-at'>{sanitizeDate(created_at)}</p>
                                </dd>
                                <dt>
                                    <p>Updated</p>
                                </dt>
                                <dd>
                                    <p data-cy='updated-at'>{sanitizeDate(updated_at)}</p>
                                </dd>
                            </DescriptionListStyled>
                        </Box>

                        {reward_cover_image_url &&
                            <Box sx={styles.pageColumn}>
                                <ImageWithModal
                                    noSpacing
                                    image={{
                                        url: reward_cover_image_url,
                                        alt: `${name}'s Cover Image`,
                                        title: 'Brand Image',
                                        variant: 'banner',
                                    }}
                                />
                            </Box>
                        }
                    </Box>

                    <Box sx={styles.buttonRow}>
                        <OfferPartnerButton
                            brand={brand}
                            offerPartnerID={offer_partner_id}
                            hasLoaded={!isLoading}
                        />
                    </Box>
                </CardContent>
            </Card>

            <AppBar sx={styles.tabs} position="static">
                <Tabs value={activeTab} classes={{ indicator: styles.indicator }}>
                    {tabs.includes('content') &&
                        <Tab sx={styles.tab} label="Client Portal Content" onClick={() => setActiveTab(tabs.indexOf('content'))} />
                    }
                </Tabs>
            </AppBar>

            <Card sx={styles.detailsCard}>
                {tabs.includes('content') &&
                    <TabPanel value={activeTab} index={tabs.indexOf('content')}>
                        <ClientPortalContent
                            client_portal_resource_content={client_portal_resource_content}
                            client_portal_success_center_content={client_portal_success_center_content}
                        />
                    </TabPanel>
                }
            </Card>
        </>
    )
}

export default BrandShow
