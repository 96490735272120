import { velocityUrl, _delete, _get, _post } from 'utils'

export async function fetchCatalogs() {
    try {
        const url = await velocityUrl(`/catalogs/queries/list_catalogs`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchCatalogShow(catalogID) {
    try {
        const url = await velocityUrl(`/catalogs/queries/fetch_catalog_by_id/${catalogID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function createNewCatalog({
    name, tag, description, active,
}) {
    try {
        const url = await velocityUrl('/catalogs/commands/create_catalog')

        const body = {
            catalog: {
                name,
                tag,
                description,
                active,
            },
        }

        console.log(':: catalog body submitted ::', body) // eslint-disable-line no-console

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function updateExistingCatalog({
    id, name, tag, description, active,
}) {
    try {
        const url = await velocityUrl(`/catalogs/commands/update_catalog`)

        const body = {
            catalog: {
                id,
                name,
                tag,
                description,
                active,
            },
        }

        console.log(':: catalog body submitted ::', body) // eslint-disable-line no-console

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function deleteExistingCatalog(catalogID) {
    try {
        const url = await velocityUrl(`/catalogs/commands/delete_catalog_by_id/${catalogID}`)

        return await _delete(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchCatalogItems(catalogID) {
    try {
        const url = await velocityUrl(`/catalogs/queries/list_items_by_catalog/${catalogID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function createCatalogItems({ catalogID, form: { upc, description } }) {
    try {
        const url = await velocityUrl(`/catalogs/commands/add_catalog_item/${catalogID}`)

        const body = {
            item: {
                upc,
                description,
            },
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function deleteCatalogItem({ catalogID, upc }) {
    try {
        const url = await velocityUrl(`/catalogs/commands/delete_catalog_item/${catalogID}/${upc}`)

        return await _post(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
