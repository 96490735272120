import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material'
import { lastXChar } from 'helpers'
import Cell from 'components/Cell'
import Row from 'components/Row'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import { useHelpFormRequests } from 'features/velocity/help_form_requests/hooks/list'
import HelpFormRequestStatus from '../HelpFormRequestStatus'
import HelpFormRequestDomainStatus from '../HelpFormRequestDomainStatus'
import useStyles from './styles'

export const HelpFormRequestsList = () => {
    const styles = useStyles()

    const {
        connectionError,
        helpFormRequests,
        pagination,
        filters,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        setPage,
        updateFilter,
        resetFilters,
        pageState,
        invalidateListQueries,
        resetFiltersDisabled,
    } = useHelpFormRequests()

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Help Form Requests</Typography>

            <Filter
                pageState={pageState}
                filters={filters}
                pagination={pagination}
                setPage={setPage}
                updateFilter={updateFilter}
                resetFilters={resetFilters}
                disabled={isFetching}
                resetFiltersDisabled={resetFiltersDisabled}
                refresh={invalidateListQueries}
            />

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align='center'>Name</TableCell>
                            <TableCell align='center'>Email</TableCell>
                            <TableCell align='center'>Status</TableCell>
                            <TableCell align='center'>Domain Status</TableCell>
                        </TableRow>
                    </TableHead>
                    {helpFormRequests.length > 0 &&
                        <TableBody data-cy='body'>
                            {helpFormRequests.map(({ id, name, email, status, domain_status }) => (
                                <Row
                                    key={id}
                                    path={`/velocity/help_form_requests/${id}`}
                                >
                                    <Cell scope='help-form-request' align='center'>{lastXChar(id, 8)}</Cell>
                                    <Cell align='center'>{name}</Cell>
                                    <Cell align='center'>{email}</Cell>
                                    <Cell align='center'>
                                        <HelpFormRequestStatus status={status} />
                                    </Cell>
                                    <Cell align='center'>
                                        <HelpFormRequestDomainStatus domain_status={domain_status} />
                                    </Cell>
                                </Row>
                            ))}
                        </TableBody>
                    }
                </Table>
            </TableContainer>

            {helpFormRequests.length === 0 &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No help form requests found matching your selection.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default HelpFormRequestsList
