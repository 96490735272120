import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const brandFormInitialState = (state = {}) => {
    return {
        name: null,
        about: null,
        website: null,
        facebook: null,
        instagram: null,
        twitter: null,
        reward_cover_image_url: null,
        image: null,
        app_link: null,
        redemption_instructions: null,
        client_portal_resource_content: null,
        client_portal_success_center_content: null,
        ...state,
    }
}

export const brandFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state[`${action.field}`] = action.value

            break
        }
        case SET_FORM: {
            return action.value
        }
        case RESET_FORM: {
            return brandFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useBrandFormReducer = (state = {}) => {
    return useReducer(brandFormReducer, brandFormInitialState(state))
}
