import { TextField, Button, Typography } from '@mui/material'
import { AutocompleteStyled, FormColumnStyled, FormStyled, SubmitBlockStyled, TextFieldStyled } from 'theme/forms'
import { PageStyled } from 'theme/page'
import { TRUE_FALSE } from 'features/status'
import BackButton from 'components/BackButton'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import useStyles from './styles'

export const CatalogBaseForm = ({
    edit, loadSuccess, connectionError, errors, isLoadingState,
    isLoadError, disabled, form, updateField, updateActiveField, onSubmit,
}) => {
    const styles = useStyles()

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isLoadError) return <ConnectionError err={errors} />

    if (!loadSuccess) return <Loading />

    return (
        <PageStyled>
            <Typography variant='h1'>{`${edit ? 'Edit' : 'New'} Catalog`}</Typography>

            <IsLoading
                isLoadingState={isLoadingState}
            />

            <FormStyled
                noValidate
                autoComplete='off'
                onSubmit={(e) => e.preventDefault()}
                data-testid='catalog-form'
            >
                <FormColumnStyled>
                    <TextFieldStyled
                        label='Name'
                        type='text'
                        data-cy='enter_catalog_name'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.name || ''}
                        onChange={updateField('name')}
                    />
                    <AutocompleteStyled
                        autoComplete
                        disableClearable
                        data-cy='active'
                        options={TRUE_FALSE}
                        getOptionLabel={(value) => value.name || ' '}
                        isOptionEqualToValue={(option) => option.value === form?.active}
                        disabled={disabled}
                        value={TRUE_FALSE.find((type) => type.value === form?.active) || ''}
                        onChange={updateActiveField}
                        renderInput={(params) => (
                            <TextField {...params} label='Active' />
                        )}
                    />
                    <TextFieldStyled
                        label='Tag'
                        type='text'
                        data-cy='enter_tag'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.tag || ''}
                        onChange={updateField('tag')}
                    />
                    <TextFieldStyled
                        label='Description'
                        type='text'
                        data-cy='enter_description'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.description || ''}
                        onChange={updateField('description')}
                    />
                </FormColumnStyled>

                <SubmitBlockStyled>
                    <Button
                        type='submit'
                        data-cy='submit_catalog'
                        variant='contained'
                        color='secondary'
                        sx={styles.btn}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>

                    <BackButton />
                </SubmitBlockStyled>
            </FormStyled>
        </PageStyled>
    )
}

export default CatalogBaseForm
