import { useParams, Link } from 'react-router-dom'
import { sanitizeDate } from 'helpers'
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Typography,
} from '@mui/material'
import { DescriptionListStyled, StatusChipStyled } from 'theme/styled_components'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import ImageWithModal from 'components/ImageWithModal'
import ImageModal from 'features/modals/components'
import LabelWithLink from 'components/LabelWithLink'
import OffersList from 'features/velocity/offers/components/OfferList'
import { useOfferPartnerShow } from 'features/velocity/offer_partners/hooks/show'
import useStyles from './styles'

export const OfferPartnerShow = () => {
    const styles = useStyles()
    const { id } = useParams()

    const {
        connectionError,
        offerPartner,
        offerPartnerBrand,
        offerPartnerOffers,
        pagination,
        filters,
        setPage,
        updateFilter,
        resetFilters,
        pageState,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        invalidateShowQueries,
    } = useOfferPartnerShow(id)

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    const {
        brand_id,
        status,
        name,
        logo_original,
        created_at,
        updated_at,
    } = offerPartner || {}

    return (
        <>
            <ImageModal />

            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
            />

            <Card sx={styles.pageHeader}>
                <CardContent>
                    <Box sx={styles.titleBar}>
                        <Typography variant='h1'>
                            {name}
                        </Typography>
                        <div style={styles.headerButtons}>
                            <Link style={styles.link} to={`/velocity/offer_partners/${id}/edit`}>
                                <Button
                                    sx={styles.btn}
                                    variant='contained'
                                    color='secondary'
                                    data-cy='edit-btn'
                                >
                                    Edit
                                </Button>
                            </Link>
                        </div>
                    </Box>

                    <Box sx={styles.pageRow}>
                        <Box sx={styles.pageColumn}>
                            <ImageWithModal
                                noSpacing
                                image={{
                                    url: logo_original,
                                    alt: `${name}'s Image`,
                                    variant: 'logo',
                                }}
                            />
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <StatusChipStyled
                                active={status === 'active'}
                                label={<p>{status}</p>}
                            />
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>ID</p>
                                </dt>
                                <dd>
                                    <p>{id}</p>
                                </dd>
                                <dt>
                                    <p>Brand</p>
                                </dt>
                                <dd>
                                    <LabelWithLink
                                        label={offerPartnerBrand?.name}
                                        path={`/brands/${brand_id}`}
                                        id={brand_id}
                                        emptyLabel={'None'}
                                    />
                                </dd>
                            </DescriptionListStyled>
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>Created</p>
                                </dt>
                                <dd>
                                    <p>{sanitizeDate(created_at)}</p>
                                </dd>
                                <dt>
                                    <p>Updated</p>
                                </dt>
                                <dd>
                                    <p>{sanitizeDate(updated_at)}</p>
                                </dd>
                            </DescriptionListStyled>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            <Divider sx={styles.divider} />

            <div style={styles.header}>
                {offerPartnerOffers?.length > 0 &&
                    <Typography variant='h1'>{`${name}'s Offers`}</Typography>
                }

                <Link
                    style={styles.link}
                    to='/velocity/offers/new'
                    state={{
                        from: `/velocity/offer_partners/${id}`,
                        offerPartnerParams: { offer_partner: offerPartner, brand: offerPartnerBrand },
                    }}
                >
                    <Button
                        sx={styles.btn}
                        variant="contained"
                        color="secondary"
                        data-cy='new-offer-btn'
                    >
                        {`Add Offer`}
                    </Button>
                </Link>
            </div>

            <OffersList
                offers={offerPartnerOffers}
                pageState={pageState}
                filters={filters}
                pagination={pagination}
                setPage={setPage}
                updateFilter={updateFilter}
                resetFilters={resetFilters}
                disabled={isFetching}
                show={true}
                refresh={invalidateShowQueries}
            />
        </>
    )
}

export default OfferPartnerShow
