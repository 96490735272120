import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

export const RetailerLinkStyled = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    flex: '0 1 50%',
    [theme.breakpoints.up('md')]: {
        flex: '0 1 33%',
    },
}))

const useStyles = () => ({
    retailerContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    retailer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '15px',
        '& p': {
            fontSize: 18,
            margin: 0,
            color: (theme) => theme.palette.text.primary,
        },
    },
    logo: {
        height: 50,
        width: 50,
        marginRight: '10px',
        objectFit: 'contain',
    },

})

export default useStyles
