import { combineReducers } from 'redux'
import csv_uploader from 'features/csv_upload/reducer'
import healthCheck from 'features/health_check/reducers'
import modals from 'features/modals/reducers'
import permissions from 'features/oxidauth/permissions/reducers'
import page_notifications from 'features/page_notifications/reducers'
import roles from 'features/oxidauth/roles/reducers'
import users from 'features/oxidauth/users/reducers'
import errors from 'features/errors/reducers'

const rootReducer = combineReducers({
    csv_uploader,
    healthCheck,
    permissions,
    modals,
    page_notifications,
    roles,
    users,
    errors,
})

export default rootReducer
