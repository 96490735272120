export async function fetchConstants() {
    try {
        const constants = await fetch('/constants.json')
            .then((res) => res.json())

        // NOTE(brianthomashammond): success key and payload nesting is to allow useQueryConstants to function with reduceQueries helper.
        return {
            success: true,
            payload: constants,
        }
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
