import { useRouteError } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { isProduction } from 'helpers'
import useStyles from './styles'

const Error = () => {
    const error = useRouteError()
    const styles = useStyles()

    return (
        <Box sx={styles.page}>
            <img
                src={'/spyglass.png'}
                alt='woman searching above clouds with spyglass'
                title='Connection error spyglass image'
                style={styles.image}
            />
            <Typography variant='h1' align='center'>
                Oops!
            </Typography>
            <Typography variant='h2' align='center' sx={styles.subtitle}>
                Sorry, an unexpected error has occurred.
            </Typography>

            {error?.data &&
                <Typography variant='h3' align='center' sx={styles.errorText}>
                    {error?.data}
                </Typography>
            }

            <Typography variant='h3' align='center'>
                Try checking your url, or return to the <a href='/' title='Home' style={styles.link}>homepage</a>.
            </Typography>

            {!isProduction() && !error?.data &&
                <Typography variant='p' align='center' sx={styles.errorText}>
                    If your url is correct, check the console for development errors.
                </Typography>
            }
        </Box>
    )
}

export default Error
