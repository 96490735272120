import { colors } from 'theme'

const useStyles = () => ({
    closeButton: {
        position: 'absolute',
        top: 25,
        right: 25,
        fontWeight: 900,
        height: 40,
        minWidth: 40,
        padding: 0,
        background: 'none',
        boxShadow: 'none',
        '& svg': {
            height: 30,
            width: 30,
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: { xs: 'column', sm: 'row' },
    },
    btn: (type) => ({
        width: 200,
        fontSize: '1.24rem',
        fontWeight: 700,
        height: 50,
        margin: '15px',
        background: calculateBG(type),
        '&:hover': {
            background: calculateBG(type, true),
        },
    }),
    page: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.paper,
        padding: '50px 35px',
        width: { xs: '90vw', sm: '100%' },
    },
    span: {
        fontSize: '1.7rem',
        fontWeight: 900,
    },
    allowSpan: {
        color: colors.success.main,
    },
    blockSpan: {
        color: colors.error.main,
    },
})

const calculateBG = (type, hover) => {
    const variant = hover ? 'dark' : 'main'

    if (type === 'delete') return colors.error[variant]
    return colors.vizerSlateGray[variant]
}

export const customModalStyles = {
    overlay: {
        zIndex: 999999999999,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background: 'none',
        marginRight: '-50%',
        border: 'none',
        transform: 'translate(-50%, -50%)',
        minHeight: 400,
        maxWidth: 600,
    },
}

export default useStyles
