export const useStyles = () => ({
    appBar: {
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: (theme) => theme.palette.background.paper,
        color: (theme) => theme.palette.text.primary,
    },
    icon: {
        marginRight: '10px',
        cursor: 'pointer',
        '&:hover': {
            color: (theme) => theme.palette.icons.hover,
        },
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        '&.MuiToolbar-root': {
            paddingLeft: '4px',
            paddingRight: '4px',
        },
    },
    menuButton: {
        padding: '12px',
        marginRight: '5px',
        color: (theme) => theme.palette.icons.main,
    },
    userName: {
        fontFamily: 'Open Sans, Avenir, sans-serif',
        margin: 0,
        marginRight: '10px',
    },
    spacer: {
        width: 10,
    },
    left: {
        width: '33vw',
        display: 'flex',
    },
    center: {
        width: '33vw',
        display: 'flex',
        justifyContent: 'center',
    },
    right: {
        width: '33vw',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '12px',
    },
    button: {
        border: 0,
        padding: 0,
        background: 'none',
    },
    full: {
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
    },
    // search: {
    //     position: 'relative',
    //     borderRadius: (theme) => theme.shape.borderRadius,
    //     [theme.breakpoints.up('sm')]: {
    //         width: 'auto',
    //     },
    //     display: 'flex',
    //     alignItems: 'center',
    //     backgroundColor: (theme) => theme.palette.searchBar.main,
    // },
    // searchIcon: {
    //     padding: (theme) => theme.spacing(0, 2),
    //     height: '100%',
    //     position: 'absolute',
    //     pointerEvents: 'none',
    //     display: 'flex',
    //     alignItems: 'center',
    //     color: (theme) => theme.palette.searchIcon.main,
    // },
    // inputInput: {
    //     padding: (theme) => theme.spacing(1, 1, 1, 0),
    //     // vertical padding + font size from searchIcon
    //     paddingLeft: (theme) => `calc(1em + ${theme.spacing(4)}px)`,
    //     transition: (theme) => theme.transitions.create('width'),
    //     width: '100%',
    //     [theme.breakpoints.up('sm')]: {
    //         width: '12ch',
    //         '&:focus': {
    //             width: '20ch',
    //         },
    //     },
    // },
})
