import queryString from 'query-string'
import { client, initOxidAuthClient } from './oxidauth'

export const bootstrap = async () => {
    const constants = await setConsts()

    const [authClientResult] = await Promise.all([authClient()])

    return {
        constants,
        authClient: authClientResult,
    }
}

export const setConsts = async () => {
    const constants = await fetch('/constants.json')
        .then((res) => res.json())

    localStorage.setItem('constants', JSON.stringify(constants))

    return constants
}

export const getConstants = async () => {
    let constants = localStorage.getItem('constants')

    if (constants !== undefined && constants !== null && constants.trim('') !== '') {
        return JSON.parse(constants)
    }

    constants = await setConsts()

    return constants
}

export const getConstant = async (name) => {
    const constants = await getConstants()
    return constants[name]
}

export const getConstantSync = (name, defaultValue) => {
    const constants = localStorage.getItem('constants')

    if (constants !== undefined && constants !== null && constants.trim('') !== '') {
        return JSON.parse(constants)[name]
    } else {
        console.warn(`${name} constant not found, returning default production value`) // eslint-disable-line no-console

        setConsts()

        return defaultValue
    }
}

export const reactQueryDefaultStaleTime = () => {
    return getConstantSync('REACT_QUERY_DEFAULT_STALETIME', 45000)
}

export const reactQueryDefaultRefetch = () => {
    return getConstantSync('REACT_QUERY_DEFAULT_REFETCH', 60000)
}

export const apiHost = async () => {
    return getConstant('API_HOST')
}

export const horaeHost = async () => {
    return getConstant('HORAE_HOST')
}

export const velocityPublicWebHost = async () => {
    return getConstant('VELOCITY_PUBLIC_WEB_HOST')
}

export const clientPortalHost = async () => {
    return getConstant('CLIENT_PORTAL_API_HOST')
}

export const velocityHost = async () => {
    return getConstant('VELOCITY_API_HOST')
}

export const brandsHost = async () => {
    return getConstant('BRANDS_API_HOST')
}

export const url = async (path, query) => {
    const base = await apiHost()

    return queryString.stringifyUrl({
        url: `${base}${path}`,
        query,
    })
}

export const horaeUrl = async (path, query) => {
    const base = await horaeHost()

    return queryString.stringifyUrl({
        url: `${base}${path}`,
        query,
    })
}

export const velocityUrl = async (path, query) => {
    const base = await velocityHost()

    return queryString.stringifyUrl({
        url: `${base}/api/v1${path}`,
        query,
    })
}

export const clientPortalUrl = async (path, query) => {
    const base = await clientPortalHost()

    return queryString.stringifyUrl({
        url: `${base}/api/v1${path}`,
        query,
    })
}

export const brandsUrl = async (path, query) => {
    const base = await brandsHost()

    return queryString.stringifyUrl({
        url: `${base}/api/v1${path}`,
        query,
    })
}

export const oxidauthHost = async () => {
    return getConstant('OXIDAUTH_HOST')
}

export const oxidauthClientKey = async () => {
    return getConstant('OXIDAUTH_CLIENT_KEY')
}

export const oxidauthUrl = async (path, query) => {
    const base = await oxidauthHost()

    return queryString.stringifyUrl({
        url: `${base}${path}`,
        query,
    })
}

export const defaultOpts = async () => {
    const headers = {
        'Content-Type': 'application/json',
    }

    const token = await client.fetchValidJWT()
    if (token !== undefined && token !== null && token.trim() !== '') {
        headers['Authorization'] = `Bearer ${token}`
    }

    // const client_key = await oxidauthClientKey()
    // if (client_key !== undefined && client_key !== null && client_key.trim() !== '') {
    //     headers['Client-Key'] = `${client_key}`
    //     headers['X-Client-Key'] = `${client_key}`
    // }

    return {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin',
        headers,
    }
}

export const authClient = async () => {
    const host = await oxidauthHost()
    const clientKey = await oxidauthClientKey()

    initOxidAuthClient(host, clientKey)

    return client
}

export const _fetch = async (url, opts) => {
    try {
        const defaults = await defaultOpts()

        const res = await fetch(url, {
            ...defaults,
            ...opts,
        })

        const raw = res.clone()
        const data = await raw.text()

        // eslint-disable-next-line no-console
        console.log('::: RAW QUERY :::', '\n', `URL: ${url}`, '\n', data)

        return await res.json()
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('_fetch error:', error)

        throw error
    }
}

export const _get = _fetch

export const _post = (url, body, opts) => {
    return _fetch(url, {
        method: 'POST',
        ...opts,
        body: body ? JSON.stringify(body) : undefined,
    })
}

export const _put = (url, body, opts) => {
    return _fetch(url, {
        method: 'PUT',
        ...opts,
        body: body ? JSON.stringify(body) : undefined,
    })
}

export const _delete = (url, opts) => {
    return _fetch(url, {
        method: 'DELETE',
        ...opts,
    })
}

export const _fetchWithoutJWT = async (url, opts) => {
    try {
        const res = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            ...opts,
        })

        const raw = res.clone()
        const data = await raw.text()

        // eslint-disable-next-line no-console
        console.log('::: RAW QUERY :::', '\n', `URL: ${url}`, '\n', data)

        return await res.json()
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('_fetch error:', error)

        throw error
    }
}

export const _getWithoutJWT = _fetchWithoutJWT

export const _postWithoutJWT = (url, body, opts) => {
    return _fetchWithoutJWT(url, {
        method: 'POST',
        ...opts,
        body: body ? JSON.stringify(body) : undefined,
    })
}

export const _putWithoutJWT = (url, body, opts) => {
    return _fetchWithoutJWT(url, {
        method: 'PUT',
        ...opts,
        body: body ? JSON.stringify(body) : undefined,
    })
}

export const _deleteWithoutJWT = (url, opts) => {
    return _fetchWithoutJWT(url, {
        method: 'DELETE',
        ...opts,
    })
}

export const _fetch_file = async (url, opts) => {
    try {
        const headers = {}

        const token = await client.fetchValidJWT()
        if (token !== undefined && token !== null && token.trim() !== '') {
            headers['Authorization'] = `Bearer ${token}`
        }

        const defaultOpts = {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers,
        }

        const res = await fetch(url, {
            ...defaultOpts,
            ...opts,
        })

        const raw = res.clone()

        const header = raw.headers.get('Content-Disposition').split('=')[1]

        const filename = header.slice(1, header.length - 1)

        const data = await res.blob()

        const blob = new Blob([data])

        return { blob, filename }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('_fetch_file error:', error)

        throw error
    }
}
