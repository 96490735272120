import { createBrowserRouter, RouterProvider, Route, Routes } from 'react-router-dom'
import { AuthContext } from 'contexts'
import Navbar from 'components/Navbar'
import Loading from 'components/Loading'
import { LOGGED_IN, LOGGED_OUT } from 'features/auth/states'
import Error from 'pages/Errors'
import AuthRoutes from 'pages/Auth'
import AboutRoutes from 'pages/About'
import AdminRoutes from 'pages/Admin'
import BrandsRoutes from 'pages/Brands'
import VelocityRoutes from 'pages/Velocity'
import OffersList from 'pages/Velocity/Offers/List'
import ProtectedRoute from '../ProtectedRoute'
import { useToken } from 'features/auth/hooks/token'

const ProtectedRoutes = {
    path: '/',
    element: <ProtectedRoute />,
    children: [
        {
            index: true,
            element: <OffersList />,
        },
        AboutRoutes,
        AdminRoutes,
        BrandsRoutes,
        VelocityRoutes,
    ],
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Navbar />,
        errorElement: <Error />,
        children: [
            AuthRoutes,
            ProtectedRoutes,
        ],
    },
])

const AuthRouter = () => {
    const { authenticated, fetchToken, status, setStatus, clearToken, logout } = useToken()

    return (
        <AuthContext.Provider value={{ authenticated, fetchToken, status, setStatus, clearToken, logout }}>
            <RouterProvider router={router}>
                <Routes>
                    {(status !== LOGGED_IN && status !== LOGGED_OUT) &&
                        <Route element={<Loading absolute />} />
                    }
                </Routes>
            </RouterProvider>
        </AuthContext.Provider>
    )
}

export default AuthRouter
