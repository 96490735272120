import { colors } from 'theme'

const useStyles = () => ({
    iframe: {
        border: 0,
        width: '100%',
        height: '100%',
    },
    contentCard: {
        padding: '1px 0px 1px 0px',
        width: '100%',
        minWidth: '500px',
        height: 350,
        borderRadius: '10px',
        border: `2px solid ${colors.vizerCoral.main}`,
    },
    exampleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: {
            xs: '15px auto',
            md: '0 auto',
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: {
            xs: 'column',
            md: 'row',
        },
    },
    contentContainer: {
        background: '#eee',
        padding: '1px 0px 1px 0px',
        maxWidth: 500,
        width: '100%',
        height: 350,
    },
})

export default useStyles
