import { put, takeEvery } from 'redux-saga/effects'
import { fetchUserShow } from '../services'
import {
    fetchUser,
    fetchUserResult,
    fetchUserError,
} from '../reducers/show'

export function* takeFetchUser(action) {
    try {
        const { payload } = yield fetchUserShow(action.payload)

        yield put(fetchUserResult(payload))
    } catch (error) {
        yield put(fetchUserError(error.toString()))
    }
}

export function* watchFetchUser() {
    yield takeEvery(fetchUser.toString(), takeFetchUser)
}
