import { useQueryBrands } from 'features/velocity/offer_partners/queries'

export const useBrands = () => {
    const result = useQueryBrands()
    const error = result.error

    if (error) return { connectionError: error, ...result }

    const { success, payload, errors, warnings, notices } = result.data || {}

    const brands = payload?.brands || []

    return {
        success,
        brands,
        errors,
        warnings,
        notices,
        ...result,
    }
}
