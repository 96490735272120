import { Typography } from '@mui/material'
import AdminVersion from 'components/AdminVersion'
import APIVersion from 'features/health_check/components'
import useStyles from './styles'

export const Versions = () => {
    const styles = useStyles()

    return (
        <div style={styles.versions}>
            <Typography variant='h3'>© 2022 Vizer Inc.</Typography>
            <AdminVersion />
            <APIVersion />
        </div>
    )
}

export default Versions
