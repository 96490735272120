import FieldWithTooltip from 'components/FieldWithTooltip'

const ImageFieldWithTooltip = ({ placement, setVisible, modal, children }) => {
    return (
        <FieldWithTooltip
            tooltip={'Filenames may include A-Z, a-z, 0-9, or the following symbols: ! - _ . * \' ( )'}
            placement={placement}
            setVisible={setVisible}
            modal={modal}
        >
            {children}
        </FieldWithTooltip >
    )
}

export default ImageFieldWithTooltip
