import { takeEvery, put, select } from '@redux-saga/core/effects'
import {
    fetchRolesList,
    fetchUserRolesList,
    fetchRoleRolesList,
} from '../services'
import {
    fetchRoles,
    fetchRolesResult,
    fetchRolesError,
} from '../reducers/list'

export function* takeFetchRoles() {
    try {
        const userID = yield select((state) => state.roles.list.userID)
        const roleID = yield select((state) => state.roles.list.roleID)

        let payload
        if (userID) {
            ({ payload } = yield fetchUserRolesList({ userID }))
        } else if (roleID) {
            ({ payload } = yield fetchRoleRolesList({ roleID }))
        } else {
            ({ payload } = yield fetchRolesList())
        }

        const { roles } = payload

        yield put(fetchRolesResult({ roles }))
    } catch (error) {
        yield put(fetchRolesError(error.toString()))
    }
}

export function* watchFetchRoles() {
    yield takeEvery(fetchRoles.toString(), takeFetchRoles)
}
