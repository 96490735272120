import { all } from 'redux-saga/effects'
import { watchFetchRoles } from './list'
import { watchFetchRole } from './show'
import { watchNewRole, watchCreateRole } from './create'
import { watchEditRole, watchUpdateRole } from './edit'

export default function* roles() {
    yield all([
        watchFetchRoles(),
        watchFetchRole(),
        watchNewRole(),
        watchCreateRole(),
        watchEditRole(),
        watchUpdateRole(),
    ])
}
