import { UPDATE_FIELD, UPDATE_STATE, RESET_FORM } from 'features/status'
import { useReducer } from 'reducerHelpers'
import { isPresent } from 'helpers'

export const shortLinkFormInitialState = (state = {}) => {
    return {
        offerID: null,
        form: {
            short_code: null,
            target: null,
            utm_source: null,
            utm_medium: null,
            utm_campaign: null,
            utm_content: null,
            utm_term: null,
        },
        ...state,
    }
}

export const shortLinkFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state.form[`${action.field}`] = action.value

            break
        }
        case UPDATE_STATE: {
            state[`${action.field}`] = action.value

            break
        }
        case RESET_FORM: {
            const { offerID } = action

            const form = shortLinkFormInitialState()

            if (isPresent(offerID)) {
                form.offerID = offerID
            }

            return form
        }
        default: {
            return state
        }
    }
}

export const useShortLinkFormReducer = (state = {}) => {
    return useReducer(shortLinkFormReducer, shortLinkFormInitialState(state))
}
