import { createSlice } from '@reduxjs/toolkit'
import {
    ERROR,
    FETCHING,
    PENDING,
    SUCCESS,
} from 'features/status'

const name = 'permissionShow'

const initialState = {
    status: PENDING,
    id: null,
    hasLoaded: false,
    permission: {},
    error: null,
}

const reducers = {
    fetchPermission: (state, { payload }) => {
        return {
            ...initialState,
            id: payload,
            status: FETCHING,
        }
    },
    fetchPermissionResult: (state, { payload }) => {
        const { permission } = payload

        state.status = SUCCESS
        state.hasLoaded = true
        state.permission = permission
    },
    fetchPermissionError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetPermission: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    fetchPermission,
    fetchPermissionResult,
    fetchPermissionError,
    resetPermission,
} = slice.actions

export default slice.reducer
