import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { client } from 'oxidauth'
import { PENDING } from 'features/status'
import { LOGGED_IN, LOGGED_OUT, VALIDATING } from '../states'

export const useToken = () => {
    const [token, setToken] = useState(null)
    const [status, setStatus] = useState(PENDING)

    const logout = () => {
        clearToken()

        return <Navigate to='/login' />
    }

    const fetchToken = async () => {
        await client.fetchValidJWT()
            .then((token) => {
                setToken(token)
                setStatus(LOGGED_IN)
            })
            .catch(() => {
                setStatus(LOGGED_OUT)
            })
    }

    const clearToken = async () => {
        await client.clearToken()
            .then(() => {
                setToken(null)
                setStatus(LOGGED_OUT)
            })
    }

    useEffect(() => {
        setStatus(VALIDATING)

        fetchToken()
    }, [])

    return {
        authenticated: status === LOGGED_IN,
        token,
        status,
        setStatus,
        fetchToken,
        clearToken,
        logout,
    }
}
