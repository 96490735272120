import { useQueryBannerRetailers } from 'features/velocity/banner_retailers/queries'

export const useBannerRetailers = (parentID) => {
    const result = useQueryBannerRetailers(parentID)
    const error = result.error

    if (error) return { connectionError: error, ...result }

    const { success, payload, errors, warnings, notices } = result.data || {}

    const bannerRetailers = payload?.banner_retailers || []

    return {
        success,
        bannerRetailers,
        errors,
        warnings,
        notices,
        ...result,
    }
}
