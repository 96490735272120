import { useReducer } from 'reducerHelpers'
import { UPDATE_FIELD, RESET_FORM } from 'features/status'

export const adminUserFormInitialState = (state = {}) => {
    return {
        first_name: null,
        last_name: null,
        email: null,
        ...state,
    }
}

export const adminUserFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state[`${action.field}`] = action.value

            break
        }
        case RESET_FORM: {
            return adminUserFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useAdminUserFormReducer = (state = {}) => {
    return useReducer(adminUserFormReducer, adminUserFormInitialState(state))
}
