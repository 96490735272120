import { Link } from 'react-router-dom'
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material'
import Row from 'components/Row'
import Cell from 'components/Cell'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import useStyles from './styles'

export const CatalogsList = ({
    connectionError, catalogs, errors,
    isError, isLoading, isFetching, isRefetching, isInitialLoading,
}) => {
    const styles = useStyles()

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Catalogs</Typography>
            <Link to={{ pathname: `/velocity/catalogs/new` }}>
                <Button
                    sx={styles.btn}
                    variant="contained"
                    color="secondary"
                    data-cy='new-catalog-btn'
                >
                    New
                </Button>
            </Link>
            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align='center'>Name</TableCell>
                            <TableCell align='center'>Tag</TableCell>
                            <TableCell align='center'>Description</TableCell>
                            <TableCell align='center'>Active</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        {catalogs.map(({ id, name, tag, description, active }) => (
                            <Row
                                key={id}
                                path={`/velocity/catalogs/${id}`}
                            >
                                <Cell align='center'>{id}</Cell>
                                <Cell align='center'>{name}</Cell>
                                <Cell align='center'>{tag}</Cell>
                                <Cell align='center'>{description}</Cell>
                                <Cell align='center'>{active ? 'active' : 'inactive'}</Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default CatalogsList
