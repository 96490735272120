import { useState } from 'react'
import {
    Button,
    Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { FormStyled, TextFieldStyled } from 'theme/forms'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import CopyButton from 'components/CopyButton'
import ConnectionError from 'components/ConnectionError'
import useStyles from './styles'

export const CustomerLookup = ({
    connectionError, errors, disabled, ingenID, onSubmit,
    success, isError, isLoading, isFetching, isRefetching, isInitialLoading,
}) => {
    const styles = useStyles()
    const [cardNumber, setCardNumber] = useState()

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography sx={styles.sectionTitle}>Find Customer IngenID by Card Number</Typography>
            <p style={styles.sectionDescription}>Obtain the Customer ID associated with a previously created card number</p>

            <div style={styles.toolbar}>
                <FormStyled
                    noValidate
                    autoComplete='off'
                    style={styles.form}
                    onSubmit={(e) => e.preventDefault()}
                    data-testid='offer-form'
                >
                    <TextFieldStyled
                        label='Card Number'
                        type='text'
                        data-cy='enter_card_number'
                        style={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={cardNumber || ''}
                        onChange={(e) => setCardNumber(e.target.value)}
                    />
                    <Button
                        type='submit'
                        data-cy='submit_offer'
                        variant='contained'
                        color='secondary'
                        sx={styles.searchBtn}
                        disabled={disabled}
                        onClick={() => onSubmit(cardNumber)}
                    >
                        <SearchIcon />
                    </Button>
                </FormStyled>
            </div>

            {ingenID &&
                <Typography sx={styles.searchResults}>
                    <span>{`Customer IngenID: ${ingenID}`}</span>

                    <CopyButton title={'ingen ID'} copy={ingenID} variant='icon' />
                </Typography>
            }

            {success && !ingenID &&
                <p style={styles.noResults}>No ID found. Please check the card number and try again.</p>
            }
        </>
    )
}

export default CustomerLookup
