import { UPDATE_FIELD, RESET_FORM } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const emailContainmentFormInitialState = (state = {}) => {
    return {
        emailPrefix: null,
        email: null,
        ...state,
    }
}

export const emailContainmentFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state[`${action.field}`] = action.value

            break
        }
        case RESET_FORM: {
            return emailContainmentFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useEmailContainmentFormReducer = (state = {}) => {
    return useReducer(emailContainmentFormReducer, emailContainmentFormInitialState(state))
}
