import { UPDATE_FIELD, RESET_FORM } from 'features/status'
import { useShortLinkFormReducer } from 'features/velocity/offers/reducers/short_link'
import { emptyToNull } from 'helpers'

export const useShortLinkForm = (state = {}) => {
    const [shortLinkState, dispatch] = useShortLinkFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })
    const updateOfferID = (value) => dispatch({ type: 'UPDATE_STATE', field: 'offerID', value })
    const resetForm = (offerID) => dispatch({ type: RESET_FORM, offerID })

    return {
        shortLinkState,
        updateField,
        updateOfferID,
        resetForm,
    }
}
