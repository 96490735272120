import { useQueryClient } from '@tanstack/react-query'
import { useQueryClientPortalUserShow } from 'features/velocity/client_portal_users/queries'
import { useQueryBrands } from 'features/velocity/offer_partners/queries'
import { isEmpty, isPresent, reduceQueries } from 'helpers'

export const useClientPortalUserShow = (clientPortalUserID) => {
    const queryClient = useQueryClient()

    const {
        payloads: [clientPortalUserPayload, brandsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [
            useQueryClientPortalUserShow(clientPortalUserID),
            useQueryBrands(),
        ],
    )

    const clientPortalUser = clientPortalUserPayload?.client_portal_user
    let portalUser

    if (isPresent(clientPortalUser)) {
        const offerPartners = clientPortalUser?.offer_partners?.map((offerPartner) => {
            if (isEmpty(offerPartner?.brand)) {
                return {
                    offer_partner: offerPartner,
                    brand: brandsPayload?.brands.find((brand) => brand.id === offerPartner?.brand_id),
                }
            } else {
                return offerPartner
            }
        })

        portalUser = { ...clientPortalUser, offer_partners: offerPartners }
    }

    const invalidateShowQueries = () => queryClient.invalidateQueries({ queryKey: ['client_portal_users', 'show', clientPortalUserID] })

    return {
        connectionError,
        success,
        clientPortalUser: portalUser,
        errors,
        warnings,
        notices,
        invalidateShowQueries,
        ...isResults,
    }
}
