import { typography } from 'theme'

export const classesFn = () => ({
    btn: {
        width: 100,
        margin: '5px',
    },
    title: {
        color: (theme) => theme.palette.text.primary,
        textAlign: 'center',
        margin: '10px',
        fontWeight: typography.h2.fontWeight,
        fontSize: typography.h2.fontSize,
    },
    page: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.paper,
        flexDirection: 'column',
        borderRadius: '10px',
        maxHeight: 700,
        maxWidth: 1000,
        padding: '30px',
        overflow: 'scroll',
    },
    messageText: (devMode) => ({
        color: (theme) => theme.palette.text.primary,
        textAlign: 'center',
        margin: '10px',
        fontWeight: devMode ? typography.h4.fontWeight : typography.h3.fontWeight,
        fontSize: devMode ? typography.h3.fontSize : typography.h3.fontSize,
    }),
    modal: {
        opacity: 0.98,
    },
})
