export const validateEmail = (email) => {
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return re.test(email)
}

export const validatePassword = (password) => {
    // 6 to 20 characters, contains at least one numeric digit, one uppercase and one lowercase letter
    const validation = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/

    return password.match(validation)
}
