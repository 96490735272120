import { useState } from 'react'
import {
    Button,
    TextField,
} from '@mui/material'

import { validateEmail } from 'utilities/user_validations'
import { classesFn } from '../styles'

const ForgotPasswordForm = () => {
    const classes = classesFn()
    const [email, setEmail] = useState('')

    const onForgotPassword = async () => {
        if (!validateEmail(email)) {
            alert('The email you entered is invalid')
            return
        }
    }

    return (
        <form style={classes.form}>
            <TextField
                required
                id='login-email-input'
                label='Email'
                onChange={(event) => setEmail(event.target.value)}
            />
            <Button
                disabled={!email}
                onClick={onForgotPassword}
                variant='contained'
                sx={classes.submitButton}
            >
                {`Submit Email`}
            </Button>
        </form>
    )
}

export default ForgotPasswordForm
