const useStyles = () => ({
    btn: {
        width: 'auto',
        minWidth: { xs: 20, sm: 0 },
    },
    bar: {
        padding: '20px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    paginationContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 10px',
        '& span': {
            padding: '0 10px',
            fontSize: '1rem',
        },
        '& p': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.3rem',
            margin: 0,
        },
    },
    input: {
        minWidth: 90,
        marginRight: '10px',
        '& input': {
            fontSize: '1.1rem',
        },
        '& label': {
            display: 'none',
            textTransform: 'capitalize',
        },
        '& fieldset': {
            top: 0,
            '& legend': {
                display: 'none',
            },
        },
        margin: { xs: 0, sm: 'initial' },
    },
})

export default useStyles
