import { velocityUrl, _get } from 'utils'  // eslint-disable-line

export async function fetchCustomerStatus({ ingenID }) {
    try {
        const url = await velocityUrl(`/customers/queries/fetch_customer_by_ingen_id/${ingenID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchCustomerActivity({ ingenID }) {
    try {
        const url = await velocityUrl(`/customers/queries/list_activity/${ingenID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchCustomerClips({ ingenID }) {
    try {
        const url = await velocityUrl(`/clips/queries/fetch_clips_by_customer/${ingenID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchCustomerByCardNumber(cardNumber) {
    try {
        const url = await velocityUrl(`/customers/queries/fetch_customer/${cardNumber}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchCustomerOfferClip({ ingenID, offerNumber }) {
    try {
        const url = await velocityUrl(`/clips/queries/fetch_clip_by_customer_and_offer/${ingenID}/${offerNumber}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
