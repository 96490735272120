const useStyles = () => ({
    field: {
        maxWidth: 350,
        minWidth: 350,
    },
    formSubmit: {
        paddingTop: '20px',
    },
    btn: {
        margin: '5px 10px',
        width: 350,
    },
})

export default useStyles
