import { takeEvery, put } from '@redux-saga/core/effects'
import {
    fetchPermission,
    fetchPermissionResult,
    fetchPermissionError,
} from '../reducers/show'
import { fetchPermissionShow } from '../services'

export function* takeFetchPermission(action) {
    try {
        const { payload } = yield fetchPermissionShow(action.payload)

        yield put(fetchPermissionResult(payload))
    } catch (error) {
        yield put(fetchPermissionError(error.toString()))
    }
}

export function* watchFetchPermission() {
    yield takeEvery(fetchPermission.toString(), takeFetchPermission)
}
