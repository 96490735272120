export const useStyles = (theme) => ({
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        height: 64,
        alignItems: 'center',
        paddingLeft: '25px',
        paddingRight: '25px',
        marginTop: '25px',
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.footerBorderTop.main}`,
    },
    copyright: {
        fontFamily: 'Avenir, sans-serif',
        fontSize: 16,
        color: (theme) => theme.palette.text.primary,
    },
    left: {
        display: 'flex',
    },
    right: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 120,
    },
})
