import { combineReducers } from 'redux'
import image_modal from './image_modal'
import graph_modal from './graph_modal'

const modals = combineReducers({
    image_modal,
    graph_modal,
})

export default modals
