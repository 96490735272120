import RetailersList from 'features/velocity/retailers/components/List'
import { useRetailers } from 'features/velocity/retailers/hooks/list'

const List = () => {
    const listRetailersResult = useRetailers()

    return (
        <RetailersList
            {...listRetailersResult}
        />
    )
}

export default List
