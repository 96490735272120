import { Button, Typography } from '@mui/material'
import { FormColumnStyled, FormStyled, SubmitBlockStyled, TextFieldStyled } from 'theme/forms'
import { PageStyled } from 'theme/page'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import useStyles from './styles'

export const AdminUserForm = ({
    connectionError, errors, isLoadingState,
    isLoadError, disabled, form, updateField, onSubmit,
}) => {
    const styles = useStyles()

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isLoadError) return <ConnectionError err={errors} />

    return (
        <PageStyled>
            <Typography variant='h1'>Create Admin User</Typography>

            <IsLoading isLoadingState={isLoadingState} />

            <FormStyled
                noValidate
                autoComplete='off'
                onSubmit={(e) => e.preventDefault()}
                data-testid='admin-users-form'
            >
                <FormColumnStyled>
                    <TextFieldStyled
                        label='First Name'
                        type='text'
                        data-cy='enter-first-name'
                        style={styles.field}
                        disabled={disabled}
                        value={form?.first_name || ''}
                        onChange={updateField('first_name')}
                    />
                    <TextFieldStyled
                        label='Last Name'
                        type='text'
                        data-cy='enter-last-name'
                        style={styles.field}
                        disabled={disabled}
                        value={form?.last_name || ''}
                        onChange={updateField('last_name')}
                    />
                    <TextFieldStyled
                        label='Email'
                        type='email'
                        data-cy='enter-email'
                        style={styles.field}
                        disabled={disabled}
                        value={form?.email || ''}
                        onChange={updateField('email')}
                    />
                </FormColumnStyled>

                <SubmitBlockStyled style={styles.formSubmit}>
                    <Button
                        type='submit'
                        data-cy='submit-user'
                        variant='contained'
                        color='secondary'
                        sx={styles.btn}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        Invite User
                    </Button>
                </SubmitBlockStyled>
            </FormStyled>
        </PageStyled>
    )
}

export default AdminUserForm
