import { useEffect } from 'react'
import { squashArr } from 'helpers'
import { useClientPortalUserForm } from 'features/velocity/client_portal_users/hooks/form'
import { useCreateClientPortalUserCmd } from 'features/velocity/client_portal_users/queries'
import { useOfferPartners } from 'features/velocity/offer_partners/hooks/filterless_list'

export const useCreateClientPortalUser = () => {
    const { form, updateField, updateOfferPartnersField, resetForm } = useClientPortalUserForm()
    const offerPartnersResult = useOfferPartners()
    const createResult = useCreateClientPortalUserCmd()

    const onSubmit = () => createResult.mutate(form)

    let offerPartners = offerPartnersResult?.success ? offerPartnersResult?.offerPartners : []
    offerPartners = offerPartners.map((p) => p.offer_partner)

    const warnings = squashArr([createResult.data?.warnings, offerPartnersResult?.warnings])
    const notices = squashArr([createResult.data?.notices, offerPartnersResult?.notices])
    const errors = squashArr([createResult?.error, createResult.data?.errors, offerPartnersResult?.errors])

    const isLoadingState = createResult.isLoading

    useEffect(() => {
        if (createResult.isSuccess) resetForm()
    }, [createResult.isSuccess])

    return {
        loadSuccess: offerPartnersResult?.success,
        offerPartners,
        errors,
        isLoadingState,
        warnings,
        notices,
        disabled: isLoadingState,
        form,
        updateField,
        updateOfferPartnersField,
        onSubmit,
    }
}
