import { useState } from 'react'
import { useQueryBrandShow } from 'features/brands/queries'
import { useQueryOfferPartners } from 'features/velocity/offer_partners/queries'
import { reduceQueries, isPresent } from 'helpers'

export const useBrandShow = (brandID) => {
    const [activeTab, setActiveTab] = useState(0)
    const {
        payloads: [brandPayload, offerPartnersPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryBrandShow(brandID), useQueryOfferPartners()],
    )

    const offerPartners = success ? offerPartnersPayload?.offer_partners : []
    const offerPartner = success ? offerPartners?.find((op) => op.brand_id == brandID) : {} // eslint-disable-line eqeqeq

    const brandWithOfferPartner = Object.assign({ offer_partner_id: offerPartner?.id }, brandPayload?.brand)

    const contentPresent = isPresent(brandWithOfferPartner?.client_portal_resource_content) ||
        isPresent(brandWithOfferPartner?.client_portal_success_center_content)

    const tabs = []

    if (contentPresent) {
        tabs.push('content')
    }

    return {
        connectionError,
        success,
        brand: brandWithOfferPartner,
        errors,
        warnings,
        notices,
        tabs,
        activeTab,
        setActiveTab,
        ...isResults,
    }
}
