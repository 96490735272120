import { brandsUrl as _url, _get, _post, _put } from 'utils'
import qs from 'qs'

export async function fetchBrandsList(pageQuery) {
    try {
        const url = await _url(`/brands?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}

export async function fetchBrandShow(brandId) {
    try {
        const url = await _url(`/brands/${brandId}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function createNewBrand({
    name, about, reward_cover_image_url, image, website, facebook,
    instagram, twitter, app_link, redemption_instructions,
    client_portal_resource_content, client_portal_success_center_content,
}) {
    try {
        const urlPath = await _url('/brands')

        const body = {
            brand: {
                name,
                about,
                reward_cover_image_url,
                website,
                facebook,
                instagram,
                twitter,
                app_link,
                redemption_instructions,
                client_portal_resource_content,
                client_portal_success_center_content,
            },
            image,
        }

        return await _post(urlPath, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function updateExistingBrand(id, {
    name, about, reward_cover_image_url, image, website, facebook,
    instagram, twitter, app_link, redemption_instructions,
    client_portal_resource_content, client_portal_success_center_content,
}) {
    try {
        const urlPath = await _url(`/brands/${id}`)

        const body = {
            brand: {
                name,
                about,
                reward_cover_image_url,
                website,
                facebook,
                instagram,
                twitter,
                app_link,
                redemption_instructions,
                client_portal_resource_content,
                client_portal_success_center_content,
            },
            image,
        }

        return await _put(urlPath, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
