import { colors } from 'theme'

const useStyles = () => ({
    banner: {
        position: 'relative',
        width: 375,
        marginTop: (props) => props.noSpacing ? 0 : '20px',
    },
    logo: {
        position: 'relative',
        borderRadius: '100%',
        height: 150,
        width: 150,
        marginTop: (props) => props.noSpacing ? 0 : '20px',
        '& img': {
            borderRadius: '100%',
            height: 150,
            width: 150,
        },
        '& > div': {
            borderRadius: '100%',
        },
    },
    icon: {
        position: 'relative',
        height: 100,
        width: 100,
        marginTop: (props) => props.noSpacing ? 0 : '20px',
        '& img': {
            height: 100,
            width: 100,
        },
    },
    cover: {
        minHeight: 100,
        width: 375,
        maxWidth: '100%',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        objectFit: 'contain',
        margin: (props) => props.noSpacing ? '0 auto' : '0 auto 20px',
    },
    expandButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: 40,
        width: 40,
        backgroundColor: 'rgba(209, 86, 86, .75)',
        color: colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

export default useStyles
