import { useMutation, useQuery } from '@tanstack/react-query'
import { useMutationDefaults, useQueryDefaults } from 'queryHelpers'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'
import { useRetailerForm } from 'features/velocity/retailers/hooks/form'
import {
    fetchRetailers,
    fetchIncommRetailers,
    fetchParentRetailers,
    fetchRetailersForOffer,
    fetchRetailerShow,
    createNewRetailer,
    updateExistingRetailer,
    generateStripImages,
} from '../services'

export const useQueryRetailers = (pageQuery, queryArgs = {}) => {
    const queryKey = ['retailers', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchRetailers(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryRetailerShow = (retailerID, queryArgs = {}) => {
    const queryKey = ['retailers', 'show', retailerID]

    return useQuery({
        queryKey,
        queryFn: () => fetchRetailerShow(retailerID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryIncommRetailers = () => {
    const queryKey = ['incommRetailers', 'list']

    return useQuery({
        queryKey,
        queryFn: () => fetchIncommRetailers(),
        ...useQueryDefaults(queryKey),
    })
}

export const useQueryParentRetailers = () => {
    const queryKey = ['parentRetailers', 'list']

    return useQuery({
        queryKey,
        queryFn: () => fetchParentRetailers(),
        ...useQueryDefaults(queryKey),
    })
}

export const useQueryRetailersForOffer = (offerId) => {
    const queryKey = ['retailersForOffer', 'list', offerId]

    return useQuery({
        queryKey,
        queryFn: () => fetchRetailersForOffer(offerId),
        ...useQueryDefaults(queryKey),
    })
}

export const useCreateRetailerCmd = () => {
    const { resetForm } = useRetailerForm()

    return useMutation({
        mutationFn: (form) => createNewRetailer(form),
        ...useMutationDefaults({
            queryKeys: [['retailers', 'list']],
            queryType: ['retailer', 'create'],
            navigationPath: '/velocity/retailers',
            reset: resetForm,
        }),
    })
}

export const useUpdateRetailerCmd = () => {
    const { resetForm } = useRetailerForm()

    return useMutation({
        mutationFn: (form) => updateExistingRetailer(form),
        ...useMutationDefaults({
            queryKeys: [
                ['retailers', 'list'],
                ['retailers', 'show', 'id'],
            ],
            queryType: ['retailer', 'update'],
            navigationPath: '/velocity/retailers',
            reset: resetForm,
        }),
    })
}

export const useGenerateStripImagesCmd = (setGeneratingImages, retailer_id) => {
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (retailerId) => generateStripImages(retailerId),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                setGeneratingImages(false)

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `${retailer_id}-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `${retailer_id}-${warn}` }))
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `retailers-${err}` })
                })
            }
        },
        onError: (data) => {
            setGeneratingImages(false)

            addNotification({ message: data.toString(), type: 'error', id: `retailers-${data.toString()}` })
        },
    })
}
