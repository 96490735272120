import { Grid } from '@mui/material'
import AnalyticsGraph from '../Graph'
import { DashboardStyled } from './styles'

export const GraphDashboard = ({
    exportGraph, presentData, clicks, clips, scansAndRefunds, conversionRates, lineColors, gridValue,
    clicksSpacing, clipsSpacing, scansSpacing, conversionsSpacing,
}) => {
    const graphParams = { exportGraph, lineColors }

    return (
        <DashboardStyled container>
            {presentData[0] &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={gridValue}>
                    <AnalyticsGraph graphTitle={'Clicks'} graphData={clicks} tickLabelSpacing={clicksSpacing} {...graphParams} />
                </Grid>
            }
            {presentData[1] &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={gridValue}>
                    <AnalyticsGraph graphTitle={'Clips'} graphData={clips} tickLabelSpacing={clipsSpacing} {...graphParams} />
                </Grid>
            }
            {presentData[2] &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={gridValue}>
                    <AnalyticsGraph graphTitle={'Scans & Refunds'} graphData={scansAndRefunds} tickLabelSpacing={scansSpacing} {...graphParams} />
                </Grid>
            }
            {presentData[3] &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={gridValue}>
                    <AnalyticsGraph
                        graphTitle={'Conversion Rates'}
                        graphData={conversionRates}
                        tickLabelSpacing={conversionsSpacing}
                        conversion={true}
                        {...graphParams}
                    />
                </Grid>
            }
        </DashboardStyled>
    )
}

export default GraphDashboard
