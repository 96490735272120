import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'
import { isEmpty } from 'helpers'
import { useTheme } from '@mui/material/styles'

const CustomAlert = ({ handleClick, message, severity, spacing, handleClose, clearProps }) => {
    return (
        <Snackbar
            style={{ marginBottom: `${spacing}px`, cursor: isEmpty(handleClick) ? 'auto' : 'pointer' }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open
            onClick={handleClick}
        >
            <Alert
                elevation={6}
                onClose={handleClose}
                severity={severity}
                variant='filled'
                {...clearProps}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export const PageNotifications = () => {
    const { pageNotifications, clearNotification, clearNotifications } = usePageNotifications()
    const theme = useTheme()

    const handleClick = (_, reason, message) => {
        if (reason === 'clickaway') return

        if (message) {
            clearNotification(message)
            return
        }

        clearNotifications()
    }

    return (
        <>
            {pageNotifications?.length > 1 &&
                <CustomAlert
                    handleClick={handleClick}
                    message={'Clear All'}
                    spacing={(pageNotifications?.length * 60)}
                    clearProps={{
                        color: 'info',
                        icon: false,
                        variant: 'outlined',
                        style: { background: '#fff', color: theme.palette.text.secondary },
                    }}
                />
            }

            {pageNotifications?.map((message, index) => (
                <CustomAlert
                    key={message.id}
                    handleClose={(e) => handleClick(e, null, message)}
                    message={message.message}
                    spacing={(index * 60)}
                    severity={message.type}
                />
            ))}
        </>
    )
}

export default PageNotifications
