import { useState } from 'react'
import {
    Button,
    Divider,
    Tooltip,
    Typography,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import SearchIcon from '@mui/icons-material/Search'
import { FormStyled, TextFieldStyled } from 'theme/forms'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import CopyButton from 'components/CopyButton'
import ConnectionError from 'components/ConnectionError'
import useStyles from './styles'

export const InCommDebug = ({
    connectionError, errors, disabled, customerStatus, form, customerState,
    updateField, onSubmit, activity, activityObject, activityStatus, clips, offerInfo,
    onOfferSubmit, isError, isLoading, isFetching, isRefetching, isInitialLoading,
}) => {
    const styles = useStyles()

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography sx={styles.sectionTitle}>View Customer Data</Typography>
            <p style={styles.sectionDescription}>Customer activity and associated offer clips</p>

            <div style={styles.toolbar}>
                <FormStyled
                    noValidate
                    autoComplete='off'
                    style={styles.form}
                    onSubmit={(e) => e.preventDefault()}
                    data-testid='offer-form'
                >
                    <TextFieldStyled
                        label='Customer ID'
                        type='text'
                        data-cy='enter_customer_id'
                        style={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form.ingenID || ''}
                        onChange={updateField('ingenID')}
                    />
                    <Button
                        type='submit'
                        data-cy='submit_offer'
                        variant='contained'
                        color='secondary'
                        sx={styles.searchBtn}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        <SearchIcon />
                    </Button>
                </FormStyled>
            </div>

            {customerStatus &&
                <JSONOutput
                    title={'Customer Status'}
                    description={'Status of customer with Incomm'}
                    json={customerStatus}
                    tooltip={
                        `Status designates the customer can perform POS transactions and clip offers. A null value means status is set to ENABLED.

Persistent indicates the Incomm Archive service will not remove the customer record. A null value means persistent is set to false.`
                    }
                />
            }

            {(activity.length > 0 || activityObject) &&
                <JSONOutput
                    title={'Customer Activity'}
                    description={'History of all actions associated with this customer'}
                    json={activity}
                    activity={true}
                    activityStatus={activityStatus}
                />
            }

            {clips.length > 0 &&
                <JSONOutput
                    title={'Customer\'s Clips'}
                    description={'Offer numbers previously clipped to a customer'}
                    json={clips}
                />
            }

            {customerState.submittedID && activity.length === 0 &&
                <p style={styles.noResults}>{`No activity was found for customer ${customerState.submittedID}`}</p>
            }

            {customerState.submittedID && clips.length === 0 &&
                <p style={styles.noResults}>{`No clips were found for customer ${customerState.submittedID}`}</p>
            }

            {customerState.submittedID && activity.length !== 0 &&
                <>
                    <Divider sx={styles.divider}></Divider>

                    <Typography variant='h1' align='center'>{`Search Offer Clipped to Customer #${customerState.submittedID}`}</Typography>

                    <div style={styles.toolbar}>
                        <FormStyled
                            noValidate
                            autoComplete='off'
                            style={styles.form}
                            onSubmit={(e) => e.preventDefault()}
                            data-testid='offer-form'
                        >
                            <TextFieldStyled
                                label='Offer Number'
                                type='text'
                                data-cy='enter_offer_number'
                                sx={styles.field}
                                InputLabelProps={{ shrink: true }}
                                disabled={disabled}
                                value={form.offerNumber || ''}
                                onChange={updateField('offerNumber')}
                            />
                            <Button
                                type='submit'
                                data-cy='submit_offer'
                                variant='contained'
                                color='secondary'
                                sx={styles.searchBtn}
                                disabled={disabled}
                                onClick={onOfferSubmit}
                            >
                                <SearchIcon />
                            </Button>
                        </FormStyled>
                    </div>

                    {offerInfo &&
                        <JSONOutput
                            title={'Offer Instance'}
                            description={'Statistics for an instance of an offer previously clipped to a customer'}
                            json={offerInfo}
                        />
                    }
                </>
            }
        </>
    )
}

export const JSONOutput = ({ title, description, json, tooltip, activity, activityStatus }) => {
    const styles = useStyles()

    return (
        <>
            <Typography sx={styles.sectionTitle}>{title}</Typography>
            <div style={styles.descriptionContainer}>
                <p style={styles.sectionDescription}>{description}</p>
                {tooltip &&
                    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>} placement='right'>
                        <InfoIcon />
                    </Tooltip>
                }
            </div>

            <div style={styles.jsonContainer}>
                <div style={styles.jsonCopy}>
                    <CopyButton title={title} copy={JSON.stringify(json, null, 4)} />
                </div>

                {activity ? (
                    <>
                        {activityStatus && <pre style={styles.activityStatus}>{JSON.stringify(activityStatus, null, 4)}</pre>}
                        {json.activity.map((act) => (
                            <CollapsibleActivity key={`${act.action}+${act.traceId}`} activity={act} />
                        ))}
                    </>
                ) : (
                    <>
                        {activityStatus && <pre style={styles.activityStatus}>{JSON.stringify(activityStatus, null, 4)}</pre>}
                        <pre style={styles.json}>{JSON.stringify(json, null, 4)}</pre>
                    </>
                )}
            </div>
        </>
    )
}

export const CollapsibleActivity = ({ activity }) => {
    const [collapsed, setCollapsed] = useState(true)
    const styles = useStyles()

    return (
        <div
            onClick={() => {
                setCollapsed(!collapsed)
            }}
        >
            {collapsed ? (
                <div style={styles.action}>
                    <ArrowRightIcon style={styles.arrow} /><pre style={styles.json}>{`{ "action": "${activity.action}" }`}</pre>
                </div>
            ) : (
                <div style={styles.action}>
                    <ArrowDropDownIcon style={styles.arrow} /><pre style={styles.json} key={activity.traceId}>{JSON.stringify(activity, null, 4)}</pre>
                </div>
            )}
        </div>
    )
}

export default InCommDebug
