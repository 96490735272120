import { put, takeEvery, select } from 'redux-saga/effects'
import { createNewRole, createNewUserRole, createNewRoleRole } from '../services'
import {
    newRole,
    newRoleResult,
    newRoleError,
    createRole,
    createRoleResult,
    createRoleError,
} from '../reducers/create'

export function* takeNewRole() {
    try {
        yield put(newRoleResult())
    } catch (error) {
        yield put(newRoleError(error.toString()))
    }
}

export function* watchNewRole() {
    yield takeEvery(newRole.toString(), takeNewRole)
}

export function* takeCreateRole() {
    try {
        const form = yield select((state) => state.roles.create.role)
        const id = yield select((state) => state.roles.create.id)
        const userID = yield select((state) => state.roles.create.role.user_id)
        const roleID = yield select((state) => state.roles.create.role.role_id)

        let payload
        if (userID) {
            ({ payload } = yield createNewUserRole(userID, id))
        } else if (roleID) {
            ({ payload } = yield createNewRoleRole(roleID, id))
        } else {
            ({ payload } = yield createNewRole(form))
        }

        yield put(createRoleResult({ role: payload }))
    } catch (error) {
        yield put(createRoleError(error.toString()))
    }
}

export function* watchCreateRole() {
    yield takeEvery(createRole.toString(), takeCreateRole)
}
