import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { useEventDeadletterCountsByDate } from 'features/velocity/events/hooks/event_deadletters'
import useStyles from './styles'

const EventDeadletterCountsByDate = () => {
    const styles = useStyles()
    const navigate = useNavigate()
    const params = useEventDeadletterCountsByDate()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <Typography variant='h2'>Event Deadletter Count By Date</Typography>
        </>
    )
}

export default EventDeadletterCountsByDate
