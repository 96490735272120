import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'contexts'
import { isPresent, reduceQueries } from 'helpers'
import { useToken } from 'features/auth/hooks/token'
import { useAcceptInvitationCmd, useQueryUserByInvitationID } from '../queries'
import { useInvitationForm } from './form'

export const useAcceptInvitation = (invitationID, state = {}) => {
    const { form, setField, setForm } = useInvitationForm(state)
    const navigate = useNavigate()
    const { clearToken } = useContext(AuthContext)
    const { authenticated } = useToken()

    const {
        payloads: [userPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryUserByInvitationID(invitationID)],
    )
    const acceptInvitationResult = useAcceptInvitationCmd()

    const { user } = userPayload || {}

    const preventSubmit = !Object.values(form).every((val) => isPresent(val))

    const acceptInvitation = () => acceptInvitationResult.mutate({ form, invitationID })

    useEffect(() => {
        if (authenticated) clearToken()

        if (user) setForm(user)
    }, [authenticated, user])

    useEffect(() => {
        if (errors !== null && errors?.length !== 0) {
            navigate('/login', { replace: true })
        }
    }, [errors])

    return {
        form,
        errors,
        success,
        disabled: isResults.isLoading,
        preventSubmit,
        connectionError,
        notices,
        warnings,
        ...isResults,
        setField,
        acceptInvitation,
    }
}
