import { takeEvery, put, select } from 'redux-saga/effects'
import {
    updateExistingPermission,
    fetchPermissionShow,
} from '../services'
import {
    editPermission,
    editPermissionResult,
    editPermissionError,
    updatePermission,
    updatePermissionResult,
    updatePermissionError,
} from '../reducers/edit'

export function* takeEditPermission(action) {
    try {
        const { payload } = yield fetchPermissionShow(action.payload)

        yield put(editPermissionResult(payload))
    } catch (error) {
        yield put(editPermissionError(error.toString()))
    }
}

export function* watchEditPermission() {
    yield takeEvery(editPermission.toString(), takeEditPermission)
}

export function* takeUpdatePermission() {
    try {
        const id = yield select((state) => state.permissions.edit.id)
        const form = yield select((state) => state.permissions.edit.permission)

        const { payload } = yield updateExistingPermission(id, form)

        yield put(updatePermissionResult({ permission: payload }))
    } catch (error) {
        yield put(updatePermissionError(error.toString()))
    }
}

export function* watchUpdatePermission() {
    yield takeEvery(updatePermission.toString(), takeUpdatePermission)
}
