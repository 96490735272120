import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const offerPartnerFormInitialState = (state = {}) => {
    return {
        name: null,
        brand_id: null,
        status: 'active',
        logo_original: null,
        logo_1x: null,
        logo_2x: null,
        logo_3x: null,
        ...state,
    }
}

export const offerPartnerFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state[`${action.field}`] = action.value

            break
        }
        case SET_FORM: {
            return action.value
        }
        case RESET_FORM: {
            return offerPartnerFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useOfferPartnerFormReducer = (state = {}) => {
    return useReducer(offerPartnerFormReducer, offerPartnerFormInitialState(state))
}
