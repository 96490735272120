import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Typography,
} from '@mui/material'
import useStyles from './styles'

const InCommRules = () => {
    const styles = useStyles()

    return (
        <>
            <Typography variant='h1' align='center'>InComm Offer Rules</Typography>
            <div style={styles.rulesBlock}>
                <Typography variant='h2'>Rules are a four-character tag that defines offer behavior:</Typography>

                <Rules style={styles} />
            </div>

            <div style={styles.rulesBlock}>
                <Typography variant='h2'>Valid Rule Combinations:</Typography>

                <RulesCombos style={styles} />
            </div>
        </>
    )
}

const Rules = () => {
    const rules = [
        {
            position: '1',
            meaning: 'Qualify Unit',
            fields: 'Buy Quantity',
            notes: `Q = Quantity, $ = Amount; offer.buyQuantity is the number of units when this value is equal to 'Q,' but when equal to '$,' offer.buyQuantity refers to the amount in cents.`, // eslint-disable-line max-len
        },
        {
            position: '2',
            meaning: 'Redeeming Action',
            fields: '--',
            notes: `G = 'Get' (the reward)`,
        },
        {
            position: '3',
            meaning: 'Discount or Reward Unit',
            fields: 'Value',
            notes: `Q = Quantity (free units), $ = Amount; Offer's value is the number of free units when this value is equal to 'Q,' but when equal to '$,' offer.value refers to max cents off.`, // eslint-disable-line max-len
        },
        {
            position: '4',
            meaning: 'Reward Item',
            fields: 'Primary UPCs, Secondary UPCs',
            notes: `R = Primary UPCs, Y = Secondary UPCs; Offer's primaryUpcs are both the qualifiers and receive the discount when this value is equal to 'R,' but when equal to 'Y,' primaryUpcs are the qualifiers and secondaryUpcs receive the discount. If the value is 'Y' and the secondaryUpcs list is empty, then the service will revert internally to 'R.'`, // eslint-disable-line max-len
        },
    ]

    return (
        <TableContainer component={Paper}>
            <Table aria-label='simple table' data-cy='table'>
                <TableHead data-cy='head'>
                    <TableRow>
                        <TableCell align='center'>Position</TableCell>
                        <TableCell align='center'>Meaning</TableCell>
                        <TableCell align='center'>Related Field</TableCell>
                        <TableCell align='center'>Values/Notes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-cy='body'>
                    {rules.map(({ position, meaning, fields, notes }) => (
                        <TableRow key={position}>
                            <TableCell scope='rule'>{position}</TableCell>
                            <TableCell align='left'>{meaning}</TableCell>
                            <TableCell align='left'>{fields}</TableCell>
                            <TableCell align='left'>{notes}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const RulesCombos = () => {
    const rules = [
        {
            rule: 'QGQR',
            description: 'Buy a quantity of qualifying items, get a quantity of those as a reward.',
            example: 'Buy three Nivea Skin Cremes, and get one of those for free',
        },
        {
            rule: 'QGQY',
            description: 'Buy a quantity of qualifying items, get a quantity of reward items.',
            example: 'Buy two Nivea Skin Cremes, and get one Nivea Body Lotion',
        },
        {
            rule: 'QG$R',
            description: 'Buy a quantity of qualifying items, get $ off those items.',
            example: 'Buy three Nivea Skin Cremes, and get $1 off. Buy three Nivea Skin Cremes, and get $1 off each.',
        },
        {
            rule: 'QG$Y',
            description: 'Buy a quantity of qualifying items, get $ off reward items.',
            example: 'Buy two Nivea Skin Cremes, and get $0.50 off Nivea Body Lotion',
        },
        {
            rule: '$GQR',
            description: 'Buy a certain $ amount or more of qualifying items, get a quantity of those qualifying items rewarded.',
            example: 'Spend $50 or more on Nivea Skin Creme, and get two of those for free',
        },
        {
            rule: '$GQY',
            description: 'Buy a certain $ amount or more of qualifying items, get a quantity of reward items.',
            example: 'Spend $20 or more on Nivea Skin Creme, and get one Nivea Body Lotion for free',
        },
        {
            rule: '$G$R',
            description: 'Buy a certain $ amount or more of qualifying items, get $ off those items.',
            example: 'Spend $40 or more on Nivea Skin Creme, and get $5 off those items',
        },
        {
            rule: '$G$Y',
            description: 'Buy a certain $ amount or more of qualifying items, get $ off reward items.',
            example: 'Spend $40 or more on Nivea Skin Creme, and get $2 off a Nivea Body Lotion',
        },
    ]

    return (
        <TableContainer component={Paper}>
            <Table aria-label='simple table' data-cy='table'>
                <TableHead data-cy='head'>
                    <TableRow>
                        <TableCell align='center'>Rules</TableCell>
                        <TableCell align='center'>Description</TableCell>
                        <TableCell align='center'>Example</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-cy='body'>
                    {rules.map(({ rule, description, example }) => (
                        <TableRow key={rule}>
                            <TableCell scope='rule' align='left'>{rule}</TableCell>
                            <TableCell align='left'>{description}</TableCell>
                            <TableCell align='left'>{example}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default InCommRules
