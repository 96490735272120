import { colors } from 'theme'

const useStyles = () => ({
    divider: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: colors.black,
        margin: '15px',
    },
    pageHeader: {
        marginBottom: '30px',
    },
    btn: {
        fontWeight: 500,
        '& svg': {
            marginLeft: '10px',
        },
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '10px',
        justifyContent: 'space-between',
        flexWrap: { xs: 'wrap', md: 'nowrap' },
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '20px',
        '& h1': {
            margin: 0,
        },
        flexWrap: { xs: 'wrap', md: 'nowrap' },
    },
    headerButtons: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        '& > button': {
            marginRight: '10px',
        },
    },
    link: {
        textDecoration: 'none',
        color: colors.black,
    },
    pageRow: {
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
    },
    pageColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 15px',
        minWidth: '33%',
        maxWidth: '50%',
        '&:nth-of-type(1)': {
            paddingLeft: 0,
        },
        '&:nth-of-type(4)': {
            flexGrow: 1,
            paddingRight: 0,
        },
    },
    status: {
        borderRadius: '6px',
        textAlign: 'center',
        width: '100%',
        fontSize: 24,
        padding: '13px 15px 9px',
        margin: '0 0 15px',
        fontWeight: 600,
        display: 'flex',
    },
    detailsCard: {
        border: (theme) => `1px solid ${theme.palette.text.primary}`,
        borderTopLeftRadius: 0,
        padding: '20px 15px',
    },
    table: {
        background: colors.vizerSlateGray.light,
    },
    tabs: {
        background: 'none',
        boxShadow: 'none',
    },
    panel: {
        padding: 0,
    },
    tab: {
        color: (theme) => theme.palette.text.primary,
        border: (theme) => `1px solid ${theme.palette.text.primary}`,
        borderBottom: 0,
        borderTopRightRadius: 10,
        borderLeft: 0,
        position: 'relative',
        overflow: 'visible',
        '&:first-of-type': {
            borderLeft: (theme) => `1px solid ${theme.palette.text.primary}`,
            borderTopLeftRadius: 10,
        },
        '&::after': {
            position: 'absolute',
            top: -1,
            right: -1,
            width: 10,
            height: 1,
            background: (theme) => theme.palette.text.primary,
            content: '""',
            display: 'block',
            opacity: '85%',
        },
        '&:last-of-type::after': {
            display: 'none',
        },
    },
})

export default useStyles
