import { useEffect } from 'react'
import { useCreateCatalogItemCmd } from 'features/velocity/catalogs/queries'
import { useCatalogForm } from './form'

export const useCreateCatalogItem = (catalogID) => {
    const {
        form,
        updateField,
        resetForm,
    } = useCatalogForm()

    const createLinkResult = useCreateCatalogItemCmd()

    const notices = createLinkResult.data?.notices
    const warnings = createLinkResult.data?.warnings
    const errors = createLinkResult?.data?.errors

    const isLoadingState = createLinkResult.isLoading

    useEffect(() => {
        if (createLinkResult?.isSuccess) resetForm()
    }, [createLinkResult.isSuccess])

    const onSubmit = () => {
        createLinkResult.mutate({ form, catalogID })
    }

    return {
        errors,
        notices,
        warnings,
        form,
        disabled: isLoadingState,
        isLoadingState,
        updateField,
        onSubmit,
        resetForm,
    }
}
