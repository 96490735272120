import { Link } from 'react-router-dom'
import { Box, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import useStyles from './styles'

const FieldWithTooltip = ({ tooltip, placement, setVisible, modal, path, children }) => {
    const styles = useStyles()

    const stopProp = !modal ? ({
        onClick: (e) => e.stopPropagation(),
        style: { cursor: 'default' },
    }) : {}

    return (
        <Box sx={styles.fieldContainer}>
            <Box sx={styles.tooltip(modal)}>
                <Tooltip
                    title={tooltip}
                    placement={placement || 'left'}
                    onClick={modal ? () => setVisible(true) : null}
                >
                    {path ? (
                        <Link to={path} target='_blank'>
                            <InfoIcon sx={styles.infoIcon} />
                        </Link>
                    ) : (
                        <InfoIcon sx={styles.infoIcon} {...stopProp} />
                    )}
                </Tooltip>
            </Box>

            {children}
        </Box>
    )
}

export default FieldWithTooltip
