import { FormControl, FormLabel, TextField } from '@mui/material'
import useStyles from './styles'

const InputWithLabel = (props) => {
    const styles = useStyles()

    return (
        <FormControl>
            <FormLabel htmlFor={props.id} sx={styles.label} required={props.required}>{props.label}</FormLabel>

            <TextField
                {...props}
                label={null}
            />
        </FormControl>
    )
}

export default InputWithLabel
