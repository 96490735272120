import { colors } from 'theme'

const useStyles = () => ({
    link: {
        textDecoration: 'none',
        color: colors.black,
    },
    divider: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: (theme) => theme.palette.text.primary,
        margin: '25px',
        width: 'auto',
        '&.MuiDivider-root': {
            display: 'flex',
        },
    },
    detailsCard: {
        border: (theme) => `2px solid ${theme.palette.text.primary}`,
        borderTopLeftRadius: 0,
    },
    detailsContainer: {
        padding: 0,
        gridTemplateColumns: { xs: '1fr', md: 'repeat(12, 1fr)' },
        gridTemplateRows: '1fr, 1fr',
    },
    detailsBlock: {
        padding: '30px',
        '&:nth-of-type(1)': {
            gridRow: 'auto',
            paddingBottom: '20px',
            borderBottom: `2px solid ${colors.vizerIceGray.main}`,
        },
        '&:nth-of-type(2)': {
            gridRow: 'span 2',
            background: (theme) => theme.palette.background.default,
            paddingLeft: { lg: '30px' },
            borderLeft: { lg: `2px solid ${colors.vizerIceGray.main}` },
            borderBottom: { xs: `2px solid ${colors.vizerIceGray.main}`, lg: 0 },
        },
        '&:nth-of-type(3)': {
            gridRow: 'auto',
            paddingTop: '30px',
        },
    },
    listRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflowWrap: 'break-word',
        '& > div': {
            width: '50%',
        },
    },
    tabs: {
        background: 'none',
        boxShadow: 'none',
    },
    tabContainer: {
        margin: '0 auto',
        maxWidth: { lg: 1200, xl: 1600 },
        '& > hr': {
            display: { xs: 'none', lg: 'initial' },
        },
    },
    miniDivider: {
        border: `1px solid ${colors.vizerSlateGray.main}`,
        margin: '10px 0 20px',
    },
    panel: {
        padding: 0,
    },
    tab: {
        color: (theme) => theme.palette.text.primary,
        border: (theme) => `2px solid ${theme.palette.text.primary}`,
        borderBottom: 0,
        borderTopRightRadius: 10,
        borderLeft: 0,
        position: 'relative',
        overflow: 'visible',
        '&:first-of-type': {
            borderLeft: (theme) => `2px solid ${theme.palette.text.primary}`,
            borderTopLeftRadius: 10,
        },
        '&::after': {
            position: 'absolute',
            top: -2,
            right: -2,
            width: 10,
            height: 2,
            background: (theme) => theme.palette.text.primary,
            content: '""',
            display: 'block',
            opacity: '85%',
        },
        '&:last-of-type::after': {
            display: 'none',
        },
    },
    table: {
        background: colors.vizerSlateGray.light,
        marginBottom: '15px',
        overflow: 'auto',
    },
    accordionContainer: {
        marginTop: '15px',
    },
    accordion: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    upc: {
        display: 'block',
        margin: 0,
        width: '50%',
    },
    templateContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: 2100,
        margin: '0 auto',
    },
})

export default useStyles
