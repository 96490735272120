import { useState } from 'react'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
} from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import AlarmIcon from '@mui/icons-material/Alarm'
import AlarmOffIcon from '@mui/icons-material/AlarmOff'
import { lastXChar, isEmpty } from 'helpers'
import { StatusChipStyled } from 'theme/styled_components'
import Row from 'components/Row'
import Cell from 'components/Cell'
import VelocityLogo from 'components/VelocityLogo'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import { OfferListAnalyticsRow } from 'features/velocity/offers/components/OfferListAnalyticsRow'
import { DETAILS, ANALYTICS } from 'features/status'
import useStyles from './styles'

export const OffersList = ({
    offers, filters, pagination, pageState, setPage, updateFilter,
    search, setSearch, resetFilters, disabled, resetFiltersDisabled,
    refresh, show,
}) => {
    const styles = useStyles()

    const [view, setView] = useState(DETAILS)

    const handleView = (_, newView) => {
        setView(newView)
    }

    return (
        <>
            <Filter
                pageState={pageState}
                filters={filters}
                pagination={pagination}
                setPage={setPage}
                updateFilter={updateFilter}
                search={search}
                setSearch={setSearch}
                resetFilters={resetFilters}
                disabled={disabled}
                resetFiltersDisabled={resetFiltersDisabled}
                refresh={refresh}
            />

            <FormControl component='fieldset'>
                <FormLabel component='legend'>View</FormLabel>
                <RadioGroup row aria-label='view' name='view' value={view} defaultValue={DETAILS} onChange={handleView}>
                    <FormControlLabel
                        value={DETAILS}
                        control={<Radio />}
                        label='Details'
                    />
                    <FormControlLabel
                        value={ANALYTICS}
                        control={<Radio />}
                        label='Analytics'
                    />
                </RadioGroup>
            </FormControl>

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        {view === DETAILS &&
                            <TableRow>
                                <TableCell align='center'>Name (ID)</TableCell>
                                <TableCell align='center'>Live</TableCell>
                                <TableCell align='center'>Status</TableCell>
                                <TableCell align='center'>InComm Status</TableCell>
                                <TableCell align='center'>InComm#</TableCell>
                                <TableCell align='center'>Type</TableCell>
                                {isEmpty(show) && <TableCell align='center'>Offer Partner</TableCell>}
                            </TableRow>
                        }
                        {view === ANALYTICS &&
                            <TableRow>
                                <TableCell align='center'>Name (ID)</TableCell>
                                <TableCell align='center'>Live</TableCell>
                                <TableCell align='center'>Total Clicks</TableCell>
                                <TableCell align='center'>Total Clips</TableCell>
                                <TableCell align='center'>Total Scans</TableCell>
                                <TableCell align='center'>Total Refunds</TableCell>
                            </TableRow>
                        }
                    </TableHead>

                    {offers.length > 0 && view === DETAILS &&
                        <TableBody data-cy='body'>
                            {offers.map(({ id, ingen_offer_number, name, offer_partner, incomm_status, status, strategy_type, currently_live }) => (
                                <Row
                                    key={id}
                                    path={`/velocity/offers/${id}`}
                                >
                                    <Cell align='center'>{`${name} (${lastXChar(id, 8)})`}</Cell>
                                    <Cell align='center'>
                                        <StatusChipStyled
                                            variant='icon'
                                            active={currently_live}
                                            label={currently_live ? (
                                                <AlarmIcon sx={styles.liveIcon} />
                                            ) : (
                                                <AlarmOffIcon sx={styles.liveIcon} />
                                            )}
                                        />
                                    </Cell>
                                    <Cell align='center'>
                                        <StatusChipStyled
                                            active={status === 'active'}
                                            variant='icon'
                                            label={
                                                <VelocityLogo fill={status === 'active' ? '#fff' : '#272727'} size={24} />
                                            }
                                        />
                                    </Cell>
                                    <Cell align='center'>
                                        {incomm_status &&
                                            <StatusChipStyled
                                                incomm
                                                status={incomm_status}
                                                variant='sm'
                                                label={
                                                    <>
                                                        <Box sx={styles.incommLogo} />
                                                        {incomm_status}
                                                    </>
                                                }
                                            />
                                        }
                                    </Cell>
                                    <Cell align='center'>{`${ingen_offer_number || 'N/A'}`}</Cell>
                                    <Cell align='center'>{strategy_type}</Cell>
                                    {isEmpty(show) &&
                                        <Cell
                                            align='center'
                                            sx={styles.cellLink}
                                            path={`/velocity/offer_partners/${offer_partner.id}`}
                                        >
                                            {`${offer_partner.name} (${lastXChar(offer_partner.id, 8)})`}
                                        </Cell>
                                    }
                                </Row>
                            ))}
                        </TableBody>
                    }
                    {offers.length > 0 && view === ANALYTICS &&
                        <TableBody data-cy='body'>
                            {offers.map(({ id, name, strategy_type, currently_live }) => (
                                <OfferListAnalyticsRow
                                    key={id}
                                    id={id}
                                    name={name}
                                    strategy_type={strategy_type}
                                    currently_live={currently_live}
                                />
                            ))}
                        </TableBody>
                    }
                </Table>
            </TableContainer>

            {offers.length === 0 &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No offers found matching your selection.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={disabled}
            />
        </>
    )
}

export default OffersList
