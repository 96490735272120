import { useMutation, useQuery } from '@tanstack/react-query'
import { useMutationDefaults, useQueryDefaults } from 'queryHelpers'
import {
    fetchHelpFormRequests,
    fetchHelpFormRequestShow,
    updateExistingHelpFormRequest,
} from '../services'

export const useQueryHelpFormRequests = (pageQuery, queryArgs = {}) => {
    const queryKey = ['help_form_requests', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchHelpFormRequests(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryHelpFormRequestShow = (helpFormRequestID, queryArgs = {}) => {
    const queryKey = ['help_form_request', 'show', helpFormRequestID]

    return useQuery({
        queryKey,
        queryFn: () => fetchHelpFormRequestShow(helpFormRequestID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useUpdateHelpFormRequestCmd = () => (
    useMutation({
        mutationFn: (form) => updateExistingHelpFormRequest(form),
        ...useMutationDefaults({
            queryKeys: [
                ['help_form_requests', 'list'],
                ['help_form_request', 'show', 'id'],
            ],
            queryType: ['help_form_request', 'update'],
            reset: () => { },
        }),
    })
)
