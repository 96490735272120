import { useState } from 'react'
import { useUpdateHelpFormRequestCmd } from 'features/velocity/help_form_requests/queries'

export const useEditHelpFormRequestStatus = (id, status) => {
    const [formStatus, setFormStatus] = useState(status)

    const updateResult = useUpdateHelpFormRequestCmd()

    const onSubmit = () => updateResult.mutate({ id, status: formStatus })

    const submitStatus = (event, newValue) => {
        setFormStatus(newValue.value)
        updateResult.mutate({ id, status: newValue.value })
    }

    return {
        formStatus,
        setFormStatus,
        success: updateResult?.isSuccess,
        disabled: updateResult?.isPending,
        isLoadingState: updateResult?.isPending,
        onSubmit,
        submitStatus,
    }
}
