import AdminUserList from 'features/admin_users/components/List'
import { useAdminUsers } from 'features/admin_users/hooks/list'

const ListPage = () => {
    const props = useAdminUsers()

    return (
        <>
            <AdminUserList {...props} />
        </>
    )
}

export default ListPage
