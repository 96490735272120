import { all } from 'redux-saga/effects'
import { watchFetchUser } from './show'
import { watchFetchUsers } from './list'
import { watchNewUser, watchCreateUser } from './create'
import { watchEditUser, watchUpdateUser } from './edit'

export default function* users() {
    yield all([
        watchFetchUser(),
        watchFetchUsers(),
        watchNewUser(),
        watchCreateUser(),
        watchEditUser(),
        watchUpdateUser(),
    ])
}
