import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import FacebookBlack from 'components/FacebookBlack'
import LinkedInBlack from 'components/LinkedInBlack'
import TwitterBlack from 'components/TwitterBlack'
import { useStyles } from './styles'

const links = [
    { link: 'https://www.twitter.com/vizerapp', Icon: TwitterBlack },
    { link: 'https://www.linkedin.com/company/vizer/', Icon: LinkedInBlack },
    { link: 'https://www.facebook.com/vizerapp', Icon: FacebookBlack },
]

const Footer = () => {
    const theme = useTheme()
    const styles = useStyles(theme)

    return (
        <div style={styles.footer}>
            <div style={styles.left}>
                <Typography sx={styles.copyright}>© Vizer Inc. {new Date().getFullYear()}</Typography>
            </div>
            <div style={styles.right}>
                {links.map(({ link, Icon }) => (
                    <a key={link} href={link} target='_blank' rel='noreferrer'>
                        <Icon size={20} />
                    </a>
                ))}
            </div>
        </div>
    )
}

export default Footer
