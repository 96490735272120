import { colors } from 'theme'
import redemptionPage from 'assets/images/redemption_page_blurred.png'

const useStyles = () => ({
    iframe: {
        border: 0,
        width: '100%',
        height: '100%',
    },
    exampleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        position: 'relative',
        height: 600,
        maxWidth: 900,
        margin: '0 auto',
    },
    svgContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2,
    },
    instructionContainer: {
        background: '#eee',
        border: `2px solid ${colors.vizerCoral.main}`,
        padding: '1px 0px 1px 0px',
        marginTop: '75px',
        maxWidth: 500,
        width: '100%',
        height: 350,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
    },
    redemptionPageImage: {
        backgroundImage: `url(${redemptionPage})`,
        backgroundSize: 'cover',
        backgroundPositionX: 'left',
        backgroundPositionY: 'center',
        width: 214,
        height: 572,
        position: 'relative',
        paddingTop: '360px',
    },
    pointA: {
        background: `rgba(0, 0, 0, 0.5)`,
        border: `2px solid ${colors.vizerCoral.main}`,
        height: 110,
        width: 214,
        position: 'absolute',
        top: 360,
        right: 0,
        zIndex: 1,
    },
    pointB: {
        position: 'absolute',
        right: 0,
        top: 470,
        width: 214,
        zIndex: 1,
    },
    redemptionPage: {
        background: 'linear-gradient(rgba(47, 47, 47, 1) 0%, rgba(47, 47, 47, 1) 125px, rgba(255, 255, 255, 1) 125px)',
        width: 225,
        '& h1': {
            fontSize: 12,
        },
        '& h4': {
            fontSize: 12,
            color: '#999',
            fontWeight: 300,
            margin: '0 0 10px',
        },
    },
    svg: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        stroke: '#FF6969',
        strokeWidth: 2,
    },
})

export default useStyles
