import { reduceQueries } from 'helpers'
import { useQueryHelpFormRequestShow } from 'features/velocity/help_form_requests/queries'

export const useHelpFormRequestShow = (helpFormRequestID) => {
    const {
        payloads: [helpFormRequestPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryHelpFormRequestShow(helpFormRequestID)],
    )

    const helpFormRequest = helpFormRequestPayload?.help_form_request?.help_form_request
    const helpFormOffer = helpFormRequestPayload?.help_form_request?.offer
    const helpFormRetailer = helpFormRequestPayload?.help_form_request?.retailer

    return {
        connectionError,
        success,
        helpFormRequest,
        helpFormOffer,
        helpFormRetailer,
        errors,
        warnings,
        notices,
        ...isResults,
    }
}
