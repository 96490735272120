import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import CatalogShow from 'features/velocity/catalogs/components/CatalogShow'
import useStyles from './styles'

const CatalogShowPage = () => {
    const navigate = useNavigate()
    const styles = useStyles()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <CatalogShow />
        </>
    )
}

export default CatalogShowPage
