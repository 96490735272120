import { colors } from 'theme'

const useStyles = () => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        maxHeight: '500px',
        width: 'auto',
        maxWidth: '100%',
    },
    subtitle: {
        fontSize: '1.5rem',
    },
    link: {
        color: colors.vizerCoral.main,
    },
    errorText: {
        margin: '15px 0',
        color: colors.error.main,
    },
})

export default useStyles
