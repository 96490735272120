
export const useStyles = () => ({
    form: {
        backgroundColor: (theme) => theme.palette.background.paper,
        position: 'absolute',
        top: '20%',
        padding: '60px 45px',
        width: '40%',
        minWidth: 305,
        maxWidth: '400px',
        minHeight: 325,
        '&.MuiBox-root > *': {
            display: 'flex',
            marginBottom: (theme) => theme.spacing(3),
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: 330,
        },
    },
    heading: {
        color: (theme) => theme.palette.text.primary,
    },
    passIcon: {
        '&:focus-visible': {
            borderColor: 'primary.main',
            borderWidth: 1,
            borderStyle: 'solid',
        },
    },
})
