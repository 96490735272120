import { colors } from 'theme'

const useStyles = () => ({
    pageHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    card: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 10px',
        position: 'relative',
        flexWrap: 'wrap',
        flexDirection: { xs: 'column', sm: 'row' },
    },
    link: {
        textDecoration: 'none',
        color: colors.black,
        marginLeft: 'auto',
    },
    pageColumn: {
        width: { xs: '100%', sm: 'calc(100% - 250px)' },
    },
    descriptionList: {
        margin: 0,
    },
    divider: {
        border: `1px solid ${colors.vizerSlateGray.main}`,
        margin: '15px',
        flexGrow: 1,
    },
    statusColumn: {
        width: 250,
    },
    btn: {
        width: 'auto',
        margin: '10px 5px 10px auto',
    },
    deleteBtn: {
        width: 'auto',
        margin: '10px 5px 10px 0',
        background: colors.error.main,
        '&:hover': {
            background: colors.error.dark,
        },
    },
})

export default useStyles
