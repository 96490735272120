import { useQueryOfferTemplateNames } from 'features/velocity/templates/queries'
import { reduceQueries } from 'helpers'

export const useOfferTemplateNames = (templateType, queryArgs = {}) => {
    const {
        payloads: [offerTemplateNamesPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryOfferTemplateNames(templateType, queryArgs)],
    )

    const templateNames = offerTemplateNamesPayload?.template_names

    return {
        connectionError,
        success,
        templateNames,
        notices,
        warnings,
        errors,
        ...isResults,
    }
}
