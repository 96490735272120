import Modal from 'react-modal'
import { Box, Button, Typography } from '@mui/material'
import { LineGraph } from 'features/velocity/analytics/components/LineGraph'
import { useGraphModal } from '../../hooks/graph_modal'
import { useTheme } from '@mui/material/styles'
import useStyles from './styles'

export const GraphModal = () => {
    Modal.setAppElement('#root')
    const { graphTitle, graphData, lineColors, visible, tickLabelSpacing, conversion, dismissGraphModal } = useGraphModal()
    const theme = useTheme()

    const customStyles = {
        overlay: {
            zIndex: 999999999999,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: theme.palette.hover.main,
        },
    }

    return (
        <GraphModalView
            visible={visible}
            dismissGraphModal={dismissGraphModal}
            customStyles={customStyles}
            graphTitle={graphTitle}
            graphData={graphData}
            lineColors={lineColors}
            tickLabelSpacing={tickLabelSpacing}
            conversion={conversion}
        />
    )
}

export const GraphModalView = ({
    visible, dismissGraphModal, customStyles,
    graphTitle, graphData, lineColors, tickLabelSpacing, conversion,
}) => {
    const styles = useStyles()

    return (
        <Modal
            isOpen={visible}
            onRequestClose={() => dismissGraphModal()}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <Button
                variant='contained'
                color='secondary'
                onClick={() => dismissGraphModal()}
                sx={styles.closeButton}
            >
                X
            </Button>
            <Box sx={styles.graphDisplayWrapper}>
                <Typography variant='h1'>{graphTitle}</Typography>
                <Box sx={styles.graphDataWrapper}>
                    <LineGraph graphData={graphData} lineColors={lineColors} tickLabelSpacing={tickLabelSpacing} conversion={conversion} />
                </Box>
            </Box>
        </Modal>
    )
}

export default GraphModal
