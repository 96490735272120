import { useEffect } from 'react'
import { reduceQueries, useInvalidateQuery } from 'helpers'
import { useQueryClientPortalUsers } from 'features/velocity/client_portal_users/queries'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'

export const useClientPortalUsers = (state = {}) => {
    const {
        setPage,
        resetPage,
        params,
        pageState,
        paginationQueryArgs,
    } = usePaginationFilters({ state })

    const {
        payloads: [retailerPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries([useQueryClientPortalUsers(params, paginationQueryArgs)])

    const invalidateListQueries = useInvalidateQuery(['client_portal_users', 'list'])

    const clientPortalUsers = retailerPayload?.client_portal_users || []
    const pagination = retailerPayload?.pagination || {}

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    return {
        connectionError,
        success,
        clientPortalUsers,
        errors,
        warnings,
        notices,
        pagination,
        invalidateListQueries,
        setPage,
        pageState,
        ...isResults,
    }
}
