import { useQuery } from '@tanstack/react-query'
import { useQueryDefaults } from 'queryHelpers'
import {
    fetchEventDashboardCounts,
    fetchBacklogDeadletterCountsByDate,
    fetchEventDeadletterCountsByDate,
    fetchEventCountsByDate,
    fetchEventCounts,
} from '../services'

export const useQueryEventDashboardCounts = (queryArgs = {}) => {
    const queryKey = ['events', 'dashboard']

    return useQuery({
        queryKey,
        queryFn: () => fetchEventDashboardCounts(),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryBacklogDeadletterCountsByDate = (queryArgs = {}) => {
    const queryKey = ['events', 'backlog_deadletter_count', 'by_date']

    return useQuery({
        queryKey,
        queryFn: () => fetchBacklogDeadletterCountsByDate(),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryEventDeadletterCountsByDate = (queryArgs = {}) => {
    const queryKey = ['events', 'event_deadletter_count', 'by_date']

    return useQuery({
        queryKey,
        queryFn: () => fetchEventDeadletterCountsByDate(),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryEventCountsByDate = (queryArgs = {}) => {
    const queryKey = ['events', 'events_count', 'by_date']

    return useQuery({
        queryKey,
        queryFn: () => fetchEventCountsByDate(),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryEventCounts = (queryArgs = {}) => {
    const queryKey = ['errors', 'events_count']

    return useQuery({
        queryKey,
        queryFn: () => fetchEventCounts(),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}
