import { velocityUrl, _get } from 'utils'

export async function fetchEventDashboardCounts() {
    try {
        const url = await velocityUrl('/events/dashboard')

        // return await _get(url)

        return {
            success: true,
            payload: {
                backlog_deadletter: {
                    total_today: 28973,
                    daily_avg: 934,
                    total_this_week: 10893,
                    weekly_avg_last_six_months: 12342,
                    weekly_avg_all_time: 132342,
                },
                event_deadletter: {
                    total_today: 28973,
                    daily_avg: 934,
                    total_this_week: 10893,
                    weekly_avg_last_six_months: 12342,
                    weekly_avg_all_time: 132342,
                },
            },
        }
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchBacklogDeadletterCountsByDate() {
    try {
        const url = await velocityUrl('/events/backlog_deadletter/by_date')

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchEventDeadletterCountsByDate() {
    try {
        const url = await velocityUrl('/events/event_deadletter/by_date')

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchEventCountsByDate() {
    try {
        const url = await velocityUrl('/events/event_counts/by_date')

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchEventCounts() {
    try {
        const url = await velocityUrl('/events/event_counts')

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
