import { emptyToNull } from 'helpers'
import { SET_FORM, UPDATE_FIELD } from '../../status'
import { useInvitationFormReducer } from '../reducers/form'

export const useInvitationForm = (state = {}) => {
    const [invitationState, dispatch] = useInvitationFormReducer(state)

    const { form } = invitationState

    const setForm = (user) => {
        const { first_name, last_name, email } = user || null

        dispatch({ type: SET_FORM, value: { first_name, last_name, email } })
    }

    const setField = (field) => (event) => {
        const raw = event.target.value
        const value = emptyToNull(raw)

        dispatch({ type: UPDATE_FIELD, field, value })
    }

    return {
        form,
        setField,
        setForm,
    }
}
