import { useMutation, useQuery } from '@tanstack/react-query'
import { useMutationDefaults, useQueryDefaults } from 'queryHelpers'
import { fetchBrandsList } from 'features/brands/services'
import { useOfferPartnerForm } from 'features/velocity/offer_partners/hooks/form'
import {
    createNewOfferPartner,
    fetchOfferPartners,
    fetchOfferPartnerShow,
    updateExistingOfferPartner,
} from '../services'

export const useQueryOfferPartners = (pageQuery, queryArgs = {}) => {
    const queryKey = ['offer_partners', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchOfferPartners(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryOfferPartnerShow = (offerPartnerID, pageQuery, queryArgs = {}) => {
    const queryKey = ['offer_partners', 'show', offerPartnerID, pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchOfferPartnerShow(offerPartnerID, pageQuery),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryBrands = (queryArgs = {}) => {
    const queryKey = ['brands', 'list']

    return useQuery({
        queryKey,
        queryFn: () => fetchBrandsList({ page: 1, per_page: 2000 }),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useCreateOfferPartnerCmd = () => {
    const { resetForm } = useOfferPartnerForm()

    return useMutation({
        mutationFn: (form) => createNewOfferPartner(form),
        ...useMutationDefaults({
            queryKeys: [
                ['offer_partners', 'list'],
                ['brands', 'list'],
            ],
            queryType: ['offer_partner', 'create'],
            navigationPath: '/velocity/offer_partners',
            reset: resetForm,
        }),
    })
}

export const useUpdateOfferPartnerCmd = () => {
    const { resetForm } = useOfferPartnerForm()

    return useMutation({
        mutationFn: (form) => updateExistingOfferPartner(form),
        ...useMutationDefaults({
            queryKeys: [
                ['offer_partners', 'list'],
                ['offer_partners', 'show', 'id'],
            ],
            queryType: ['offer_partner', 'update'],
            navigationPath: '/velocity/offer_partners',
            reset: resetForm,
        }),
    })
}
