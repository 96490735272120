import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'theme'

export const DashboardStyled = styled(Grid)(({ theme }) => ({
    padding: '20px 10px 0 25px',
    backgroundColor: theme.palette.graphDashboard.main,
    [theme.breakpoints.up(1600)]: {
        padding: 0,
        '& > .MuiGrid-item': {
            padding: '25px 15px 15px',
            border: `1px solid ${colors.vizerIceGray.main}`,
        },
    },
}))
