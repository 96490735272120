import { colors } from 'theme'

const useStyles = () => ({
    fieldContainer: {
        minWidth: 400,
        maxWidth: 450,
        position: 'relative',
        margin: '5px',
        width: '100%',
        '& > div': {
            margin: 0,
        },
        '& > svg': {
            zIndex: 2,
        },
    },
    tooltip: (modal) => ({
        position: 'absolute',
        right: 2,
        top: 2,
        zIndex: 1,
        color: colors.vizerCoral.main,
        cursor: modal ? 'pointer' : 'default',
        '& :hover': {
            color: modal ? colors.vizerCoral.light : 'inherit',
        },
    }),
    infoIcon: {
        color: colors.vizerCoral.main,
        fontSize: '.9rem',
    },
})

export default useStyles
