import dayjs from 'dayjs'
import { bundleImageData, dateTimeLocalToUtc } from 'helpers'
import { UPDATE_FIELD, RESET_FORM, SET_FORM, UPDATE_TEMPLATE_FIELD } from 'features/status'
import { useOfferFormReducer } from 'features/velocity/offers/reducers/form'
import { emptyToNull } from 'helpers'

export const useOfferForm = (state = {}) => {
    const [offerState, dispatch] = useOfferFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })
    const updateNumberField = (field) => (event) => {
        const parsed = parseInt(event.target.value)
        const value = emptyToNull(parsed)

        dispatch({ type: UPDATE_FIELD, field, value, template: 'template_landing' })
    }
    const setStrategy = (strategy) => dispatch({ type: UPDATE_FIELD, field: 'strategy', value: strategy })
    const updateStatusField = (field) => (_, status) => dispatch({ type: UPDATE_FIELD, field, value: status.value })
    const updateIncommOfferTypeField = (_, type) => dispatch({ type: UPDATE_FIELD, field: 'type', value: type.value })
    const updateImageField = async (e, field) => {
        const file = e.target.files[0]
        const image = await bundleImageData(file)

        dispatch({ type: UPDATE_FIELD, field, value: image })
    }
    const updateOfferPartnerField = (_, { offer_partner, brand }) => {
        dispatch({ type: UPDATE_FIELD, field: 'offer_partner_id', value: offer_partner.id })
        dispatch({ type: UPDATE_FIELD, field: 'brand_id', value: brand.id })
    }
    const updateDateTimeField = ({ field, datetime, value, edit }) => {
        const date = datetime && dayjs(datetime).isValid() ? dateTimeLocalToUtc(datetime.toISOString()) : value

        dispatch({
            type: (edit ? 'UPDATE_WARNING' : UPDATE_FIELD),
            field,
            value: date,
        })
    }
    const setStack = (value, edit) => {
        dispatch({ type: (edit ? 'UPDATE_WARNING' : UPDATE_FIELD), field: 'stack', value })
    }
    const updateUserIdentificationMethodField = (_, method) => {
        dispatch({ type: UPDATE_FIELD, field: 'user_identification_method', value: method.value })
    }
    const updateFieldWithWarning = (field) => (event) => dispatch({ type: 'UPDATE_WARNING', field, value: event.target.value })
    const updateNumberFieldWithWarning = (field) => (event) => {
        const parsed = parseInt(event.target.value)
        const value = emptyToNull(parsed)

        dispatch({ type: 'UPDATE_WARNING', field, value })
    }
    const addUPCField = (field) => dispatch({ type: 'ADD_UPC_FIELD', field, value: null })
    const updateUPCField = (index, field, event) => {
        dispatch({ type: 'UPDATE_UPC_FIELD', field, value: event.target.value, index, eventType: event.nativeEvent.inputType })
    }
    const addScheduleField = (field) => dispatch({ type: 'ADD_SCHEDULE_FIELD', field, value: null })
    const updateScheduleField = ({ index, id, field, datetime, value }) => {
        const date = datetime && dayjs(datetime).isValid() ? dateTimeLocalToUtc(datetime.toISOString()) : value

        dispatch({
            type: 'UPDATE_SCHEDULE_FIELD',
            id,
            index,
            field,
            value: date,
        })
    }
    const updateRetailer = (value) => dispatch({ type: 'UPDATE_RETAILER', field: 'retailer_ids', value: value })
    const selectAllRetailers = (array) => dispatch({ type: 'SELECT_ALL_RETAILERS', field: 'retailer_ids', array: array })

    const getAllIDs = (retailer, banner_stores) => {
        const allIncommIDs = banner_stores.map((banner_store) => banner_store.id)
        allIncommIDs.push(retailer?.id)

        return allIncommIDs
    }
    const checked = (id) => {
        return offerState.form?.retailer_ids?.includes(id)
    }
    const selectAllChecked = (retailer, banner_stores) => {
        return getAllIDs(retailer, banner_stores).every((v) => offerState.form?.retailer_ids?.includes(v))
    }

    const setTemplate = (template, value) => dispatch({ type: 'SET_TEMPLATE', template, value })
    const updateTemplateCheckbox = (template, field) => (event) => {
        dispatch({ type: UPDATE_TEMPLATE_FIELD, template, field, value: event.target.checked })
    }
    const updateTemplate = (template, field) => (event) => {
        dispatch({ type: UPDATE_TEMPLATE_FIELD, template, field, value: event.target.value })
    }
    const updateTemplateDropdown = (template, field, value, action) => {
        dispatch({ type: UPDATE_TEMPLATE_FIELD, template, field, value, action })
    }

    const clearField = (field) => dispatch({ type: UPDATE_FIELD, field, value: null })
    const setForm = (offer) => dispatch({ type: SET_FORM, value: offer })
    const resetForm = () => dispatch({ type: RESET_FORM })

    const setDev = () => dispatch({ type: 'SET_DEV' })

    const setDisplayExpirationType = (_, expType) => {
        dispatch({
            type: 'SET_EXPIRATION_DISPLAY',
            value: expType.value,
        })
    }

    const setStaticDisplayExpiration = ({ datetime, value }) => {
        const date = datetime && dayjs(datetime).isValid() ? dateTimeLocalToUtc(datetime.toISOString()) : value

        dispatch({ type: 'SET_STATIC_EXPIRATION', value: date })
    }

    const selectedExpOptFilter = (form) => (option) => {
        if (typeof option.value === 'string' && form?.display_expiration?.name === 'static') {
            return form?.display_expiration?.name === option.value
        }

        if (typeof option.value === 'string' && form?.display_expiration?.name === 'dynamic') {
            const opt = option.value.split('_')
            const opt_val = parseInt(opt[1])

            return form?.display_expiration?.data === opt_val
        }

        return option.value === form?.display_expiration
    }

    return {
        form: offerState.form,
        strategy: offerState.strategy,
        offerState,
        updateTemplate,
        updateTemplateCheckbox,
        updateTemplateDropdown,
        setTemplate,
        addUPCField,
        updateUPCField,
        addScheduleField,
        updateScheduleField,
        updateRetailer,
        selectAllRetailers,
        updateField,
        updateNumberField,
        updateStatusField,
        setStrategy,
        updateIncommOfferTypeField,
        updateOfferPartnerField,
        updateDateTimeField,
        updateImageField,
        setStack,
        updateFieldWithWarning,
        updateNumberFieldWithWarning,
        updateUserIdentificationMethodField,
        getAllIDs,
        checked,
        selectAllChecked,
        clearField,
        setForm,
        resetForm,
        setDev,
        selectedExpOptFilter,
        setDisplayExpirationType,
        setStaticDisplayExpiration,
    }
}
