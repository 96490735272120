import { UPDATE_FIELD, RESET_FORM, UPDATE_STATE, RESET_FIELD } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const customerFormInitialState = (state = {}) => {
    return {
        form: {
            offerNumber: null,
            ingenID: null,
            cardNumber: null,
        },
        submittedID: null,
        ...state,
    }
}

export const customerFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state.form[`${action.field}`] = action.value

            break
        }
        case UPDATE_STATE: {
            state[`${action.field}`] = action.value

            break
        }
        case RESET_FIELD: {
            state.form[`${action.field}`] = null

            break
        }
        case RESET_FORM: {
            state.form = customerFormInitialState().form

            break
        }
        default: {
            return state
        }
    }
}

export const useCustomerFormReducer = (state = {}) => {
    return useReducer(customerFormReducer, customerFormInitialState(state))
}
