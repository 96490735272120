import { FormColumnStyled } from 'theme/forms'
import useStyles from './styles'
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormLabel,
} from '@mui/material'

export const BannerStoreSelector = ({ retailer, updateRetailer, selectAllRetailers, getAllIDs, checked, selectAllChecked }) => {
    const { name, banner_stores, id } = retailer
    const styles = useStyles()

    const half = Math.ceil(banner_stores.length / 2)

    return (
        <div>
            <fieldset style={styles.retailGroup}>
                <legend>
                    {name ? (
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={styles.checkbox}
                                        name={name}
                                        onChange={() => updateRetailer(id)}
                                        checked={checked(id)}
                                    />
                                }
                                label={name}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={styles.checkbox}
                                        name='Select All'
                                        onChange={() => selectAllRetailers(getAllIDs(retailer, banner_stores))}
                                        checked={selectAllChecked(retailer, banner_stores)}
                                    />
                                }
                                label='Select All'
                            />
                        </>
                    ) : (
                        <FormLabel>Other</FormLabel>
                    )}
                </legend>
                <Box sx={styles.row}>
                    <FormColumnStyled style={styles.column}>
                        {banner_stores.slice(0, half).map((banner_store) => (
                            <FormControlLabel
                                key={banner_store.id}
                                control={
                                    <Checkbox
                                        sx={styles.checkbox}
                                        name={banner_store.name}
                                        onChange={() => updateRetailer(banner_store.id)}
                                        checked={checked(banner_store.id)}
                                    />
                                }
                                label={banner_store.name}
                            />
                        ))}
                    </FormColumnStyled>
                    <FormColumnStyled style={styles.column}>
                        {banner_stores.slice(half).map((banner_store) => (
                            <FormControlLabel
                                key={banner_store.id}
                                control={
                                    <Checkbox
                                        sx={styles.checkbox}
                                        name={banner_store.name}
                                        onChange={() => updateRetailer(banner_store.id)}
                                        checked={checked(banner_store.id)}
                                    />
                                }
                                label={banner_store.name}
                            />
                        ))}
                    </FormColumnStyled>
                </Box>
            </fieldset >
        </div >
    )
}

export default BannerStoreSelector
