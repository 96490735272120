import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'theme'
import workoutWithDumbells from 'assets/images/workout-with-dumbells-by-derick-mckinney.jpg'

export const LoginFormStyled = styled('form')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    position: 'absolute',
    top: '20%',
    padding: '60px 45px',
    minWidth: 320,
    width: '31%',
    minHeight: 325,
    '& .MuiTextField-root': {
        display: 'flex',
        marginBottom: theme.spacing(3),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 330,
    },
}))

export const SubmitStyled = styled(Button)(({ theme }) => ({
    width: '100%',
    maxWidth: 330,
    margin: 'auto',
    display: 'flex',
    ...theme.buttons.large.primary,
}))

export const classesFn = () => ({
    page: {
        height: '100vh',
        overflow: 'auto',
    },
    backgroundWrapper: {
        display: 'flex',
        height: '100vh',
    },
    backgroundSpacer: {
        backgroundColor: (theme) => theme.palette.background.paper,
        width: '35%',
    },
    background: {
        width: '65%',
        height: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
    },
    backgroundColor: {
        background: colors.vizerSunrise.main,
    },
    backgroundImage: {
        opacity: 0.7,
        backgroundImage: `url(${workoutWithDumbells})`,
        backgroundSize: 'cover',
        backgroundPositionX: 'left',
        backgroundPositionY: 'center',
    },
    checkbox: {
        color: colors.disabled,
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiButtonBase-root': {
            marginLeft: '-9px',
            paddingRight: 0,
        },
        '& .MuiButton-root:hover': {
            backgroundColor: 'none',
        },
    },
    rememberMe: {
        display: 'flex',
        alignItems: 'center',
        height: 42,
        '& .MuiCheckboxColorSecondary.MuiChecked': {
            color: colors.vizerOrange.main,
        },
    },
    linkReset: {
        textDecoration: 'none',
    },
    // actionableItem: {
    //     ...theme.inputs.helperText,
    //     textTransform: 'initial',
    // },
})
