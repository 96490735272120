import { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'

import { Accordion, AccordionDetails, AccordionSummary, Chip, TextField, Tooltip } from '@mui/material'
import StyleIcon from '@mui/icons-material/Style'
import { colors } from 'theme'
import FieldWithTooltip from 'components/FieldWithTooltip'
import useStyles from '../styles'

const cssProperties = {
    color: {
        name: 'Font Color',
        tooltip: 'Enter or select valid values: css-supported color strings, hex codes, and rgb()',
    },
    background: {
        name: 'Background',
        tooltip: 'Enter or select valid values: css-supported color strings, hex codes, rgb(), rgba(), and gradients',
    },
}

const dropdownColors = [
    { name: 'White', value: colors.white },
    { name: 'Ice Gray', value: colors.vizerIceGray.main },
    { name: 'Charcoal', value: colors.vizerCharcoal.main },
    { name: 'Slate Gray', value: colors.vizerSlateGray.main },
    { name: 'Black', value: colors.black },
    { name: 'Vizer Coral', value: colors.vizerCoral.main },
    { name: 'Bubblegum', value: colors.vizerBubblegum.main },
    { name: 'Orchid', value: colors.vizerOrchid.main },
    { name: 'Olipop Green', value: '#14433d' },
    { name: 'Indigo', value: colors.vizerIndigo.main },
    { name: 'Peacock', value: colors.vizerPeacock.main },
    { name: 'Suja Green', value: '#225340' },
    { name: 'VitaCoco Blue', value: '#1947ba' },
    { name: 'Vizer Sunrise (Background only)', value: colors.vizerSunrise.main },
]

export const StyleUpdater = ({
    children, disabled, value, path, css, updateTemplateDropdown, variant,
}) => {
    const styles = useStyles()
    const [expanded, setExpanded] = useState(null)

    return (
        <Accordion
            expanded={expanded}
            sx={variant === 'field' ? styles.accordionField : styles.accordion}
            onChange={() => setExpanded(!expanded)}
        >
            <AccordionSummary
                aria-controls={`${path}-content`}
                sx={styles.accordionSummary}
                id={`${path}-header`}
                disabled={disabled}
                expandIcon={
                    <Tooltip title={`Adjust ${variant || 'section'} styling`}>
                        <StyleIcon />
                    </Tooltip>
                }
            >
                {children}
            </AccordionSummary>

            <AccordionDetails>
                {css.map((prop) => (
                    <FieldWithTooltip
                        key={prop}
                        tooltip={cssProperties[prop].tooltip}
                    >
                        <Autocomplete
                            freeSolo
                            clearOnBlur
                            selectOnFocus
                            handleHomeEndKeys
                            disabled={disabled}
                            value={value ? dropdownColors.find((opt) => opt.value === value[prop]) || value[prop] : ' '}
                            options={dropdownColors}
                            renderOption={(props, opt) => (
                                <li {...props}>
                                    <Chip sx={styles.colorInput(opt.value)} />

                                    {opt.name}
                                </li>
                            )}
                            getOptionLabel={(opt) => {
                                if (typeof opt === 'string') return opt

                                if (opt.inputValue) return opt.inputValue

                                return opt.name
                            }}
                            onChange={(_, newVal) => {
                                if (typeof newVal === 'string') {
                                    updateTemplateDropdown(`${path}.${prop}`, newVal)
                                } else if (newVal && newVal.inputValue) {
                                    updateTemplateDropdown(`${path}.${prop}`, newVal.inputValue)
                                } else if (newVal && newVal.value) {
                                    updateTemplateDropdown(`${path}.${prop}`, newVal.value)
                                } else {
                                    updateTemplateDropdown(`${path}.${prop}`, null, 'delete')
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label={cssProperties[prop].name} />
                            )}
                        />
                    </FieldWithTooltip>
                ))}
            </AccordionDetails>
        </Accordion>
    )
}
