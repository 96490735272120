import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import ConnectionError from 'components/ConnectionError'
import IsLoading from 'components/IsLoading'
import Loading from 'components/Loading'
import IncommOfferBaseForm from 'features/velocity/offers/components/InCommForm'
import { useOfferEdit } from 'features/velocity/offers/hooks/edit'
import useStyles from './styles'

const OfferEdit = () => {
    const navigate = useNavigate()
    const styles = useStyles()
    const { id } = useParams()

    const offerEditResult = useOfferEdit(id)

    const {
        connectionError,
        strategy,
        errors,
        isError,
        isInitialLoading,
        isLoading,
        isFetching,
        isRefetching,
    } = offerEditResult

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
            />

            {strategy === 'incomm' &&
                <IncommOfferBaseForm {...offerEditResult} edit={true} />
            }
        </>
    )
}

export default OfferEdit
