import { colors } from 'theme'

const useStyles = () => ({
    templateContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto',
        maxWidth: 1000,
        width: '100%',
    },
    template: {
        background: (theme) => theme.palette.mode === 'dark' ? colors.vizerCharcoal.dark : colors.lightGray.main,
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        padding: '25px 10px',
        position: 'relative',
        marginTop: '25px',
        width: '100%',
        '& > div': {
            flex: { xs: '0 0 auto', md: '0 1 48%' },
        },
    },
    templateTitle: {
        width: '100%',
    },
    resetButton: {
        position: { xs: 'relative', md: 'absolute' },
        right: { md: '10px' },
        top: { md: '20px' },
        margin: '0 auto 10px',
        width: 'auto',
    },
    templateSection: {
        marginBottom: '10px',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > div': {
            width: '100%',
        },
    },
    accordion: {
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    accordionSummary: {
        background: (theme) => theme.palette.background.paper,
    },
    accordionField: {
        background: 0,
        margin: '5px',
        maxWidth: 450,
        position: 'relative',
        width: '100%',
        border: `1px solid ${colors.lightGray.main}`,
        '&.Mui-expanded': {
            margin: '5px',
        },
    },
    templateSectionHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 5px',
        width: '100%',
        minHeight: 52,
        '& > h2.MuiTypography-root': {
            marginBottom: 0,
        },
    },
    templateSectionBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 0',
    },
    field: {
        margin: '5px',
        maxWidth: 450,
        position: 'relative',
        width: '100%',
        '& .Mui-disabled': {
            color: colors.lightGray.dark,
        },
    },
    checkbox: {
        margin: '5px',
        maxWidth: 450,
        position: 'relative',
        width: 'auto',
        '& label': {
            marginRight: 0,
        },
        '& .Mui-checked': {
            color: colors.vizerCoral.main,
            '& + span': {
                color: colors.vizerCoral.main,
            },
        },
        '& .Mui-disabled': {
            color: colors.lightGray.dark,
            '&.Mui-checked + span': {
                color: colors.lightGray.dark,
            },
        },
    },
    colorInput: (bg) => ({
        width: 10,
        height: 10,
        border: `1px solid ${colors.white}`,
        marginRight: '5px',
        background: bg,
    }),
    imgColumn: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    contain: {
        maxHeight: 950,
        objectFit: 'contain',
    },
})

export default useStyles
