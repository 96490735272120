import { useParams, Link } from 'react-router-dom'
import {
    Box,
    Button,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material'
import { DescriptionListStyled, StatusChipStyled } from 'theme/styled_components'
import { lastXChar } from 'helpers'
import Cell from 'components/Cell'
import Row from 'components/Row'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import useStyles from './styles'
import { sanitizeDate } from 'helpers'
import { useClientPortalUserShow } from '../../hooks/show'

export const ClientPortalUserShow = () => {
    const styles = useStyles()
    const { id } = useParams()

    const {
        connectionError,
        clientPortalUser,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
    } = useClientPortalUserShow(id)

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    const {
        status,
        name,
        email,
        offer_partners,
        created_at,
        updated_at,
    } = clientPortalUser || {}

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
            />

            <Card sx={styles.pageHeader}>
                <CardContent>
                    <Box sx={styles.titleBar}>
                        <Typography variant='h1'>
                            {name}
                        </Typography>

                        <Box>
                            <Link to={`/velocity/client_portal/users/${id}/edit`}>
                                <Button
                                    sx={styles.btn}
                                    variant='contained'
                                    color='secondary'
                                    data-cy='edit-btn'
                                >
                                    Edit
                                </Button>
                            </Link>
                        </Box>
                    </Box>

                    <Box sx={styles.pageRow}>
                        <Box sx={styles.pageColumn}>
                            <StatusChipStyled style={{ width: 250 }} label={status} active={status === 'active'} />

                            <DescriptionListStyled>
                                <dt>
                                    <p>Email:</p>
                                </dt>
                                <dd>
                                    <p>{email}</p>
                                </dd>
                                <dt>
                                    <p>Created At:</p>
                                </dt>
                                <dd>
                                    <p>{sanitizeDate(created_at)}</p>
                                </dd>
                                <dt>
                                    <p>Updated At:</p>
                                </dt>
                                <dd>
                                    <p>{sanitizeDate(updated_at)}</p>
                                </dd>
                            </DescriptionListStyled>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            <Typography variant="h1">Offer Partners</Typography>

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align='center'>Name</TableCell>
                            <TableCell align='center'>Brand</TableCell>
                            <TableCell align='center'>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    {offer_partners.length > 0 &&
                        <TableBody data-cy='body'>
                            {offer_partners.map(({ offer_partner, brand }) => {
                                const { id, name, status } = offer_partner

                                return (
                                    <Row
                                        key={id}
                                        path={`/velocity/offer_partners/${id}`}
                                    >
                                        <Cell scope='offer-partner' align='center'>{lastXChar(id, 8)}</Cell>
                                        <Cell align='center'>{name}</Cell>
                                        <Cell
                                            align='center'
                                            sx={styles.cellLink}
                                            path={`/brands/${brand?.id}`}
                                        >
                                            {`${brand?.name} (${brand?.id})`}
                                        </Cell>
                                        <Cell align='center'>{status}</Cell>
                                    </Row>
                                )
                            })}
                        </TableBody>
                    }
                </Table>
            </TableContainer>

            {offer_partners.length === 0 &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No offer partners found.</Typography>
            }
        </>
    )
}

export default ClientPortalUserShow
