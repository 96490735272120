import { bundleImageData, emptyToNull } from 'helpers'
import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'
import { useBrandFormReducer } from '../reducers'

export const useBrandForm = (state = {}) => {
    const [form, dispatch] = useBrandFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })

    const updateImageField = async (e, _) => {
        const file = e.target.files[0]
        const image = await bundleImageData(file)

        dispatch({ type: UPDATE_FIELD, field: 'image', value: image })
    }

    const updateWYSIWYG = (field) => (content) => {
        dispatch({ type: UPDATE_FIELD, field, value: content })
    }

    const resetForm = () => dispatch({ type: RESET_FORM })

    const setForm = (brand) => dispatch({ type: SET_FORM, value: brand })

    return {
        form,
        updateField,
        updateImageField,
        updateWYSIWYG,
        setForm,
        resetForm,
    }
}
