import Modal from 'react-modal'
import { useTheme } from '@mui/material/styles'
import { Button } from '@mui/material'
import useStyles, { customModalStyles } from './styles'

export const VizerModal = ({ visible, dismissModal, children }) => {
    Modal.setAppElement('#root')

    return (
        <ModalView
            visible={visible}
            dismissModal={dismissModal}
        >
            {children}
        </ModalView>
    )
}

export const ModalView = ({ visible, dismissModal, children }) => {
    const styles = useStyles()
    const theme = useTheme()

    return (
        <Modal
            isOpen={visible}
            onRequestClose={() => dismissModal()}
            style={customModalStyles(theme)}
            contentLabel='Content Modal'
        >
            <Button
                variant='contained'
                color='secondary'
                onClick={() => dismissModal()}
                sx={styles.closeButton}
            >
                X
            </Button>
            {children}
        </Modal>
    )
}

export default VizerModal
