import AdminUserNew from './AdminUsers/New'
import AdminUsersList from './AdminUsers/List'
// import List from './List'
// import UserNew from './Users/New'
// import UserShow from './Users/Show'
// import UserEdit from './Users/Edit'
// import UserRoleNew from './UserRole/New'
// import UserPermissionNew from './UserPermission/New'
// import RoleNew from './Roles/New'
// import RoleShow from './Roles/Show'
// import RoleEdit from './Roles/Edit'
// import RoleRoleNew from './RoleRole/New'
// import RolePermissionNew from './RolePermission/New'
// import PermissionShow from './Permissions/Show'
// import PermissionEdit from './Permissions/Edit'

const AdminRoutes = {
    path: '/admin',
    children: [
        {
            path: 'users',
            children: [
                {
                    path: '',
                    element: <AdminUsersList />,
                },
                {
                    path: 'new',
                    element: <AdminUserNew />,
                },
            ],
        },
        // {
        //     path: 'users/oxidauth',
        //     children: [
        //         {
        //             path: '',
        //             element: <List />,
        //         },
        //         {
        //             path: ':id/edit',
        //             element: <UserEdit />,
        //         },
        //         {
        //             path: ':id',
        //             element: <UserShow />,
        //         },
        //         {
        //             path: 'new',
        //             element: <UserNew />,
        //         },
        //         {
        //             path: ':id/roles/new',
        //             element: <UserRoleNew />,
        //         },
        //         {
        //             path: ':id/permissions/new',
        //             element: <UserPermissionNew />,
        //         },
        //     ],
        // },
        // {
        //     path: 'roles',
        //     children: [
        //         {
        //             path: 'new',
        //             element: <RoleNew />,
        //         },
        //         {
        //             path: ':id/edit',
        //             element: <RoleEdit />,
        //         },
        //         {
        //             path: ':id',
        //             element: <RoleShow />,
        //         },
        //         {
        //             path: ':id/roles/new',
        //             element: <RoleRoleNew />,
        //         },
        //         {
        //             path: ':id/permissions/new',
        //             element: <RolePermissionNew />,
        //         },
        //     ],
        // },
        // {
        //     path: 'permissions',
        //     children: [
        //         {
        //             path: ':id/edit',
        //             element: <PermissionEdit />,
        //         },
        //         {
        //             path: ':id',
        //             element: <PermissionShow />,
        //         },
        //     ],
        // },
    ],
}

export default AdminRoutes
