import { createSelector } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { setError, dismissError } from '../reducers/error_modal'

export const error_modal = (state) => state.errors.error_modal
export const titleSel = createSelector(error_modal, (error_modal) => error_modal.title)
export const userMessageSel = createSelector(error_modal, (error_modal) => error_modal.userMessage)
export const buttonTextSel = createSelector(error_modal, (error_modal) => error_modal.buttonText)
export const devMessageSel = createSelector(error_modal, (error_modal) => error_modal.devMessage)
export const visibleSel = createSelector(error_modal, (error_modal) => error_modal.visible)
export const navigateToSel = createSelector(error_modal, (error_modal) => error_modal.navigate_to)
export const navigateToParamsSel = createSelector(error_modal, (error_modal) => error_modal.navigate_to_params)

export const useShowErrorModal = (navigate) => {
    const dispatch = useDispatch()

    const title = useSelector(titleSel)
    const userMessage = useSelector(userMessageSel)
    const buttonText = useSelector(buttonTextSel)
    const devMessage = useSelector(devMessageSel)
    const visible = useSelector(visibleSel)
    const navigate_to = useSelector(navigateToSel)
    const navigate_to_params = useSelector(navigateToParamsSel)

    return {
        title,
        userMessage,
        buttonText,
        devMessage,
        visible,
        setError: (error) => dispatch(setError(error)),
        dismissError: () => {
            dispatch(dismissError())
            if (navigate && navigate_to) {
                navigate(navigate_to_params)
            }
        },
    }
}
