import { useEffect } from 'react'
import { reduceLoadingState, squashArr } from 'helpers'
import { useUpdateOfferPartnerCmd } from 'features/velocity/offer_partners/queries'
import { useOfferPartnerShow } from 'features/velocity/offer_partners/hooks/show'
import { useOfferPartnerForm } from 'features/velocity/offer_partners/hooks/form'

export const useEditOfferPartner = (id) => {
    const offerPartnerFormReturn = useOfferPartnerForm()
    const offerPartnerShowResult = useOfferPartnerShow(id)

    const isLoadError = offerPartnerShowResult.isError
    const loadSuccess = offerPartnerShowResult.success

    const updateResult = useUpdateOfferPartnerCmd()

    const connectionError = offerPartnerShowResult.connectionError
    const warnings = squashArr([offerPartnerShowResult.warnings, updateResult.data?.warnings])
    const notices = squashArr([offerPartnerShowResult.notices, updateResult.data?.notices])
    const errors = squashArr([offerPartnerShowResult.errors, updateResult.data?.errors, updateResult.error])

    const isLoadingState = reduceLoadingState([offerPartnerShowResult]) || updateResult.isLoading
    const isDisabled = offerPartnerShowResult.isLoading || updateResult.isLoading

    const offerPartner = loadSuccess ? offerPartnerShowResult?.offerPartner : {}

    useEffect(() => {
        if (loadSuccess) {
            offerPartnerFormReturn.setForm(offerPartner)
        }
    }, [loadSuccess, offerPartner])

    const onSubmit = () => updateResult.mutate(offerPartnerFormReturn.form)

    return {
        loadSuccess,
        connectionError,
        errors,
        warnings,
        notices,
        isLoadError,
        isLoadingState,
        disabled: isDisabled,
        ...offerPartnerFormReturn,
        onSubmit,
    }
}
