import { useState, useEffect } from 'react'
import useStyles from './styles'

export const ExpandableText = ({ text }) => {
    const [textExpanded, setTextExpanded] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(false)
    const [overflowingText, setOverflowingText] = useState(false)
    const showExpandable = (isOverflowing || overflowingText)
    const styles = useStyles()

    const checkOverflow = (el) => {
        const curOverflow = el?.style.overflow

        if (el && (!curOverflow || curOverflow === 'visible')) {
            el.style.overflow = 'hidden'
        }

        const isOverflowing = el?.clientWidth < el?.scrollWidth || el?.clientHeight < el?.scrollHeight

        if (el) {
            el.style.overflow = curOverflow
        }

        return isOverflowing
    }

    useEffect(() => {
        setIsOverflowing(checkOverflow(document.getElementById('text')))
    }, [text])

    return (
        <div style={styles.expandContainer}>
            <p id='text' style={textExpanded ? styles.textExpanded : styles.text}>
                {text}
            </p>
            {showExpandable &&
                <>
                    <div style={styles.expandBG}></div>
                    <span
                        style={styles.expandBtn}
                        onClick={() => {
                            setTextExpanded(!textExpanded)
                            setOverflowingText(true)
                        }}
                    >
                        {`...(${textExpanded ? 'See Less' : 'See More'})`}
                    </span>
                </>
            }
        </div>
    )
}

export default ExpandableText
