import React from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useStyles from './styles'
import Versions from 'components/Versions'
import VizerLogoMark from 'components/VizerLogoMark'

export const Stats = () => {
    const styles = useStyles()
    const navigate = useNavigate()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <div style={styles.page}>
                <VizerLogoMark size={200} />
                <Versions />
            </div>
        </>
    )
}

export default Stats
