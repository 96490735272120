import { UPDATE_FIELD, UPDATE_STATE, RESET_FORM, SET_FORM, UPDATE_TEMPLATE_FIELD } from 'features/status'
import { setNestedObjectKey } from 'helpers'
import { useReducer } from 'reducerHelpers'

export const DISPLAY_EXPIRATION_OPTIONS = [
    {
        name: 'None',
        value: null,
    },
    {
        name: 'Static',
        value: 'static',
    },
    {
        name: '7 days rolling',
        value: 'dynamic_7',
    },
    {
        name: '14 days rolling',
        value: 'dynamic_14',
    },
    {
        name: '30 days rolling',
        value: 'dynamic_30',
    },
    {
        name: '45 days rolling',
        value: 'dynamic_45',
    },
    {
        name: '60 days rolling',
        value: 'dynamic_60',
    },
    {
        name: '90 days rolling',
        value: 'dynamic_90',
    },
]

export const offerFormInitialState = (state = {}) => {
    return {
        strategy: null,
        form: {
            image: null,
            offer_partner_id: null,
            offer_partner_image: null,
            offer_partner_logo_original: null,
            offer_partner_logo_1x: null,
            offer_partner_logo_2x: null,
            offer_partner_logo_3x: null,
            status: 'active',
            engage_status: 'active',
            incomm_status: null,
            clip_url: null,
            title: null,
            start_at: null,
            end_at: null,
            name: null,
            description: null,
            verbiage: null,
            print_text: null,
            purse_name: null,
            value: null,
            buy_quantity: null,
            offer_limit: null,
            clip_limit: null,
            customer_limit: null,
            purchase_limit: null,
            terms: null,
            rules: null,
            offer_start: null,
            offer_end: null,
            type: null,
            retailer_ids: [],
            primary_upcs: [null],
            secondary_upcs: [null],
            stack: false,
            max_unit: null,
            max_reward: null,
            user_identification_method: null,
            schedules: [
                { id: null, start_at: null, end_at: null },
            ],
            template_engage: null,
            template_landing: null,
            display_expiration: null,
        },
        originalForm: null,
        warning: {},
        ...state,
    }
}

export const emptySchedule = () => ({ id: null, start_at: null, end_at: null })

export const offerFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state.form[`${action.field}`] = action.value

            break
        }
        case UPDATE_STATE: {
            state[`${action.field}`] = action.value

            break
        }
        case 'ADD_SCHEDULE_FIELD': {
            const schedules = state.form.schedules

            if (schedules[schedules.length - 1].start_at || schedules[schedules.length - 1].end_at) {
                schedules.push({ id: null, start_at: null, end_at: null })
            }

            break
        }
        case 'UPDATE_SCHEDULE_FIELD': {
            const schedules = state.form.schedules

            if (action.id) {
                const scheduleIndex = schedules.findIndex((sched) => sched.id === action.id)

                schedules[scheduleIndex][action.field] = action.value
            } else {
                schedules[action.index][action.field] = action.value
            }

            break
        }
        case 'ADD_UPC_FIELD': {
            const upcs = state.form[`${action.field}`]

            if (upcs[upcs.length - 1]) {
                upcs.push(action.value)
            }

            break
        }
        case 'UPDATE_UPC_FIELD': {
            const upcs = state.form[`${action.field}`]
            const value = action.value

            const cleanString = (str) => str.replace(/,/g, '').trim()

            if (value.includes(',') && action.eventType === 'insertFromPaste') {
                const upcsPasted = value.replace(/\s/g, '').split(',')
                const cleanUpcs = upcs.map((u) => u?.indexOf(',') ? cleanString(u) : u)

                upcsPasted.map((upc) => {
                    const cleanUpc = cleanString(upc)
                    if (cleanUpc && !cleanUpcs.includes(cleanUpc)) {
                        cleanUpcs.unshift(cleanUpc)
                    }
                })

                state.form[`${action.field}`] = cleanUpcs
            } else {
                upcs[action.index] = value
            }

            break
        }
        case 'UPDATE_WARNING': {
            const warning = state.warning

            if (state.originalForm[action.field] != action.value) { // eslint-disable-line
                warning[action.field] = true
            } else {
                warning[action.field] = false
            }

            state.form[`${action.field}`] = action.value

            break
        }
        case 'UPDATE_RETAILER': {
            let retailers = state.form[action.field]

            retailers.includes(action.value) ? retailers = retailers.filter((v) => v !== action.value) : retailers.push(action.value)

            state.form[`${action.field}`] = retailers

            break
        }
        case 'SELECT_ALL_RETAILERS': {
            let retailers = state.form[action.field]

            const allPresent = action.array.every((v) => retailers.includes(v))

            if (allPresent) {
                action.array.forEach(((r) => retailers = retailers.filter((v) => v !== r)))
            } else {
                action.array.forEach((v) => retailers.includes(v) ? null : retailers.push(v))
            }

            state.form[`${action.field}`] = retailers

            break
        }
        case UPDATE_TEMPLATE_FIELD: {
            const template = state.form[action.template]

            setNestedObjectKey(template, (action.field).split('.'), action.value, action.action)

            break
        }
        case 'SET_TEMPLATE': {
            state.form[action.template] = action.value

            break
        }
        case 'SET_DEV': {
            const today = new Date()
            const tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1)
            const week_away = new Date(today)
            week_away.setDate(week_away.getDate() + 7)

            state.form.name = 'Incomm Offer Test'
            state.form.type = 'SPEND'
            state.form.user_identification_method = 'none'
            state.form.title = 'Some title'
            state.form.description = 'Some description'
            state.form.verbiage = 'Some verb'
            state.form.print_text = 'Some print text'
            state.form.terms = 'terms and conditions'
            state.form.value = 400
            state.form.buy_quantity = 10
            state.form.offer_limit = 10
            state.form.clip_limit = 10
            state.form.customer_limit = 10
            state.form.purchase_limit = 10
            state.form.offer_start = `${tomorrow.toISOString().slice(0, -5)}Z`
            state.form.offer_end = `${week_away.toISOString().slice(0, -5)}Z`
            state.form.primary_upcs = ['65748392016574']

            break
        }
        case 'SET_EXPIRATION_DISPLAY': {
            if (action.value === null) {
                state.form.display_expiration = null
                break
            }

            const [name, data] = action.value.split('_')

            state.form.display_expiration = {
                name,
                // data will be undefined for 'static'
                data: parseInt(data) || null,
            }

            break
        }
        case 'SET_STATIC_EXPIRATION': {
            // the input should be disabled when not static, but good to set it anyways
            state.form.display_expiration = {
                name: 'static',
                data: action.value,
            }

            break
        }
        case SET_FORM: {
            const payload = { ...action.value }
            const schedules = [...payload.form?.schedules]

            if (schedules.length === 0) {
                payload.form.schedules.push(emptySchedule())
            }

            return offerFormInitialState(payload)
        }
        case RESET_FORM: {
            return offerFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useOfferFormReducer = (state = {}) => {
    return useReducer(offerFormReducer, offerFormInitialState(state))
}
