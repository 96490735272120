import { useState } from 'react'
import {
    Button,
} from '@mui/material'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import useStyles from './styles'

const CopyButton = ({ title, copy }) => {
    const styles = useStyles()

    const [visible, setVisible] = useState(null)

    return (
        <Button
            type='submit'
            data-cy='submit_offer'
            variant='contained'
            sx={styles.copyItem}
            onClick={() => {
                navigator.clipboard.writeText(copy)
                setVisible(title)
                setTimeout(() => setVisible(null), 2000)
            }}
        >
            <span style={styles.copyIcon}>
                <FileCopyIcon />
            </span>

            {visible === title ? `Copied ${title} to Clipboard` : `Copy ${title} to Clipboard`}
        </Button >
    )
}

export default CopyButton
