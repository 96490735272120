import { clientPortalUrl, velocityUrl, _get, _post } from 'utils'
import qs from 'qs'

export async function createNewClientPortalUser({
    first_name, last_name, email, offer_partners,
}) {
    try {
        const url = await clientPortalUrl('/invitations')

        const offer_partner_ids = offer_partners.map((op) => op.id)

        const body = {
            first_name,
            last_name,
            email,
            offer_partner_ids,
        }

        console.log(':: client_portal_user body submitted ::', body) // eslint-disable-line no-console

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function updateExistingClientPortalUser({ client_portal_user_id, form }) {
    try {
        const url = await velocityUrl(`/client_portal_users/update`)

        const offer_partner_ids = form?.offer_partners.map((op) => op.id)

        const body = {
            client_portal_user_id,
            offer_partner_ids,
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchClientPortalUsers(pageQuery) {
    try {
        const url = await velocityUrl(`/client_portal_users?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}

export async function fetchClientPortalUser(clientPortalUserID) {
    try {
        const url = await velocityUrl(`/client_portal_users/${clientPortalUserID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}
