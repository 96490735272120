const classesFn = () => ({
    container: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    absolute: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        zIndex: 9999999,
        background: ({ withOverlay }) => withOverlay ? 'rgba(255, 255, 255, .35)' : null,
    },
})

export default classesFn
