const VizerLogoMark = ({ size }, props) => (
    <svg
        width={size}
        height={size}
        viewBox='0 0 153 153'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        {...props}
    >
        <title>Vizer Logo</title>
        <defs>
            <linearGradient x1='50%' y1='0%' x2='50%' y2='99.747%' id='prefix__b'>
                <stop stopColor='#FF6969' offset='0%' />
                <stop stopColor='#EF9C70' offset='100%' />
            </linearGradient>
            <path
                // eslint-disable-next-line max-len
                d='M6.838 37.095H40.53l24.704 52.387-4.773 9.22-25.456-52.96h-31.7A66.438 66.438 0 000 66.5C0 103.227 29.773 133 66.5 133c36.727 0 66.5-29.773 66.5-66.5 0-7.249-1.16-14.227-3.304-20.758H100.36l-33.586 65.573-4.514-9.213 32.575-65.007h31.327C115.309 15.117 92.67 0 66.5 0S17.691 15.117 6.838 37.095'
                id='prefix__a'
            />
        </defs>
        <g fill='none' fillRule='evenodd'>
            <circle fill='#FFF' cx={76.5} cy={76.5} r={76.5} />
            <g transform='translate(10 10)'>
                <mask id='prefix__c' fill='#fff'>
                    <use xlinkHref='#prefix__a' />
                </mask>
                <path
                    // eslint-disable-next-line max-len
                    d='M6.838 37.095H40.53l24.704 52.387-4.773 9.22-25.456-52.96h-31.7A66.438 66.438 0 000 66.5C0 103.227 29.773 133 66.5 133c36.727 0 66.5-29.773 66.5-66.5 0-7.249-1.16-14.227-3.304-20.758H100.36l-33.586 65.573-4.514-9.213 32.575-65.007h31.327C115.309 15.117 92.67 0 66.5 0S17.691 15.117 6.838 37.095'
                    fill='url(#prefix__b)'
                    mask='url(#prefix__c)'
                />
            </g>
        </g>
    </svg>
)

export default VizerLogoMark
