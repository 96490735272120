import { colors } from 'theme'

const useStyles = () => ({
    form: {
        marginTop: '15px',
        width: '100%',
    },
    field: {
        minWidth: 0,
        maxWidth: '100%',
        marginBottom: '20px',
    },
    pageHeader: {
        marginBottom: '30px',
    },
    btn: {
        fontWeight: 500,
        '& svg': {
            marginLeft: '10px',
        },
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        marginBottom: '20px',
        '& h1': {
            margin: 0,
        },
    },
    headerButtons: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        '& > button': {
            marginRight: '10px',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    link: {
        textDecoration: 'none',
        color: colors.vizerCoral.main,
    },
    pageRow: {
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
    },
    pageColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 15px',
        '&:nth-of-type(1)': {
            paddingLeft: 0,
            width: '40%',
        },
        '&:nth-of-type(3)': {
            flexGrow: 1,
            paddingRight: 0,
            maxWidth: 400,
            marginLeft: 'auto',
        },
    },
    copy: {
        color: colors.vizerCoral.main,
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        '& svg': {
            marginRight: '10px',
        },
    },
    copyText: {
        color: colors.black,
        background: colors.vizerCoral.main,
        padding: '2.5px 5px',
        borderRadius: '6px',
        position: 'absolute',
        right: -100,
        margin: 'auto',
        width: 'auto',
    },
    messageContainer: {
        background: (theme) => theme.palette.background.default,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: '10px',
        padding: '10px',
        height: '100%',
    },
    message: {
        fontSize: 16,
    },
    status: {
        borderRadius: '6px',
        textAlign: 'center',
        width: '100%',
        fontSize: 24,
        padding: '13px 15px 9px',
        margin: '0 0 15px',
        fontWeight: 600,
        display: 'flex',
    },
    pending: {
        background: colors.warning.light,
        color: colors.vizerCharcoal.dark,
    },
    viewed: {
        background: colors.vizerOrange.main,
        color: colors.white,
    },
    in_progress: {
        background: colors.vizerPeacock.main,
        color: colors.white,
    },
    resolved: {
        background: colors.info.main,
        color: colors.white,
    },
    centered: {
        margin: 'auto',
    },
    divider: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: colors.vizerCharcoal.light,
        margin: '15px',
    },
})

export default useStyles
