import * as colors from './colors'

export const large = {
    primary: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: 15,
        color: colors.white,
        textTransform: 'uppercase',
        letterSpacing: 0.46,
    },
}

export const medium = {
    secondary: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: 14,
        color: colors.vizerCoral.main,
        textTransform: 'uppercase',
        letterSpacing: 0.46,
        backgroundColor: colors.white,
        border: `solid 1px ${colors.vizerCoral.main}`,
        boxShadow: 'none',
    },
}
