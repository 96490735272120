import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useMutationDefaults, useQueryDefaults } from 'queryHelpers'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'
import { useOfferForm } from '../hooks/form'
import {
    createNewOffer,
    createNewShortLink,
    fetchOffers,
    fetchOfferShow,
    updateExistingOffer,
    mockOfferApproval,
    refetchInCommOffer,
    generateStripImages,
    sendEmails,
    fetchDomainsByOffer,
} from '../services'
import { useSendEmailsForm } from '../hooks/send_emails_form'

export const useQueryOffers = (pageQuery, queryArgs = {}) => {
    const queryKey = ['offers', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchOffers(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryOfferShow = (offerID, queryArgs = {}) => {
    const queryKey = ['offers', 'show', offerID]

    return useQuery({
        queryKey,
        queryFn: () => fetchOfferShow(offerID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useCreateOfferCmd = () => {
    const { resetForm } = useOfferForm()

    return useMutation({
        mutationFn: (form) => createNewOffer(form),
        ...useMutationDefaults({
            queryKeys: [['offers', 'list']],
            queryType: ['offer', 'create'],
            navigationPath: '/velocity/offers',
            reset: resetForm,
        }),
    })
}

export const useUpdateOfferCmd = () => {
    const { resetForm } = useOfferForm()

    return useMutation({
        mutationFn: (form) => updateExistingOffer(form),
        ...useMutationDefaults({
            queryKeys: [
                ['offers', 'list'],
                ['offers', 'show', 'id'],
                ['retailersForOffer', 'list', 'id'],
                ['offer_retailers', 'list_by_offer_id', 'id'],
            ],
            queryType: ['offer', 'update'],
            navigationPath: '/velocity/offers',
            reset: resetForm,
        }),
    })
}

export const useCreateShortLinkCmd = () => {
    const queryClient = useQueryClient()
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: createNewShortLink,
        onSuccess: (data, variables) => {
            if (data?.success) {
                const { notices, warnings } = data
                const shortLinkID = data.payload?.short_link.id

                queryClient.invalidateQueries({ queryKey: ['offers', 'show', variables?.offerID] })

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `${shortLinkID}-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `${shortLinkID}-${warn}` }))

                return
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `shortLinkCreate-${data.toString()}` })
        },
    })
}

export const useQueryOfferMockApproval = (offerID) => {
    const queryClient = useQueryClient()
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: () => mockOfferApproval(offerID),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                queryClient.invalidateQueries({ queryKey: ['offers', 'show', offerID] })

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `${offerID}-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `${offerID}-${warn}` }))

                return
            }
            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `mockApproval-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `mockApproval-${data.toString()}` })
        },
    })
}

export const useQueryOfferRefetch = (offerID) => {
    const queryClient = useQueryClient()
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: () => refetchInCommOffer(offerID),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                queryClient.invalidateQueries({ queryKey: ['offers', 'show', offerID] })

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `${offerID}-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `${offerID}-${warn}` }))

                return
            }
            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `incommRefetch-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `incommRefetch-${data.toString()}` })
        },
    })
}

export const useGenerateStripImagesCmd = (setGeneratingImages, offer_id) => {
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (offerId) => generateStripImages(offerId),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                setGeneratingImages(false)

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `${offer_id}-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `${offer_id}-${warn}` }))
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `offers-${err}` })
                })
            }
        },
        onError: (data) => {
            setGeneratingImages(false)

            addNotification({ message: data.toString(), type: 'error', id: `offers-${data.toString()}` })
        },
    })
}

export const useSendEmailsCmd = () => {
    const { resetForm } = useSendEmailsForm()
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (form) => sendEmails(form),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                resetForm()

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `send-emails-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `send-emails-${warn}` }))

                return
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `send-emails-${data.toString()}` })
        },
    })
}

export const useQueryDomainsByOffer = (offerId, pageQuery) => {
    const queryKey = ['domains', 'list', offerId, pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchDomainsByOffer(offerId, pageQuery),
        ...useQueryDefaults(queryKey),
    })
}
