import incommIcon from 'assets/images/incommIcon.png'

const useStyles = () => ({
    incommLogo: {
        height: 22,
        width: 24,
        background: `url('${incommIcon}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        display: 'inline-block',
        marginRight: '4px',
        filter: (theme) => theme.palette.mode === 'light' ? 'brightness(0.2)' : 'none',
    },
    cellLink: {
        textDecoration: 'none',
        color: (theme) => theme.palette.link.main,
        fontWeight: 600,
        '&:visited': {
            color: (theme) => theme.palette.link.visited,
        },
        cursor: 'pointer',
    },
    noResults: {
        padding: '20px 0',
    },
})

export default useStyles
