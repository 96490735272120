import { velocityUrl, _get, _post } from 'utils'
import { isEmpty } from 'helpers'
import qs from 'qs'

export async function fetchRetailers(pageQuery) {
    try {
        const url = await velocityUrl(`/retailers/queries/list_retailers?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}

export async function fetchParentRetailers() {
    try {
        const url = await velocityUrl(`/retailers/queries/list_retailers/parentless`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}

export async function fetchIncommRetailers() {
    try {
        const url = await velocityUrl('/retailers/queries/list_retailers/incomm')

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchRetailersForOffer(offerId) {
    try {
        const params = isEmpty(offerId) ? '' : `?offer_id=${offerId}`
        const url = await velocityUrl(`/retailers/queries/list_retailers_for_offer${params}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}

export async function fetchRetailerShow(retailerId) {
    try {
        const url = await velocityUrl(`/retailers/queries/fetch_retailer_by_id/${retailerId}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function createNewRetailer({
    incomm_retailers, parent_id, status, name, logo, icon,
    redemption_instructions, barcode_type, print_status,
}) {
    try {
        const url = await velocityUrl('/retailers/commands/create_retailer')

        const body = {
            retailer: {
                parent_id,
                status,
                name,
                redemption_instructions,
                print_status,
                barcode_type,
            },
            incomm_retailers,
            logo,
            icon,
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function updateExistingRetailer({
    id, incomm_id, status, name, logo_url, logo, icon, icon_original,
    icon_1x, icon_2x, icon_3x, redemption_instructions, incomm_retailers, barcode_type, print_status,
}) {
    try {
        const url = await velocityUrl(`/retailers/commands/update_retailer`)

        const body = {
            retailer: {
                id,
                incomm_id,
                status,
                name,
                logo_url,
                icon_original,
                icon_1x,
                icon_2x,
                icon_3x,
                redemption_instructions,
                print_status,
                barcode_type,
            },
            incomm_retailers,
            logo,
            icon,
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function generateStripImages(retailer_id) {
    try {
        const url = await velocityUrl(`/retailers/commands/generate_strip_images/${retailer_id}`)

        return await _post(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
