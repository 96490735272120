import Modal from 'react-modal'
import { Box, Button, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useDisablerTimeout } from 'helpers'
import Progress from 'components/ProgressCircle'
import useStyles, { customModalStyles } from './styles'

export const ConfirmationModal = ({ showModal, setShowModal, selectedEmail, setSelectedEmail, onDeleteSubmit }) => {
    const styles = useStyles()
    const disabled = useDisablerTimeout(3000)
    const { email, filter, id } = selectedEmail || {}

    const handleDeleteClick = () => {
        onDeleteSubmit(id)
        setSelectedEmail()
        setShowModal(false)
    }

    Modal.setAppElement('#root')

    return (
        <Modal
            isOpen={!!showModal}
            onRequestClose={() => setShowModal(false)}
            style={customModalStyles}
            contentLabel="Confirm Modal"
        >
            <Button
                variant='contained'
                onClick={() => setShowModal(false)}
                sx={styles.closeButton}
            >
                <ClearIcon />
            </Button>
            <div style={styles.page}>
                <Box sx={styles.container}>

                    <div>
                        <Typography variant='h2' align='center'>
                            Are you sure you want to delete <span style={styles.blockSpan}>{email || filter}</span>?
                        </Typography>
                    </div>
                    <Box sx={styles.buttonContainer}>
                        <Button
                            variant='contained'
                            color='secondary'
                            sx={styles.btn()}
                            onClick={() => setShowModal(false)}
                        >
                            No, Cancel
                        </Button>
                        <Button
                            disabled={disabled}
                            variant='contained'
                            color='secondary'
                            sx={styles.btn('delete')}
                            onClick={handleDeleteClick}
                        >
                            {disabled ? <Progress interval={60} /> : 'Yes, Delete'}
                        </Button>
                    </Box>
                </Box>
            </div>
        </Modal>
    )
}

export default ConfirmationModal
