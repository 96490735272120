import React from 'react'
import { useParams } from 'react-router-dom'
import BrandForm from 'features/brands/components/Form'
import { useEditBrand } from 'features/brands/hooks/edit'

export const BrandEdit = () => {
    const { id } = useParams()
    const editBrandResult = useEditBrand(id)

    return (
        <BrandForm
            edit={true}
            {...editBrandResult}
        />
    )
}

export default BrandEdit
