import { useQuery, useMutation } from '@tanstack/react-query'
import { useMutationDefaults, useQueryDefaults } from 'queryHelpers'
import {
    fetchBlockedEmailPrefixes,
    fetchAllowedEmails,
    deleteAllowedEmail,
    deleteBlockedEmailPrefix,
    createAllowedEmail,
    createBlockedEmailPrefix,
} from '../services'
import { useEmailContainmentForm } from '../hooks/form'

export const useQueryAllowedEmails = (pageQuery, queryArgs = {}) => {
    const queryKey = ['emails', 'allowed', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchAllowedEmails(pageQuery),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useDeleteAllowedEmailCmd = () => {
    return useMutation({
        mutationFn: (id) => deleteAllowedEmail(id),
        ...useMutationDefaults({
            queryKeys: [
                ['emails', 'allowed'],
            ],
            queryType: ['emails', 'allowed', 'delete'],
        }),
    })
}

export const useCreateAllowedEmailCmd = () => {
    const { resetForm } = useEmailContainmentForm()

    return useMutation({
        mutationFn: (form) => createAllowedEmail(form),
        ...useMutationDefaults({
            queryKeys: [
                ['emails', 'allowed'],
            ],
            queryType: ['emails', 'allowed', 'create'],
            reset: resetForm,
        }),
    })
}

export const useQueryBlockedEmailPrefixes = (pageQuery, queryArgs = {}) => {
    const queryKey = ['email_prefixes', 'blocked', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchBlockedEmailPrefixes(pageQuery),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useDeleteBlockedEmailPrefixCmd = () => {
    return useMutation({
        mutationFn: (id) => deleteBlockedEmailPrefix(id),
        ...useMutationDefaults({
            queryKeys: [
                ['email_prefixes', 'blocked'],
            ],
            queryType: ['email_prefixes', 'blocked', 'delete'],
        }),
    })
}

export const useCreateBlockedEmailPrefixCmd = () => {
    const { resetForm } = useEmailContainmentForm()

    return useMutation({
        mutationFn: (form) => createBlockedEmailPrefix(form),
        ...useMutationDefaults({
            queryKeys: [
                ['email_prefixes', 'blocked'],
            ],
            queryType: ['email_prefixes', 'blocked', 'create'],
            reset: resetForm,
        }),
    })
}
