
const useStyles = () => ({
    btnContainer: {
        height: 53,
        display: 'flex',
        flexGrow: { xs: 0, md: 1 },
        mb: { xs: '10px' },
    },
    btn: {
        width: 'auto',
        height: '100%',
        marginRight: '10px',
        flexGrow: { xs: 1, sm: 0 },
    },
    bar: {
        padding: { xs: 0, sm: '20px 0' },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        flexWrap: 'wrap',
    },
    input: {
        width: 'auto',
        minWidth: 215,
        marginRight: { xs: 0, md: '10px' },
        marginBottom: '10px',
        '& .MuiInputLabel-root': {
            textTransform: 'capitalize',
        },
    },
    perPage: {
        width: 'auto',
        minWidth: 150,
        marginLeft: { xs: 0, md: '10px' },
        margin: { xs: '10px 10px 0 10px', sm: 0 },
    },
    refresh: {
        height: '100%',
        width: 50,
        marginLeft: { md: 'auto' },
        margin: { xs: '0 10px', sm: 0 },
    },
})

export default useStyles
