import { colors } from 'theme'

export const useStyles = () => ({
    pageTop: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '50px',
    },
    tabContainer: {
        width: '33%',
    },
    tabBar: {
        borderColor: colors.vizerCoral.dark,
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: '6px',
    },
    tab: {
        width: 'auto',
        minWidth: '50%',
        filter: 'grayscale(1)',
        '&.Mui-selected': {
            filter: 'grayscale(0)',
            backgroundColor: colors.vizerCoral.main,
        },
    },
})
