import { velocityUrl, _get, _delete } from 'utils'

export async function fetchBannerRetailers(parentID) {
    try {
        const url = await velocityUrl(`/retailers/queries/list_banner_retailers_by_parent_id/${parentID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}

export async function deleteBannerRetailer({ parentID, bannerStoreID }) {
    try {
        const url = await velocityUrl(`/retailers/commands/delete_banner_retailer/${parentID}/${bannerStoreID}`)

        return await _delete(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}
