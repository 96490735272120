import { useEffect } from 'react'
import { squashArr } from 'helpers'
import { useDomainForm } from 'features/velocity/domains/hooks/form'
import { useCreateDomainCmd } from 'features/velocity/domains/queries'

const domainTabs = {
    pending: 0,
    blocked: 1,
    allowed: 2,
}

export const useCreateDomain = ({ setShowCreateFormModal, switchTabs }) => {
    const {
        form,
        updateField,
        updateStatusField,
        resetForm,
    } = useDomainForm()

    const createResult = useCreateDomainCmd()
    const onSubmit = () => createResult.mutate(form)

    const warnings = squashArr([createResult.data?.warnings])
    const notices = squashArr([createResult.data?.notices])
    const errors = squashArr([createResult.data?.errors, createResult.error])

    const success = createResult?.data?.success || false
    const domain = createResult?.data?.payload?.domain || null

    useEffect(() => {
        if (success) {
            setShowCreateFormModal(false)
            resetForm()

            switchTabs(domainTabs[domain?.status])
        }
    }, [success])

    const isDisabled = createResult.isLoading || false

    return {
        errors,
        warnings,
        notices,
        domain,
        disabled: isDisabled,
        form,
        updateField,
        updateStatusField,
        onSubmit,
    }
}
