const useStyles = () => ({
    form: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    subtitle: {
        alignSelf: { xs: 'center', md: 'flex-start' },
        margin: '20px',
    },
    cover: {
        minHeight: 100,
        width: 375,
        padding: '0 75px',
        marginBottom: '20px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    base64_cover: {
        width: 'auto',
        height: 'auto',
        maxHeight: 200,
        alignContent: 'center',
    },
    editorTitle: {
        margin: '30px 0 20px',
    },
    btnGroup: {
        margin: '10px',
        width: 400,
        display: 'flex',
        flexDirection: 'column',
    },
    formLabel: {
        marginBottom: '15px',
    },
    btn: {
        margin: '0 5px',
        width: 100,
    },
})

export default useStyles
