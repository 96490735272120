import { squashArr } from 'helpers'
import { useBlockDomainCmd } from '../queries'

export const useBlockDomain = () => {
    const blockResult = useBlockDomainCmd()
    const onBlockSubmit = (domainID) => blockResult.mutate(domainID)

    const warnings = squashArr([blockResult.data?.warnings])
    const notices = squashArr([blockResult.data?.notices])
    const errors = squashArr([blockResult.error, blockResult.data?.errors])

    const isLoadingState = blockResult.isLoading

    return {
        isLoadingState,
        errors,
        warnings,
        notices,
        onBlockSubmit,
    }
}
