import { createSelector } from '@reduxjs/toolkit'

export const image_modal = (state) => state.modals.image_modal
export const imageSel = createSelector(image_modal, (image_modal) => image_modal.image)
export const imageVisibleSel = createSelector(image_modal, (image_modal) => image_modal.visible)

export const graph_modal = (state) => state.modals.graph_modal
export const graphTitleSel = createSelector(graph_modal, (graph_modal) => graph_modal.graphTitle)
export const graphDataSel = createSelector(graph_modal, (graph_modal) => graph_modal.graphData)
export const lineColorsSel = createSelector(graph_modal, (graph_modal) => graph_modal.lineColors)
export const graphVisibleSel = createSelector(graph_modal, (graph_modal) => graph_modal.visible)
export const tickLabelSpacingSel = createSelector(graph_modal, (graph_modal) => graph_modal.tickLabelSpacing)
export const conversionSel = createSelector(graph_modal, (graph_modal) => graph_modal.conversion)
