import qs from 'qs'
import { velocityUrl, _get, _post } from 'utils'

export async function fetchAllowedDomains(pageQuery) {
    try {
        const url = await velocityUrl(`/domains/allowed?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchBlockedDomains(pageQuery) {
    try {
        const url = await velocityUrl(`/domains/blocked?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchPendingDomains(pageQuery) {
    try {
        const url = await velocityUrl(`/domains/pending?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function blockDomain(domainID) {
    try {
        const url = await velocityUrl(`/domains/block/${domainID}`)

        return await _post(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function allowDomain(domainID) {
    try {
        const url = await velocityUrl(`/domains/allow/${domainID}`)

        return await _post(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function createNewDomain({
    domain, status,
}) {
    try {
        const url = await velocityUrl('/domains')

        const body = {
            domain,
            status,
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
