import { SvgIcon } from '@mui/material'

const VelocityLogo = (props) => {
    return (
        <SvgIcon
            viewBox='0 53 125 125'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            {...props}
        >
            <title>Velocity Logo</title>
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M6.838 171.349H81.5947L125.112 61.1963H94.1834L53.1962 171.349ZM62.6385 130.74L47.5282 171.349H45.557L-0.000213623 61.1963H36.7175L62.6385 130.74Z"
                />
            </g>
        </SvgIcon>
    )
}

export default VelocityLogo
