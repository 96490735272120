import Modal from 'react-modal'
import { Box, TextField, Button, Typography } from '@mui/material'
import { AutocompleteStyled, FormColumnStyled, FormStyled, SubmitBlockStyled, TextFieldStyled } from 'theme/forms'
import { DOMAIN_STATUS_OPTIONS } from 'features/status'
import BackButton from 'components/BackButton'
import useStyles, { customModalStyles } from './styles'

export const CreateFormModal = ({
    showModal, setShowModal, disabled, form,
    updateField, updateStatusField, onSubmit,
}) => {
    const styles = useStyles()

    Modal.setAppElement('#root')

    return (
        <Modal
            isOpen={!!showModal}
            onRequestClose={() => setShowModal(false)}
            style={customModalStyles}
            contentLabel="Create Form Modal"
        >
            <Box sx={styles.page}>
                <Box sx={styles.container}>
                    <Typography variant='h1'>New Domain</Typography>

                    <FormStyled
                        noValidate
                        autoComplete='off'
                        onSubmit={(e) => e.preventDefault()}
                        data-testid='domain-form'
                    >
                        <FormColumnStyled>
                            <TextFieldStyled
                                label='Domain'
                                variant='outlined'
                                type='text'
                                data-cy='enter_domain'
                                InputLabelProps={{ shrink: true }}
                                disabled={disabled}
                                value={form?.domain || ''}
                                onChange={updateField('domain')}
                            />
                            <AutocompleteStyled
                                autoComplete
                                disableClearable
                                data-cy='enter_status'
                                options={DOMAIN_STATUS_OPTIONS}
                                getOptionLabel={(value) => value.name || ' '}
                                isOptionEqualToValue={(option) => option.value === form?.status}
                                disabled={disabled}
                                value={DOMAIN_STATUS_OPTIONS.find((type) => type.value === form?.status) || ''}
                                onChange={updateStatusField}
                                renderInput={(params) => (
                                    <TextField {...params} label='Status' variant='outlined' />
                                )}
                            />
                        </FormColumnStyled>
                        <SubmitBlockStyled>
                            <Button
                                type='submit'
                                data-cy='submit_domain'
                                variant='contained'
                                color='secondary'
                                sx={styles.btn}
                                disabled={disabled}
                                onClick={onSubmit}
                            >
                                Submit
                            </Button>

                            <BackButton alternateAction={() => setShowModal(false)} />
                        </SubmitBlockStyled>
                    </FormStyled>
                </Box>
            </Box>
        </Modal>
    )
}

export default CreateFormModal
