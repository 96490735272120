import { useEffect } from 'react'
import { squashArr } from 'helpers'
import { useEmailContainmentForm } from 'features/velocity/email_containment/hooks/form'
import { useCreateBlockedEmailPrefixCmd } from 'features/velocity/email_containment/queries'

export const useCreateBlockedEmailPrefix = () => {
    const {
        form,
        updateField,
        updateStatusField,
        resetForm,
    } = useEmailContainmentForm()

    const createResult = useCreateBlockedEmailPrefixCmd()
    const onSubmit = () => createResult.mutate(form)

    const warnings = squashArr([createResult.data?.warnings])
    const notices = squashArr([createResult.data?.notices])
    const errors = squashArr([createResult.data?.errors, createResult.error])

    const success = createResult?.data?.success || false
    const emailPrefix = createResult?.data?.payload?.email_prefix || null

    useEffect(() => {
        if (success) {
            resetForm()
        }
    }, [success])

    const isDisabled = createResult.isLoading || false

    return {
        errors,
        warnings,
        notices,
        emailPrefix,
        disabled: isDisabled,
        form,
        updateField,
        updateStatusField,
        onSubmit,
    }
}
