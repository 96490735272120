const useStyles = () => ({
    accordion: {
        width: '100%',
        background: `rgba(255, 255, 255, .05)`,
    },
    accordionHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
})

export default useStyles
