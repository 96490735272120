import { useDeleteCatalogItemCmd } from 'features/velocity/catalogs/queries'
import { squashArr } from 'helpers'

export const useCatalogItemDelete = (catalogID) => {
    const deleteItemResult = useDeleteCatalogItemCmd()

    const onDeleteCatalogItem = (upc) => deleteItemResult.mutate({ catalogID, upc })

    const errors = squashArr([deleteItemResult.data?.errors, deleteItemResult.error])
    const notices = deleteItemResult.data?.notices

    const isLoadingState = deleteItemResult.isLoading

    return {
        deleteUPCErrors: errors,
        deleteUPCNotices: notices,
        deleteUPCIsLoading: isLoadingState,
        onDeleteCatalogItem,
    }
}
