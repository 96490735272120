import { useQueryBrands } from 'features/brands/queries'
import { reduceQueries } from 'helpers'

export const useBrands = () => {
    const {
        payloads: [brandsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryBrands({ page: 1, per_page: 1000 })],
    )

    const brands = brandsPayload?.brands || []

    return {
        connectionError,
        success,
        brands,
        errors,
        warnings,
        notices,
        ...isResults,
    }
}
