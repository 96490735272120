import { useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import {
    Box,
    Button,
    CardMedia,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@mui/material'

import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import FieldWithTooltip from 'components/FieldWithTooltip'
import { footerVariants } from 'features/velocity/offers/components/OfferTemplating'
import { TemplateSectionHeader } from '../TemplateSectionHeader'
import { StyleUpdater } from '../StyleUpdater'
import { useOfferTemplate } from 'features/velocity/templates/hooks/template'
import useStyles from '../styles'

export const OfferEngage1 = ({
    disabled, template: offerTemplate, setTemplate, updateTemplate,
    updateTemplateCheckbox, updateTemplateDropdown, templateType,
}) => {
    const styles = useStyles()

    const offerTemplateResult = useOfferTemplate(templateType, 'engage_acacia')
    const { template, templateImage } = offerTemplateResult

    useEffect(() => {
        if (template && offerTemplate?.id !== template.id) {
            setTemplate('template_engage', template)
        }
    }, [offerTemplate?.id, template?.id])

    const updateEngageTemplate = (path) => updateTemplate('template_engage', path)
    const updateEngageTemplateCheckbox = (path, value) => updateTemplateCheckbox('template_engage', path, value)
    const updateEngageTemplateDropdown = (path, value) => updateTemplateDropdown('template_engage', path, value)

    const { intro, retailer_select, wallet, footer } = offerTemplate?.children ?? {}
    const { children: introChildren } = intro ?? {}

    if (offerTemplateResult.isInitialLoading) return <Loading />

    if (offerTemplateResult.connectionError) return <ConnectionError err={offerTemplateResult.connectionError} />

    if (offerTemplateResult.isError) return <ConnectionError err={offerTemplateResult.errors} />

    return (
        <>
            <Box sx={styles.template}>
                <Typography variant='h2' align='center' sx={styles.templateTitle}>Engage Acacia</Typography>

                {!disabled &&
                    <Button
                        sx={styles.resetButton}
                        variant='contained'
                        color='secondary'
                        disabled={disabled}
                        onClick={() => setTemplate('template_engage', template)}
                    >
                        Reset to Default Values
                    </Button>
                }

                <div>
                    <Box sx={styles.templateSection}>
                        <StyleUpdater
                            disabled={disabled}
                            updateTemplateDropdown={updateEngageTemplateDropdown}
                            value={intro?.styles}
                            path='children.intro.styles'
                            css={['color', 'background']}
                        >
                            <Box sx={styles.templateSectionHeader}>
                                <Typography variant='h2'>Intro Section</Typography>
                            </Box>
                        </StyleUpdater>

                        <div style={styles.templateSectionBody}>
                            <StyleUpdater
                                disabled={disabled}
                                updateTemplateDropdown={updateEngageTemplateDropdown}
                                value={introChildren?.heading1?.styles}
                                path='children.intro.children.heading1.styles'
                                css={['color']}
                                variant='field'
                            >
                                <FieldWithTooltip tooltip={'The main page heading'} >
                                    <TextField
                                        label='Heading1'
                                        type='text'
                                        data-cy='2'
                                        sx={styles.field}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={disabled}
                                        value={introChildren?.heading1?.content || ''}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={updateEngageTemplate('children.intro.children.heading1.content')}
                                    />
                                </FieldWithTooltip>
                            </StyleUpdater>

                            <FieldWithTooltip tooltip={'The offer image must be included'} >
                                <FormGroup style={styles.checkbox}>
                                    <FormControlLabel
                                        label="Include Offer Image"
                                        control={
                                            <Checkbox
                                                checked={introChildren?.offer_img?.active || false}
                                                disabled
                                                onChange={updateEngageTemplateCheckbox('children.intro.children.offer_img.active')}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </FieldWithTooltip>

                            <FieldWithTooltip tooltip={'The secondary page heading'} >
                                <TextField
                                    label='Heading2'
                                    type='text'
                                    data-cy='enter_heading2'
                                    sx={styles.field}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={disabled}
                                    value={introChildren?.heading2?.content || ''}
                                    onChange={updateEngageTemplate('children.intro.children.heading2.content')}
                                />
                            </FieldWithTooltip>

                            <StyleUpdater
                                disabled={disabled}
                                updateTemplateDropdown={updateEngageTemplateDropdown}
                                value={introChildren?.retailer_instructions?.styles}
                                path='children.intro.children.retailer_instructions.styles'
                                css={['background']}
                                variant='field'
                            >
                                <FieldWithTooltip
                                    tooltip={'Font color and styling should be handled by updating the Retailer\'s Instructions WYSIWYG'}
                                >
                                    <Typography variant='h3'>Retailer Instructions</Typography>
                                </FieldWithTooltip>
                            </StyleUpdater>

                            <StyleUpdater
                                disabled={disabled}
                                updateTemplateDropdown={updateEngageTemplateDropdown}
                                value={introChildren?.terms?.styles}
                                path='children.intro.children.terms.styles'
                                css={['color']}
                                variant='field'
                            >
                                <Typography variant='h3'>Terms & Conditions</Typography>
                            </StyleUpdater>
                        </div>
                    </Box>

                    <Box sx={styles.templateSection}>
                        <StyleUpdater
                            disabled={disabled}
                            updateTemplateDropdown={updateEngageTemplateDropdown}
                            value={retailer_select?.styles}
                            path='children.retailer_select.styles'
                            css={['color', 'background']}
                        >
                            <Box sx={styles.templateSectionHeader}>
                                <Typography variant='h2'>Choose Retailer Block</Typography>
                            </Box>
                        </StyleUpdater>
                    </Box>

                    <Box sx={styles.templateSection}>
                        <StyleUpdater
                            disabled={disabled}
                            updateTemplateDropdown={updateEngageTemplateDropdown}
                            value={wallet?.styles}
                            path='children.wallet.styles'
                            css={['color', 'background']}
                        >
                            <TemplateSectionHeader
                                disabled={disabled}
                                title='Wallet and Print Section'
                            />
                        </StyleUpdater>
                    </Box>

                    <Box sx={styles.templateSection}>
                        <StyleUpdater
                            disabled={disabled}
                            updateTemplateDropdown={updateEngageTemplateDropdown}
                            value={footer?.styles}
                            path='children.footer.styles'
                            css={['color', 'background']}
                        >
                            <TemplateSectionHeader
                                disabled={disabled}
                                title='Footer'
                                value={footer?.active}
                                onChange={updateEngageTemplateCheckbox('children.footer.active')}
                            />
                        </StyleUpdater>

                        <div style={styles.templateSectionBody}>
                            <Autocomplete
                                autoComplete
                                disableClearable
                                sx={styles.field}
                                data-cy='select_footer_variant'
                                options={footerVariants}
                                getOptionLabel={(op) => op.name || ' '}
                                disabled={disabled || !footer?.active}
                                value={footerVariants.find((op) => op.value === footer?.variant) || ''}
                                onChange={(_, val) => updateEngageTemplateDropdown('children.footer.variant', val.value)}
                                renderInput={(params) => (
                                    <TextField {...params} label='Variant' />
                                )}
                            />
                        </div>
                    </Box>
                </div>

                <div style={styles.imgColumn}>
                    <CardMedia
                        sx={styles.contain}
                        component='img'
                        alt={'template representation'}
                        image={`data:image/png;base64,${templateImage}`}
                        title={'engage_acacia'}
                    />
                </div>
            </Box>

            <IsLoading
                isLoading={offerTemplateResult.isLoading}
                isFetching={offerTemplateResult.isFetching}
                isRefetching={offerTemplateResult.isRefetching}
            />

        </>
    )
}

export default OfferEngage1
