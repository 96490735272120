import { TextField, Button, Typography } from '@mui/material'
import { AutocompleteStyled, FormColumnStyled, FormStyled, SubmitBlockStyled, TextFieldStyled } from 'theme/forms'
import { PageStyled } from 'theme/page'
import BackButton from 'components/BackButton'
import useStyles from './styles'

export const SendEmailsForm = ({
    disabled, offers, form, uploadCSV,
    updateOfferField, onSubmit,
}) => {
    const styles = useStyles()

    return (
        <PageStyled>
            <Typography variant='h1'>Send Offer Emails</Typography>

            <FormStyled
                noValidate
                autoComplete='off'
                onSubmit={(e) => e.preventDefault()}
                data-testid='send-emails-form'
            >
                <FormColumnStyled>
                    <AutocompleteStyled
                        autoComplete
                        disableClearable
                        data-cy='enter_offer'
                        options={offers}
                        getOptionLabel={(op) => op?.name || 'None'}
                        disabled={disabled}
                        value={offers?.find((op) => op?.id === form.offer_id) || ''}
                        onChange={updateOfferField}
                        renderInput={(params) => (
                            <TextField {...params} label='Offer' />
                        )}
                    />
                    <TextFieldStyled
                        label='Emails CSV'
                        type='file'
                        disabled={disabled}
                        data-cy='csv'
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: '.csv' }}
                        onChange={uploadCSV}
                    />
                </FormColumnStyled>

                <SubmitBlockStyled>
                    <Button
                        type='submit'
                        data-cy='submit_offer'
                        variant='contained'
                        color='secondary'
                        sx={styles.btn}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>

                    <BackButton />
                </SubmitBlockStyled>
            </FormStyled>
        </PageStyled>
    )
}

export default SendEmailsForm
