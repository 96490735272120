import { Button } from '@mui/material'
import { TextFieldStyled } from 'theme/forms'
import Row from 'components/Row'
import Cell from 'components/Cell'
import useStyles from './styles'

export const CreateEmailContainmentForm = ({
    variant, disabled, form, updateField, onSubmit,
}) => {
    const styles = useStyles()

    return (
        <Row>
            <Cell align='center' noLink></Cell>
            <Cell scope='email' align='left' noLink>
                {variant === 'email' &&
                    <TextFieldStyled
                        variant='filled'
                        type='text'
                        data-cy='enter_email'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.email || ''}
                        style={styles.field}
                        onChange={updateField('email')}
                    />
                }
                {variant === 'filter' &&
                    <TextFieldStyled
                        variant='filled'
                        type='text'
                        data-cy='enter_email_prefix'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.email_prefix || ''}
                        style={styles.field}
                        onChange={updateField('email_prefix')}
                    />
                }
            </Cell>
            <Cell scope='action' align='right' noLink>
                <Button
                    type='submit'
                    data-cy='submit_domain'
                    variant='contained'
                    color='secondary'
                    sx={styles.btn}
                    disabled={disabled}
                    onClick={onSubmit}
                >
                    {`Add New ${variant}`}
                </Button>
            </Cell>
        </Row>
    )
}

export default CreateEmailContainmentForm
