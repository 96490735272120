import { squashArr } from 'helpers'
import { useAllowDomainCmd } from '../queries'

export const useAllowDomain = () => {
    const allowResult = useAllowDomainCmd()
    const onAllowSubmit = (domainID) => allowResult.mutate(domainID)

    const warnings = squashArr([allowResult.data?.warnings])
    const notices = squashArr([allowResult.data?.notices])
    const errors = squashArr([allowResult.error, allowResult.data?.errors])

    const isLoadingState = allowResult.isLoading

    return {
        isLoadingState,
        errors,
        warnings,
        notices,
        onAllowSubmit,
    }
}
