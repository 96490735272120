import { squashArr } from 'helpers'
import { useBrandForm } from 'features/brands/hooks/form'
import { useCreateBrandCmd } from 'features/brands/queries'

export const useCreateBrand = () => {
    const {
        form,
        updateField,
        updateImageField,
        updateWYSIWYG,
    } = useBrandForm()

    const createResult = useCreateBrandCmd()
    const onSubmit = () => createResult.mutate(form)

    const warnings = squashArr([
        createResult.data?.warnings,
    ])
    const notices = squashArr([
        createResult.data?.notices,
    ])
    const errors = squashArr([createResult.error])
    const isLoadError = errors?.length > 0

    const isLoadingState = createResult.isLoading

    return {
        errors,
        isLoadingState,
        loadSuccess: !isLoadingState,
        isLoadError,
        warnings,
        notices,
        disabled: isLoadingState,
        form,
        updateField,
        updateImageField,
        updateWYSIWYG,
        onSubmit,
    }
}
