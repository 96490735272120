import { put, takeEvery, select } from 'redux-saga/effects'
import { createNewPermission, createNewUserPermission, createNewRolePermission } from '../services'
import {
    newPermission,
    newPermissionResult,
    newPermissionError,
    createPermission,
    createPermissionResult,
    createPermissionError,
} from '../reducers/create'

export function* takeNewPermission() {
    try {
        yield put(newPermissionResult())
    } catch (error) {
        yield put(newPermissionError(error.toString()))
    }
}

export function* watchNewPermission() {
    yield takeEvery(newPermission.toString(), takeNewPermission)
}

export function* takeCreatePermission() {
    try {
        const form = yield select((state) => state.permissions.create.permission)
        const userID = yield select((state) => state.permissions.create.permission.user_id)
        const roleID = yield select((state) => state.permissions.create.permission.role_id)

        let payload
        if (userID) {
            ({ payload } = yield createNewUserPermission(userID, form))
        } else if (roleID) {
            ({ payload } = yield createNewRolePermission(roleID, form))
        } else {
            ({ payload } = yield createNewPermission(form))
        }

        yield put(createPermissionResult({ permission: payload }))
    } catch (error) {
        yield put(createPermissionError(error.toString()))
    }
}

export function* watchCreatePermission() {
    yield takeEvery(createPermission.toString(), takeCreatePermission)
}
