import { useQueryIncommRetailers } from 'features/velocity/retailers/queries'

export const useIncommRetailers = () => {
    const result = useQueryIncommRetailers()
    const error = result.error

    if (error) return { connectionError: error, ...result }

    const { success, payload, errors, warnings, notices } = result.data || {}

    const retailers = payload?.retailers || []
    const incommRetailers = retailers.map((ret) => ({ displayName: `(${ret.incomm_id}) ${ret.name}`, ...ret }))

    return {
        success,
        incommRetailers,
        errors,
        warnings,
        notices,
        ...result,
    }
}
