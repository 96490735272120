import { useQueryCatalogs } from 'features/velocity/catalogs/queries'
import { reduceQueries } from 'helpers'

export const useCatalogs = (queryArgs = {}) => {
    const {
        payloads: [catalogsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryCatalogs(queryArgs)],
    )

    const catalogs = catalogsPayload?.catalogs || []

    return {
        connectionError,
        success,
        catalogs,
        errors,
        warnings,
        notices,
        ...isResults,
    }
}

