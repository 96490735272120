import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import ClientPortalUsersForm from 'features/velocity/client_portal_users/components/Form'
import { useCreateClientPortalUser } from 'features/velocity/client_portal_users/hooks/create'
import useStyles from './styles'

export const ClientPortalUsersNew = () => {
    const navigate = useNavigate()
    const styles = useStyles()
    const createClientPortalUsersResult = useCreateClientPortalUser()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <ClientPortalUsersForm
                edit={false}
                {...createClientPortalUsersResult}
            />
        </>
    )
}

export default ClientPortalUsersNew
