import CatalogList from './List'
import CatalogShow from './Show'
import CatalogEdit from './Edit'
import CatalogNew from './New'

const CatalogsRoutes = {
    path: 'catalogs',
    children: [
        {
            path: '',
            element: <CatalogList />,
        },
        {
            path: 'new',
            element: <CatalogNew />,
        },
        {
            path: ':id/edit',
            element: <CatalogEdit />,
        },
        {
            path: ':id',
            element: <CatalogShow />,
        },
    ],
}

export default CatalogsRoutes
