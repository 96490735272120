import { Typography } from '@mui/material'
import useStyles from './styles'

const HelpFormRequestStatus = ({ status, variant }) => {
    const styles = useStyles({ variant })

    return (
        <Typography sx={styles.status({ status, variant })}>
            <span style={styles.centered}>
                {status?.replaceAll('_', ' ').toUpperCase()}
            </span>
        </Typography>
    )
}

export default HelpFormRequestStatus
