import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useQueryRetailers } from 'features/velocity/retailers/queries'
import { reduceQueries } from 'helpers'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'

export const useRetailers = (state = {}) => {
    const queryClient = useQueryClient()

    const {
        setPage,
        resetPage,
        updateFilter,
        setSearch,
        resetFilters,
        params,
        search,
        pageState,
        paginationQueryArgs,
        resetFiltersDisabled: disabled,
    } = usePaginationFilters({ state, debounceSkipKeys: ['retailer_status'] })

    const {
        payloads: [retailerPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries([useQueryRetailers(params, paginationQueryArgs)])

    const resetFiltersDisabled = isResults.isFetching || disabled

    const retailers = retailerPayload?.retailers || []
    const filters = retailerPayload?.filters || {}
    const pagination = retailerPayload?.pagination || {}

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    const invalidateListQueries = () => queryClient.invalidateQueries({ queryKey: ['retailers', 'list'] })

    return {
        connectionError,
        success,
        retailers,
        errors,
        warnings,
        notices,
        filters,
        pagination,
        setPage,
        updateFilter,
        setSearch,
        search,
        resetFilters,
        pageState,
        invalidateListQueries,
        resetFiltersDisabled,
        ...isResults,
    }
}
