import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { reactQueryDefaultRefetch, reactQueryDefaultStaleTime } from 'utils'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'

export const queryCacheDefaults = () => {
    return {
        onError: (error, query) => {
            if (query.meta.notify) {
                query.meta.notify(error.toString(), 'error')
            }
        },
        onSuccess: (data, query) => {
            if (data?.success && data?.notices && query.meta.notify) {
                data?.notices.map((note) => {
                    query.meta.notify(note, 'success')
                })
            }
            if (data?.success && data?.warnings && query.meta.notify) {
                data?.warnings.map((warn) => {
                    query.meta.notify(warn, 'warning')
                })
            }
            if (data?.errors?.length > 0 && query.meta.notify) {
                data.errors.map((err) => {
                    query.meta.notify(err.toString(), 'error')
                })
            }
        },
    }
}

export const queryOptionsDefaults = () => ({
    staleTime: reactQueryDefaultStaleTime(),
    cacheTime: 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: reactQueryDefaultRefetch(),
    refetchIntervalInBackground: true,
})

export const useQueryDefaults = (queryKey) => {
    const { addNotification } = usePageNotifications()

    return {
        meta: {
            notify: (message, type) => addNotification({ message, type, id: `${queryKey.toString()}-${message}` }),
        },
    }
}

export const useMutationDefaults = (props) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { addNotification } = usePageNotifications()

    const { queryKeys, queryType, navigationPath, navID, reset } = props
    const notificationID = `${[queryType[0]]}-${[queryType[1]]}`

    return {
        onSuccess: (data) => {
            if (data?.success) {
                const id = data?.payload[queryType[0]]?.id || navID || ''
                const { notices, warnings } = data

                queryKeys.map((queryKey) => {
                    const replaceIdIndex = queryKey.indexOf('id')

                    if (replaceIdIndex >= 0) queryKey[replaceIdIndex] = id

                    queryClient.invalidateQueries({ queryKey })
                })

                if (reset instanceof Function) reset()

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `${id}-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `${id}-${warn}` }))

                if (navigationPath) {
                    navigate(`${navigationPath}/${id}`, { replace: true })
                } else {
                    return
                }
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `${notificationID}-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `${notificationID}-${data.toString()}` })
        },
    }
}
