import { squashArr } from 'helpers'
import {
    useQueryCustomerActivity,
    useQueryCustomerClips,
    useQueryCustomerStatus,
    useQueryCustomerOfferHistory,
} from 'features/velocity/incomm_debug/queries'
import { useCustomerForm } from './form'

export const useCustomerActivity = () => {
    const customerForm = useCustomerForm()
    const customerStatusResult = useQueryCustomerStatus()
    const customerActivityResult = useQueryCustomerActivity()
    const customerClipsResult = useQueryCustomerClips()
    const customerOfferClipsResult = useQueryCustomerOfferHistory()

    const onSubmit = () => {
        customerForm.saveID('submittedID', customerForm.form.ingenID)
        customerStatusResult.mutate(customerForm.form)
        customerActivityResult.mutate(customerForm.form)
        customerClipsResult.mutate(customerForm.form)

        customerOfferClipsResult.reset()
        customerForm.resetField('offerNumber')
    }

    const onOfferSubmit = () => {
        customerOfferClipsResult.mutate(customerForm.form)
    }

    const error = customerActivityResult.error

    if (error) return { connectionError: error, ...customerActivityResult }

    const { success: customerSuccess, payload: customerPayload, errors: activityErrors } = customerActivityResult.data || {}
    const { success: statusSuccess, payload: statusPayload, errors: statusErrors } = customerStatusResult.data || {}
    const { success: clipsSuccess, payload: clipsPayload, errors: clipsErrors } = customerClipsResult.data || {}
    const { success: offerSuccess, payload: offerPayload, errors: offerErrors } = customerOfferClipsResult.data || {}

    const warnings = squashArr([
        customerStatusResult.data?.warnings,
        customerActivityResult.data?.warnings,
        customerClipsResult.data?.warnings,
        customerOfferClipsResult.data?.warnings,
    ])
    const notices = squashArr([
        customerStatusResult.data?.notices,
        customerActivityResult.data?.notices,
        customerClipsResult.data?.notices,
        customerOfferClipsResult.data?.notices,
    ])
    const errors = squashArr([
        customerStatusResult.error,
        statusErrors,
        customerActivityResult.error,
        activityErrors,
        customerClipsResult.error,
        clipsErrors,
        customerOfferClipsResult.error,
        offerErrors,
    ])

    const isLoadingState =
        customerActivityResult.isLoading ||
        customerActivityResult.isLoading ||
        customerClipsResult.isLoading

    const activity = customerSuccess ? customerPayload?.activity : []
    const activityStatus = customerSuccess ? customerPayload?.activity.status : []
    const customerStatus = statusSuccess ? statusPayload?.customer : null
    const clips = clipsSuccess ? clipsPayload.offers : []
    const offerInfo = offerSuccess ? offerPayload.offer_info : null

    const activityObject = activity?.constructor === Object

    return {
        activity,
        activityObject,
        activityStatus,
        customerStatus,
        clips,
        offerInfo,
        errors,
        warnings,
        notices,
        disabled: isLoadingState,
        isLoadingState,
        ...customerActivityResult,
        ...customerForm,
        onSubmit,
        onOfferSubmit,
    }
}
