import { all } from 'redux-saga/effects'
import { watchFetchPermissions } from './list'
import { watchFetchPermission } from './show'
import { watchNewPermission, watchCreatePermission } from './create'
import { watchEditPermission, watchUpdatePermission } from './edit'

export default function* permissions() {
    yield all([
        watchFetchPermissions(),
        watchFetchPermission(),
        watchNewPermission(),
        watchCreatePermission(),
        watchEditPermission(),
        watchUpdatePermission(),
    ])
}
