import { RESET_FORM, SET_FORM, UPDATE_FIELD } from '../../status'
import { useReducer } from 'reducerHelpers'

export const invitationFormInitialState = (state = {}) => {
    return {
        form: {
            first_name: null,
            last_name: null,
            email: null,
            password: null,
            password_confirmation: null,
        },
        ...state,
    }
}

export const invitationFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state.form[`${action.field}`] = action.value

            break
        }
        case SET_FORM: {
            state.form = { ...state.form, ...action.value }

            break
        }
        case RESET_FORM: {
            return invitationFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useInvitationFormReducer = (state = {}) => {
    return useReducer(invitationFormReducer, invitationFormInitialState(state))
}
