import Loading from 'components/Loading'

export const IsLoading = ({ isLoading, isFetching, isRefetching, isInitialLoading, isLoadingState, withOverlay }) => {
    if (isLoading || isFetching || isRefetching || isInitialLoading || isLoadingState) {
        return <Loading absolute={true} withOverlay={withOverlay} />
    } else {
        return null
    }
}

export default IsLoading
