import { useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Fade,
    Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import LaunchIcon from '@mui/icons-material/Launch'
import { DescriptionListStyled } from 'theme/styled_components'
import ShortLinkForm from '../ShortLinkForm'
import useStyles from './styles'

export const ShortLinks = ({ offer, links }) => {
    const styles = useStyles()

    const [visible, setVisible] = useState(null)
    const [modalVisible, setModalVisible] = useState(false)

    const [expanded, setExpanded] = useState([])

    const toggleAcordion = (link_id) => {
        if (!expanded.includes(link_id)) {
            setExpanded((arr) => [...arr, link_id])
        } else {
            setExpanded((arr) => [...arr.filter((id) => id !== link_id)])
        }
    }

    return (
        <>
            <ShortLinkForm
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                offer={offer}
            />

            <div style={styles.tabHeader}>
                <Typography variant='h2'>Short Links</Typography>
                <Button
                    sx={styles.accordionBtn}
                    variant='contained'
                    color='secondary'
                    data-cy='edit-btn'
                    onClick={() => setModalVisible(true)}
                >
                    Add Link
                </Button>
            </div>

            <div style={styles.linksContainer}>
                {links?.length > 0 &&
                    links.map(({ offer_short_link, short_link }, i) => {
                        const { utm_codes } = short_link

                        return (
                            <Box key={offer_short_link.short_link_url} sx={styles.linkContainer}>
                                <Typography variant='h3' sx={styles.linkName}>{offer_short_link?.name}</Typography>

                                <Accordion sx={styles.accordion} expanded={expanded.includes(offer_short_link.id)}>
                                    <AccordionSummary
                                        expandIcon={
                                            <ExpandMoreIcon
                                                sx={styles.expandIcon}
                                                onClick={() => toggleAcordion(offer_short_link.id)}
                                            />
                                        }
                                        aria-controls='short-link-content'
                                        id='short-link-header'
                                        sx={styles.accordionHeader}

                                    >
                                        <p style={styles.linkItem}>
                                            {offer_short_link.short_link_url}
                                            <span
                                                style={styles.copyIcon}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(offer_short_link.short_link_url)
                                                    setVisible(offer_short_link.short_link_url)
                                                    setTimeout(() => setVisible(null), 2000)
                                                }}
                                            >
                                                <FileCopyIcon />

                                                <Fade
                                                    in={visible === offer_short_link.short_link_url}
                                                    style={{ transitionDelay: '150ms' }}
                                                >
                                                    <span style={styles.copyText}>
                                                        {'Copied!'}
                                                    </span>
                                                </Fade>
                                            </span>
                                            <span style={styles.iconBlock}>
                                                <a
                                                    style={styles.link}
                                                    href={offer_short_link.short_link_url}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    <LaunchIcon sx={styles.linkIcon} />
                                                </a>
                                            </span>
                                        </p>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <DescriptionListStyled>
                                            <div style={styles.listRow}>
                                                <div>
                                                    <dt>Source</dt>
                                                    <dd>{utm_codes.utm_source || 'None'}</dd>
                                                </div>
                                                <div>
                                                    <dt>Medium</dt>
                                                    <dd>{utm_codes.utm_medium || 'None'}</dd>
                                                </div>
                                                <div>
                                                    <dt>Campaign</dt>
                                                    <dd>{utm_codes.utm_campaign || 'None'}</dd>
                                                </div>
                                            </div>
                                            <div style={styles.listRow}>
                                                <div>
                                                    <dt>Content</dt>
                                                    <dd>{utm_codes.utm_content || 'None'}</dd>
                                                </div>
                                                <div>
                                                    <dt>Term</dt>
                                                    <dd>{utm_codes.utm_term || 'None'}</dd>
                                                </div>
                                            </div>
                                        </DescriptionListStyled>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        )
                    })}
            </div>
        </>
    )
}

export default ShortLinks
