import DataTile from '../DataTile'
import useStyles, { GridStyled } from './styles'
import GetAppIcon from '@mui/icons-material/GetApp'
import {
    Button,
} from '@mui/material'
import { useOfferDashboardAnalytics } from '../../hooks/offer_analytics'

export const OfferDashboard = ({ offerID, strategy }) => {
    const styles = useStyles()

    const {
        analytics,
        downloadSummaryExportCSV,
        downloadDailySummaryExportCSV,
    } = useOfferDashboardAnalytics(offerID, strategy)

    const {
        totalClicks,
        uniqueClicks,
        clicksToClips,
        totalClips,
        uniqueClips,
        clipsToScans,
        totalScans,
        uniqueScans,
        totalScanRefunds,
        uniqueScanRefunds,
    } = analytics || {}

    return (
        <>
            <div style={styles.exports}>
                <Button
                    style={styles.exportBtn}
                    variant='contained'
                    color='secondary'
                    data-cy='export-summary-btn'
                    onClick={downloadSummaryExportCSV}
                    title='Download summary data as CSV'
                >
                    Summary Export
                    <GetAppIcon />
                </Button>

                <Button
                    style={styles.exportBtn}
                    variant='contained'
                    color='secondary'
                    data-cy='export-daily-summary-btn'
                    onClick={downloadDailySummaryExportCSV}
                    title='Download daily summary data as CSV'
                >
                    Daily Summary Export
                    <GetAppIcon />
                </Button>
            </div>
            <GridStyled>
                <div style={styles.totalClicks}>
                    <DataTile
                        total={totalClicks}
                        subject={'CLICKS'}
                        suffix={'(Total)'}
                        color={'vizerPeacock'}
                        border={'solid'}
                        percentage={false}
                    />
                </div>
                <div style={styles.uniqueClicks}>
                    <DataTile
                        total={uniqueClicks}
                        subject={'CLICKS'}
                        suffix={'(Unique)'}
                        color={'vizerPeacock'}
                        border={'dashed'}
                        percentage={false}
                    />
                </div>
                <div style={styles.clicksToClips}>
                    <DataTile
                        total={clicksToClips}
                        subject={'CLICK TO CLIP CONVERSION'}
                        color={'vizerIndigo'}
                        border={'solid'}
                        percentage={true}
                    />
                </div>
                <div style={styles.totalClips}>
                    <DataTile
                        total={totalClips}
                        subject={'CLIPS'}
                        suffix={'(Total)'}
                        color={'vizerOrchid'}
                        border={'solid'}
                        percentage={false}
                    />
                </div>
                <div style={styles.uniqueClips}>
                    <DataTile
                        total={uniqueClips}
                        subject={'CLIPS'}
                        suffix={'(Unique)'}
                        color={'vizerOrchid'}
                        border={'dashed'}
                        percentage={false}
                    />
                </div>
                <div style={styles.clipsToScans}>
                    <DataTile
                        total={clipsToScans}
                        subject={'CLIP TO SCAN CONVERSION'}
                        color={'vizerBubblegum'}
                        border={'solid'}
                        percentage={true}
                    />
                </div>
                <div style={styles.totalScans}>
                    <DataTile
                        total={totalScans}
                        subject={'SCANS'}
                        suffix={'(Total)'}
                        color={'vizerCoral'}
                        border={'solid'}
                        percentage={false}
                    />
                </div>
                <div style={styles.uniqueScans}>
                    <DataTile
                        total={uniqueScans}
                        subject={'SCANS'}
                        suffix={'(Unique)'}
                        color={'vizerCoral'}
                        border={'dashed'}
                        percentage={false}
                    />
                </div>
                <div style={styles.totalScanRefunds}>
                    <DataTile
                        total={totalScanRefunds}
                        subject={'REFUNDS'}
                        suffix={'(Total)'}
                        color={'vizerCherry'}
                        border={'solid'}
                        percentage={false}
                    />
                </div>
                <div style={styles.uniqueScanRefunds}>
                    <DataTile
                        total={uniqueScanRefunds}
                        subject={'REFUNDS'}
                        suffix={'(Unique)'}
                        color={'vizerCherry'}
                        border={'dashed'}
                        percentage={false}
                    />
                </div>
            </GridStyled>
        </>
    )
}

export default OfferDashboard
