import { createSlice } from '@reduxjs/toolkit'
import {
    PENDING,
    REQUESTING,
    SUCCESS,
    ERROR,
} from 'features/status'

const name = 'csvUploader'

const initialState = {
    status: PENDING,
    file: null,
    error: null,
    disabled: false,
}

const reducers = {
    selectedFile: (state, { payload }) => {
        const { file } = payload

        state.status = REQUESTING
        state.file = file
        state.disabled = true
    },
    handleSubmitFileResult: (state) => {
        state.status = SUCCESS
        state.disabled = true
    },
    handleSubmitFileError: (state, { payload }) => {
        state.status = ERROR
        state.errors = payload
        state.disabled = false
    },
    resetButton: () => initialState,
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    selectedFile,
    handleSubmitFileResult,
    handleSubmitFileError,
    resetButton,
    disabled,
} = slice.actions

export default slice.reducer
