import { Accordion } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'theme'

const drawerWidth = 300

export const AccordionStyled = styled(Accordion)(({ open, theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.background,
    borderTop: `2px solid ${colors.vizerIceGray.main}`,
    ...(open && {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: 'none',
    }),
    ...(!open && {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    }),
}))

export const classesFn = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: (theme) => theme.palette.background,
        borderRight: `2px solid ${colors.vizerIceGray.main}`,
    },
    menuButton: {
        color: (theme) => theme.palette.icons.main,
        borderRadius: 0,
    },
    filterTitle: {
        display: 'flex',
        width: 100,
        alignItems: 'center',
        justifyContent: 'center',
    },
    label: {
        fontSize: '0.975rem',
        textAlign: 'center',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 500,
        whiteSpace: 'normal',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        paddingTop: '3px',
    },
    filterList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 0',
        height: '100%',
    },
    field: {
        maxWidth: 250,
        position: 'relative',
        margin: '5px',
        width: '100%',
        '& label.Mui-disabled': {
            color: `${colors.vizerIceGray.dark}`,
        },
        '& input.Mui-disabled': {
            color: `${colors.vizerIceGray.dark}`,
        },
    },
    buttonContainer: {
        maxWidth: 250,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    btn: {
        width: 'auto',
        margin: '10px 0px 20px 5px',
    },
    graphContainer: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: `2px solid ${colors.vizerIceGray.main}`,
        border: { lg: `2px solid ${colors.vizerIceGray.main}` },
        borderRadius: { lg: '6px' },
    },
    graphContainerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        margin: { lg: '25px 35px' },
    },
    chevronLeft: {
        position: 'absolute',
        right: '17px',
    },
    exportButtonContainer: {
        maxWidth: 250,
        width: '100%',
        marginTop: 'auto',
        textAlign: 'right',
    },
    exportBtn: {
        marginLeft: 'auto',
        '& svg': {
            marginLeft: '5px',
        },
    },
    tooltipHeader: {
        display: 'flex',
        justifyContent: 'end',
        padding: '0px 5px 5px 0px',
    },
    tooltip: {
        color: colors.vizerCoral.main,
        cursor: 'pointer',
        '& :hover': {
            color: colors.vizerCoral.light,
        },
    },
    infoIcon: {
        fontSize: '1.5rem',
    },
})
