const useStyles = () => ({
    btn: {
        width: 'auto',
        marginBottom: '20px',
    },
    noResults: {
        padding: '20px 0',
    },
})

export default useStyles
