import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useMutationDefaults, useQueryDefaults } from 'queryHelpers'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'
import { useCatalogForm } from 'features/velocity/catalogs/hooks/form'
import {
    createCatalogItems,
    createNewCatalog,
    deleteCatalogItem,
    deleteExistingCatalog,
    fetchCatalogItems,
    fetchCatalogs,
    fetchCatalogShow,
    updateExistingCatalog,
} from 'features/velocity/catalogs/services'

export const useQueryCatalogs = (queryArgs = {}) => {
    const queryKey = ['catalogs', 'list']

    return useQuery({
        queryKey,
        queryFn: () => fetchCatalogs(),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryCatalogShow = (catalogID, queryArgs = {}) => {
    const queryKey = ['catalogs', 'show', catalogID]

    return useQuery({
        queryKey,
        queryFn: () => fetchCatalogShow(catalogID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useCreateCatalogCmd = () => {
    const { resetForm } = useCatalogForm()

    return useMutation({
        mutationFn: (form) => createNewCatalog(form),
        ...useMutationDefaults({
            queryKeys: [['catalogs', 'list']],
            queryType: ['catalog', 'create'],
            navigationPath: '/velocity/catalogs',
            reset: resetForm,
        }),
    })
}

export const useUpdateCatalogCmd = () => {
    const { resetForm } = useCatalogForm()

    return useMutation({
        mutationFn: (form) => updateExistingCatalog(form),
        ...useMutationDefaults({
            queryKeys: [
                ['catalogs', 'list'],
                ['catalogs', 'show', 'id'],
            ],
            queryType: ['catalog', 'update'],
            navigationPath: '/velocity/catalogs',
            reset: resetForm,
        }),
    })
}

export const useDeleteCatalogCmd = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (form) => deleteExistingCatalog(form),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                queryClient.invalidateQueries({ queryKey: ['catalogs', 'list'] })

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `catalogDelete-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `catalogDelete-${warn}` }))

                navigate(`/velocity/catalogs`, { replace: true })
            }

            if (data?.errors?.length > 0) {
                data?.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `catalogDelete-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `catalogDelete-${data.toString()}` })
        },
    })
}

export const useQueryCatalogItems = (catalogID) => {
    const queryKey = ['catalog_items', 'list', catalogID]

    return useQuery({
        queryKey,
        queryFn: () => fetchCatalogItems(catalogID),
        ...useQueryDefaults(queryKey),
    })
}

export const useCreateCatalogItemCmd = () => {
    const queryClient = useQueryClient()
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (params) => createCatalogItems(params),
        onSuccess: (data, variables) => {
            if (data?.success) {
                const { notices, warnings } = data
                const catalogID = variables?.catalogID

                queryClient.invalidateQueries({ queryKey: ['catalogs', 'show', catalogID] })
                queryClient.invalidateQueries({ queryKey: ['catalog_items', 'list', catalogID] })

                if (notices) {
                    notices.map((note) => addNotification({ message: note, type: 'success', id: `catalogItemCreate-${catalogID}-${note}` }))
                }
                if (warnings) {
                    warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `catalogItemCreate-${catalogID}-${warn}` }))
                }

                return
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `catalogItemCreate-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `catalogItemCreate-${data.toString()}` })
        },
    })
}

export const useDeleteCatalogItemCmd = () => {
    const queryClient = useQueryClient()
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (params) => deleteCatalogItem(params),
        onSuccess: (data, variables) => {
            if (data?.success) {
                const { notices, warnings } = data
                const catalogID = variables?.catalogID

                queryClient.invalidateQueries({ queryKey: ['catalogs', 'show', catalogID] })
                queryClient.invalidateQueries({ queryKey: ['catalog_items', 'list', catalogID] })

                if (notices) {
                    notices.map((note) => addNotification({ message: note, type: 'success', id: `catalogItemDelete-${catalogID}-${note}` }))
                }
                if (warnings) {
                    warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `catalogItemDelete-${catalogID}-${warn}` }))
                }

                return
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `catalogItemDelete-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `catalogItemDelete-${data.toString()}` })
        },
    })
}
