import { all, put, takeEvery } from 'redux-saga/effects'
import {
    selectedFile,
    handleSubmitFileResult,
    handleSubmitFileError,
} from './reducer'
import { uploadCSVFile } from './service'

export default function* csv_upload() {
    yield all([
        watchSelectedFile(),
    ])
}

function* watchSelectedFile() {
    yield takeEvery(selectedFile.toString(), takeSelectedFile)
}

function* takeSelectedFile(action) {
    try {
        const { file, upload_path } = action.payload
        const { success, errors } = yield uploadCSVFile(file, upload_path)

        if (!success) {
            yield put(handleSubmitFileError(errors.join(', ')))
            return
        }

        yield put(handleSubmitFileResult())
    } catch (errors) {
        yield put(handleSubmitFileError(errors.toString()))
    }
}
