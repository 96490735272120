import { Typography } from '@mui/material'
import useHealthCheck from './hooks'
import { SUCCESS } from '../status'
import Loading from 'components/Loading'

export const APIVersion = () => {
    const {
        status,
        success,
        version,
        latency,
        db_connected,
    } = useHealthCheck()

    if (status !== SUCCESS && success !== true) {
        return <Loading inline />
    }

    return (
        <>
            <Typography variant='h3'>API Version: {version}</Typography>
            <Typography variant='h3'>
                Database: {db_connected} ({latency})
            </Typography>
        </>
    )
}

export default APIVersion
