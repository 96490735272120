import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { classesFn } from '../styles'

const AuthPageWrapper = () => {
    const classes = classesFn()

    return (
        <main>
            <Header />
            <section style={classes.page}>
                <aside style={classes.backgroundWrapper}>
                    <Box sx={classes.backgroundSpacer}></Box>
                    <div style={{ ...classes.background, ...classes.backgroundColor }}></div>
                    <div style={{ ...classes.background, ...classes.backgroundImage }}></div>
                </aside>
                <Outlet />
            </section>
            <Footer />
        </main>
    )
}

export default AuthPageWrapper
