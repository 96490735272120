import { createSlice } from '@reduxjs/toolkit'
import { PENDING, FETCHING, SUCCESS, ERROR } from 'features/status'

const name = 'healthCheck'

const initialState = {
    status: PENDING,
    data: {},
}

const reducers = {
    checkHealth: (state) => {
        state.status = FETCHING
    },
    checkHealthResult: (state, { payload }) => {
        state.status = SUCCESS
        state.data = payload
    },
    checkHealthError: (state, { payload }) => {
        state.status = ERROR
    },
}

const slice = createSlice({ name, initialState, reducers })

export const {
    checkHealth,
    checkHealthResult,
    checkHealthError,
} = slice.actions

export default slice.reducer
