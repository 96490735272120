import { Box, Typography } from '@mui/material'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import { useGraphModal } from 'features/modals/hooks/graph_modal'
import { LineGraph } from 'features/velocity/analytics/components/LineGraph'
import { isEmpty } from 'helpers'
import useStyles from './styles'

export const AnalyticsGraph = ({ graphTitle, graphData, lineColors, tickLabelSpacing, conversion }) => {
    const styles = useStyles()
    const { setGraphModal } = useGraphModal()

    if (isEmpty(graphData)) return null

    const dataArr = graphData.map((item) => {
        const data = item.data.map((data) => {
            return data.y
        })

        const dataPresent = data.some((y) => y > 0)

        return dataPresent
    })
    const noData = !dataArr.some((bool) => bool === true)

    return (
        <div style={styles.graphDisplayWrapper}>
            <Typography variant='h2' sx={styles.graphTitle}>{graphTitle}</Typography>

            <div
                style={styles.graphButton}
                onClick={() => setGraphModal({ graphTitle, graphData, lineColors, tickLabelSpacing, conversion })}
                title='View graph in fullscreen'
            >
                <ZoomOutMapIcon />
            </div>
            <Box sx={styles.graphDataWrapper}>
                <LineGraph graphData={graphData} lineColors={lineColors} tickLabelSpacing={tickLabelSpacing} conversion={conversion} />
            </Box>

            {noData &&
                <Box sx={styles.graphNoDisplayOverlay}>
                    <Typography variant='h3'>No data available for query</Typography>
                </Box>
            }
        </div>
    )
}

export default AnalyticsGraph
