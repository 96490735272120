import { UPDATE_FIELD, RESET_FORM } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const authFormInitialState = (state = {}) => {
    return {
        form: {
            username: null,
            password: null,
        },
        ...state,
    }
}

export const authFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state.form[`${action.field}`] = action.value

            break
        }
        case RESET_FORM: {
            return authFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useAuthFormReducer = (state = {}) => {
    return useReducer(authFormReducer, authFormInitialState(state))
}

