import { squashArr } from 'helpers'
import { useQueryCustomerByCardNumber } from 'features/velocity/incomm_debug/queries'

export const useCustomerLookup = () => {
    const customerLookupResult = useQueryCustomerByCardNumber()

    const onSubmit = (cardNumber) => {
        customerLookupResult.mutate(cardNumber)
    }

    const error = customerLookupResult.error

    if (error) return { connectionError: error, ...customerLookupResult }

    const { success, payload, errors: lookupErrors } = customerLookupResult.data || {}

    const warnings = squashArr([customerLookupResult.data?.warnings])
    const notices = squashArr([customerLookupResult.data?.notices])
    const errors = squashArr([customerLookupResult.error, lookupErrors])

    const isLoadingState = customerLookupResult.isLoading

    const ingenID = payload?.ingen_id || null

    return {
        success,
        ingenID,
        errors,
        warnings,
        notices,
        disabled: isLoadingState,
        isLoadingState,
        ...customerLookupResult,
        onSubmit,
    }
}
