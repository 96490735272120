import { colors } from 'theme'

const useStyles = () => ({
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        margin: '5px',
    },
    imageColumn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 460,
        marginBottom: { xs: '20px', md: 0 },
        position: 'relative',
    },
    imageContainer: {
        marginBottom: '20px',
        width: '48%',
    },
    clearField: {
        position: 'absolute',
        right: '20px',
        top: '20px',
        '& .MuiButton-label': {
            lineHeight: 2,
        },
    },
    clearIcon: {
        height: 18,
        marginTop: '-3px',
    },
    dateRow: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: 460,
    },
    dateField: {
        margin: '5px',
    },
    field: {
        margin: '5px',
        minWidth: 400,
        maxWidth: 450,
        position: 'relative',
        width: '100%',
    },
    upcColumn: {
        position: 'relative',
    },
    radioFormLabel: {
        fontSize: 18,
    },
    formLabel: {
        padding: '30px 0 10px',
        fontSize: 18,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 450,
        alignItems: 'center',
    },
    rulesBlock: {
        marginBottom: '10px',
        padding: '20px 10px',
    },
    warningField: {
        '& label': {
            color: colors.error.main,
        },
        '& .MuiOutlinedInput-root': {
            border: `1px solid ${colors.error.main}`,
            borderRadius: '4px',
        },
    },
    helperText: {
        color: colors.error.main,
        width: 450,
        display: 'block',
        marginBottom: '10px',
    },
    divider: {
        borderWidth: '1px',
        borderStyle: 'solid',
        margin: '40px 0 15px',
        width: '80%',
    },
    heading: {
        width: '100%',
    },
    subtitle: {
        alignSelf: { xs: 'center', md: 'flex-start' },
        margin: '20px',
    },
    addButton: {
        width: 'auto',
        minWidth: 0,
        color: colors.vizerCoral.main,
    },
    formImage: {
        width: 'auto',
        height: 'auto',
        maxHeight: 200,
        maxWidth: '100%',
    },
    btn: {
        margin: '0 5px',
        width: 'auto',
    },
    infoIcon: {
        fontSize: '.9rem',
    },
})

export default useStyles
