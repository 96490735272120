import { useParams } from 'react-router-dom'
import { sanitizeDate } from 'helpers'
import {
    Card,
    CardContent,
    Typography,
} from '@mui/material'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import ImageModal from 'features/modals/components'
import LabelWithLink from 'components/LabelWithLink'
import { useOfferRetailerShow } from 'features/velocity/offer_retailers/hooks/show'
import useStyles from './styles'
import StripImages from '../StripImages'

export const OfferRetailerShow = () => {
    const styles = useStyles()
    const { offerID, retailerID } = useParams()

    const {
        connectionError,
        offerRetailer,
        offer,
        retailer,
        generateStripImages,
        errors,
        disabled,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
    } = useOfferRetailerShow(offerID, retailerID)

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    const {
        id,
        offer_id,
        retailer_id,
        google_strip_url,
        apple_strip_url_1x,
        apple_strip_url_2x,
        apple_strip_url_3x,
        created_at,
        updated_at,
    } = offerRetailer || {}

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
            />

            <div style={styles.header}>
                <Typography variant='h1'>
                    {`${offer?.name} - ${retailer?.name}`}
                </Typography>
            </div>
            <Card sx={styles.card}>
                <CardContent sx={styles.content}>
                    <dl style={styles.descriptionList}>
                        <dt>
                            <Typography variant='h5'>ID:</Typography>
                        </dt>
                        <dd>
                            <Typography data-cy='description'>{id}</Typography>
                        </dd>
                        <dt>
                            <Typography variant='h5'>Offer:</Typography>
                        </dt>
                        <dd>
                            <Typography data-cy='offer'>
                                <LabelWithLink
                                    label={offer?.name}
                                    path={`/velocity/offers/${offer_id}`}
                                    id={offer_id}
                                    emptyLabel={'None'}
                                />
                            </Typography>
                        </dd>
                        <dt>
                            <Typography variant='h5'>Retailer:</Typography>
                        </dt>
                        <dd>
                            <Typography data-cy='retailer'>
                                <LabelWithLink
                                    label={retailer?.name}
                                    path={`/velocity/retailers/${retailer_id}`}
                                    id={retailer_id}
                                    emptyLabel={'None'}
                                />
                            </Typography>
                        </dd>
                        <dt>
                            <Typography variant='h5'>Created At:</Typography>
                        </dt>
                        <dd>
                            <Typography data-cy=''>{sanitizeDate(created_at) || 'None'}</Typography>
                        </dd>
                        <dt>
                            <Typography variant='h5'>Updated At:</Typography>
                        </dt>
                        <dd>
                            <Typography data-cy=''>{sanitizeDate(updated_at) || 'None'}</Typography>
                        </dd>
                    </dl>
                </CardContent>
                <CardContent sx={styles.imageColumn}>
                    <StripImages
                        id={id}
                        google_strip_url={google_strip_url}
                        apple_strip_url_1x={apple_strip_url_1x}
                        apple_strip_url_2x={apple_strip_url_2x}
                        apple_strip_url_3x={apple_strip_url_3x}
                        generateStripImages={generateStripImages}
                        disabled={disabled}
                    />
                </CardContent>
            </Card>
            <ImageModal />
        </>
    )
}

export default OfferRetailerShow
