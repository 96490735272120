// import { url as _url, _get, _post } from 'utils'
import { users } from 'features/oxidauth/users/components/List/fakeData'

export async function fetchUsersList() {
    // const url = await _url('/users')

    // return _get(url)

    return {
        payload: {
            users,
        },
    }
}

export async function fetchUserShow(id) {
    // const url = await _url(`/users/${id}`)

    // return _get(url)

    const user = users.find((user) => user.id === parseInt(id))

    return {
        payload: {
            user,
        },
    }
}

export async function createNewUser({
    username, email, first_name, last_name, profile, status, kind,
}) {
    // const url = await _url('/users')

    const body = {
        username,
        email,
        first_name,
        last_name,
        profile,
        status,
        kind,
    }

    // return _post(url, body)

    console.log('FORM:::', body) // eslint-disable-line no-console

    return {
        success: true,
        payload: {
            id: 3,
            username,
            email,
            first_name,
            last_name,
            profile,
            status,
            kind,
            created_at: '2021-03-20T08:01:41Z',
            updated_at: '2021-04-10T03:15:33Z',
        },
        errors: null,
    }
}

export async function updateExistingUser(id, {
    username, email, last_name, first_name, profile, status, kind,
}) {
    // const url = await _url(`/users/${id}`)

    const body = {
        username,
        email,
        first_name,
        last_name,
        profile,
        status,
        kind,
    }

    // return _post(url, body)

    console.log('EDIT:::', body) // eslint-disable-line no-console

    return {
        success: true,
        payload: {
            id,
            ...body,
            created_at: '2021-03-20T08:01:41Z',
            updated_at: '2021-04-10T03:15:33Z',
        },
        errors: null,
    }
}
