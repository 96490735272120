import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import useStyles from './styles'

export const RedemptionInstructions = ({ activeTab, redemption_instructions }) => {
    const styles = useStyles()
    const [coordinates, setCoordinates] = useState(null)

    const getCoordinate = (point, offset) => {
        const element = document.getElementById(point)

        if (offset === 'top') return element?.offsetTop
        if (offset === 'left') return element?.offsetLeft
        if (offset === 'right') return element?.offsetLeft + element?.offsetWidth
    }

    const getCoordinates = () => ({
        axs: getCoordinate('pointa', 'left'),
        ays: getCoordinate('pointa', 'top'),
        axe: getCoordinate('pointa', 'right'),
        bxs: getCoordinate('pointb', 'left'),
        bys: getCoordinate('pointb', 'top'),
        bxe: getCoordinate('pointb', 'right'),
    })

    const resetCoordinates = () => {
        setTimeout(() => {
            setCoordinates(getCoordinates())
        }, 200)
    }

    useEffect(() => {
        if (activeTab === 1) setCoordinates(getCoordinate())
    }, [activeTab])

    useEffect(() => {
        if (document.readyState === 'complete' && !coordinates && getCoordinates().axs) {
            setCoordinates(getCoordinates)
        }
    }, [document.readyState, getCoordinates])

    useEffect(() => {
        window.addEventListener('resize', resetCoordinates)

        return () => {
            window.removeEventListener('resize', resetCoordinates)
        }
    }, [])

    return (
        <>
            <Typography variant='h2' align='center'>Example Redemption Instructions Output</Typography>

            <div style={styles.exampleContainer}>

                {coordinates &&
                    <div style={styles.svgContainer}>
                        <svg style={styles.svg}>
                            <line x1='0' y1='76' x2={coordinates?.axs} y2={coordinates?.ays} />
                        </svg>
                        <svg style={styles.svg}>
                            <line x1='500' y1='76' x2={coordinates?.axe} y2={coordinates?.ays} />
                        </svg>
                        <svg style={styles.svg}>
                            <line x1='0' y1='424' x2={coordinates?.bxs} y2={coordinates?.bys} />
                        </svg>
                        <svg style={styles.svg}>
                            <line x1='500' y1='424' x2={coordinates?.bxe} y2={coordinates?.bys} />
                        </svg>
                    </div>
                }

                <div id='iframe' style={styles.instructionContainer}>
                    <iframe
                        style={styles.iframe}
                        srcDoc={`
                            <html style="font-family: Avenir, Open-Sans, sans-serif; scrollbar-color: #ff6969 rgba(255, 105, 105, 0.15)">
                                <body>${redemption_instructions}</body>
                                <style>
                                    html ::-webkit-scrollbar { width: 11px; }
                                    ::-webkit-scrollbar-thumb { background: #ff6969; border-radius: 10px; }
                                    ::-webkit-scrollbar-track-piece { background: rgba(255, 105, 105, 0.15) }
                                </style>
                            </html>
                        `}
                    />
                </div>

                <div id='pointa' style={styles.pointA}></div>
                <div id='pointb' style={styles.pointB}></div>
                <div style={styles.redemptionPageImage}></div>
            </div>
        </>
    )
}

export default RedemptionInstructions
