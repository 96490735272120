import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import ClientPortalUsersForm from 'features/velocity/client_portal_users/components/Form'
import { useEditClientPortalUser } from 'features/velocity/client_portal_users/hooks/edit'
import useStyles from './styles'

export const ClientPortalUsersEdit = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const styles = useStyles()
    const editClientPortalUserResult = useEditClientPortalUser(id)

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <ClientPortalUsersForm
                edit={true}
                {...editClientPortalUserResult}
            />
        </>
    )
}

export default ClientPortalUsersEdit
