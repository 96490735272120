import { createSlice } from '@reduxjs/toolkit'
import {
    PENDING,
    LOADED,
    CREATING,
    CREATED,
    ERROR,
} from 'features/status'

const name = 'rolesCreate'

const initialState = {
    status: PENDING,
    id: null,
    hasLoaded: false,
    role: {
        realm_id: null,
        name: null,
        user_id: null,
        role_id: null,
    },
    error: null,
}

const reducers = {
    setFormField: (state, { payload }) => {
        const form = state.role
        const { field, value } = payload

        const role = {
            ...form,
            [field]: value,
        }

        return { ...state, role }
    },
    setRole: (state, { payload }) => {
        const { id, realm_id, name } = payload
        return {
            ...state,
            id,
            role: {
                ...state.role,
                realm_id,
                name,
            },
        }
    },
    newRole: (state, { payload }) => {
        const { id, parent } = payload
        if (id) state.role[`${parent}_id`] = parseInt(id)
    },
    newRoleResult: (state) => {
        state.status = LOADED
        state.hasLoaded = true
    },
    newRoleError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    createRole: (state) => {
        state.status = CREATING
    },
    createRoleResult: (state, { payload }) => {
        const { role } = payload

        state.status = CREATED
        state.role = role
    },
    createRoleError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetForm: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    setFormField,
    setRole,
    newRole,
    newRoleResult,
    newRoleError,
    createRole,
    createRoleResult,
    createRoleError,
    resetForm,
} = slice.actions

export default slice.reducer
