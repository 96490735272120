export const users = [
    {
        id: 1,
        username: 'usurperperson',
        email: 'usurper@person.com',
        first_name: 'Usurper',
        last_name: 'Person',
        profile: 'tbd',
        status: 'active',
        kind: 'human',
        roles: [11, 13],
        permissions: [23],
        created_at: '2021-03-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 2,
        username: 'userpersonality',
        email: 'user@personality.com',
        first_name: 'User',
        last_name: 'Personality',
        profile: 'tbd',
        status: 'active',
        kind: 'human',
        roles: [12],
        permissions: [23],
        created_at: '2021-03-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 3,
        username: 'usedtoperson',
        email: 'usedto@person.com',
        first_name: 'Used To',
        last_name: 'Person',
        profile: 'tbd',
        status: 'inactive',
        kind: 'api',
        roles: [],
        permissions: [],
        created_at: '2021-03-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 4,
        username: 'userperson',
        email: 'user@person.com',
        first_name: 'User',
        last_name: 'Person',
        profile: 'tbd',
        status: 'active',
        kind: 'human',
        roles: [10],
        permissions: [20, 21],
        created_at: '2021-03-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
]

export const roles = [
    {
        id: 10,
        realm_id: 40,
        roles: [11, 12],
        permissions: [20, 21],
        name: 'Super Admin',
        created_at: '2021-03-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 11,
        realm_id: 41,
        roles: [12],
        permissions: [21],
        name: 'Admin',
        created_at: '2021-03-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 12,
        realm_id: 42,
        roles: [],
        permissions: [23],
        name: 'Peon',
        created_at: '2021-03-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 13,
        realm_id: 43,
        roles: [12],
        permissions: [],
        name: 'Admin of Nothing',
        created_at: '2021-02-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 14,
        realm_id: 43,
        roles: [10, 11, 13],
        permissions: [24],
        name: 'Danger Admin',
        created_at: '2021-02-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
]

export const permissions = [
    {
        id: 20,
        realm_resource_action: 'vizerapp:**:**',
        created_at: '2021-03-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 21,
        realm_resource_action: 'vizerapp:fitness_challenge:**',
        created_at: '2021-03-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 22,
        realm_resource_action: 'vizerapp:user:**',
        created_at: '2021-03-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 23,
        realm_resource_action: 'vizerapp:user:read',
        created_at: '2021-02-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
    {
        id: 24,
        realm_resource_action: '**:**:**',
        created_at: '1974-02-20T08:01:41Z',
        updated_at: '2021-04-10T03:15:33Z',
    },
]
