import { useEffect, useState } from 'react'
import { useQueryOfferDashboardAnalytics } from 'features/velocity/analytics/queries'
import { reduceQueries } from 'helpers'
import { velocityUrl, _fetch_file } from 'utils'
import { isPresent } from 'helpers'

export const useOfferDashboardAnalytics = (offerID, strategy) => {
    const {
        payloads: [analyticsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryOfferDashboardAnalytics(offerID, strategy)],
    )

    const {
        downloadSummaryExportCSV,
        downloadDailySummaryExportCSV,
    } = useOfferSummariesExport(offerID)

    const total_clicks = analyticsPayload?.total_clicks
    const unique_clicks = analyticsPayload?.unique_clicks
    const clicks_to_clips = analyticsPayload?.clicks_to_clips
    const total_clips = analyticsPayload?.total_clips
    const unique_clips = analyticsPayload?.unique_clips
    const clips_to_scans = analyticsPayload?.clips_to_scans
    const total_scans = analyticsPayload?.total_scans
    const unique_scans = analyticsPayload?.unique_scans
    const total_scan_refunds = analyticsPayload?.total_scan_refunds
    const unique_scan_refunds = analyticsPayload?.unique_scan_refunds

    const analytics = {
        totalClicks: total_clicks,
        uniqueClicks: unique_clicks,
        clicksToClips: clicks_to_clips,
        totalClips: total_clips,
        uniqueClips: unique_clips,
        clipsToScans: clips_to_scans,
        totalScans: total_scans,
        uniqueScans: unique_scans,
        totalScanRefunds: total_scan_refunds,
        uniqueScanRefunds: unique_scan_refunds,
    }

    return {
        connectionError,
        success,
        analytics,
        downloadSummaryExportCSV,
        downloadDailySummaryExportCSV,
        errors,
        warnings,
        notices,
        ...isResults,
    }
}

const useOfferSummariesExport = (offerID) => {
    const [masterExportLink, setSummaryExportLink] = useState()
    const [dailySummaryExportLink, setDailySummaryExportLink] = useState()
    const [downloadSummaryExport, setDownloadSummaryExport] = useState(false)
    const [downloadDailySummaryExport, setDownloadDailySummaryExport] = useState(false)

    useEffect(() => {
        velocityUrl('/offers/queries/fetch_offer_analytics')
            .then(async (url) => {
                if (isPresent(url)) {
                    setSummaryExportLink(`${url}/${offerID}/export_summaries`)
                    setDailySummaryExportLink(`${url}/${offerID}/export_summaries_by_day`)
                }
            })
    }, [offerID])

    useEffect(() => {
        if (downloadSummaryExport) {
            const downloadFile = async () => {
                const a = document.createElement('a')

                const { blob, filename } = await _fetch_file(masterExportLink, {})

                const objectUrl = window.URL.createObjectURL(blob)
                a.href = objectUrl
                a.download = filename
                a.style = 'display: none;'

                document.body.appendChild(a)
                a.click()

                document.body.removeChild(a)
                window.URL.revokeObjectURL(objectUrl)
            }

            downloadFile()
        }

        setDownloadSummaryExport(false)
    }, [downloadSummaryExport])

    useEffect(() => {
        if (downloadDailySummaryExport) {
            const downloadFile = async () => {
                const a = document.createElement('a')

                const { blob, filename } = await _fetch_file(dailySummaryExportLink, {})

                const objectUrl = window.URL.createObjectURL(blob)
                a.href = objectUrl
                a.download = filename
                a.style = 'display: none;'

                document.body.appendChild(a)
                a.click()

                document.body.removeChild(a)
                window.URL.revokeObjectURL(objectUrl)
            }

            downloadFile()
        }

        setDownloadDailySummaryExport(false)
    }, [downloadDailySummaryExport])

    const downloadSummaryExportCSV = () => {
        if (masterExportLink) setDownloadSummaryExport(true)
    }

    const downloadDailySummaryExportCSV = () => {
        if (dailySummaryExportLink) setDownloadDailySummaryExport(true)
    }

    return {
        downloadSummaryExportCSV,
        downloadDailySummaryExportCSV,
    }
}
