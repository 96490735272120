import { useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    Tooltip,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { lastXChar } from 'helpers'
import Cell from 'components/Cell'
import Row from 'components/Row'
import Pagination from 'features/pagination_filtering/components/Pagination'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import useStyles from './styles'
import Filter from 'features/pagination_filtering/components/Filter'

export const AdminUsersList = ({
    connectionError, adminUsers, pagination, errors, warnings, notices,
    isError, isLoading, isFetching, isRefetching, isInitialLoading, setPage,
    pageState, invalidateListQueries,
}) => {
    const styles = useStyles()
    const [copied, setCopied] = useState(false)

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Admin Users</Typography>

            <Link to={'/admin/users/new'}>
                <Button
                    sx={styles.btn}
                    variant="contained"
                    color="secondary"
                    data-cy='new-btn'
                >
                    New
                </Button>
            </Link>

            <Filter
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
                refresh={invalidateListQueries}
            />

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align='center'>Name</TableCell>
                            <TableCell align='center'>Username</TableCell>
                            <TableCell align='center'>Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        {adminUsers.map(({ id, first_name, last_name, username, email }) => (
                            <Row key={id}>
                                <Cell
                                    scope='admin user'
                                    align='center'
                                    noLink
                                    sx={styles.copy}
                                    onClick={() => {
                                        navigator.clipboard.writeText(id)
                                        setCopied(true)
                                        setTimeout(() => setCopied(false), 800)
                                    }}
                                >
                                    <Tooltip title={copied ? 'Copied to clipboard!' : id}>
                                        <Typography>{lastXChar(id, 8)}</Typography>
                                    </Tooltip>
                                </Cell>
                                <Cell align='center' noLink>{first_name || ''} {last_name || ''}</Cell>
                                <Cell align='center' noLink>{username || ''}</Cell>
                                <Cell align='center' noLink>{email || ''}</Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {adminUsers.length === 0 &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No admin users found.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default AdminUsersList
