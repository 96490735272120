import { useQueryRetailersForOffer } from 'features/velocity/retailers/queries'
import { isEmpty } from 'helpers'

export const useRetailersForOffer = (offerId) => {
    const result = useQueryRetailersForOffer(offerId)
    const error = result.error

    if (error) return { connectionError: error, ...result }

    const { success, payload, errors, warnings, notices } = result.data || {}

    const allRetailers = payload?.retailers || []
    const offerRetailers = payload?.offer_retailers || []

    const { children, parents } = allRetailers.reduce(
        (acc, retailer) => {
            if (isEmpty(retailer.parent_id)) {
                acc.parents[retailer.id] = retailer
            } else {
                if (isEmpty(acc.children[retailer.parent_id])) {
                    acc.children[retailer.parent_id] = []
                }

                acc.children[retailer.parent_id].push(retailer)
            }

            return acc
        },
        { children: {}, parents: {} },
    )

    const { haveChildren, other } = Object.keys(parents).reduce(
        (acc, parent_id) => {
            if (isEmpty(children[parent_id])) {
                acc.other.push({
                    id: parent_id,
                    name: parents[parent_id].name,
                })
            } else {
                acc.haveChildren.push({
                    id: parent_id,
                    name: parents[parent_id].name,
                    banner_stores: children[parent_id],
                })
            }

            return acc
        },
        { haveChildren: [], other: [] },
    )

    const retailers = haveChildren.concat([{
        id: null,
        name: null,
        banner_stores: other,
    }])

    return {
        success,
        retailers,
        offerRetailers,
        errors,
        warnings,
        notices,
        ...result,
    }
}
