import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { isPresent } from 'helpers'
import Loading from 'components/Loading'
import useStyles from './styles'

const OfferPartnerButton = ({ brand, offerPartnerID, hasLoaded, disabled }) => {
    const styles = useStyles()

    if (!hasLoaded) return <Loading inline={true} />

    return (
        <>
            {isPresent(offerPartnerID) ? (
                <Link
                    style={styles.link}
                    to={`/velocity/offer_partners/${offerPartnerID}`}
                    state={{ from: `/brands/${brand.id}`, brand }}
                >
                    <Button
                        sx={styles.btn}
                        data-cy='show-btn'
                        variant='contained'
                        color='secondary'
                        disabled={disabled}
                    >
                        Manage Offer Partner
                    </Button>
                </Link>
            ) : (
                <Link
                    style={styles.link}
                    to='/velocity/offer_partners/new'
                    state={{ from: `/brands/${brand.id}`, brand }}
                >
                    <Button
                        sx={styles.btn}
                        data-cy='new-btn'
                        variant='contained'
                        color='secondary'
                        disabled={disabled}
                    >
                        New Offer Partner
                    </Button>
                </Link>
            )}
        </>
    )
}

export default OfferPartnerButton
