export const PENDING = 'PENDING'
export const LOADED = 'LOADED'
export const CREATING = 'CREATING'
export const CREATED = 'CREATED'
export const REQUESTING = 'REQUESTING'
export const FETCHING = 'FETCHING'
export const UPDATING = 'UPDATING'
export const UPDATED = 'UPDATED'
export const DONE = 'DONE'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const DESTROYING = 'DESTROYING'
export const DESTROYED = 'DESTROYED'
export const CREDIT_VALUES = ['0', '1', '2', '3', '4', '5']
export const UPDATE_FIELD = 'UPDATE_FIELD'
export const UPDATE_STATE = 'UPDATE_STATE'
export const UPDATE_TEMPLATE_FIELD = 'UPDATE_TEMPLATE_FIELD'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const RESET_FILTERS = 'RESET_FILTERS'
export const RESET_STATE = 'RESET_STATE'
export const SET_FORM = 'SET_FORM'
export const RESET_FORM = 'RESET_FORM'
export const RESET_FIELD = 'RESET_FIELD'
export const DETAILS = 'DETAILS'
export const ANALYTICS = 'ANALYTICS'
export const DOMAIN_ATTEMPT_THRESHOLD_OPTIONS = ['3', '5', '10', '25', '50', '100']
export const PER_PAGE_DEFAULT = ['5', '10', '25', '50', '100']
export const TRUE_FALSE = [
    {
        name: 'True',
        value: true,
    },
    {
        name: 'False',
        value: false,
    },
]
export const UNLIMITED_OPTIONS = [
    {
        name: 'Limited',
        value: false,
    },
    {
        name: 'Unlimited',
        value: true,
    },
]
export const OFFER_TYPES = ['incomm']
export const HELP_REQUEST_STATUSES = [
    {
        name: 'Pending',
        value: 'pending',
    },
    {
        name: 'Viewed',
        value: 'viewed',
    },
    {
        name: 'In Progress',
        value: 'in_progress',
    },
    {
        name: 'Resolved',
        value: 'resolved',
    },
    {
        name: 'Duplicate',
        value: 'duplicate',
    },
]
export const INCOMM_OFFER_TYPES = [
    {
        name: 'Spend',
        value: 'SPEND',
    },
    {
        name: 'Discount',
        value: 'DISCOUNT',
    },
]
export const STATUS_OPTIONS = [
    {
        name: 'Active',
        value: 'active',
    },
    {
        name: 'Inactive',
        value: 'inactive',
    },
]
export const REDEMPTION_TYPES = [
    {
        name: 'In Person',
        value: 'in_person',
    },
    {
        name: 'Online',
        value: 'online',
    },
    {
        name: 'Online - Location Specific',
        value: 'online_with_location',
    },
]
export const REDEMPTION_CODE_TYPE_OPTIONS = [
    {
        name: 'None',
        value: null,
    },
    {
        name: 'Static',
        value: 'static',
    },
    {
        name: 'Template',
        value: 'template',
    },
    {
        name: 'Basic',
        value: 'basic',
    },
    // {
    //     name: 'Qples',
    //     value: 'qples',
    // },
    {
        name: 'Revtrax',
        value: 'revtrax',
    },
]
export const PRESENTATION_TYPES = [
    {
        name: 'None',
        value: null,
        target: ['in_person'],
    },
    {
        name: 'Link & Code',
        value: 'link_and_code',
        target: ['online'],
    },
    {
        name: 'Code',
        value: 'code',
        target: ['online', 'in_person'],
    },
    {
        name: 'Link',
        value: 'link',
        target: ['online'],
    },
    {
        name: 'Button',
        value: 'button',
        target: ['online'],
    },
    {
        name: 'Barcode',
        value: 'barcode',
        target: ['in_person'],
    },
]
export const LOCATION_TYPES = [
    {
        name: 'None',
        value: 'none',
    },
    {
        name: 'Location',
        value: 'location',
    },
    // {
    //     name: 'Radius',
    //     value: 'radius',
    // },
    // {
    //     name: 'Geofence',
    //     value: 'geofence',
    // },
]
export const DURATION_OPTIONS = [
    {
        name: 'Day',
        value: 'day',
    },
    {
        name: 'Week',
        value: 'week',
    },
    {
        name: 'Month',
        value: 'month',
    },
    {
        name: 'Year',
        value: 'year',
    },
    {
        name: 'Forever',
        value: 'forever',
    },
]
export const USER_KIND_OPTIONS = [
    {
        name: 'Human',
        value: 'human',
    },
    {
        name: 'API',
        value: 'api',
    },
]

export const INCOMM_USER_IDENTIFICATION_METHOD_OPTIONS = [
    {
        name: 'Email',
        value: 'email',
    },
    {
        name: 'SMS',
        value: 'sms',
    },
]

export const BARCODE_TYPE_OPTIONS = [
    {
        name: 'Barcode',
        value: 'code128',
    },
    {
        name: 'QR Code',
        value: 'qr_code',
    },
]

export const DOMAIN_STATUS_OPTIONS = [
    {
        name: 'Allowed',
        value: 'allowed',
    },
    {
        name: 'Blocked',
        value: 'blocked',
    },
]
