import { velocityUrl, _post, _get } from 'utils'
import qs from 'qs'

export async function fetchAdminUsers(pageQuery) {
    try {
        const url = await velocityUrl(`/admin_users?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}

export async function createNewAdminUser({
    first_name, last_name, email,
}) {
    try {
        const url = await velocityUrl('/invitations')

        const body = {
            first_name,
            last_name,
            email,
        }

        console.log(':: admin_user body submitted ::', body) // eslint-disable-line no-console

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
    }
}

