import { useState } from 'react'
import {
    AppBar, Box, Card,
    Tab, Tabs, Typography,
} from '@mui/material'
import { TabPanel } from 'components/TabPanel'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'
import { AllowedEmails } from 'features/velocity/email_containment/components/AllowedEmails'
import { BlockedEmailPrefixes } from 'features/velocity/email_containment/components/BlockedEmailPrefixes'
import useStyles from './styles'

export const EmailContainment = () => {
    const styles = useStyles()

    const [activeTab, setActiveTab] = useState(0)

    const paginationFilters = usePaginationFilters({ debounceSkipKeys: ['attempt_threshold'] })

    const switchTabs = (i) => {
        paginationFilters.setPreventDebounce()
        paginationFilters.resetFilters()
        setActiveTab(i)
    }

    return (
        <Box sx={styles.page}>
            <Typography variant='h1' align='center'>
                Email Containment
            </Typography>

            <AppBar sx={styles.tabs} position="static">
                <Tabs value={activeTab} classes={{ indicator: styles.indicator }}>
                    <Tab sx={styles.tab} label="Email Prefixes" onClick={() => switchTabs(0)} />
                    <Tab sx={styles.tab} label="Emails" onClick={() => switchTabs(1)} />
                </Tabs>
            </AppBar>

            <Card sx={styles.detailsCard}>
                <TabPanel value={activeTab} index={0} noPadding>
                    <Box sx={styles.tabContainer}>
                        <Typography variant='h2' align='center'>
                            Blocked Email Prefixes
                        </Typography>

                        <BlockedEmailPrefixes {...paginationFilters} />
                    </Box>
                </TabPanel>
                <TabPanel value={activeTab} index={1} noPadding>
                    <Box sx={styles.tabContainer}>
                        <Typography variant='h2' align='center'>
                            Allowed Emails
                        </Typography>

                        <AllowedEmails {...paginationFilters} />
                    </Box>
                </TabPanel>
            </Card>
        </Box>
    )
}

export default EmailContainment
