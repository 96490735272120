const useStyles = () => ({
    btn: {
        fontWeight: 500,
    },
    loading: {
        marginTop: '5px',
        marginLeft: '5px',
    },
    emptyStripUrls: {
        height: '100%',
    },
})

export default useStyles

