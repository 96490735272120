import { createSlice } from '@reduxjs/toolkit'
import {
    PENDING,
    FETCHING,
    SUCCESS,
    ERROR,
} from 'features/status'

const name = 'usersShow'

const initialState = {
    status: PENDING,
    id: null,
    hasLoaded: false,
    user: {},
    error: null,
}

const reducers = {
    fetchUser: (state, { payload }) => {
        return {
            ...initialState,
            id: parseInt(payload),
            status: FETCHING,
        }
    },
    fetchUserResult: (state, { payload }) => {
        const { user } = payload

        state.status = SUCCESS
        state.hasLoaded = true
        state.user = user
    },
    fetchUserError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetUser: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    fetchUser,
    fetchUserResult,
    fetchUserError,
    resetUser,
} = slice.actions

export default slice.reducer
