import { useQuery } from '@tanstack/react-query'
import { useQueryDefaults } from 'queryHelpers'
import { fetchOfferDashboardAnalytics, fetchOfferGraphAnalytics, fetchOfferGraphsAnalyticsDefaultDates } from '../services'

export const useQueryOfferDashboardAnalytics = (offerID, strategy, queryArgs = {}) => {
    const queryKey = ['offers', 'analytics', 'summary', offerID]

    return useQuery({
        queryKey,
        queryFn: () => fetchOfferDashboardAnalytics(offerID, strategy),
        enabled: !!strategy,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryOfferGraphAnalytics = (offerID, filterState, queryArgs = {}) => {
    const enabled = filterState?.offer_strategy && filterState?.start_date && filterState?.end_date
    const queryKey = ['offers', 'analytics', 'graph', offerID, filterState]

    return useQuery({
        queryKey,
        queryFn: () => fetchOfferGraphAnalytics(offerID, filterState),
        enabled: !!enabled,
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryOfferGraphsAnalyticsDefaultDates = (offerID, queryArgs = {}) => {
    const queryKey = ['offers', 'analytics', 'graph', 'start date', offerID]

    return useQuery({
        queryKey,
        queryFn: () => fetchOfferGraphsAnalyticsDefaultDates(offerID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}
