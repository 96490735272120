import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { isPresent } from 'helpers'
import useStyles from '../styles'

export const TemplateSectionHeader = ({ title, value, disabled, onChange }) => {
    const styles = useStyles()

    return (
        <Box sx={styles.templateSectionHeader}>
            <Typography variant='h2' sx={styles.header}>{title}</Typography>

            {isPresent(value) &&
                <FormGroup sx={styles.checkbox}>
                    <FormControlLabel
                        label="Include?"
                        onClick={(e) => e.stopPropagation()}
                        control={
                            <Checkbox
                                checked={value || false}
                                disabled={disabled}
                                onChange={onChange}
                            />
                        }
                    />
                </FormGroup>
            }
        </Box>
    )
}
