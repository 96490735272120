import { velocityUrl, _get, _post } from 'utils'
import { squashArr, sanitizedDateString, isEmpty } from 'helpers'
import qs from 'qs'

export async function fetchOffers(pageQuery) {
    try {
        const url = await velocityUrl(`/offers/queries/list_offers?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchOfferShow(offerId) {
    try {
        const url = await velocityUrl(`/offers/queries/fetch_offer_by_id/${offerId}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

const formatDisplayExpiration = (display_expiration) => {
    if (isEmpty(display_expiration)) return null

    const { name, data } = display_expiration

    const formatted = {
        name,
        data: name === 'static' ? sanitizedDateString(data) : data,
    }

    return formatted
}

export async function createNewOffer({
    strategy, offer_partner_id, brand_id, status, engage_status, title,
    name, type, description, verbiage, print_text, purse_name, value, buy_quantity,
    customer_limit, offer_limit, clip_limit, purchase_limit, rules, terms,
    offer_start, offer_end, retailer_ids, primary_upcs, secondary_upcs, image, offer_partner_image,
    stack, max_unit, max_reward, schedules, user_identification_method, template_landing, template_engage,
    display_expiration,
}) {
    try {
        const url = await velocityUrl('/offers/commands/create_offer')

        const offerBody = {
            offer: {
                description,
                verbiage,
                print_text,
                purse_name,
                value,
                buy_quantity,
                offer_limit,
                clip_limit,
                customer_limit,
                purchase_limit,
                terms,
                offer_start,
                offer_end,
                type,
                retailer_ids,
                primary_upcs: squashArr(primary_upcs),
                secondary_upcs: squashArr(secondary_upcs),
                pre_tax: type === 'SPEND' ? null : {
                    rules,
                    stack,
                    max_unit,
                    max_reward,
                },
            },
            image,
            offer_partner_image,
        }

        const body = {
            ...offerBody,
            offer: {
                strategy,
                offer_partner_id,
                brand_id,
                name,
                status,
                engage_status,
                template_engage,
                title,
                template_landing,
                user_identification_method,
                schedules,
                display_expiration: formatDisplayExpiration(display_expiration),
                ...offerBody.offer,
            },
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function updateExistingOffer({
    id, strategy, strategy_id, status, engage_status, title, name, type, description, verbiage, print_text,
    purse_name, value, buy_quantity, customer_limit, offer_limit, clip_limit, purchase_limit,
    rules, terms, offer_start, offer_end, retailer_ids, primary_upcs, secondary_upcs,
    offer_partner_image, offer_partner_logo_original, offer_partner_logo_1x, offer_partner_logo_2x, offer_partner_logo_3x,
    image, image_url, stack, max_unit, max_reward, schedules, template_landing, template_engage,
    display_expiration,
}) {
    try {
        const url = await velocityUrl(`/offers/commands/update_offer`)

        const offerBody = {
            offer: {
                description,
                verbiage,
                print_text,
                purse_name,
                value,
                buy_quantity,
                offer_limit,
                clip_limit,
                customer_limit,
                purchase_limit,
                terms,
                offer_start,
                offer_end,
                type,
                retailer_ids,
                primary_upcs: squashArr(primary_upcs),
                secondary_upcs: squashArr(secondary_upcs),
                pre_tax: type === 'SPEND' ? null : {
                    rules,
                    stack,
                    max_unit,
                    max_reward,
                },
            },
            image,
            offer_partner_image,
        }

        const body = {
            ...offerBody,
            offer: {
                id,
                name,
                status,
                engage_status,
                template_engage,
                title,
                template_landing,
                schedules,
                strategy,
                strategy_id,
                image_url,
                offer_partner_logo_original,
                offer_partner_logo_1x,
                offer_partner_logo_2x,
                offer_partner_logo_3x,
                display_expiration: formatDisplayExpiration(display_expiration),
                ...offerBody.offer,
            },
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function createNewShortLink({ offerID, form }) {
    try {
        const url = await velocityUrl(`/offers/commands/create_short_link`)

        const {
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term,
            target,
            name,
        } = form

        const body = {
            offer_id: offerID,
            short_link: {
                name,
                target,
                utm_codes: {
                    utm_source,
                    utm_medium,
                    utm_campaign,
                    utm_content,
                    utm_term,
                },
            },
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function refetchInCommOffer(offer_id) {
    try {
        const url = await velocityUrl(`/offers/queries/fetch_incomm_data/${offer_id}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function mockOfferApproval(offer_id) {
    try {
        const url = await velocityUrl(`/offers/commands/mock_offer_approval/${offer_id}`)

        return await _post(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function generateStripImages(offer_id) {
    try {
        const url = await velocityUrl(`/offers/commands/generate_strip_images/${offer_id}`)

        return await _post(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function sendEmails(form) {
    try {
        const {
            offer_id,
            csv_data,
        } = form

        const url = await velocityUrl(`/offers/commands/email_users/${offer_id}`)

        const body = {
            data: csv_data,
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchDomainsByOffer(offerID, pageQuery) {
    try {
        const url = await velocityUrl(`/domains/domains_by_offer/${offerID}?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
