const useStyles = () => ({
    versions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 100,
        margin: '5px',
    },
    btn: {
        width: 100,
        margin: '10px 0',
    },
})

export default useStyles
