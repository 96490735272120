import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { addNotification, clearNotification, clearNotifications } from 'features/page_notifications/reducers/page_notifications'

export const usePageNotifications = () => {
    const dispatch = useDispatch()

    const pageNotificationState = (state) => state.page_notifications.notifications
    const notificationState = createSelector(pageNotificationState, (pageNotificationState) => pageNotificationState.notifications)

    const pageNotifications = useSelector(notificationState)

    return {
        pageNotifications,
        addNotification: (msg) => dispatch(addNotification(msg)),
        clearNotification: (msg) => dispatch(clearNotification(msg)),
        clearNotifications: () => dispatch(clearNotifications()),
    }
}
