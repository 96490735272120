import { put, takeEvery, select } from 'redux-saga/effects'
import { createNewUser } from '../services'
import {
    newUser,
    newUserResult,
    newUserError,
    createUser,
    createUserResult,
    createUserError,
} from '../reducers/create'

export function* takeNewUser() {
    try {
        yield put(newUserResult())
    } catch (error) {
        yield put(newUserError(error.toString()))
    }
}

export function* watchNewUser() {
    yield takeEvery(newUser.toString(), takeNewUser)
}

export function* takeCreateUser() {
    try {
        const form = yield select((state) => state.users.create.user)
        const { payload } = yield createNewUser(form)

        yield put(createUserResult({ user: payload }))
    } catch (error) {
        yield put(createUserError(error.toString()))
    }
}

export function* watchCreateUser() {
    yield takeEvery(createUser.toString(), takeCreateUser)
}
