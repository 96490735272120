import { bundleImageData } from 'helpers'
import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'
import { useRetailerFormReducer, UPDATE_BARCODE_TYPE, UPDATE_BARCODE_DATA } from 'features/velocity/retailers/reducers'
import { emptyToNull } from 'helpers'

export const useRetailerForm = (state = {}) => {
    const [form, dispatch] = useRetailerFormReducer(state)

    const updateStatusField = (_, status) => dispatch({ type: UPDATE_FIELD, field: 'status', value: status.value })
    const updatePrintStatusField = (_, status) => dispatch({ type: UPDATE_FIELD, field: 'print_status', value: status.value })

    const updateBarcodeTypeField = (_, barcode_type) => dispatch({ type: UPDATE_BARCODE_TYPE, value: barcode_type.value })
    const updateBarcodeDataField = (event) => {
        dispatch({ type: UPDATE_BARCODE_DATA, value: event.target.value })
    }

    const updateField = (field) => (event) => {
        dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })
    }

    const updateParentRetailerField = (_, retailer) => {
        dispatch({ type: UPDATE_FIELD, field: 'parent_id', value: retailer.id })
    }

    const updateLogoField = async (e, _) => {
        const file = e.target.files[0]
        const logo = await bundleImageData(file)

        dispatch({ type: UPDATE_FIELD, field: 'logo', value: logo })
    }

    const updateIconField = async (e, _) => {
        const file = e.target.files[0]
        const icon = await bundleImageData(file)

        dispatch({ type: UPDATE_FIELD, field: 'icon', value: icon })
    }

    const updateWYSIWYG = (content) => {
        dispatch({ type: UPDATE_FIELD, field: 'redemption_instructions', value: content })
    }

    const toggleIncommRetailer = (incomm_retailer, checked) => {
        const field = 'incomm_retailers'
        const incomm_retailers = form.incomm_retailers

        if (checked) {
            const value = [incomm_retailer, ...incomm_retailers]

            dispatch({ type: UPDATE_FIELD, field, value })
        } else {
            const value = incomm_retailers.filter((r) => r.incomm_id !== incomm_retailer.incomm_id)

            dispatch({ type: UPDATE_FIELD, field, value })
        }
    }

    const resetForm = () => dispatch({ type: RESET_FORM })
    const setForm = (retailer) => dispatch({ type: SET_FORM, value: retailer })

    return {
        form,
        updateStatusField,
        updateBarcodeTypeField,
        updateBarcodeDataField,
        updateField,
        updatePrintStatusField,
        updateParentRetailerField,
        updateLogoField,
        updateIconField,
        updateWYSIWYG,
        toggleIncommRetailer,
        setForm,
        resetForm,
    }
}
