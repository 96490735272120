import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import AdminUsersForm from 'features/admin_users/components/Form'
import { useCreateAdminUser } from 'features/admin_users/hooks/create'
import useStyles from './styles'

export const AdminUserNew = () => {
    const navigate = useNavigate()
    const styles = useStyles()
    const createAdminUsersResult = useCreateAdminUser()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <AdminUsersForm
                edit={false}
                {...createAdminUsersResult}
            />
        </>
    )
}

export default AdminUserNew
