import { useQueryOfferTemplate } from 'features/velocity/templates/queries'
import { reduceQueries } from 'helpers'

export const useOfferTemplate = (templateType, templateID, queryArgs = {}) => {
    const {
        payloads: [templatePayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryOfferTemplate(templateType, templateID, queryArgs)],
    )

    const template = templatePayload?.template
    const templateImage = templatePayload?.template_image

    return {
        connectionError,
        success,
        template,
        templateImage,
        notices,
        warnings,
        errors,
        ...isResults,
    }
}
