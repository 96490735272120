
const useStyles = () => ({
    btn: {
        width: 'auto',
    },
    cellLink: {
        textDecoration: 'none',
        color: (theme) => theme.palette.link.main,
        fontWeight: 600,
        '&:visited': {
            color: (theme) => theme.palette.link.visited,
        },
        cursor: 'pointer',
    },
    noResults: {
        padding: '20px 0',
    },
})

export default useStyles
