// NOTE(alishaevn): DO NOT call the element styles directly! Use the theme instead.
// Otherwise, your element will look slightly different than the ones using the "variant" keyword

// If you need to override, or add to, any of the element styles
// add an "sx" prop to the `Typography` component and a '.MuiTypography-root' class for specificity

export const h1 = {
    fontFamily: 'Open Sans, Avenir, sans-serif',
    fontWeight: 700,
    fontSize: 36,
    textTransform: 'initial',
    letterSpacing: -1.5,
    marginBottom: '18px',
}

export const h2 = {
    fontFamily: 'Open Sans, Avenir, sans-serif',
    fontWeight: 600,
    fontSize: 24,
    textTransform: 'initial',
    letterSpacing: -0.5,
    marginBottom: '18px',
}

export const h3 = {
    fontFamily: 'Open Sans, Avenir, sans-serif',
    fontWeight: 400,
    fontSize: 18,
    textTransform: 'initial',
    letterSpacing: 0,
}

export const h4 = {
    fontFamily: 'Open Sans, Avenir, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    textTransform: 'uppercase',
    letterSpacing: 0.25,
}

export const h5 = {
    fontFamily: 'Arial',
    fontWeight: 600,
    fontSize: 16,
    textTransform: 'initial',
    letterSpacing: 0.15,
}

export const p = {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: 14,
    textTransform: 'initial',
    letterSpacing: 0.15,
}
