import { colors } from 'theme'

const useStyles = () => ({
    btn: {
        width: 'auto',
        marginBottom: '20px',
    },
    detailsCard: {
        border: (theme) => `2px solid ${theme.palette.text.primary}`,
        borderTopLeftRadius: 0,
        paddingTop: '10px',
        minHeight: { xs: 0, md: 900 },
    },
    tabs: {
        background: 'none',
        boxShadow: 'none',
    },
    tabContainer: {
        margin: '20px auto 0',
        maxWidth: { lg: 1120, xl: 1600 },
        '& > hr': {
            display: { xs: 'none', lg: 'initial' },
        },
    },
    tab: {
        color: (theme) => theme.palette.text.primary,
        border: (theme) => `2px solid ${theme.palette.text.primary}`,
        borderBottom: 0,
        borderTopRightRadius: 10,
        borderLeft: 0,
        position: 'relative',
        overflow: 'visible',
        maxWidth: { xs: '30vw', sm: '100%' },
        '&:first-of-type': {
            borderLeft: (theme) => `2px solid ${theme.palette.text.primary}`,
            borderTopLeftRadius: 10,
        },
        '&::after': {
            position: 'absolute',
            top: -2,
            right: -2,
            width: 10,
            height: 2,
            background: (theme) => theme.palette.text.primary,
            content: '""',
            display: 'block',
            opacity: '85%',
        },
        '&:last-of-type::after': {
            display: 'none',
        },
    },
    table: {
        background: colors.vizerSlateGray.light,
        marginBottom: '15px',
        overflow: 'auto',
    },
    page: {
        width: { xs: '90vw', sm: 'auto' },
        margin: { xs: 'auto', sm: 'initial' },
    },
})

export default useStyles
