import { UPDATE_FIELD, RESET_FORM } from 'features/status'
import { useAdminUserFormReducer } from 'features/admin_users/reducers/form'
import { emptyToNull } from 'helpers'

export const useAdminUserForm = (state = {}) => {
    const [form, dispatch] = useAdminUserFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })
    const resetForm = () => dispatch({ type: RESET_FORM })

    return {
        form,
        updateField,
        resetForm,
    }
}
