import { createSlice } from '@reduxjs/toolkit'

const name = 'error_modal'

const initialState = {
    title: 'It looks like something is a little off. So sorry!',
    userMessage: 'Can we try this again?',
    buttonText: 'Okay',
    devMessage: 'This is a placeholder error for devs.',
    visible: false,
}

const reducers = {
    setError: (state, { payload }) => {
        const newState = { ...state }

        return {
            ...newState,
            ...payload,
            visible: true,
        }
    },
    dismissError: () => initialState,
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    setError,
    dismissError,
} = slice.actions

export default slice.reducer
