import { shallowDiff } from 'utilities/shallowDiff'
import { isValidDate, sanitizeDate, squashArr } from 'helpers'
import { useUpdateOfferCmd } from 'features/velocity/offers/queries'
import { useOfferShow } from 'features/velocity/offers/hooks/show'
import { useOfferForm } from 'features/velocity/offers/hooks/form'
import { useEffect } from 'react'
import { isEmpty, isPresent, reduceLoadingState } from 'helpers'
import { useRetailersForOffer } from 'features/velocity/retailers/hooks/retailersForOffer'

export const useOfferEdit = (id) => {
    const offerFormReturn = useOfferForm()
    const offerShowResult = useOfferShow(id)
    const retailersResult = useRetailersForOffer(id)

    const isLoadError = offerShowResult.isError || retailersResult?.isError
    const loadSuccess = offerShowResult?.success && retailersResult?.success

    const updateResult = useUpdateOfferCmd()

    const connectionError = offerShowResult.error || retailersResult?.connectionError
    const warnings = squashArr([offerShowResult.warnings, retailersResult?.warnings, updateResult?.data?.warnings])
    const notices = squashArr([offerShowResult.notices, retailersResult?.warnings, updateResult?.data?.notices])
    const errors = squashArr([offerShowResult.errors, retailersResult?.errors, updateResult.error, updateResult?.data?.errors])

    const isLoadingState = reduceLoadingState([offerShowResult, retailersResult]) || updateResult.isLoading
    const isDisabled = offerShowResult.isLoading || retailersResult.isLoading || updateResult.isLoading

    const offer = loadSuccess ? offerShowResult?.offer : {}
    const strategy = loadSuccess ? offerShowResult?.strategy : {}
    const schedules = loadSuccess ? offerShowResult?.schedules : []
    const retailers = loadSuccess ? retailersResult?.retailers : []
    const offerRetailers = loadSuccess ? retailersResult?.offerRetailers : []

    useEffect(() => {
        if (loadSuccess) {
            const form = { ...offer, ...offer.strategy.incomm_data, schedules, retailer_ids: offerRetailers.map((r) => r.retailer_id) }

            if (form?.secondary_upcs?.length === 0 || isEmpty(form.secondary_upcs)) {
                form.secondary_upcs = [null]
            }

            offerFormReturn.setForm({
                form,
                originalForm: { ...form },
                strategy,
            })
        }
    }, [loadSuccess])

    const onSubmit = () => {
        updateResult.mutate({ ...offerFormReturn.form, strategy })
    }

    const generateConfirmMessage = () => {
        const fields = Object.keys(offerFormReturn.form)

        const originalForm = {}
        const updatedForm = {}

        // only diff fields we care about
        if (isPresent(offerFormReturn.offerState.originalForm)) {
            fields.forEach((f) => {
                if (['max_unit', 'max_reward', 'offer_end', 'buy_quantity', 'value', 'stack', 'rules', 'retailer_ids'].includes(f)) {
                    originalForm[f] = offerFormReturn.offerState.originalForm[f]
                    updatedForm[f] = offerFormReturn.form[f]
                }
            })
        }

        const diff = shallowDiff(originalForm, updatedForm)

        if ((diff.diffs).length > 0) {
            const diffsOutput = diff.diffs.map((v) => {
                const prev = formatDiff(v.prev)
                const curr = formatDiff(v.curr)

                return `${(v.field).toUpperCase()} from ${prev} to ${curr}`
            })

            const confirmMessage = `Are you sure you wish to change these values?\n\n${diffsOutput.join('\n')}\n\nIf this offer is live/approved, this will deactivate it and put it back into review stage.` // eslint-disable-line

            return confirmMessage
        } else {
            return null
        }
    }

    const confirmMessage = generateConfirmMessage()

    return {
        loadSuccess,
        connectionError,
        errors,
        warnings,
        notices,
        isLoadError,
        isLoadingState,
        confirmMessage,
        disabled: isDisabled,
        strategy,
        retailers,
        ...offerFormReturn,
        onSubmit,
    }
}

const formatDiff = (value) => {
    if (value instanceof Array === true) {
        return value.join(', ')
    }

    if (isValidDate(new Date(value))) {
        return sanitizeDate(value)
    }

    return value
}
