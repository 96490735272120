import { UPDATE_FIELD, RESET_FORM } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const domainFormInitialState = (state = {}) => {
    return {
        domain: null,
        status: null,
        ...state,
    }
}

export const domainFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state[`${action.field}`] = action.value

            break
        }
        case RESET_FORM: {
            return domainFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useDomainFormReducer = (state = {}) => {
    return useReducer(domainFormReducer, domainFormInitialState(state))
}
