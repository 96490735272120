import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useStyles from './styles'
import { isPresent } from 'helpers'

const BackButton = ({ alternateAction }) => {
    const navigate = useNavigate()
    const styles = useStyles()

    return (
        <Button sx={styles.btn} variant='outlined' onClick={() => isPresent(alternateAction) ? alternateAction() : navigate(-1)}>
            Back
        </Button>
    )
}

export default BackButton
