import { Autocomplete, FormControlLabel, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FormStyled = styled('form')(({ style, theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: 1000,
    ...style,
}))

export const FormColumnStyled = styled('div')(({ style, theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 10px',
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
        minWidth: '50%',
    },
    ...style,
}))

export const AutocompleteStyled = styled(Autocomplete)(({ style, theme }) => ({
    margin: '5px',
    minWidth: 400,
    maxWidth: 450,
    position: 'relative',
    width: '100%',
    ...style,
}
))

export const TextFieldStyled = styled(TextField)(({ style, theme }) => ({
    margin: '5px',
    minWidth: 400,
    maxWidth: 450,
    position: 'relative',
    width: '100%',
    ...style,
}))

export const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
    justifyContent: 'flex-end',
    width: '100%',
    marginLeft: 0,
    '& .MuiFormControlLabel-label': {
        fontSize: '1.125rem',
    }
}))

export const SubmitBlockStyled = styled('div')(({ style, theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '30px',
    width: '100%',
    ...style,
}))
