import AuthPageWrapper from 'features/auth/components/AuthPageWrapper'
import Login from './Login'
import Register from './Register'
import Invitation from './Invitation'
import ResetPassword from './ResetPassword'
import ForgotPassword from './ForgotPassword'

const AuthRoutes = {
    path: '/',
    element: <AuthPageWrapper />,
    children: [
        {
            path: 'login',
            element: <Login />,
        },
        {
            path: 'forgot-password',
            element: <ForgotPassword />,
        },
        {
            path: 'reset-password',
            element: <ResetPassword />,
        },
        {
            path: 'register',
            element: <Register />,
        },
        {
            path: '/invitations/:invitation_id/accept',
            element: <Invitation />,
        },
    ],
}

export default AuthRoutes
