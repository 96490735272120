import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import BrandEdit from 'features/brands/components/Edit'
import useStyles from './styles'

const Edit = () => {
    const styles = useStyles()
    const navigate = useNavigate()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <BrandEdit />
        </>
    )
}

export default Edit
