import { useState } from 'react'
import dayjs from 'dayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import InfoIcon from '@mui/icons-material/Info'
import ClearIcon from '@mui/icons-material/Clear'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
    Box, Button, CardMedia, Divider, Radio, RadioGroup,
    FormControlLabel, FormLabel, FormHelperText, TextField, Tooltip, Typography,
} from '@mui/material'

import { checkImageNotNull, isPresent, isProduction } from 'helpers'
import { AutocompleteStyled, FormColumnStyled, FormStyled, SubmitBlockStyled, TextFieldStyled } from 'theme/forms'
import { PageStyled } from 'theme/page'
import { INCOMM_OFFER_TYPES, STATUS_OPTIONS, INCOMM_USER_IDENTIFICATION_METHOD_OPTIONS, TRUE_FALSE } from 'features/status'
import { DISPLAY_EXPIRATION_OPTIONS } from '../../reducers/form'
import ImageFieldWithTooltip from 'components/ImageFieldWithTooltip'
import FieldWithTooltip from 'components/FieldWithTooltip'
import BackButton from 'components/BackButton'
import RetailerSelector from 'features/velocity/offers/components/RetailerSelector'
import ConfirmModal from 'features/modals/components/ConfirmModal'
import OfferTemplating from '../OfferTemplating'
import useStyles from './styles'

export const IncommOfferBaseForm = ({
    edit, disabled, offerPartners, offerPartner, offerState,
    form, updateField, updateIncommOfferTypeField, updateStatusField, updateImageField,
    updateOfferPartnerField, updateDateTimeField, setStack, updateFieldWithWarning,
    updateTemplate, setTemplate, updateTemplateDropdown, updateTemplateCheckbox,
    addUPCField, updateUPCField, onSubmit, confirmMessage, strategy,
    updateRetailer, selectAllRetailers, addScheduleField, updateScheduleField, updateNumberField, updateNumberFieldWithWarning,
    updateUserIdentificationMethodField, retailers, getAllIDs, checked, selectAllChecked, clearField, setDev, selectedExpOptFilter,
    setDisplayExpirationType, setStaticDisplayExpiration,
}) => {
    const [confirmVisible, setConfirmVisible] = useState(false)
    const [inputKey, setInputKey] = useState(null)
    const styles = useStyles()

    const image = checkImageNotNull(form.image)
    const formImage = checkImageNotNull(form.image_url)

    const partnerImage = checkImageNotNull(form.offer_partner_image)
    const partnerImageUrl = checkImageNotNull(form.offer_partner_logo_original)

    const title = offerPartner ? offerPartner?.offer_partner?.name : ''

    if (offerPartner && !form?.offer_partner_id) {
        updateOfferPartnerField(null, offerPartner)
    }

    const submit = () => {
        if (edit && strategy === 'incomm' && isPresent(confirmMessage)) {
            setConfirmVisible(true)
        } else {
            onSubmit()
        }
    }

    const showDiscountFields = form?.type === 'DISCOUNT'

    return (
        <PageStyled>
            <ConfirmModal
                visible={confirmVisible}
                message={confirmMessage}
                dismissModal={() => setConfirmVisible(false)}
                accept={onSubmit}
            />

            <Typography variant='h1'>{`${edit ? 'Edit' : 'New'} ${title} InComm Offer`}</Typography>

            <FormStyled
                noValidate
                autoComplete='off'
                onSubmit={(e) => e.preventDefault()}
                data-testid='offer-form'
            >
                <FormColumnStyled>
                    <TextFieldStyled
                        label='Name'
                        type='text'
                        data-cy='enter_offer_name'
                        sx={styles.field}
                        error={form?.name?.length > 100}
                        helperText={form?.name?.length > 100 ?
                            'Any name longer than 100 characters may negatively affect strip image generation' :
                            ''
                        }
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.name || ''}
                        onChange={updateField('name')}
                    />

                    <FieldWithTooltip
                        tooltip={'Spend - post-tax, Discount - pre-tax'}
                    >
                        <AutocompleteStyled
                            autoComplete
                            disableClearable
                            data-cy='type'
                            options={INCOMM_OFFER_TYPES}
                            getOptionLabel={(value) => value.name || ' '}
                            disabled={disabled}
                            value={INCOMM_OFFER_TYPES.find((type) => type.value === form?.type) || ''}
                            onChange={updateIncommOfferTypeField}
                            renderInput={(params) => (
                                <TextField {...params} label='Type' />
                            )}
                        />
                    </FieldWithTooltip>

                    {!edit && !offerPartner &&
                        <AutocompleteStyled
                            autoComplete
                            disableClearable
                            data-cy='enter_offer_partner'
                            options={offerPartners}
                            getOptionLabel={(op) => op?.offer_partner?.name || 'None'}
                            disabled={disabled}
                            value={offerPartners.find((op) => op?.offer_partner?.id === form.offer_partner_id) || ''}
                            onChange={updateOfferPartnerField}
                            renderInput={(params) => (
                                <TextField {...params} label='Offer Partner' />
                            )}
                        />
                    }
                    <ImageFieldWithTooltip>
                        <TextFieldStyled
                            key={inputKey}
                            label='Offer Partner Logo Override'
                            type='file'
                            disabled={disabled}
                            helperText={partnerImage &&
                                'This field will override ALL instances of the offer partner logo on the offer\'s public page.'
                            }
                            data-cy='image'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => updateImageField(e, 'offer_partner_image')}
                        />
                    </ImageFieldWithTooltip>
                    {partnerImageUrl &&
                        <Box sx={styles.imageColumn}>
                            <div style={styles.imageContainer}>
                                <Box sx={styles.subtitle}>
                                    <Typography variant='h3'>{`Offer Partner Logo Override:`}</Typography>
                                </Box>
                                <CardMedia
                                    sx={styles.formImage}
                                    component='img'
                                    alt='existing offer partner logo override image'
                                    src={form.offer_partner_logo_original}
                                    title={`${form.name}'s Offer Partner Logo Override`}
                                />
                                <Button
                                    sx={styles.clearField}
                                    variant='outlined'
                                    title='Remove uploaded image'
                                    onClick={() => {
                                        clearField('offer_partner_logo_original')
                                        clearField('offer_partner_logo_1x')
                                        clearField('offer_partner_logo_2x')
                                        clearField('offer_partner_logo_3x')
                                    }}
                                >
                                    <ClearIcon sx={styles.clearIcon} /> Clear
                                </Button>
                            </div>
                        </Box>
                    }
                    {partnerImage &&
                        <Box sx={styles.imageColumn}>
                            <div style={styles.imageContainer}>
                                <Box sx={styles.subtitle}>
                                    <Typography variant='h3'>{`Pending Offer Partner Logo Override:`}</Typography>
                                </Box>
                                <CardMedia
                                    sx={styles.formImage}
                                    component='img'
                                    alt='pending offer partner logo override image'
                                    src={`data:image/png;base64,${form.offer_partner_image.data}`}
                                    title={`Pending ${form.name}'s Offer Partner Logo Override`}
                                />
                                <Button
                                    sx={styles.clearField}
                                    variant='outlined'
                                    title='Remove pending override image'
                                    onClick={() => {
                                        setInputKey(form.offer_partner_image?.data)
                                        clearField('offer_partner_image')
                                    }}
                                >
                                    <ClearIcon sx={styles.clearIcon} /> Clear
                                </Button>
                            </div>
                        </Box>
                    }
                    <AutocompleteStyled
                        autoComplete
                        sx={styles.field}
                        disableClearable
                        data-cy='status'
                        options={STATUS_OPTIONS}
                        getOptionLabel={(value) => value.name || ' '}
                        disabled={disabled}
                        value={STATUS_OPTIONS.find((type) => type.value === form?.status) || ''}
                        onChange={updateStatusField('status')}
                        renderInput={(params) => (
                            <TextField {...params} label='Status' />
                        )}
                    />
                    <AutocompleteStyled
                        autoComplete
                        sx={styles.field}
                        disableClearable
                        data-cy='engage_status'
                        options={STATUS_OPTIONS}
                        getOptionLabel={(value) => value.name || ' '}
                        disabled={disabled}
                        value={STATUS_OPTIONS.find((type) => type.value === form?.engage_status) || ''}
                        onChange={updateStatusField('engage_status')}
                        renderInput={(params) => (
                            <TextField {...params} label='Engage Status' />
                        )}
                    />
                    {!edit &&
                        <AutocompleteStyled
                            autoComplete
                            disableClearable
                            data-cy='user_identification_method'
                            options={INCOMM_USER_IDENTIFICATION_METHOD_OPTIONS}
                            getOptionLabel={(value) => value.name || ' '}
                            disabled={disabled}
                            value={INCOMM_USER_IDENTIFICATION_METHOD_OPTIONS.find((type) => type.value === form?.user_identification_method) || ''}
                            onChange={updateUserIdentificationMethodField}
                            renderInput={(params) => (
                                <TextField {...params} label='User Identification Method' />
                            )}
                        />
                    }
                    <TextFieldStyled
                        sx={styles.field}
                        label='Title'
                        type='text'
                        data-cy='enter_title'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.title || ''}
                        onChange={updateField('title')}
                    />
                    <TextFieldStyled
                        label='Description'
                        type='text'
                        multiline
                        minRows={2}
                        data-cy='enter_offer_description'
                        sx={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.description || ''}
                        onChange={updateField('description')}
                    />

                    <FieldWithTooltip
                        tooltip={'Sent to Walmart'}
                    >
                        <TextFieldStyled
                            label='Verbiage'
                            type='text'
                            data-cy='enter_offer_verbiage'
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            value={form?.verbiage || ''}
                            onChange={updateField('verbiage')}
                        />
                    </FieldWithTooltip>

                    <FieldWithTooltip
                        tooltip={'Receipt text'}
                    >
                        <TextFieldStyled
                            label='Print Text'
                            type='text'
                            data-cy='enter_offer_print_text'
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            value={form?.print_text || ''}
                            onChange={updateField('print_text')}
                        />
                    </FieldWithTooltip>

                    {!edit &&
                        <FieldWithTooltip
                            tooltip={'Presented to the consumer along with the balance at the end of the transaction'}
                        >
                            <TextFieldStyled
                                label='Purse Name'
                                type='text'
                                data-cy='enter_offer_purse_name'
                                InputLabelProps={{ shrink: true }}
                                disabled={disabled}
                                value={form?.purse_name || ''}
                                onChange={updateField('purse_name')}
                            />
                        </FieldWithTooltip>
                    }

                    <ImageFieldWithTooltip>
                        <TextFieldStyled
                            label='Image'
                            type='file'
                            disabled={disabled}
                            data-cy='image'
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => updateImageField(e, 'image')}
                        />
                    </ImageFieldWithTooltip>

                    <div style={styles.imageColumn}>
                        {(formImage || image) &&
                            <div style={styles.imageContainer}>
                                <Box sx={styles.subtitle}>
                                    <Typography variant='h3'>{`Offer Image:`}</Typography>
                                </Box>
                                <CardMedia
                                    sx={styles.formImage}
                                    component='img'
                                    alt='thumbnail_image'
                                    src={!formImage ? `data:image/png;base64,${form.image.data}` : form.image_url}
                                    title={`${form.name}'s Offer Image`}
                                />
                            </div>
                        }
                        {formImage && image &&
                            <div style={styles.imageContainer}>
                                <Box sx={styles.subtitle}>
                                    <Typography variant='h3'>{`New Image:`}</Typography>
                                </Box>
                                <CardMedia
                                    sx={styles.formImage}
                                    component='img'
                                    alt='thumbnail_image'
                                    src={`data:image/png;base64,${form.image.data}`}
                                    title={`${form.name}'s New Image`}
                                />
                            </div>
                        }
                    </div>
                </FormColumnStyled>

                <FormColumnStyled>
                    {showDiscountFields &&
                        <FieldWithTooltip
                            path={'/velocity/offers/rules'}
                            tooltip={`Click for list of rules`}
                        >
                            <TextFieldStyled
                                error={offerState?.warning?.rules}
                                helperText={offerState?.warning?.rules ? 'Updating this value may affect offer status' : ''}
                                label='Rules'
                                type='text'
                                multiline
                                data-cy='enter_offer_rules'
                                InputLabelProps={{ shrink: true }}
                                disabled={disabled}
                                value={form?.rules || ''}
                                onChange={edit ? updateFieldWithWarning('rules') : updateField('rules')}
                            />
                        </FieldWithTooltip>
                    }

                    <TextFieldStyled
                        label='Terms & Conditions'
                        type='text'
                        multiline
                        minRows={2}
                        data-cy='enter_offer_terms'
                        sx={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.terms || ''}
                        onChange={updateField('terms')}
                    />
                    <TextFieldStyled
                        error={offerState?.warning?.value}
                        helperText={offerState?.warning?.value ? 'Updating this value may affect offer status' : ''}
                        label='Value'
                        type='text'
                        data-cy='enter_offer_value'
                        sx={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={isPresent(form?.value) ? form?.value : ''}
                        onChange={edit ? updateNumberFieldWithWarning('value') : updateNumberField('value')}
                    />

                    <FieldWithTooltip
                        tooltip={`Minimum purchase quanitity to qualify`}
                    >
                        <TextFieldStyled
                            error={offerState?.warning?.buy_quantity}
                            helperText={offerState?.warning?.buy_quantity ? 'Updating this value may affect offer status' : ''}
                            label='Buy Quantity'
                            type='text'
                            data-cy='enter_offer_buy_quantity'
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            value={isPresent(form?.buy_quantity) ? form?.buy_quantity : ''}
                            onChange={edit ? updateNumberFieldWithWarning('buy_quantity') : updateNumberField('buy_quantity')}
                        />
                    </FieldWithTooltip>

                    <FieldWithTooltip
                        tooltip={`Total number of redemptions available. 0 - no limit`}
                    >
                        <TextFieldStyled
                            label='Offer Limit'
                            type='text'
                            data-cy='enter_offer_limit'
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            value={isPresent(form?.offer_limit) ? form?.offer_limit : ''}
                            onChange={updateNumberField('offer_limit')}
                        />
                    </FieldWithTooltip>

                    <FieldWithTooltip
                        tooltip={`Total clips (across all customers). 0 - no limit/unlimited customers can clip offers`}
                    >
                        <TextFieldStyled
                            label='Clip Limit'
                            type='text'
                            data-cy='enter_offer_clip_limit'
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            value={isPresent(form?.clip_limit) ? form?.clip_limit : ''}
                            onChange={updateNumberField('clip_limit')}
                        />
                    </FieldWithTooltip>

                    <FieldWithTooltip
                        tooltip={`Total redemptions for single customer. 0 - no limit. 1 - customer can redeem an offer one time.`}
                    >
                        <TextFieldStyled
                            label='Customer Limit'
                            type='text'
                            data-cy='enter_offer_customer_limit'
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            value={isPresent(form?.customer_limit) ? form?.customer_limit : ''}
                            onChange={updateNumberField('customer_limit')}
                        />
                    </FieldWithTooltip>

                    <TextFieldStyled
                        label='Purchase Limit'
                        type='text'
                        data-cy='enter_purchase_limit'
                        sx={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={isPresent(form?.purchase_limit) ? form?.purchase_limit : ''}
                        onChange={updateNumberField('purchase_limit')}
                    />

                    {showDiscountFields &&
                        <FieldWithTooltip
                            tooltip={`Defines the unit of maximum reward. Quantity - Q. Amount - $`}
                        >
                            <FormLabel sx={styles.radioFormLabel}>
                                Max Unit
                                <RadioGroup
                                    aria-label="max-unit"
                                    name="max-unit"
                                    sx={styles.buttons}
                                    value={form?.max_unit}
                                    onChange={updateField('max_unit')}
                                >
                                    <FormControlLabel value={'Q'} control={<Radio />} label="Quantity (Q)" />
                                    <FormControlLabel value={'$'} control={<Radio />} label="Amount ($)" />
                                </RadioGroup>
                            </FormLabel>
                        </FieldWithTooltip>
                    }

                    {showDiscountFields &&
                        <FieldWithTooltip
                            tooltip={`Maximum reward, based on max units. 1 for Q, 400 for $4.00, 0 for unlimited.`}
                        >
                            <TextFieldStyled
                                error={offerState?.warning?.max_reward}
                                helperText={offerState?.warning?.max_reward ? 'Updating this value may affect offer status' : ''}
                                label='Max Reward'
                                type='text'
                                data-cy='enter_max_reward'
                                InputLabelProps={{ shrink: true }}
                                disabled={disabled}
                                value={isPresent(form?.max_reward) ? form?.max_reward : ''}
                                onChange={edit ? updateNumberFieldWithWarning('max_reward') : updateNumberField('max_reward')}
                            />
                        </FieldWithTooltip>
                    }

                    {/* TODO(adriennehein) https://www.pivotaltracker.com/story/show/187895677
                    fix MUI element outline */}
                    {showDiscountFields &&
                        <FieldWithTooltip
                            tooltip={`Allow discounts on items already discounted (by the retailer before InComm's evaluation or by InComm previously within the same transaction). True - allow discounts, False - don't allow`} // eslint-disable-line max-len
                        >
                            <AutocompleteStyled
                                autoComplete
                                style={offerState?.warning?.stack && styles.warningField}
                                disableClearable
                                data-cy='stack'
                                options={TRUE_FALSE}
                                getOptionLabel={(value) => value.name || ' '}
                                disabled={disabled}
                                value={TRUE_FALSE.find((type) => type.value === form?.stack) || ''}
                                onChange={() => setStack(!form?.stack, edit)}
                                renderInput={(params) => (
                                    <TextField {...params} label='Stack' />
                                )}
                            />
                            {offerState?.warning?.stack &&
                                <FormHelperText sx={styles.helperText}>Updating this value may affect offer status</FormHelperText>
                            }
                        </FieldWithTooltip>
                    }

                    <div style={styles.dateRow}>
                        <DateTimePicker
                            clearable
                            disabled={disabled}
                            invalidLabel={form?.offer_start}
                            placeholder='mm/dd/yyyy hh:mm a/p'
                            label='Offer Start'
                            sx={styles.dateField}
                            value={form?.offer_start ? dayjs(form?.offer_start) : null}
                            onChange={(datetime, value) => updateDateTimeField({ field: 'offer_start', datetime, value })}
                        />
                        <DateTimePicker
                            clearable
                            error={offerState?.warning?.offer_end}
                            disabled={disabled}
                            invalidLabel={form?.offer_end}
                            placeholder='mm/dd/yyyy hh:mm a/p'
                            label='Offer End'
                            sx={styles.dateField}
                            value={form?.offer_end ? dayjs(form?.offer_end) : null}
                            onChange={(datetime, value) => updateDateTimeField({ field: 'offer_end', datetime, value, edit })}
                        />
                    </div>

                    {offerState?.warning?.offer_end &&
                        <FormHelperText sx={styles.helperText}>Updating this value may affect offer status</FormHelperText>
                    }

                    <div style={styles.formLabel}>
                        Schedules

                        <Button
                            type='submit'
                            data-cy='submit_offer'
                            variant='outlined'
                            sx={styles.addButton}
                            disabled={disabled}
                            onClick={() => addScheduleField('schedules')}
                        >
                            <AddCircleOutlineIcon />
                        </Button>
                    </div>

                    {form?.schedules?.map((schedule, index) => (
                        <div style={styles.dateRow} key={index}>
                            <DateTimePicker
                                clearable
                                disabled={disabled}
                                invalidLabel={form?.schedules[index]?.start_at}
                                placeholder='mm/dd/yyyy hh:mm a/p'
                                label={'Start'}
                                sx={styles.dateField}
                                value={form?.schedules[index]?.start_at ? dayjs(form?.schedules[index]?.start_at) : null}
                                onChange={(datetime, value) => updateScheduleField({ field: 'start_at', datetime, value, index, id: schedule.id })}
                            />
                            <DateTimePicker
                                clearable
                                disabled={disabled}
                                invalidLabel={form?.schedules[index]?.end_at}
                                placeholder='mm/dd/yyyy hh:mm a/p'
                                label={'End'}
                                sx={styles.dateField}
                                value={form?.schedules[index]?.end_at ? dayjs(form?.schedules[index]?.end_at) : null}
                                onChange={(datetime, value) => updateScheduleField({ field: 'end_at', datetime, value, index, id: schedule.id })}
                            />
                        </div>
                    ))}

                    <Box sx={styles.formLabel}>
                        Expiration Display
                    </Box>

                    <AutocompleteStyled
                        autoComplete
                        disableClearable
                        data-cy='display_expiration_type'
                        options={DISPLAY_EXPIRATION_OPTIONS}
                        getOptionLabel={(value) => value.name || ''}
                        isOptionEqualToValue={selectedExpOptFilter(form)}
                        disabled={disabled}
                        value={DISPLAY_EXPIRATION_OPTIONS.find(selectedExpOptFilter(form)) || ''}
                        onChange={setDisplayExpirationType}
                        renderInput={(params) => (
                            <TextField {...params} label='Expiration Display' variant='outlined' />
                        )}
                    />

                    <DateTimePicker
                        clearable
                        sx={styles.field}
                        disabled={form?.display_expiration?.name !== 'static'}
                        invalidLabel={form?.offer_start}
                        placeholder="mm/dd/yyyy hh:mm a"
                        label={'Static Expiration Date'}
                        value={form?.display_expiration?.name === 'static' && form?.display_expiration?.date ?
                            dayjs(form?.display_expiration?.data) : null
                        }
                        onChange={(datetime, value) => setStaticDisplayExpiration({ datetime, value })}
                    />
                </FormColumnStyled>

                <Divider sx={styles.divider} />

                <RetailerSelector
                    form={form}
                    retailers={retailers}
                    updateRetailer={updateRetailer}
                    selectAllRetailers={selectAllRetailers}
                    getAllIDs={getAllIDs}
                    checked={checked}
                    selectAllChecked={selectAllChecked}
                />

                <Divider sx={styles.divider} />

                <Typography variant='h2' align='center' sx={styles.heading}>
                    UPCs
                    <Tooltip title="List of Global Trade Identification Numbers ('GTINs'; always 14 positions with leading zeroes)" placement='right'>
                        <InfoIcon />
                    </Tooltip>
                </Typography>

                <FormColumnStyled>
                    <div style={styles.formLabel}>
                        Primary UPCs
                        <Button
                            type='submit'
                            data-cy='submit_offer'
                            variant='outlined'
                            sx={styles.addButton}
                            disabled={disabled}
                            onClick={() => addUPCField('primary_upcs')}
                        >
                            <AddCircleOutlineIcon />

                        </Button>
                    </div>
                    {form?.primary_upcs?.map((upc, index) => (
                        <TextFieldStyled
                            key={index}
                            label={(index + 1)}
                            type='text'
                            data-cy='enter_primary_upcs'
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            value={upc || ''}
                            onChange={(e) => updateUPCField(index, 'primary_upcs', e)}
                        />
                    ))}
                </FormColumnStyled>

                <FormColumnStyled>
                    <div style={styles.formLabel}>
                        Secondary UPCs
                        <Button
                            type='submit'
                            data-cy='submit_offer'
                            variant='outlined'
                            sx={styles.addButton}
                            disabled={disabled}
                            onClick={() => addUPCField('secondary_upcs')}
                        >
                            <AddCircleOutlineIcon />

                        </Button>
                    </div>
                    {form?.secondary_upcs?.map((upc, index) => (
                        <TextFieldStyled
                            key={index}
                            label={(index + 1)}
                            type='text'
                            data-cy='enter_secondary_upcs'
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            value={upc || ''}
                            onChange={(e) => updateUPCField(index, 'secondary_upcs', e)}
                        />
                    ))}
                </FormColumnStyled>

                <FormColumnStyled>
                    <Divider sx={styles.divider} />

                    <OfferTemplating
                        updateTemplate={updateTemplate}
                        updateTemplateCheckbox={updateTemplateCheckbox}
                        updateTemplateDropdown={updateTemplateDropdown}
                        setTemplate={setTemplate}
                        template={form?.template_landing}
                        templateType='offer_landing'
                        displayName='Landing'
                    />

                    <Divider sx={styles.divider} />

                    <OfferTemplating
                        updateTemplate={updateTemplate}
                        updateTemplateCheckbox={updateTemplateCheckbox}
                        updateTemplateDropdown={updateTemplateDropdown}
                        setTemplate={setTemplate}
                        template={form?.template_engage}
                        templateType='offer_engage'
                        displayName='Engage'
                    />
                </FormColumnStyled>

                <SubmitBlockStyled>
                    {!isProduction() && !edit &&
                        <Tooltip
                            title='You must enter an Offer Partner, an Image, and Retailers manually'
                            placement='top'
                        >
                            <Button
                                variant='contained'
                                color='secondary'
                                sx={styles.btn}
                                disabled={disabled}
                                onClick={setDev}
                            >
                                Auto-fill form
                            </Button>
                        </Tooltip>
                    }

                    <Button
                        type='submit'
                        data-cy='submit_offer'
                        variant='contained'
                        color='secondary'
                        sx={styles.btn}
                        disabled={disabled}
                        onClick={submit}
                    >
                        Submit
                    </Button>

                    <BackButton />
                </SubmitBlockStyled>
            </FormStyled>
        </PageStyled>
    )
}

export default IncommOfferBaseForm
