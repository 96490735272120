import { typography } from 'theme'

const useStyles = () => ({
    btn: {
        width: 100,
        margin: '5px',
    },
    title: {
        color: (theme) => theme.palette.text.primary,
        textAlign: 'center',
        margin: '10px',
        fontWeight: typography.h2.fontWeight,
        fontSize: typography.h2.fontSize,
    },
    page: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.paper,
        flexDirection: 'column',
        borderRadius: '10px',
        maxHeight: 700,
        maxWidth: 1000,
        padding: '30px',
        overflow: 'scroll',
    },
    messageText: {
        color: (theme) => theme.palette.text.primary,
        textAlign: 'center',
        margin: '10px',
        whiteSpace: 'pre-line',
        fontWeight: typography.h3.fontWeight,
        fontSize: typography.h3.fontSize,
    },
})

export const customModalStyles = {
    overlay: {
        zIndex: 999999999999,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background: 'none',
        marginRight: '-50%',
        border: 'none',
        transform: 'translate(-50%, -50%)',
        minHeight: 400,
        minWidth: 400,
    },
}

export default useStyles
