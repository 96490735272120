const useStyles = () => ({
    page: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.paper,
        padding: '50px 35px',
        width: { xs: '90vw', sm: '100%' },
    },
    btn: {
        margin: '0 5px',
        width: 100,
    },
})

export const customModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background: 'none',
        marginRight: '-50%',
        border: 'none',
        transform: 'translate(-50%, -50%)',
        minHeight: 400,
        maxWidth: 600,
    },
}

export default useStyles
