import { useParams } from 'react-router-dom'
import RetailerForm from 'features/velocity/retailers/components/Form'
import { useEditRetailer } from 'features/velocity/retailers/hooks/edit'

export const RetailerEdit = () => {
    const { id } = useParams()
    const editRetailerResult = useEditRetailer(id)

    return (
        <RetailerForm
            edit={true}
            {...editRetailerResult}
        />
    )
}

export default RetailerEdit
