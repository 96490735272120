export const ConnectionError = ({ err }) => {
    return (
        <div>
            <h2>Oh no, something went wrong!</h2>
            {err &&
                <h3>{err.toString()}</h3>
            }
        </div>
    )
}

export default ConnectionError
