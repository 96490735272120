import { useState } from 'react'
import {
    Button,
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material'
import {
    Visibility,
    VisibilityOff,
} from '@mui/icons-material'

import { validatePassword } from 'utilities/user_validations'
import { classesFn } from '../styles'

const ResetPasswordForm = () => {
    const classes = classesFn()
    const [password, setPassword] = useState('')
    const [confirmedPassword, setConfirmedPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmedPassword, setShowConfirmedPassword] = useState(false)
    const handleClickShowPassword = (num) => num === 0 ? setShowPassword(!showPassword) : setShowConfirmedPassword(!showConfirmedPassword)
    const handleMouseDownPassword = (num) => num === 0 ? setShowPassword(!showPassword) : setShowConfirmedPassword(!showConfirmedPassword)

    const resetPassword = () => {
        if (password !== confirmedPassword) return alert('Please enter matching passwords')
        if (validatePassword(password)) {
            console.log('success!') // eslint-disable-line no-console
            // configure resetting the password
            // redirect the user to the login screen
        } else {
            alert('Your password must be 6-20 characters long, contain at least one numeric digit, one uppercase and one lowercase letter')
        }
    }

    return (
        <form style={classes.form} onSubmit={(e) => e.preventDefault()}>
            <TextField
                required
                id='new-password-input'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                color='primary'
                                onClick={() => handleClickShowPassword(0)}
                                onMouseDown={() => handleMouseDownPassword(0)}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                required
                id='confirm-new-password-input'
                label='Confirm Password'
                type={showConfirmedPassword ? 'text' : 'password'}
                onChange={(event) => setConfirmedPassword(event.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                color='primary'
                                onClick={() => handleClickShowPassword(1)}
                                onMouseDown={() => handleMouseDownPassword(1)}
                            >
                                {showConfirmedPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                type='submit'
                disabled={!password || !confirmedPassword}
                onClick={resetPassword}
                variant='contained'
                sx={classes.submitButton}
            >
                {`Reset Password`}
            </Button>
        </form>
    )
}

export default ResetPasswordForm
