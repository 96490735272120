const Monkey = (props) => (
    <svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 279 314" width="225" height="253" {...props}>
        <path
            d="M184 181C184 198.59 179.67 213.79 171.94 224.36C164.41 234.68 153.4 241 138.5 241C123.6 241 112.59 234.68 105.06 224.36C97.33
            213.79 93 198.59 93 181C93 164.94 101.29 149.79 111.95 138.38C117.22 132.73 122.87 128.23 127.91 125.2C133.22 122 136.93 121 138.5
            121C140.07 121 143.78 122 149.09 125.2C154.13 128.23 159.78 132.73 165.05 138.38C175.71 149.79 184 164.94 184 181Z"
            fill='#ffb4b4' stroke='#ff6969' strokeWidth='16'
        />
        <path
            d="M197.16 88.05C197.16 92.21 195.92 95.87 194.05 98.42C192.18 100.97 189.83 102.25 187.5 102.25C185.17 102.25 182.82 100.97 180.95
            98.42C179.08 95.87 177.84 92.21 177.84 88.05C177.84 83.88 179.08 80.22 180.95 77.67C182.82 75.12 185.17 73.84 187.5 73.84C189.83 73.84
            192.18 75.12 194.05 77.67C195.92 80.22 197.16 83.88 197.16 88.05Z"
            fill='#ffb4b4' stroke='#ff6969' strokeWidth='5.682'
        />
        <path
            d="M98.16 88.05C98.16 92.21 96.92 95.87 95.05 98.42C93.18 100.97 90.83 102.25 88.5 102.25C86.17 102.25 83.82 100.97 81.95 98.42C80.08
            95.87 78.84 92.21 78.84 88.05C78.84 83.88 80.08 80.22 81.95 77.67C83.82 75.12 86.17 73.84 88.5 73.84C90.83 73.84 93.18 75.12 95.05
            77.67C96.92 80.22 98.16 83.88 98.16 88.05Z"
            fill='#ffb4b4' stroke='#ff6969' strokeWidth='5.682'
        />
        <path
            d="M183 86.74C183 98.38 180.9 109.63 174.61 117.9C168.52 125.9 157.81 132 138.5 132C119.19 132 108.48 125.9 102.39 117.9C96.1 109.63
            94 98.38 94 86.74C94 65.42 114.47 44 138.5 44C162.53 44 183 65.42 183 86.74Z"
            fill='#ffb4b4' stroke='#ff6969' strokeWidth='10'
        />
        <path
            d="M121 67.5C113.16 67.44 100 74 97 74C94 74 114 54 114 54L139.5 46L166.5 54L180.5 74C180.5 74 164.09 66.44 155 67.5C148.48 68.26
            139.5 74 139.5 74C139.5 74 128.66 67.56 121 67.5Z"
            fill='#ff6969' stroke='#ff6969' strokeWidth='5'
        />
        <path
            d="M123.5 95C119.35 95 116 91.65 116 87.5C116 83.35 119.35 80 123.5 80C127.65 80 131 83.35 131 87.5C131 91.65 127.65 95 123.5 95Z"
            fill='#ff6969'
        />
        <path
            d="M125.5 88C124.12 88 123 86.88 123 85.5C123 84.12 124.12 83 125.5 83C126.88 83 128 84.12 128 85.5C128 86.88 126.88 88 125.5 88Z"
            fill='#ffffff'
        />
        <path
            d="M139 105C135.13 105 132 103.44 132 101.5C132 99.56 135.13 98 139 98C142.87 98 146 99.56 146 101.5C146 103.44 142.87 105 139 105Z"
            fill='#ff6969'
        />
        <path
            d="M153.5 95C149.35 95 146 91.65 146 87.5C146 83.35 149.35 80 153.5 80C157.65 80 161 83.35 161 87.5C161 91.65 157.65 95 153.5 95Z"
            fill='#ff6969'
        />
        <path
            d="M118.5 109.5C124.61 113.57 129.91 116.37 138 116.5C146.46 116.63 152.4 113.57 158.5 109.5"
            fill='none' stroke='#ff6969' strokeWidth='4'
        />
        <path
            d="M216 181.5C205 143.5 167 130.5 167 130.5L177.5 165C177.5 165 189 165 198 183C207 201 211 209.5 204 211C197 212.5 195 221.5 198
            221.5C201 221.5 201.59 215.9 207 218C211.07 219.58 209 221.5 209 225.5C209 228.86 207.22 234.09 205 232.5C202.78 230.91 206.08 229.28
            204 227.5C202.22 225.97 199.78 225.97 198 227.5C195.92 229.28 197 231.5 198 234.5C199 237.5 204 240 204 240C204 240 216.5 242 220.5
            232.5C224.5 223 227 219.5 216 181.5Z"
            fill='#ff6969' stroke='#ff6969'
        />
        <path
            d="M103.88 222L127 238.97C127 238.97 105.58 250.6 103.88 262.42C102.35 273.09 115.28 277.16 116.33 287.87C116.62 290.78 117.03 292.49
            116.33 295.36C113.54 306.71 72.11 323.05 72.46 307.83C72.58 302.65 69.5 298.85 77.8 295.36C86.1 291.86 90.87 293.2 94.99 287.87C102.39
            278.28 79.59 273.75 77.8 262.42C74.98 244.61 103.88 222 103.88 222Z"
            fill='#ff6969'
        />
        <path
            d="M174.12 222L151 238.97C151 238.97 172.42 250.6 174.12 262.42C175.65 273.09 162.72 277.16 161.67 287.87C161.38 290.78 160.97
            292.49 161.67 295.36C164.46 306.71 205.89 323.05 205.54 307.83C205.42 302.65 208.5 298.85 200.2 295.36C191.9 291.86 187.13 293.2
            183.01 287.87C175.61 278.28 198.41 273.75 200.2 262.42C203.02 244.61 174.12 222 174.12 222Z"
            fill='#ff6969'
        />
        <path
            d="M67.5 176.5C80.71 172.53 86.13 143.34 108 128.43C129.87 113.52 98.55 172.06 98.55 172.06C98.55 172.06 87 197.31 73.04 200.88C54.5
            205.63 41.15 178.03 34.32 172.06C30.43 168.67 23.65 168.72 19.29 166.1C14.47 163.21 10.65 158.35 12.45 152.69C14.45 146.41 23.19
            158.88 27.49 154.18C30.64 150.73 27.59 143.09 32.04 142.75C36.65 142.39 34.94 149.99 37.51 154.18C46.73 169.2 54.29 180.47 67.5 176.5Z"
            fill='#ff6969' stroke='#ff6969'
        />
        <path
            d="M155.5 88C154.12 88 153 86.88 153 85.5C153 84.12 154.12 83 155.5 83C156.88 83 158 84.12 158 85.5C158 86.88 156.88 88 155.5 88Z"
            fill='#ffffff'
        />
        <path
            d="M10.12 129.64L26.62 125.56L37.01 189.87L28 192.01L10.12 129.64Z"
            fill='#c4c4c4'
        />
        <path
            fillRule="evenodd" d="M21.5 119.5L19.5 112L18 105.5C18 105.5 26.88 107.05 30 112C33.55 117.64 33.51 123.33 30 129C29.19 130.3 28.53
            131.27 27.68 132.15L36.56 187.05C37.29 187.29 38.02 187.68 38.76 188.23C39.75 188.97 40.2 189.55 40.87 190.59C42.38 192.94 40.57
            200.43 40.57 200.43L37.58 194.24L30.99 195.51L32.34 203.4C32.34 203.4 25.06 199.19 25.15 195.88C25.23 193.29 25.4 191.55 27.34
            189.69L12.06 136.42C6.89 135.82 3.61 132.17 2.5 130.5C0.5 127.5 2.5 110.5 2.5 110.5L10 122L21.5 119.5Z"
            fill='#c4c4c4'
        />
        <path
            fillRule="evenodd" d="M22.65 107.36C21.37 106.87 20.18 106.52 19.31 106.3C19.07 106.24 18.85 106.19 18.66 106.15L19.98
            111.88L21.98 119.37L22.12 119.88L21.61 119.99L10.11 122.49L10 122L10 122L10.11 122.49L9.77 122.56L9.58 122.27L2.85 111.95C2.81
            112.32 2.76 112.76 2.72 113.25C2.56 114.89 2.37 117.1 2.25 119.4C2.12 121.71 2.06 124.09 2.16 126.09C2.2 127.08 2.29 127.97
            2.42 128.69C2.55 129.43 2.72 129.94 2.92 130.22C3.99 131.83 7.16 135.35 12.12 135.92L12.45 135.96L12.54 136.28L27.82
            189.56L27.34 189.69L27.34 189.69L27.82 189.56L27.9 189.85L27.68 190.06C26.77 190.93 26.3 191.75 26.03 192.65C25.75 193.57
            25.69 194.59 25.65 195.9C25.64 196.55 25.99 197.32 26.63 198.15C27.27 198.97 28.14 199.78 29.03 200.5C29.91 201.22 30.81
            201.84 31.48 202.28C31.54 202.32 31.6 202.36 31.66 202.39L30.49 195.59L30.41 195.11L30.89 195.02L37.49 193.75L37.86
            193.68L38.03 194.02L37.58 194.24L37.58 194.24L38.03 194.02L40.38 198.9C40.52 198.19 40.67 197.29 40.79 196.33C40.92
            195.26 41 194.14 40.96 193.14C40.92 192.13 40.75 191.34 40.45 190.87C39.8  189.85 39.39 189.32 38.46 188.63C37.75
            188.11 37.08 187.75 36.4 187.52L36.11 187.43L36.06 187.13L27.19 132.23L27.68 132.15L27.68 132.15L27.19 132.23L27.15
            131.99L27.32 131.81C28.13 130.96 28.77 130.03 29.57 128.74C31.29 125.97 32.14 123.22 32.15 120.49C32.15 117.76 31.31
            115.02 29.58 112.27C28.11 109.93 25.24 108.36 22.65 107.36ZM19.01 112.12L19.02 112.13L19.01 112.11L17.51 105.61L17.34
            104.88L18.09 105.01L18 105.5C18.09 105.01 18.09 105.01 18.09 105.01C18.09 105.01 18.09 105.01 18.09 105.01C18.09 105.01
            18.09 105.01 18.09 105.01C18.09 105.01 18.09 105.01 18.09 105.01C18.1 105.01 18.11 105.01 18.12 105.01C18.13 105.02 18.16
            105.02 18.19 105.03C18.26 105.04 18.36 105.06 18.49 105.09C18.74 105.14 19.11 105.22 19.56 105.33C20.45 105.56 21.68 105.92
            23.01 106.43C25.64 107.44 28.77 109.11 30.42 111.73C32.24 114.62 33.15 117.55 33.15 120.49C33.14 123.44 32.22 126.37 30.43
            129.26C29.67 130.49 29.01 131.44 28.22 132.31L37 186.68C37.7 186.94 38.37 187.32 39.06 187.83C40.12 188.62 40.6 189.26
            41.29 190.32C41.74 191.03 41.91 192.05 41.96 193.1C42 194.17 41.91 195.36 41.78 196.45C41.65 197.55 41.46 198.57 41.32
            199.32C41.24 199.69 41.18 200 41.13 200.21C41.11 200.32 41.09 200.4 41.07 200.46C41.07 200.49 41.06 200.51 41.06
            200.53C41.06 200.53 41.05 200.54 41.05 200.54C41.05 200.54 41.05 200.55 41.05 200.55C41.05 200.55 41.05 200.55
            41.05 200.55C41.05 200.55 41.05 200.55 41.05 200.55C41.05 200.55 41.05 200.55 40.57 200.43L41.05 200.55L40.72
            201.91L40.12 200.65L37.3 194.8L31.56 195.91L32.83 203.32L32.34 203.4C32.34 203.4 32.34 203.4 32.34 203.4C32.09
            203.84 32.09 203.84 32.09  203.84C32.09 203.84 32.09 203.84 32.09 203.84C32.09 203.84 32.09 203.84 32.09 203.84C32.09
            203.84 32.09 203.83 32.08 203.83C32.08 203.83 32.07 203.83 32.07 203.82C32.05 203.81 32.03 203.8 32 203.79C31.95 203.75
            31.87 203.71 31.77 203.64C31.56 203.52 31.28 203.34 30.93 203.11C30.24 202.66 29.32 202.02 28.4 201.27C27.48 200.53 26.54
            199.66 25.84 198.76C25.15 197.87 24.63 196.87 24.65 195.87C24.69 194.57 24.75 193.43 25.07 192.37C25.37 191.36 25.88
            190.46 26.78 189.55L11.67 136.87C6.49 136.14 3.21 132.47 2.08 130.78C1.78 130.31 1.57 129.64 1.43 128.87C1.29 128.09
            1.2 127.15 1.16  126.13C1.06 124.09 1.13 121.67 1.25 119.35C1.38 117.03 1.56 114.8 1.72 113.16C1.8 112.33 1.87 111.66
            1.92 111.18C1.95 110.95 1.97 110.76 1.98 110.64C1.99 110.57 1.99 110.52 2 110.49C2 110.47 2 110.46 2 110.45C2 110.45 2
            110.45 2 110.44C2 110.44 2 110.44 2 110.44C2 110.44 2 110.44 2 110.44C2 110.44 2 110.44 2.5 110.5C2.5 110.5 2.5 110.5
            2.5 110.5L2.92 110.23L10.23 121.44L20.88 119.12L19.02 112.13L19.01 112.11L19.01 112.12ZM32.83 203.32L33.01 204.37L32.09
            203.84L32.34 203.4L32.83 203.32ZM2 110.44L2.16 109.07L2.92 110.23L2.5 110.5L2 110.44ZM30 112L30.42 111.73L30.42
            111.73L30  112ZM30 129L30.43 129.26L30.43 129.26L30 129ZM38.76 188.23L38.46 188.63L38.46 188.63L38.76 188.23ZM40.87
            190.59L40.45 190.87L40.45 190.87L40.87 190.59ZM25.15 195.88L25.65 195.9L25.65 195.9L25.15 195.88ZM2.5 130.5L2.08
            130.78L2.08 130.78L2.5 130.5Z"
            fill='#a8a8a8'
        />
        <path
            d="M21.49 139.9L30.22 181.75"
            fill='none' stroke='#a8a8a8'
        />
        <path
            d="M193.5 62.22L84 62.22C84 62.22 90.67 57.28 94 53.22C101.7 43.8 97.57 34.04 104 23.72C107.31 18.41 109.18 15.19 114
            11.22C128.92 -1.07 148.58 -1.07 163.5 11.22C168.32 15.19 170.19 18.41 173.5 23.72C179.93 34.04 175.8 43.8 183.5
            53.22C186.83 57.28 193.5 62.22 193.5 62.22Z"
            fill='#ffe600' stroke='#dfc902'
        />
        <path
            d="M123.5 1.5L155.5 1.5L155.5 62.5L123.5 62.5L123.5 1.5Z"
            fill='#ffe500' stroke='#dfc902'
        />
    </svg>
)

export default Monkey
