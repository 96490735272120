const useStyles = () => ({
    modalCover: {
        maxHeight: '85vh',
        width: 'auto',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        margin: 'auto',
    },
    closeButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        fontWeight: 900,
        minWidth: 30,
    },
})

export default useStyles
