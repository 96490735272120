import { colors } from 'theme'

const useStyles = () => ({
    subtitle: {
        alignSelf: { xs: 'center', md: 'flex-start' },
        margin: '20px',
    },
    cover: {
        minHeight: 100,
        width: 375,
        padding: '0 75px',
        marginBottom: '20px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        objectFit: 'contain',
    },
    base64_cover: {
        width: 'auto',
        height: 'auto',
        maxHeight: 200,
        alignContent: 'center',
    },
    editorTitle: {
        margin: '30px 0 20px',
    },
    retailerWrapper: {
        width: '100%',
        display: 'grid',
        alignContent: 'right',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    },
    btn: {
        margin: '0 5px',
        width: 100,
    },
    checkbox: {
        color: colors.vizerCoral.main,
        '&.MuiChecked': {
            color: colors.vizerCoral.main,
        },
    },
})

export default useStyles
