import {
    Typography,
    Button,
    Tooltip,
} from '@mui/material'
import ImageWithModal from 'components/ImageWithModal'
import { isEmpty } from 'helpers'
import useStyles from './styles'
import Loading from 'components/Loading'

export const StripImages = ({
    id, google_strip_url, apple_strip_url_1x,
    apple_strip_url_2x, apple_strip_url_3x,
    generateStripImages, disabled,
}) => {
    const styles = useStyles()

    const noStripImages = isEmpty(google_strip_url) &&
        isEmpty(apple_strip_url_1x) &&
        isEmpty(apple_strip_url_2x) &&
        isEmpty(apple_strip_url_3x)

    const generateButtonContent = disabled ? (
        <>
            Generating Strip Images
            <div style={styles.loading}>
                <Loading inline={true} />
            </div>
        </>
    ) : 'Generate Strip Images'

    return (
        <>
            {noStripImages ? (
                <div style={styles.emptyStripUrls}>
                    <Typography variant='h3'>
                        No Strip Images Found
                    </Typography>
                </div>
            ) : (
                <>
                    <ImageWithModal
                        image={{
                            url: google_strip_url,
                            alt: `Offer Retailer ${id}'s Google Wallet Strip Image`,
                            title: `Google Wallet Strip Image`,
                            variant: 'banner',
                        }}
                    />
                    <ImageWithModal
                        image={{
                            url: apple_strip_url_1x,
                            alt: `Offer Retailer ${id}'s Apple Wallet Strip Image 1x`,
                            title: `Apple Wallet Strip Image 1x`,
                            variant: 'banner',
                        }}
                    />
                    <ImageWithModal
                        image={{
                            url: apple_strip_url_2x,
                            alt: `Offer Retailer ${id}'s Apple Wallet Strip Image 2x`,
                            title: `Apple Wallet Strip Image 2x`,
                            variant: 'banner',
                        }}
                    />
                    <ImageWithModal
                        image={{
                            url: apple_strip_url_3x,
                            alt: `Offer Retailer ${id}'s Apple Wallet Strip Image 3x`,
                            title: `Apple Wallet Strip Image 3x`,
                            variant: 'banner',
                        }}
                    />
                </>
            )}
            <Tooltip
                title={'Generating these images takes some time. Please wait for confirmation of success or failure before trying again.'}
                placement={'top'}
            >
                <Button
                    sx={styles.btn}
                    variant='contained'
                    color='secondary'
                    data-cy='generate-strip-image-btn'
                    disabled={disabled}
                    onClick={() => generateStripImages()}
                >
                    {generateButtonContent}
                </Button>
            </Tooltip>
        </>
    )
}

export default StripImages

