import { useLocation } from 'react-router-dom'
import OfferPartnerForm from 'features/velocity/offer_partners/components/OfferPartnerForm'
import { useCreateOfferPartner } from 'features/velocity/offer_partners/hooks/create'

export const OfferPartnerNewForm = () => {
    const location = useLocation()
    const brand = location?.state?.brand
    const createOfferPartnerResult = useCreateOfferPartner()

    return (
        <OfferPartnerForm
            edit={false}
            brand={brand}
            {...createOfferPartnerResult}
        />
    )
}

export default OfferPartnerNewForm
