import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
    Box,
    Button,
    TextField,
} from '@mui/material'
import useStyles from './styles'
import { AutocompleteStyled } from 'theme/forms'

const Pagination = ({ pagination, setPage, pageState, disabled }) => {
    const styles = useStyles()

    let pages = [...Array(pagination.total_pages).keys()]
    pages = pages.map((i) => (i + 1))

    return (
        <div style={styles.bar}>
            {pagination?.total_pages !== 0 &&
                <>
                    <Button
                        sx={styles.btn}
                        variant="contained"
                        data-cy='prev-btn'
                        disabled={pageState?.page == 1 || disabled} // eslint-disable-line eqeqeq
                        onClick={(e) => setPage(e, pageState?.page - 1, 'page')}
                    >
                        <ChevronLeftIcon />
                    </Button>
                    <Box sx={styles.paginationContainer}>
                        <AutocompleteStyled
                            style={styles.input}
                            disableClearable
                            disablePortal
                            data-cy='page-select'
                            options={pages}
                            getOptionLabel={(value) => value.toString()}
                            isOptionEqualToValue={(p) => p === Number(pageState?.page)}
                            disabled={disabled}
                            value={pages.find((p) => p === Number(pageState?.page)) || 1}
                            onChange={(e, value) => setPage(e, value, 'page')}
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                        />
                        <p>
                            <span>of</span>
                            {pagination?.total_pages}
                        </p>
                    </Box>

                    <Button
                        sx={styles.btn}
                        variant="contained"
                        data-cy='next-btn'
                        disabled={pageState?.page == pagination?.total_pages || disabled} // eslint-disable-line eqeqeq
                        onClick={(e) => setPage(e, Number(pageState?.page) + 1, 'page')}
                    >
                        <ChevronRightIcon />
                    </Button>
                </>
            }
        </div>
    )
}

export default Pagination
