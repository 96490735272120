import { useEffect, useState } from 'react'
import qs from 'qs'
import dayjs from 'dayjs'

import {
    reduceQueries, chartDataHelper, emptyToNull, isPresent,
    squashArr, tickLabelSpacingHelper, useDebounceObject,
    dateTimeLocalToUtcDate,
} from 'helpers'
import { velocityUrl, _fetch_file } from 'utils'
import { colors } from 'theme'
import { UPDATE_FIELD } from 'features/status'
import { useQueryOfferGraphAnalytics, useQueryOfferGraphsAnalyticsDefaultDates } from 'features/velocity/analytics/queries'
import { useGraphFiltersReducer } from 'features/velocity/analytics/reducers/graph_filters'

export const fmtGraphDate = (date) => date ? dayjs(date).format('YYYY-MM-DD') : null

export const useOfferGraphAnalytics = (offerID, state) => {
    const [filterState, dispatch] = useGraphFiltersReducer(state)
    const [filterParams, setPreventDebounce] = useDebounceObject(filterState, 1500)

    const {
        payloads: [analyticsPayload, defaultDatesPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [
            useQueryOfferGraphAnalytics(offerID, filterParams),
            useQueryOfferGraphsAnalyticsDefaultDates(offerID),
        ],
    )

    const filterStartDate = defaultDatesPayload?.start_date
    const filterEndDate = defaultDatesPayload?.end_date

    let totalClicks = analyticsPayload?.total_clicks
    let uniqueClicks = analyticsPayload?.unique_clicks
    let totalClips = analyticsPayload?.total_clips
    let uniqueClips = analyticsPayload?.unique_clips
    let totalScans = analyticsPayload?.total_scans
    let uniqueScans = analyticsPayload?.unique_scans
    let totalScanRefunds = analyticsPayload?.total_scan_refunds
    let uniqueScanRefunds = analyticsPayload?.unique_scan_refunds
    let clicksToClips = analyticsPayload?.clicks_to_clips
    let clipsToScans = analyticsPayload?.clips_to_scans

    // NOTE(brianthomashammond): consts below assume total/unique || conversion arrays are same length
    const clicksSpacing = tickLabelSpacingHelper(totalClicks)
    const clipsSpacing = tickLabelSpacingHelper(totalClips)
    const scansSpacing = tickLabelSpacingHelper(totalScans)
    const conversionsSpacing = tickLabelSpacingHelper(clicksToClips)

    const lineColors = {
        'Total Clicks': colors.vizerPeacock.main,
        'Unique Clicks': colors.vizerPeacock.main,
        'Total Clips': colors.vizerOrchid.main,
        'Unique Clips': colors.vizerOrchid.main,
        'Total Scans': colors.vizerCoral.main,
        'Unique Scans': colors.vizerCoral.main,
        'Total Refunds': colors.vizerCherry.main,
        'Unique Refunds': colors.vizerCherry.main,
        'Click to Clip Conversion Rate': colors.vizerIndigo.main,
        'Clip to Scan Conversion Rate': colors.vizerBubblegum.main,
    }

    totalClicks = isPresent(totalClicks) ? chartDataHelper('Total Clicks', lineColors['Total Clicks'], totalClicks) : null
    uniqueClicks = isPresent(uniqueClicks) ? chartDataHelper('Unique Clicks', lineColors['Unique Clicks'], uniqueClicks) : null

    totalClips = isPresent(totalClips) ? chartDataHelper('Total Clips', lineColors['Total Clips'], totalClips) : null
    uniqueClips = isPresent(uniqueClips) ? chartDataHelper('Unique Clips', lineColors['Unique Clips'], uniqueClips) : null

    totalScans = isPresent(totalScans) ? chartDataHelper('Total Scans', lineColors['Total Scans'], totalScans) : null
    uniqueScans = isPresent(uniqueScans) ? chartDataHelper('Unique Scans', lineColors['Unique Scans'], uniqueScans) : null

    totalScanRefunds = isPresent(totalScanRefunds) ? chartDataHelper('Total Refunds', lineColors['Total Refunds'], totalScanRefunds) : null
    uniqueScanRefunds = isPresent(uniqueScanRefunds) ? chartDataHelper('Unique Refunds', lineColors['Unique Refunds'], uniqueScanRefunds) : null

    clicksToClips = isPresent(clicksToClips) ?
        chartDataHelper(
            'Click to Clip Conversion Rate',
            lineColors['Click to Clip Conversion Rate'],
            clicksToClips,
        ) : null
    clipsToScans = isPresent(clipsToScans) ?
        chartDataHelper(
            'Clip to Scan Conversion Rate',
            lineColors['Clip to Scan Conversion Rate'],
            clipsToScans,
        ) : null

    const clicks = squashArr([totalClicks, uniqueClicks])
    const clips = squashArr([totalClips, uniqueClips])
    const scansAndRefunds = squashArr([totalScans, uniqueScans, totalScanRefunds, uniqueScanRefunds])
    const conversionRates = squashArr([clicksToClips, clipsToScans])

    const updateDateField = ({ field, datetime, value }) => {
        const date = datetime && dayjs(datetime).isValid() ? dateTimeLocalToUtcDate(datetime.toISOString()) : value

        dispatch({ type: UPDATE_FIELD, field, value: date })
    }

    const updateUTMFieldsByLinkField = (_, link, reason) => {
        if (reason === 'clear') {
            setPreventDebounce()

            dispatch({ type: 'RESET_LINK_FILTER_VALUES' })
        } else {
            setPreventDebounce()

            const { utm_codes } = link.short_link

            dispatch({ type: UPDATE_FIELD, field: 'shortlink_id', value: link.offer_short_link.id })
            dispatch({ type: UPDATE_FIELD, field: 'utm_source', value: utm_codes.utm_source })
            dispatch({ type: UPDATE_FIELD, field: 'utm_medium', value: utm_codes.utm_medium })
            dispatch({ type: UPDATE_FIELD, field: 'utm_campaign', value: utm_codes.utm_campaign })
            dispatch({ type: UPDATE_FIELD, field: 'utm_content', value: utm_codes.utm_content })
            dispatch({ type: UPDATE_FIELD, field: 'utm_term', value: utm_codes.utm_term })
        }
    }

    const updateField = (field, event) => {
        dispatch({
            type: UPDATE_FIELD,
            field,
            value: emptyToNull(event.target.value),
        })
    }

    const updateUtmCode = (field) => (event) => {
        dispatch({ type: UPDATE_FIELD, field: 'shortlink_id', value: null })

        updateField(field, event)
    }

    const updateStrategy = (value) => {
        dispatch({ type: UPDATE_FIELD, field: 'offer_strategy', value })
    }

    const resetFilterValues = () => {
        setPreventDebounce()

        dispatch({ type: 'RESET_FILTER_VALUES' })
    }

    const { exportGraph } = useGraphsExport(offerID, filterState)

    return {
        connectionError,
        success,
        clicks,
        clips,
        scansAndRefunds,
        conversionRates,
        lineColors,
        clicksSpacing,
        clipsSpacing,
        scansSpacing,
        conversionsSpacing,
        errors,
        warnings,
        notices,
        filterState,
        filterStartDate,
        filterEndDate,
        updateField,
        updateUtmCode,
        updateStrategy,
        updateDateField,
        updateUTMFieldsByLinkField,
        resetFilterValues,
        exportGraph,
        ...isResults,
    }
}

const useGraphsExport = (offerID, filterState) => {
    const [csvLink, setCsvLink] = useState()
    const [download, setDownload] = useState(false)

    useEffect(() => {
        const { utm_source, utm_term, utm_campaign, utm_content, utm_medium, offer_strategy } = filterState
        const params = { offer_strategy, start_date: fmtGraphDate(filterState.start_date), end_date: fmtGraphDate(filterState.end_date) }

        const filterParams = {
            ...params,
            utm_source,
            utm_term,
            utm_campaign,
            utm_content,
            utm_medium,
        }

        velocityUrl('/offers/queries/fetch_offer_analytics')
            .then(async (url) => {
                if (isPresent(url)) {
                    const params = qs.stringify(filterParams, { skipNulls: true })

                    setCsvLink(`${url}/${offerID}/graphs/export?${params}`)
                }
            })
    }, [filterState])

    useEffect(() => {
        if (download) {
            const downloadFile = async () => {
                const a = document.createElement('a')

                const { blob, filename } = await _fetch_file(csvLink, {})

                const objectUrl = window.URL.createObjectURL(blob)
                a.href = objectUrl
                a.download = filename
                a.style = 'display: none;'

                document.body.appendChild(a)
                a.click()

                document.body.removeChild(a)
                window.URL.revokeObjectURL(objectUrl)
            }

            downloadFile()
        }

        setDownload(false)
    }, [download])

    const exportGraph = () => {
        if (csvLink) setDownload(true)
    }

    return {
        exportGraph,
    }
}
