import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'
import { useSendEmailsFormReducer } from '../reducers/send_emails'
import { bundleFileData, emptyToNull } from 'helpers'

export const useSendEmailsForm = (state = {}) => {
    const [sendEmailsState, dispatch] = useSendEmailsFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })
    const updateOfferField = (_, offer) => {
        dispatch({ type: UPDATE_FIELD, field: 'offer_id', value: offer.id })
    }
    const uploadCSV = async (e) => {
        const target_file = e.target.files[0]
        const file = await bundleFileData(target_file)

        dispatch({ type: UPDATE_FIELD, field: 'csv_data', value: file })
    }

    const clearField = (field) => dispatch({ type: UPDATE_FIELD, field, value: null })
    const setForm = (offer) => dispatch({ type: SET_FORM, value: offer })
    const resetForm = () => dispatch({ type: RESET_FORM })

    return {
        form: sendEmailsState.form,
        sendEmailsState,
        updateField,
        updateOfferField,
        uploadCSV,
        clearField,
        setForm,
        resetForm,
    }
}
