import { UPDATE_FIELD, RESET_FORM } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const registerFormInitialState = (state = {}) => {
    return {
        form: {
            first_name: null,
            last_name: null,
            email: null,
            password: null,
            confirmed_password: null,
        },
        show_password: false,
        form_errors: [],
        errors: null,
        ...state,
    }
}

export const registerFormReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_FORM_FIELD': {
            state.form[`${action.field}`] = action.value

            break
        }
        case 'UPDATE_FORM_ERRORS': {
            state.form_errors.push(action.value)

            break
        }
        case UPDATE_FIELD: {
            state[`${action.field}`] = action.value

            break
        }
        case 'CLEAR_FORM_ERRORS': {
            state.form_errors = []

            break
        }
        case RESET_FORM: {
            return registerFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useRegisterFormReducer = (state = {}) => {
    return useReducer(registerFormReducer, registerFormInitialState(state))
}
