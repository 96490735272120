import { useEffect } from 'react'
import { reduceLoadingState, squashArr } from 'helpers'
import { useCatalogShow } from 'features/velocity/catalogs/hooks/show'
import { useCatalogForm } from 'features/velocity/catalogs/hooks/form'
import { useUpdateCatalogCmd } from 'features/velocity/catalogs/queries'

export const useEditCatalog = (id) => {
    const {
        form,
        updateField,
        updateActiveField,
        setForm,
    } = useCatalogForm()

    const catalogShowResult = useCatalogShow(id)

    const isLoadError = catalogShowResult.isError
    const loadSuccess = catalogShowResult.success

    const updateResult = useUpdateCatalogCmd()
    const onSubmit = () => updateResult.mutate(form)

    const connectionError = catalogShowResult.connectionError
    const warnings = squashArr([catalogShowResult.warnings, updateResult.data?.warnings])
    const notices = squashArr([catalogShowResult.notices, updateResult.data?.notices])
    const errors = squashArr([catalogShowResult.errors, updateResult.error, updateResult.data?.errors])

    const isLoadingState = reduceLoadingState([catalogShowResult]) || updateResult.isLoading
    const isDisabled = catalogShowResult.isLoading || updateResult.isLoading

    const catalog = loadSuccess ? catalogShowResult?.catalog : {}

    useEffect(() => {
        if (loadSuccess) {
            setForm(catalog)
        }
    }, [loadSuccess, catalog])

    return {
        loadSuccess,
        connectionError,
        errors,
        warnings,
        notices,
        isLoadError,
        isLoadingState,
        disabled: isDisabled,
        form,
        updateField,
        updateActiveField,
        onSubmit,
    }
}
