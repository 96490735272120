import { colors } from 'theme'

const useStyles = () => ({
    accordion: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    pageHeader: {
        marginBottom: '30px',
    },
    btn: {
        fontWeight: 500,
        '& svg': {
            marginLeft: '10px',
        },
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '20px',
        '& h1': {
            margin: 0,
        },
        flexWrap: { xs: 'wrap', md: 'nowrap' },
    },
    headerButtons: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        '& > button': {
            marginRight: '10px',
        },
    },
    link: {
        textDecoration: 'none',
        color: colors.black,
    },
    pageRow: {
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
    },
    pageColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 15px',
        '&:nth-of-type(1)': {
            paddingLeft: 0,
        },
        '&:nth-of-type(4)': {
            flexGrow: 1,
            paddingRight: 0,
        },
    },
    detailsCard: {
        border: (theme) => `1px solid ${theme.palette.text.primary}`,
        borderTopLeftRadius: 0,
        padding: '20px 15px',
    },
    table: {
        background: colors.vizerSlateGray.light,
    },
    tabs: {
        background: 'none',
        boxShadow: 'none',
    },
    panel: {
        padding: 0,
    },
    tab: {
        color: (theme) => theme.palette.text.primary,
        border: (theme) => `1px solid ${theme.palette.text.primary}`,
        borderBottom: 0,
        borderTopRightRadius: 10,
        borderLeft: 0,
        position: 'relative',
        overflow: 'visible',
        '&:first-of-type': {
            borderLeft: (theme) => `1px solid ${theme.palette.text.primary}`,
            borderTopLeftRadius: 10,
        },
        '&::after': {
            position: 'absolute',
            top: -1,
            right: -1,
            width: 10,
            height: '1px',
            background: (theme) => theme.palette.text.primary,
            content: '""',
            display: 'block',
            opacity: '85%',
        },
        '&:last-of-type::after': {
            display: 'none',

        },
    },
})

export default useStyles
