import { createSlice } from '@reduxjs/toolkit'
import {
    FETCHING,
    SUCCESS,
    ERROR,
    PENDING,
} from 'features/status'

const name = 'permissionList'

const initialState = {
    status: PENDING,
    hasLoaded: false,
    permissions: [],
    error: null,
    userID: null,
    roleID: null,
}

const reducers = {
    fetchPermissions: (state, { payload }) => {
        const { id, parent } = payload

        if (id) state[`${parent}ID`] = parseInt(id)

        state.status = FETCHING
    },
    fetchPermissionsResult: (state, { payload }) => {
        const { permissions } = payload

        state.status = SUCCESS
        state.hasLoaded = true
        state.permissions = permissions
    },
    fetchPermissionsError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetPermissions: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    fetchPermissions,
    fetchPermissionsResult,
    fetchPermissionsError,
    resetPermissions,
} = slice.actions

export default slice.reducer
