import { Box, Grid } from '@mui/material'
import useStyles, { TypographyStyled } from './styles'

const EventCountBox = ({ total, subject, suffix, color, border, grow }) => {
    const styles = useStyles()

    const localizedTotal = total?.toLocaleString()
    const totalDisplay = (localizedTotal || '-')

    return (
        <Grid item xs={12} sm={grow ? 12 : 6} lg>
            <Box sx={styles.totalValueContainer({ color, border })}>
                <Box sx={styles.totalValue}>
                    <TypographyStyled variant='h4' type='subject' data-testid='subject'>
                        {subject}
                    </TypographyStyled>
                    <TypographyStyled variant='h4' type='suffix' data-testid='suffix'>
                        {suffix}
                    </TypographyStyled>
                    <TypographyStyled variant='h3' type='value' data-testid='total'>
                        {totalDisplay}
                    </TypographyStyled>
                </Box>
            </Box>
        </Grid>
    )
}

export default EventCountBox
