import { velocityUrl, _get, _post } from 'utils'
import qs from 'qs'

export async function fetchOfferPartners(pageQuery) {
    try {
        const url = await velocityUrl(`/offer_partners/queries/list_offer_partners?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}

export async function fetchOfferPartnerShow(id, pageQuery) {
    try {
        const url = await velocityUrl(`/offer_partners/queries/fetch_offer_partner_by_id/${id}?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function createNewOfferPartner({
    brand_id, status, name, image,
}) {
    try {
        const url = await velocityUrl('/offer_partners/commands/create_offer_partner')

        const body = {
            offer_partner: {
                brand_id,
                status,
                name,
            },
            image,
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function updateExistingOfferPartner({
    id, status, name, logo_original, logo_1x, logo_2x, logo_3x, image,
}) {
    try {
        const url = await velocityUrl(`/offer_partners/commands/update_offer_partner`)

        const body = {
            offer_partner: {
                id,
                status,
                name,
                logo_original,
                logo_1x,
                logo_2x,
                logo_3x,
            },
            image,
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
