const styles = {
    logo: {
        fontFamily: 'Avenir, sans-serif',
        fontWeight: 400,
        fontSize: 22,
        textTransform: 'uppercase',
        letterSpacing: 5,
        margin: 0,
        height: 30,
    },
}

export default styles
