import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import OfferPartnerEdit from 'features/velocity/offer_partners/components/OfferPartnerEdit'
import useStyles from './styles'

export const Edit = () => {
    const styles = useStyles()
    const navigate = useNavigate()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <OfferPartnerEdit />
        </>
    )
}

export default Edit
