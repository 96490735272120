export const black = '#000000'
export const white = '#FFFFFF'
export const disabled = 'rgba(0, 0, 0, 0.3)'

// vizer brand colors
export const vizerCharcoal = {
    main: '#2F2F2F',
    dark: '#272727',
    light: 'rgba(47, 47, 47, 0.5)',
    default: '#424242',
}

export const vizerCoral = {
    main: '#FF6969',
    dark: '#D15656',
    light: 'rgba(255, 105, 105, 0.15)',
}

export const vizerIceGray = {
    main: '#E5E5EA',
    dark: '#BCBCC0',
    light: 'rgba(242, 242, 244, 0.5)',
}

export const vizerOrange = {
    main: '#EF9C70',
    dark: '#C4805C',
    light: 'rgba(239, 156, 112, 0.5)',
}

export const vizerSlateGray = {
    main: '#707071',
    dark: '#5C5C5D',
    light: 'rgba(112, 112, 113, 0.5)',
}

export const lightGray = {
    main: '#F0F0F0',
    dark: '#909090',
    light: '#F8F8F8',
    lighter: '#FAFAFA',
}

// this can only be used as a "background" property
export const vizerSunrise = {
    main: 'linear-gradient(#FF6969, #EF9C70)',
    dark: 'linear-gradient(#D15656, #C4805C)',
    light: 'linear-gradient(rgba(255, 105, 105, 0.5), rgba(239, 156, 112, 0.5))',
}

// alerts
export const error = {
    main: '#E10606',
    dark: '#B90505',
    light: 'rgba(225, 6, 6, 0.5)',
    lighter: 'rgba(225, 6, 6, 0.35)',
    lightest: 'rgba(225, 6, 6, 0.15)',
}

export const info = {
    main: '#15A5D2',
    dark: '#1187AC',
    light: 'rgba(21, 165, 210, 0.35)',
}

export const success = {
    main: '#0D9C13',
    dark: '#0B8010',
    light: 'rgba(13, 156, 19, 0.5)',
    lighter: 'rgba(13, 156, 19, 0.35)',
}

export const warning = {
    main: '#FFC700',
    dark: '#D1A300',
    light: 'rgba(255, 199, 0, 0.55)',
    lighter: 'rgba(255, 199, 0, 0.35)',
}

export const vizerPeacock = {
    main: '#53D4B6',
    dark: '#42AA92',
    light: 'rgba(83, 212, 182, 0.15)',
}

export const vizerIndigo = {
    main: '#5369D4',
    dark: '#4254AA',
    light: 'rgba(83, 105, 212, 0.15)',
}

export const vizerOrchid = {
    main: '#CA53D4',
    dark: '#A242AA',
    light: 'rgba(202, 83, 212, 0.15)',
}

export const vizerBubblegum = {
    main: '#FF69C3',
    dark: '#CC549C',
    light: 'rgba(255, 105, 195, 0.15)',
}

export const vizerCherry = {
    main: '#fa2b6f',
    dark: '#bb0440',
    light: 'rgba(252, 143, 179, 0.15)',
}
