import { createSlice } from '@reduxjs/toolkit'

const name = 'graph_modal'

const initialState = {
    graphTitle: null,
    graphData: null,
    lineColors: null,
    visible: false,
    tickLabelSpacing: null,
    conversion: false,
}

const reducers = {
    setGraphModal: (state, { payload }) => {
        const { graphTitle, graphData, lineColors, tickLabelSpacing, conversion } = payload

        state.graphTitle = graphTitle
        state.graphData = graphData
        state.lineColors = lineColors
        state.visible = true
        state.tickLabelSpacing = tickLabelSpacing
        state.conversion = conversion
    },
    dismissGraphModal: () => initialState,
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    setGraphModal,
    dismissGraphModal,
} = slice.actions

export default slice.reducer
