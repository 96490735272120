import { reduceQueries } from 'helpers'
import { useQueryPendingDomains } from 'features/velocity/domains/queries'

export const usePendingDomains = ({ params, paginationQueryArgs }) => {
    const {
        payloads: [pendingDomainsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryPendingDomains(params, paginationQueryArgs)],
    )

    const domains = pendingDomainsPayload?.pending_domains || []
    const pagination = pendingDomainsPayload?.pagination || {}

    return {
        connectionError,
        success,
        domains,
        errors,
        warnings,
        notices,
        pagination,
        ...isResults,
    }
}
