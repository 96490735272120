import { takeEvery, put } from '@redux-saga/core/effects'
import {
    fetchRole,
    fetchRoleResult,
    fetchRoleError,
} from '../reducers/show'
import { fetchRoleShow } from '../services'

export function* takeFetchRole(action) {
    try {
        const { payload } = yield fetchRoleShow(action.payload)

        yield put(fetchRoleResult(payload))
    } catch (error) {
        yield put(fetchRoleError(error.toString()))
    }
}

export function* watchFetchRole() {
    yield takeEvery(fetchRole.toString(), takeFetchRole)
}
