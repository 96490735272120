import { put, takeEvery } from 'redux-saga/effects'
import { fetchUsersList } from '../services'
import {
    fetchUsers,
    fetchUsersResult,
    fetchUsersError,
} from '../reducers/list'

export function* takeFetchUsers() {
    try {
        const { payload } = yield fetchUsersList()
        const { users } = payload

        yield put(fetchUsersResult({ users }))
    } catch (error) {
        yield put(fetchUsersError(error.toString()))
    }
}

export function* watchFetchUsers() {
    yield takeEvery(fetchUsers.toString(), takeFetchUsers)
}
