import { colors } from 'theme'

const useStyles = () => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    btn: {
        width: 100,
        margin: '10px 5px',
    },
    link: {
        textDecoration: 'none',
        color: colors.black,
    },
})

export default useStyles
