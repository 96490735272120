import { useState } from 'react'
import { useDeleteBannerRetailer } from 'features/velocity/banner_retailers/hooks/delete'
import { useQueryRetailerShow, useGenerateStripImagesCmd } from 'features/velocity/retailers/queries'
import { BARCODE_TYPE_OPTIONS } from 'features/status'
import { reduceQueries } from 'helpers'

export const useRetailerShow = (retailerID) => {
    const deleteBannerRetailerResult = useDeleteBannerRetailer(retailerID)
    const [generatingImages, setGeneratingImages] = useState(false)

    const {
        payloads: [retailerPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryRetailerShow(retailerID)],
    )

    const retailer = retailerPayload?.retailer
    const barcodeType = BARCODE_TYPE_OPTIONS.find((op) => op.value === retailer?.barcode_type?.name || '')?.name
    const barcodeData = retailer?.barcode_type?.data
    const generateResult = useGenerateStripImagesCmd(setGeneratingImages, retailerID)

    return {
        connectionError,
        success,
        retailer,
        barcodeType,
        barcodeData,
        errors,
        warnings,
        notices,
        generateStripImages: () => {
            setGeneratingImages(true)
            generateResult.mutate(retailerID)
        },
        generateStripImagesDisabled: generatingImages,
        ...deleteBannerRetailerResult,
        ...isResults,
    }
}
