import { useParams } from 'react-router-dom'
import { Container } from '@mui/material'
import AcceptInvitationForm from 'features/invitations/components/InvitationForm'
import { useAcceptInvitation } from 'features/invitations/hooks/accept'

const Invitation = () => {
    const { invitation_id } = useParams()

    const invitationProps = useAcceptInvitation(invitation_id)

    return (
        <Container maxWidth='xl'>
            <AcceptInvitationForm {...invitationProps} />
        </Container>
    )
}
export default Invitation
