import { useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete'

import {
    Box,
    Button,
    CardMedia,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
} from '@mui/material'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import FieldWithTooltip from 'components/FieldWithTooltip'
import { footerVariants } from 'features/velocity/offers/components/OfferTemplating'
import { TemplateSectionHeader } from '../TemplateSectionHeader'
import { StyleUpdater } from '../StyleUpdater'
import { useOfferTemplate } from 'features/velocity/templates/hooks/template'
import useStyles from '../styles'

export const LandingAcacia = ({
    disabled, template: offerTemplate, setTemplate, updateTemplate,
    updateTemplateCheckbox, updateTemplateDropdown, templateType,
}) => {
    const styles = useStyles()

    const offerTemplateResult = useOfferTemplate(templateType, 'landing_acacia')
    const { template, templateImage } = offerTemplateResult

    useEffect(() => {
        if (template && offerTemplate?.id !== template.id) {
            setTemplate('template_landing', template)
        }
    }, [offerTemplate?.id, template?.id])

    const updateLandingTemplate = (path) => updateTemplate('template_landing', path)
    const updateLandingTemplateCheckbox = (path, value) => updateTemplateCheckbox('template_landing', path, value)
    const updateLandingTemplateDropdown = (path, value) => updateTemplateDropdown('template_landing', path, value)

    const { intro, instructions, steps, footer } = offerTemplate?.children ?? {}
    const { children: introChildren } = intro ?? {}

    if (offerTemplateResult.isInitialLoading) return <Loading />

    if (offerTemplateResult.connectionError) return <ConnectionError err={offerTemplateResult.connectionError} />

    if (offerTemplateResult.isError) return <ConnectionError err={offerTemplateResult.errors} />

    return (
        <>
            <Box sx={styles.template}>
                <Typography variant='h2' align='center' sx={styles.templateTitle}>Landing Acacia</Typography>

                {!disabled &&
                    <Button
                        sx={styles.resetButton}
                        variant='contained'
                        color='secondary'
                        disabled={disabled}
                        onClick={() => setTemplate('template_landing', template)}
                    >
                        Reset to Default Values
                    </Button>
                }

                <div>
                    <Box sx={styles.templateSection}>
                        <StyleUpdater
                            disabled={disabled}
                            updateTemplateDropdown={updateLandingTemplateDropdown}
                            value={intro?.styles}
                            path='children.intro.styles'
                            css={['color', 'background']}
                        >
                            <Box sx={styles.templateSectionHeader}>
                                <Typography variant='h2'>Intro Section</Typography>
                            </Box>
                        </StyleUpdater>

                        <div style={styles.templateSectionBody}>
                            <FieldWithTooltip tooltip={'The main page heading'} >
                                <TextField
                                    label='Heading1'
                                    type='text'
                                    data-cy='enter_heading1'
                                    sx={styles.field}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={disabled}
                                    value={introChildren?.heading1?.content || ''}
                                    onChange={updateLandingTemplate('children.intro.children.heading1.content')}
                                />
                            </FieldWithTooltip>

                            <FieldWithTooltip tooltip={'The secondary page heading'} >
                                <TextField
                                    label='Heading2'
                                    type='text'
                                    data-cy='enter_heading2'
                                    sx={styles.field}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={disabled}
                                    value={introChildren?.heading2?.content || ''}
                                    onChange={updateLandingTemplate('children.intro.children.heading2.content')}
                                />
                            </FieldWithTooltip>

                            <FieldWithTooltip tooltip={'Choose whether to include "Find the Nearest Location" messaging and update link'} >
                                <FormGroup style={styles.checkbox}>
                                    <FormControlLabel
                                        label="Include 'Find Locations' Message"
                                        control={
                                            <Checkbox
                                                checked={introChildren?.locations_link?.active || false}
                                                disabled={disabled}
                                                onChange={updateLandingTemplateCheckbox('children.intro.children.locations_link.active')}
                                            />
                                        }
                                    />
                                    <TextField
                                        label='Locations Link Path'
                                        type='text'
                                        data-cy='enter_locations_link'
                                        sx={styles.field}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={disabled || !introChildren?.locations_link?.active}
                                        value={introChildren?.locations_link?.href || ''}
                                        onChange={updateLandingTemplate('children.intro.children.locations_link.href')}
                                    />
                                </FormGroup>
                            </FieldWithTooltip>

                            <StyleUpdater
                                disabled={disabled}
                                updateTemplateDropdown={updateLandingTemplateDropdown}
                                value={introChildren?.wifi_text?.styles}
                                path='children.intro.children.wifi_text.styles'
                                css={['color']}
                                variant='field'
                            >
                                <FieldWithTooltip tooltip={'"*Make sure you are not on Wi-Fi while generating the offer" text'} >
                                    <FormGroup style={styles.checkbox}>
                                        <FormControlLabel
                                            label="Include Wi-Fi Message"
                                            onClick={(e) => e.stopPropagation()}
                                            control={
                                                <Checkbox
                                                    checked={introChildren?.wifi_text?.active || false}
                                                    disabled={disabled}
                                                    onChange={updateLandingTemplateCheckbox('children.intro.children.wifi_text.active')}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </FieldWithTooltip>
                            </StyleUpdater>

                            <FieldWithTooltip tooltip={'The offer image must be included'} >
                                <FormGroup style={styles.checkbox}>
                                    <FormControlLabel
                                        label="Include Offer Image"
                                        control={
                                            <Checkbox
                                                checked={introChildren?.offer_img?.active || false}
                                                disabled
                                                onChange={updateLandingTemplateCheckbox('children.intro.children.offer_img.active')}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </FieldWithTooltip>

                            <StyleUpdater
                                disabled={disabled}
                                updateTemplateDropdown={updateLandingTemplateDropdown}
                                value={introChildren?.offer_link?.styles}
                                path='children.intro.children.offer_link.styles'
                                css={['color', 'background']}
                                variant='field'
                            >
                                <FieldWithTooltip tooltip={'Text for the offer link button'} >
                                    <TextField
                                        label='Offer Link Text'
                                        type='text'
                                        data-cy='enter_offer_link'
                                        sx={styles.field}
                                        InputLabelProps={{ shrink: true }}
                                        disabled={disabled}
                                        value={introChildren?.offer_link?.content || ''}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={updateLandingTemplate('children.intro.children.offer_link.content')}
                                    />
                                </FieldWithTooltip>
                            </StyleUpdater>

                            <FieldWithTooltip tooltip={'"After opening the offer, make sure to click \'Add to Wallet\'" text'} >
                                <FormGroup style={styles.checkbox}>
                                    <FormControlLabel
                                        label="Include 'Add to Wallet' text"
                                        control={
                                            <Checkbox
                                                checked={introChildren?.wallet_text?.active || false}
                                                disabled={disabled}
                                                onChange={updateLandingTemplateCheckbox('children.intro.children.wallet_text.active')}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </FieldWithTooltip>
                        </div>
                    </Box>

                    <Box sx={styles.templateSection}>
                        <StyleUpdater
                            disabled={disabled}
                            updateTemplateDropdown={updateLandingTemplateDropdown}
                            value={instructions?.styles}
                            path='children.instructions.styles'
                            css={['color', 'background']}
                        >
                            <TemplateSectionHeader
                                disabled={disabled}
                                title='Instructions Section'
                                value={instructions?.active}
                                onChange={updateLandingTemplateCheckbox('children.instructions.active')}
                            />
                        </StyleUpdater>
                    </Box>

                    <Box sx={styles.templateSection}>
                        <StyleUpdater
                            disabled={disabled}
                            updateTemplateDropdown={updateLandingTemplateDropdown}
                            value={steps?.styles}
                            path='children.steps.styles'
                            css={['color', 'background']}
                        >
                            <TemplateSectionHeader
                                disabled={disabled}
                                title='Steps Section'
                                value={steps?.active}
                                onChange={updateLandingTemplateCheckbox('children.steps.active')}
                            />
                        </StyleUpdater>
                    </Box>

                    <Box sx={styles.templateSection}>
                        <StyleUpdater
                            disabled={disabled}
                            updateTemplateDropdown={updateLandingTemplateDropdown}
                            value={footer?.styles}
                            path='children.footer.styles'
                            css={['color', 'background']}
                        >
                            <TemplateSectionHeader
                                disabled={disabled}
                                title='Footer'
                                value={footer?.active}
                                onChange={updateLandingTemplateCheckbox('children.footer.active')}
                            />
                        </StyleUpdater>

                        <div style={styles.templateSectionBody}>
                            <Autocomplete
                                autoComplete
                                disableClearable
                                sx={styles.field}
                                data-cy='select_footer_variant'
                                options={footerVariants}
                                getOptionLabel={(op) => op.name || ' '}
                                disabled={disabled || !footer?.active}
                                value={footerVariants.find((op) => op.value === footer?.variant) || ''}
                                onChange={(_, val) => updateLandingTemplateDropdown('children.footer.variant', val.value)}
                                renderInput={(params) => (
                                    <TextField {...params} label='Variant' />
                                )}
                            />
                        </div>
                    </Box>
                </div>

                <div style={styles.imgColumn}>
                    <CardMedia
                        sx={styles.contain}
                        component='img'
                        alt={'template representation'}
                        image={`data:image/png;base64,${templateImage}`}
                        title={'landing_acacia'}
                    />
                </div>
            </Box>

            <IsLoading
                isLoading={offerTemplateResult.isLoading}
                isFetching={offerTemplateResult.isFetching}
                isRefetching={offerTemplateResult.isRefetching}
            />

        </>
    )
}

export default LandingAcacia
