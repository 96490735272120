import { Typography } from '@mui/material'
import { colors } from 'theme'
import { styled } from '@mui/material/styles'

export const TypographyStyled = styled(Typography)(({ type, theme }) => ({
    '&.MuiTypography-root': {
        ...(type === 'value' && {
            fontSize: '2.75rem',
            fontWeight: 'bold',
            marginBottom: '10px',
            [theme.breakpoints.up('xl')]: {
                fontSize: '4rem',
            },
        }),
        ...(type === 'subject' && {
            marginBottom: '5px',
            lineHeight: 1.5,
            [theme.breakpoints.up('xl')]: {
                fontSize: '2.25rem',
            },
        }),
        ...(type === 'suffix' && {
            fontSize: '1.15rem',
            textTransform: 'uppercase',
            letterSpacing: 1,
            margin: 0,
            [theme.breakpoints.up('xl')]: {
                fontSize: '2.125rem',
            },
        }),
    },
}))

const useStyles = () => ({
    totalValueContainer: ({ color, border }) => ({
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        backgroundColor: colors[color].light,
        borderRadius: '15px',
        boxShadow: `4px 5px 6px rgba(0, 0, 0, .25)`,
        padding: { xs: '25px 0', lg: 0 },
        border: `4px ${border} ${colors[color].main}`,
    }),
    totalValue: {
        color: (theme) => theme.palette.mode === 'light' ? colors.vizerCharcoal.main : 'inherit',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'space-between',
        flexDirection: 'column',
    },
})

export default useStyles
