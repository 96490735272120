import { Provider } from 'react-redux'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { CssBaseline } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { ThemeProvider } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

import { AppModeContext } from 'contexts'
import Loading from 'components/Loading'
import initializeStore from 'store/initializeStore'
import PageNotifications from 'features/page_notifications/components/PageNotifications'
import useStartup from 'features/settings/hooks/startup'
import AuthRouter from 'features/auth/components/AuthWrapper'
import useDarkMode from 'features/settings/hooks/darkMode'

const App = () => {
    const darkModePreferred = useMediaQuery('(prefers-color-scheme: dark)')
    const { darkMode, toggleDarkMode } = useDarkMode(darkModePreferred)
    const { loaded, error, theme, queryClient } = useStartup(darkMode)

    if (!loaded || !theme) return <Loading absolute />

    if (loaded && error) return <div>{`Oh no, error ${error}`}</div>

    const store = initializeStore()

    return (
        <StyledEngineProvider injectFirst>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Provider store={store}>
                        <ThemeProvider theme={theme}>
                            <AppModeContext.Provider value={{ darkMode, toggleDarkMode }}>
                                <CssBaseline />

                                <PageNotifications />

                                <AuthRouter />
                            </AppModeContext.Provider>
                        </ThemeProvider>
                    </Provider>
                </LocalizationProvider>

                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </StyledEngineProvider>
    )
}

export default App
