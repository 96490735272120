import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'
import { useClientPortalUserFormReducer } from 'features/velocity/client_portal_users/reducers/form'
import { emptyToNull } from 'helpers'

export const useClientPortalUserForm = (state = {}) => {
    const [form, dispatch] = useClientPortalUserFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })

    const updateOfferPartnersField = (_, offer_partners) => {
        const offerPartners = offer_partners.filter((offer_partner, i) => {
            return offer_partners.filter((op) => op.id === offer_partner.id).length === 1
        })
        dispatch({ type: UPDATE_FIELD, field: 'offer_partners', value: offerPartners })
    }

    const resetForm = () => dispatch({ type: RESET_FORM })

    const setForm = (client_portal_user) => dispatch({ type: SET_FORM, value: client_portal_user })

    return {
        form,
        updateField,
        updateOfferPartnersField,
        setForm,
        resetForm,
    }
}
