import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '../selectors'
import { setImageModal, dismissImageModal } from '../reducers/image_modal'

export const useImageModal = () => {
    const dispatch = useDispatch()

    const image = useSelector(selectors.imageSel)
    const visible = useSelector(selectors.imageVisibleSel)

    return {
        image,
        visible,
        setImageModal: (error) => dispatch(setImageModal(error)),
        dismissImageModal: () => {
            dispatch(dismissImageModal())
        },
    }
}
