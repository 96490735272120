import { colors } from 'theme'

const useStyles = () => ({
    btn: {
        width: 100,
        margin: '10px 5px 10px 0',
    },
    link: {
        textDecoration: 'none',
        color: colors.black,
    },
})

export default useStyles
