import {
    Autocomplete,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import Cell from 'components/Cell'
import Row from 'components/Row'
import { useDomainsByOffer } from 'features/velocity/offers/hooks/domains_by_offer'
import { DOMAIN_ATTEMPT_THRESHOLD_OPTIONS } from 'features/status'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import useStyles from './styles'

export const DomainsByOfferList = ({ offerID }) => {
    const styles = useStyles()

    const {
        domains,
        pagination,
        isFetching,
        isLoading,
        connectionError,
        isInitialLoading,
        isError,
        errors,
        isRefetching,
        setPage,
        pageState,
        invalidateListQueries,
        updateFilter,
    } = useDomainsByOffer(offerID)

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Domains</Typography>

            <Filter
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                updateFilter={updateFilter}
                refresh={invalidateListQueries}
            >
                <Autocomplete
                    autoComplete
                    freeSolo
                    clearOnBlur
                    selectOnFocus
                    sx={styles.input}
                    data-cy={'attempt-threshold'}
                    options={DOMAIN_ATTEMPT_THRESHOLD_OPTIONS}
                    getOptionLabel={(value) => value}
                    isOptionEqualToValue={(f) => f === pageState.filters.attempt_threshold}
                    value={
                        DOMAIN_ATTEMPT_THRESHOLD_OPTIONS.find((f) => f === pageState.filters.attempt_threshold) ||
                        pageState.filters.attempt_threshold ||
                        null
                    }
                    onChange={(e, value) => updateFilter(e, value, 'attempt_threshold')}
                    renderInput={(params) => (
                        <TextField {...params} label={'Minimum Attempts'} variant='outlined' />
                    )}
                />
            </Filter>

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>Domain</TableCell>
                            <TableCell align='center'>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        {domains?.map(({ domain, count }) => (
                            <Row
                                key={domain}
                                sx={styles.row}
                            >
                                <Cell scope='domain' align='center' noLink>{domain}</Cell>
                                <Cell align='center' noLink>{count}</Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {domains?.length === 0 &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No domains found.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default DomainsByOfferList
