import Stats from './Stats'

const AboutRoutes = {
    path: '/about',
    children: [
        {
            path: 'stats',
            element: <Stats />,
        },
    ],
}

export default AboutRoutes
