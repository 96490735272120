import { useState } from 'react'
import { Box, IconButton, InputAdornment, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ButtonStyled } from 'theme/styled_components'
import InputWithLabel from 'components/InputWithLabel'
import { useStyles } from './styles'

const AcceptInvitationForm = ({
    form: { first_name, last_name, email, password, password_confirmation },
    disabled, preventSubmit, setField, acceptInvitation,
}) => {
    const [displayPassword, setDisplayPassword] = useState(false)
    const styles = useStyles()

    return (
        <Box>
            <form onSubmit={(e) => e.preventDefault()}>
                <Box sx={styles.form}>
                    <Typography variant='h1' align='center' sx={styles.heading}>Confirm Your Account Details</Typography>
                    <InputWithLabel
                        id='first-name'
                        label='First Name'
                        data-cy='first-name'
                        disabled={disabled}
                        variant='standard'
                        value={first_name || ''}
                        onChange={setField('first_name')}
                    />
                    <InputWithLabel
                        id='last-name'
                        label='Last Name'
                        data-cy='last-name'
                        disabled={disabled}
                        value={last_name || ''}
                        variant='standard'
                        onChange={setField('last_name')}
                    />
                    <InputWithLabel
                        id='email'
                        label='Email'
                        data-cy='email'
                        type='email'
                        disabled={disabled}
                        value={email || ''}
                        variant='standard'
                        onChange={setField('email')}
                    />
                    <InputWithLabel
                        id='password'
                        data-testid='password-field'
                        label='Password'
                        type={displayPassword ? 'text' : 'password'}
                        data-cy='password'
                        disabled={disabled}
                        autoComplete='current-password'
                        variant='standard'
                        value={password || ''}
                        onChange={setField('password')}
                        InputProps={{
                            endAdornment: (
                                <ViewPasswordIcon
                                    displayPassword={displayPassword}
                                    setDisplayPassword={setDisplayPassword}
                                    disabled={disabled}
                                />
                            ),
                        }}
                    />
                    <InputWithLabel
                        id='confirm-password'
                        data-testid='confirm-password-field'
                        label='Confirm Password'
                        type={displayPassword ? 'text' : 'password'}
                        data-cy='confirm-password'
                        disabled={disabled}
                        variant='standard'
                        autoComplete='confirm-password'
                        value={password_confirmation || ''}
                        onChange={setField('password_confirmation')}
                        InputProps={{
                            endAdornment: (
                                <ViewPasswordIcon
                                    displayPassword={displayPassword}
                                    setDisplayPassword={setDisplayPassword}
                                    disabled={disabled}
                                />
                            ),
                        }}
                    />
                    <ButtonStyled
                        data-cy='login-submit'
                        data-testid='submit'
                        type='submit'
                        onClick={acceptInvitation}
                        variant='contained'
                        sx={styles.button}
                        disabled={disabled || preventSubmit}
                    >
                        {disabled ? '...' : 'Register'}
                    </ButtonStyled>
                </Box>
            </form>
        </Box>
    )
}

const ViewPasswordIcon = ({ disabled, displayPassword, setDisplayPassword }) => {
    const styles = useStyles()

    return (
        <InputAdornment position='end'>
            <IconButton
                sx={styles.passIcon}
                aria-label='toggle password visibility'
                color='primary'
                disableRipple={true}
                size='small'
                disabled={disabled}
                onClick={() => setDisplayPassword(!displayPassword)}
                onMouseDown={() => setDisplayPassword(!displayPassword)}
            >
                {displayPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    )
}

export default AcceptInvitationForm
