import { useEffect } from 'react'
import { reduceQueries, useInvalidateQuery } from 'helpers'
import { useQueryBrands, useQueryOfferPartners } from 'features/velocity/offer_partners/queries'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'

export const useOfferPartners = (state = {}) => {
    const debounceSkipKeys = ['offer_partner_status']

    const {
        setPage,
        resetPage,
        updateFilter,
        setSearch,
        resetFilters,
        search,
        params,
        pageState,
        paginationQueryArgs,
        resetFiltersDisabled: disabled,
    } = usePaginationFilters({ state, debounceSkipKeys })

    const {
        payloads: [offerPartnersPayload, brandsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [
            useQueryOfferPartners(params, paginationQueryArgs),
            useQueryBrands(paginationQueryArgs),
        ],
    )

    const invalidateListQueries = useInvalidateQuery(['offer_partners', 'list'])

    const resetFiltersDisabled = isResults.isFetching || disabled

    const offerPartnersArr = offerPartnersPayload?.offer_partners || []
    const filters = offerPartnersPayload?.filters || {}
    const pagination = offerPartnersPayload?.pagination || {}

    const offerPartners = offerPartnersArr.map((offerPartner) => {
        return {
            offer_partner: offerPartner,
            brand: brandsPayload?.brands.find((brand) => brand.id === offerPartner?.brand_id),
        }
    })

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    return {
        connectionError,
        success,
        offerPartners,
        errors,
        warnings,
        notices,
        filters,
        pagination,
        setPage,
        updateFilter,
        setSearch,
        search,
        resetFilters,
        pageState,
        invalidateListQueries,
        resetFiltersDisabled,
        ...isResults,
    }
}
