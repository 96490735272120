import { useEffect } from 'react'
import { squashArr } from 'helpers'
import { useAdminUserForm } from 'features/admin_users/hooks/form'
import { useCreateAdminUserCmd } from 'features/admin_users/queries'

export const useCreateAdminUser = () => {
    const { form, updateField, resetForm } = useAdminUserForm()
    const createResult = useCreateAdminUserCmd()

    const onSubmit = () => createResult.mutate(form)

    const warnings = squashArr([createResult.data?.warnings])
    const notices = squashArr([createResult.data?.notices])
    const errors = squashArr([createResult?.error, createResult.data?.errors])

    const isLoadingState = createResult.isLoading

    useEffect(() => {
        if (createResult.isSuccess) resetForm()
    }, [createResult.isSuccess])

    return {
        errors,
        isLoadingState,
        warnings,
        notices,
        disabled: isLoadingState,
        form,
        updateField,
        onSubmit,
    }
}
