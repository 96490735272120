import { colors } from 'theme'

const useStyles = () => ({
    retailGroup: {
        border: `1px solid ${colors.vizerSlateGray.light}`,
        borderRadius: '5px',
        margin: '5px',
        userSelect: 'none',
        width: 450,
    },
    checkbox: {
        color: colors.vizerCoral.main,
        '&.MuiChecked': {
            color: colors.vizerCoral.main,
        },
    },
    column: {
        alignItems: 'start',
    },
    row: {
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'start',
        flexDirection: 'row',
        width: '100%',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
    },
})

export default useStyles
