import { squashArr } from 'helpers'
import { useCatalogForm } from 'features/velocity/catalogs/hooks/form'
import { useCreateCatalogCmd } from 'features/velocity/catalogs/queries'

export const useCreateCatalog = () => {
    const {
        form,
        updateField,
        updateActiveField,
    } = useCatalogForm()

    const createResult = useCreateCatalogCmd()
    const onSubmit = () => createResult.mutate(form)

    const warnings = squashArr([createResult.data?.warnings])
    const notices = squashArr([createResult.data?.notices])
    const errors = squashArr([createResult.error, createResult.data?.errors])

    const isLoadingState = createResult.isLoading

    return {
        loadSuccess: true,
        errors,
        isLoadingState,
        warnings,
        notices,
        disabled: isLoadingState,
        form,
        updateField,
        updateActiveField,
        onSubmit,
    }
}
