import { createSlice } from '@reduxjs/toolkit'
import {
    UPDATING,
    UPDATED,
    ERROR,
    PENDING,
    LOADED,
} from 'features/status'

const name = 'rolesEdit'

const initialState = {
    status: PENDING,
    id: null,
    hasLoaded: false,
    role: {
        realm_id: null,
        name: null,
    },
    error: null,
}

const reducers = {
    setFormField: (state, { payload }) => {
        const form = state.role
        const { field, value } = payload

        const role = {
            ...form,
            [field]: value,
        }

        return { ...state, role }
    },
    setRealm: (state, { payload }) => {state.role.realm_id = payload},
    editRole: (state, { payload }) => {
        state.id = payload
    },
    editRoleResult: (state, { payload }) => {
        const { role } = payload

        state.statue = LOADED
        state.hasLoaded = true
        state.role = role
    },
    editRoleError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    updateRole: (state) => {
        state.status = UPDATING
    },
    updateRoleResult: (state, { payload }) => {
        const { role } = payload

        state.status = UPDATED
        state.role = role
    },
    updateRoleError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetForm: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    setFormField,
    setRealm,
    editRole,
    editRoleResult,
    editRoleError,
    updateRole,
    updateRoleResult,
    updateRoleError,
    resetForm,
} = slice.actions

export default slice.reducer
