import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import CatalogBaseForm from 'features/velocity/catalogs/components/Form'
import { useCreateCatalog } from 'features/velocity/catalogs/hooks/create'
import useStyles from './styles'

export const CatalogNew = () => {
    const navigate = useNavigate()
    const styles = useStyles()
    const createCatalogResult = useCreateCatalog()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <CatalogBaseForm
                edit={false}
                {...createCatalogResult}
            />
        </>
    )
}

export default CatalogNew
