import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import { Box, Tooltip, Button, Typography, CardMedia } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { FormColumnStyled, FormStyled, SubmitBlockStyled, TextFieldStyled } from 'theme/forms'
import { checkImageNotNull } from 'helpers'
import BackButton from 'components/BackButton'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import ImageFieldWithTooltip from 'components/ImageFieldWithTooltip'
import useStyles from './styles'
import { PageStyled } from 'theme/page'

export const BrandForm = ({
    edit, loadSuccess, connectionError, errors, isLoadingState,
    isLoadError, disabled, form, updateImageField, updateField, onSubmit, updateWYSIWYG,
}) => {
    const styles = useStyles()

    const image = checkImageNotNull(form.image)
    const formImage = checkImageNotNull(form.reward_cover_image_url)

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isLoadError) return <ConnectionError err={errors} />

    if (!loadSuccess) return <Loading />

    return (
        <PageStyled>
            <Typography variant='h1'>{`${edit ? 'Edit' : 'New'} Brand`}</Typography>

            <IsLoading
                isLoadingState={isLoadingState}
            />

            <FormStyled
                noValidate
                style={styles.form}
                autoComplete='off'
                onSubmit={(e) => e.preventDefault()}
                data-testid='offer-form'
            >
                <FormColumnStyled>
                    <TextFieldStyled
                        label='Name'
                        type='text'
                        data-cy='enter_brand_name'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.name || ''}
                        onChange={updateField('name')}
                    />
                    <TextFieldStyled
                        label='About'
                        type='text'
                        data-cy='enter_brand_about'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.about || ''}
                        onChange={updateField('about')}
                    />
                    <TextFieldStyled
                        label='Website'
                        type='text'
                        data-cy='enter_brand_website'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.website || ''}
                        onChange={updateField('website')}
                    />
                    <TextFieldStyled
                        label='Facebook'
                        type='text'
                        data-cy='enter_brand_facebook'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.facebook || ''}
                        onChange={updateField('facebook')}
                    />
                    <TextFieldStyled
                        label='Instagram'
                        type='text'
                        data-cy='enter_brand_instagram'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.instagram || ''}
                        onChange={updateField('instagram')}
                    />
                    <TextFieldStyled
                        label='Twitter'
                        type='text'
                        data-cy='enter_brand_twitter'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.twitter || ''}
                        onChange={updateField('twitter')}
                    />
                </FormColumnStyled>

                <FormColumnStyled>
                    <TextFieldStyled
                        label='App Link'
                        type='text'
                        data-cy='enter_brand_app_link'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.app_link || ''}
                        onChange={updateField('app_link')}
                    />
                    <TextFieldStyled
                        label='Redemption Instructions'
                        type='text'
                        data-cy='enter_brand_redemption_instructions'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.redemption_instructions || ''}
                        onChange={updateField('redemption_instructions')}
                    />
                    <ImageFieldWithTooltip>
                        <TextFieldStyled
                            label='Image'
                            type='file'
                            disabled={disabled}
                            data-cy='image'
                            InputLabelProps={{ shrink: true }}
                            onChange={updateImageField}
                        />
                    </ImageFieldWithTooltip>
                    {(formImage || image) &&
                        <>
                            <Box sx={styles.subtitle}>
                                <Typography variant='h3'>{'Current Image:'}</Typography>
                            </Box>
                            <CardMedia
                                sx={styles.cover}
                                component='img'
                                alt='thumbnail_image'
                                src={!formImage ? `data:image/png;base64,${form.image.data}` : form.reward_cover_image_url}
                                title={`${form.name}'s Current Image`}
                            />
                        </>
                    }
                    {formImage && image &&
                        <>
                            <Box sx={styles.subtitle}>
                                <Typography variant='h3'>{'New Image:'}</Typography>
                            </Box>
                            <CardMedia
                                sx={styles.base64_cover}
                                component='img'
                                alt='thumbnail_image'
                                src={`data:image/png;base64,${form.image.data}`}
                                title={`${form.name}'s New Image`}
                            />
                        </>
                    }
                </FormColumnStyled>

                <FormColumnStyled>
                    <Typography variant='h2' sx={styles.editorTitle}>
                        Add Resources Content
                        <Tooltip title="Populates the Resources card on the brand's client portal dashboard" placement='right'>
                            <InfoIcon />
                        </Tooltip>
                    </Typography>

                    <SunEditor
                        height={250}
                        setDefaultStyle="font-family:  Avenir, Open-Sans, sans-serif;"
                        setOptions={{
                            linkTargetNewWindow: true,
                            buttonList: [
                                ['font', 'formatBlock', 'fontSize', 'fontColor'],
                                ['bold', 'italic', 'underline', 'strike'],
                                ['align', 'list', 'horizontalRule'],
                                ['hiliteColor', 'lineHeight', 'paragraphStyle', 'textStyle'],
                                ['image', 'link'],
                                ['showBlocks', 'codeView'],
                            ],
                        }}
                        setContents={form?.client_portal_resource_content}
                        onChange={updateWYSIWYG('client_portal_resource_content')}
                    />
                </FormColumnStyled>

                <FormColumnStyled>
                    <Typography variant='h2' sx={styles.editorTitle}>
                        Add Success Center Content
                        <Tooltip title="Populates the Success Center card on the brand's client portal dashboard" placement='right'>
                            <InfoIcon />
                        </Tooltip>
                    </Typography>

                    <SunEditor
                        height={250}
                        setDefaultStyle="font-family:  Avenir, Open-Sans, sans-serif;"
                        setOptions={{
                            linkTargetNewWindow: true,
                            buttonList: [
                                ['font', 'formatBlock', 'fontSize', 'fontColor'],
                                ['bold', 'italic', 'underline', 'strike'],
                                ['align', 'list', 'horizontalRule'],
                                ['hiliteColor', 'lineHeight', 'paragraphStyle', 'textStyle'],
                                ['image', 'link'],
                                ['showBlocks', 'codeView'],
                            ],
                        }}
                        setContents={form?.client_portal_success_center_content}
                        onChange={updateWYSIWYG('client_portal_success_center_content')}
                    />
                </FormColumnStyled>

                <SubmitBlockStyled>
                    <Button
                        type='submit'
                        data-cy='submit_brand'
                        variant='contained'
                        color='secondary'
                        sx={styles.btn}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>

                    <BackButton />
                </SubmitBlockStyled>
            </FormStyled>
        </PageStyled>
    )
}

export default BrandForm
