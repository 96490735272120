// import { url as _url, _get, _post } from 'utils'
import { roles } from 'features/oxidauth/users/components/List/fakeData'
import { users } from 'features/oxidauth/users/components/List/fakeData'

export async function fetchRolesList() {
    // const url = await _url(`/roles`)

    // return _get(url)

    return {
        success: true,
        payload: {
            roles,
        },
        errors: null,
    }
}

export async function fetchUserRolesList({ userID }) {
    // const url = await _url(`/user_roles/${userID}`)

    // return _get(url)

    const parentUser = users.find((user) => user.id === userID)
    const userRoles = (parentUser.roles).map((id) => {
        return roles.find((el) => el.id === id)
    })

    return {
        success: true,
        payload: {
            roles: userRoles,
        },
        errors: null,
    }
}

export async function fetchRoleRolesList({ roleID }) {
    // const url = await _url(`/role_roles/${roleID}`)

    // return _get(url)

    const parentRole = roles.find((role) => role.id === roleID)
    const roleRoles = (parentRole.roles).map((id) => {
        return roles.find((el) => el.id === id)
    })

    return {
        success: true,
        payload: {
            roles: roleRoles,
        },
        errors: null,
    }
}

export async function fetchRoleShow(id) {
    // const url = await _url(`/roles/${id}`)

    // return _get(url)

    const role = roles.find((el) => el.id === parseInt(id))

    return {
        success: true,
        payload: {
            role: role,
        },
        errors: null,
    }
}

export async function createNewRole({
    realm_id, name,
}) {
    // const url = await _url('/roles')

    const body = {
        realm_id,
        name,
    }

    // return _post(url, body)

    console.log('FORM:::', body) // eslint-disable-line no-console

    return {
        success: true,
        payload: {
            id: 12,
            realm_id,
            name,
            created_at: '2021-03-20T08:01:41Z',
            updated_at: '2021-04-10T03:15:33Z',
        },
        errors: null,
    }
}

export async function createNewUserRole(userID, id) {
    // const url = await _url(`/user_roles/${userID}_${id}`)

    const body = {
        userID,
        id,
    }

    // return _post(url, body)

    console.log('FORM:::', body) // eslint-disable-line no-console

    return {
        success: true,
        payload: {
            id,
        },
        errors: null,
    }
}

export async function createNewRoleRole(roleID, id) {
    // const url = await _url(`/role_roles/${roleID}_${id}`)

    const body = {
        roleID,
        id,
    }

    // return _post(url, body)

    console.log('FORM:::', body) // eslint-disable-line no-console

    return {
        success: true,
        payload: {
            id,
        },
        errors: null,
    }
}

export async function updateExistingRole(id, {
    realm_id, name,
}) {
    // const url = await _url(`/roles/${id}`)

    const body = {
        realm_id,
        name,
    }

    // return _post(url, body)

    console.log('EDIT:::', body) // eslint-disable-line no-console

    return {
        success: true,
        payload: {
            id,
            ...body,
            created_at: '2021-03-20T08:01:41Z',
            updated_at: '2021-04-10T03:15:33Z',
        },
        errors: null,
    }
}
