import { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
    Button,
    Card,
    CardContent,
    Divider,
    Typography,
} from '@mui/material'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import UPCs from 'features/velocity/catalogs/components/UPCs'
import ConfirmModal from 'features/modals/components/ConfirmModal'
import { useCatalogShow } from 'features/velocity/catalogs/hooks/show'
import useStyles from './styles'
import { StatusChipStyled } from 'theme/styled_components'

export const CatalogShow = () => {
    const { id } = useParams()
    const styles = useStyles()

    const {
        connectionError,
        catalog,
        items,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        onDeleteCatalog,
        onDeleteCatalogItem,
        deleteIsLoading,
        deleteUPCIsLoading,
    } = useCatalogShow(id)

    const [visible, setVisible] = useState(false)

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    const {
        name,
        tag,
        description,
        active,
    } = catalog || {}

    const active_str = active ? 'active' : 'inactive'

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isLoadingState={deleteIsLoading || deleteUPCIsLoading}
            />

            <ConfirmModal
                visible={visible}
                message={`Are you sure you wish to delete this catalog (${id})? This cannot be undone.`}
                dismissModal={() => setVisible(false)}
                accept={onDeleteCatalog}
            />

            <div style={styles.pageHeader}>
                <Typography variant='h1'>
                    {name}
                </Typography>

                <Link style={styles.link} to={`/velocity/catalogs/${id}/edit`}>
                    <Button
                        sx={styles.btn}
                        variant='contained'
                        color='secondary'
                        data-cy='edit-btn'
                    >
                        Edit
                    </Button>
                </Link>
                <div>
                    <Button
                        sx={styles.deleteBtn}
                        variant='contained'
                        color='secondary'
                        data-cy='delete-btn'
                        onClick={() => setVisible(true)}
                    >
                        Delete
                    </Button>
                </div>
            </div>
            <Card sx={styles.card}>
                <CardContent sx={styles.pageColumn}>
                    <dl style={styles.descriptionList}>
                        <dt>
                            <Typography variant='h5'>Name (ID):</Typography>
                        </dt>
                        <dd>
                            <Typography data-cy='name'>{`${name} (${id})`}</Typography>
                        </dd>
                        <dt>
                            <Typography variant='h5'>Tag:</Typography>
                        </dt>
                        <dd>
                            <Typography data-cy='tag'>{tag}</Typography>
                        </dd>
                        <dt>
                            <Typography variant='h5'>Description:</Typography>
                        </dt>
                        <dd>
                            <Typography data-cy='description'>{description}</Typography>
                        </dd>
                    </dl>
                </CardContent>

                <CardContent sx={styles.statusColumn}>
                    <StatusChipStyled
                        active={active_str === 'active'}
                        label={<p>{active_str}</p>}
                    />
                </CardContent>
            </Card>

            <Divider sx={styles.divider} />

            <Card sx={styles.card}>
                <UPCs catalogID={id} items={items} onDeleteCatalogItem={onDeleteCatalogItem} />
            </Card>
        </>
    )
}

export default CatalogShow
