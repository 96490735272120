
const useStyles = () => ({
    block: {
        padding: '15px',
        height: '50px',
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cell: {
        '&:hover': {
            backgroundColor: (theme) => theme?.palette?.hover?.main,
        },
    },
})

export default useStyles
