import {
    Box,
    CardMedia,
    Typography,
} from '@mui/material'
import useStyles, { RetailerLinkStyled } from './styles'

export const RetailersList = ({ retailers, offerID }) => {
    const styles = useStyles()

    return (
        <>
            <Typography variant='h2'>Retailers</Typography>

            <div style={styles.retailerContainer}>
                {retailers.map((r) => (
                    <RetailerLinkStyled
                        key={r.id}
                        style={styles.retailersLink}
                        to={`/velocity/offers/${offerID}/retailers/${r.id}`}
                    >
                        <Box sx={styles.retailer}>
                            <CardMedia
                                style={styles.logo}
                                component='img'
                                alt={r.name}
                                image={r.logo_url}
                                title={r.name}
                            />
                            <p>{r.name}</p>
                        </Box>
                    </RetailerLinkStyled>
                ))}
            </div>
        </>
    )
}

export default RetailersList
