import { useReducer } from 'reducerHelpers'
import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'

export const catalogFormInitialState = (state = {}) => {
    return {
        id: null,
        name: null,
        tag: null,
        upc: null,
        description: null,
        active: null,
        ...state,
    }
}

export const catalogFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state[`${action.field}`] = action.value

            break
        }
        case SET_FORM: {
            return action.value
        }
        case RESET_FORM: {
            return catalogFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useCatalogFormReducer = (state = {}) => {
    return useReducer(catalogFormReducer, catalogFormInitialState(state))
}
