import { squashArr, reduceLoadingState } from 'helpers'
import { useSendEmailsCmd } from 'features/velocity/offers/queries'
import { useOffers } from 'features/velocity/offers/hooks/filterless_list'
import { useSendEmailsForm } from './send_emails_form'

export const useSendEmails = () => {
    const offersResult = useOffers()

    const isLoadError = offersResult?.isError
    const loadSuccess = offersResult?.success

    const sendResult = useSendEmailsCmd()
    const onSubmit = () => {
        sendResult.mutate(sendEmailsFormReturn.form)
    }

    const connectionError = offersResult?.connectionError
    const warnings = squashArr([offersResult.warnings, sendResult.data?.warnings])
    const notices = squashArr([offersResult.notices, sendResult.data?.notices])
    const errors = squashArr([offersResult.errors, sendResult.error, sendResult.data?.errors])

    const sendEmailsFormReturn = useSendEmailsForm()

    const isLoadingState = reduceLoadingState([offersResult]) || sendResult.isLoading
    const isDisabled = offersResult.isLoading || sendResult.isLoading

    const offers = loadSuccess ? offersResult?.offers : []

    return {
        loadSuccess,
        connectionError,
        errors,
        isLoadingState,
        warnings,
        notices,
        isLoadError,
        disabled: isDisabled,
        offers,
        ...sendEmailsFormReturn,
        onSubmit,
    }
}
