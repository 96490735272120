import { useState } from 'react'
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material'
import { lastXChar } from 'helpers'
import Cell from 'components/Cell'
import Row from 'components/Row'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import ConfirmModal from 'features/modals/components/ConfirmModal'
import useStyles from './styles'

export const BannerRetailersList = ({
    connectionError, bannerRetailers, errors,
    isError, isLoading, isFetching, isRefetching, isInitialLoading,
    onDeleteBannerRetailer,
}) => {
    const styles = useStyles()

    const [visible, setVisible] = useState(false)
    const [bannerRetailerAttrs, setBannerRetailerAttrs] = useState(null)

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <ConfirmModal
                visible={visible}
                message={`Are you sure you wish to delete this Banner Retailer ${bannerRetailerAttrs?.name}? This cannot be undone.`}
                dismissModal={() => setVisible(false)}
                accept={() => {
                    onDeleteBannerRetailer(bannerRetailerAttrs?.id)
                    setVisible(false)
                }}
            />

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align='center'>Name</TableCell>
                            <TableCell align='center'>Status</TableCell>
                            <TableCell align='center'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        {bannerRetailers.map(({ id, name, status }) => (
                            <Row
                                key={id}
                                path={`/velocity/retailers/${id}`}
                            >
                                <Cell scope='retailer' align='center'>{lastXChar(id, 8)}</Cell>
                                <Cell align='center'>{name}</Cell>
                                <Cell align='center'>{status}</Cell>
                                <Cell align='center' noLink={true}>
                                    <Button
                                        type='delete'
                                        data-cy='delete_banner_retailer'
                                        variant='contained'
                                        color='secondary'
                                        sx={styles.btn}
                                        onClick={() => {
                                            setVisible(true)
                                            setBannerRetailerAttrs({ id: id, name: name })
                                        }}
                                    >
                                        DELETE
                                    </Button>
                                </Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default BannerRetailersList
