const useStyles = () => ({
    field: {
        maxWidth: '600px',
    },
    btn: {
        margin: '0 5px',
        width: 250,
    },
})

export default useStyles
