import { reduceQueries } from 'helpers'
import { useQueryAllowedEmails } from 'features/velocity/email_containment/queries'
import { useDeleteAllowedEmail } from './delete_allowed_email'

export const useAllowedEmails = ({ params, paginationQueryArgs }) => {
    const {
        payloads: [allowedEmailsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryAllowedEmails(params, paginationQueryArgs)],
    )

    const { onDeleteSubmit } = useDeleteAllowedEmail()

    const emails = allowedEmailsPayload?.allowed_emails || []
    const pagination = allowedEmailsPayload?.pagination || {}

    return {
        connectionError,
        success,
        emails,
        errors,
        warnings,
        notices,
        pagination,
        onDeleteSubmit,
        ...isResults,
    }
}
