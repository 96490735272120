import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'

const Progress = ({ interval }) => {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const counter = setInterval(() => setProgress(progress + 2.5), interval)

        return () => clearInterval(counter)
    }, [progress])

    return <CircularProgress variant='determinate' value={progress} />
}

export default Progress
