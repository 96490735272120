import { createSlice } from '@reduxjs/toolkit'

const name = 'notifications'

const initialState = {
    notifications: [],
}

const reducers = {
    addNotification: (state, { payload }) => {
        const notifications = state.notifications

        if (notifications.every((not) => not.id !== payload.id)) {
            state.notifications.push(payload)
        }
    },
    clearNotification: (state, { payload }) => {
        const notifications = state.notifications

        state.notifications = notifications.filter((not) => not.id !== payload.id)
    },
    clearNotifications: () => initialState,
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    addNotification,
    clearNotification,
    clearNotifications,
} = slice.actions

export default slice.reducer
