import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import HelpFormRequestShow from 'features/velocity/help_form_requests/components/HelpFormRequestShow'
import useStyles from './styles'

const Show = () => {
    const styles = useStyles()
    const navigate = useNavigate()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <HelpFormRequestShow />
        </>
    )
}

export default Show
