import { useEffect } from 'react'
import { Button, FormLabel } from '@mui/material'
import { FormColumnStyled, FormStyled, SubmitBlockStyled, TextFieldStyled } from 'theme/forms'
import IsLoading from 'components/IsLoading'
import VizerModal from 'features/modals/components/Modal'
import { useCreateShortLink } from 'features/velocity/offers/hooks/create_short_link'
import useStyles from './styles'

export const ShortLinkForm = ({ offer, setModalVisible, modalVisible }) => {
    const styles = useStyles()

    const {
        form,
        success,
        disabled,
        isLoadingState,
        updateField,
        onSubmit,
    } = useCreateShortLink(offer)

    useEffect(() => {
        if (success) setModalVisible(false)
    }, [success])

    return (
        <VizerModal
            visible={modalVisible}
            dismissModal={() => setModalVisible(false)}
        >
            <IsLoading
                isLoadingState={isLoadingState}
            />

            <FormStyled
                noValidate
                autoComplete='off'
                style={styles.form}
                onSubmit={(e) => e.preventDefault()}
                data-testid='url-form'
            >
                <FormColumnStyled>
                    <TextFieldStyled
                        label='Name'
                        type='text'
                        data-cy='enter_name'
                        style={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.name || ''}
                        onChange={updateField('name')}
                    />

                    <TextFieldStyled
                        label='Target'
                        type='text'
                        data-cy='enter_target'
                        style={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.target || ''}
                        onChange={updateField('target')}
                    />

                    <FormLabel sx={styles.formLabel}>UTM Parameters</FormLabel>

                    <TextFieldStyled
                        label='Source'
                        type='text'
                        data-cy='enter_source'
                        sx={styles.field}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.utm_source || ''}
                        onChange={updateField('utm_source')}
                    />
                    <TextFieldStyled
                        sx={styles.field}
                        label='Medium'
                        type='text'
                        data-cy='enter_medium'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.utm_medium || ''}
                        onChange={updateField('utm_medium')}
                    />
                    <TextFieldStyled
                        sx={styles.field}
                        label='Campaign'
                        type='text'
                        data-cy='enter_campaign'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.utm_campaign || ''}
                        onChange={updateField('utm_campaign')}
                    />
                    <TextFieldStyled
                        sx={styles.field}
                        label='Content'
                        type='text'
                        data-cy='enter_content'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.utm_content || ''}
                        onChange={updateField('utm_content')}
                    />
                    <TextFieldStyled
                        sx={styles.field}
                        label='Term'
                        type='text'
                        data-cy='enter_term'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form?.utm_term || ''}
                        onChange={updateField('utm_term')}
                    />
                </FormColumnStyled>

                <SubmitBlockStyled>
                    <Button
                        type='submit'
                        data-cy='submit_url'
                        variant='contained'
                        color='secondary'
                        sx={styles.btn}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </SubmitBlockStyled>
            </FormStyled>
        </VizerModal>
    )
}

export default ShortLinkForm
