import { useQueryParentRetailers } from 'features/velocity/retailers/queries'

export const useParentRetailers = () => {
    const result = useQueryParentRetailers()
    const error = result.error

    if (error) return { connectionError: error, ...result }

    const { success, payload, errors, warnings, notices } = result.data || {}

    const parentRetailers = payload?.retailers || []

    return {
        success,
        parentRetailers,
        errors,
        warnings,
        notices,
        ...result,
    }
}
