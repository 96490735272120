import { useQueryCatalogItems, useQueryCatalogShow } from 'features/velocity/catalogs/queries'
import { useCatalogDelete } from 'features/velocity/catalogs/hooks/delete'
import { useCatalogItemDelete } from './delete_item'
import { reduceQueries } from 'helpers'

export const useCatalogShow = (catalogID) => {
    const deleteResult = useCatalogDelete(catalogID)
    const deleteItemResult = useCatalogItemDelete(catalogID)

    const {
        payloads: [catalogPayload, catalogItemsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [
            useQueryCatalogShow(catalogID),
            useQueryCatalogItems(catalogID),
        ],
    )

    const catalog = catalogPayload?.catalog
    const items = catalogItemsPayload?.items

    return {
        connectionError,
        success,
        catalog,
        items,
        errors,
        warnings,
        notices,
        ...deleteResult,
        ...deleteItemResult,
        ...isResults,
    }
}
