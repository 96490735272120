import { TextField } from '@mui/material'
import { AutocompleteStyled } from 'theme/forms'

function MultiSelect({ onChange, options, filterOptions, style, value, label, disabled }) {
    return (
        <AutocompleteStyled
            multiple
            style={style}
            options={options}
            filterOptions={filterOptions}
            value={value}
            getOptionLabel={(op) => op.name || 'None'}
            disableCloseOnSelect
            disabled={disabled}
            onChange={onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                />
            )}
            filterSelectedOptions
        />
    )
}

export default MultiSelect
