import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'theme'

export const TypographyStyled = styled(Typography)(({ type, theme }) => ({
    '&.MuiTypography-root': {
        textAlign: 'left',
        ...(type === 'value' && {
            fontSize: '2.25rem',
            fontWeight: '500',
            marginTop: 'auto',
            [theme.breakpoints.up('xl')]: {
                fontSize: '2.5rem',
            },
        }),
        ...(type === 'subject' && {
            marginBottom: '5px',
            fontSize: '1rem',
            lineHeight: 1.5,
            fontWeight: '700',
            textTransform: 'capitalize',
            textAlign: 'left',
            [theme.breakpoints.up('xl')]: {
                fontSize: '1.125rem',
            },
        }),
        ...(type === 'suffix' && {
            textTransform: 'uppercase',
            fontWeight: '500',
            letterSpacing: 1,
            margin: 0,
            marginBottom: '10px',
            [theme.breakpoints.up('xl')]: {
                fontSize: '1.25rem',
            },
        }),
    },
}))

const useStyles = () => ({
    totalValueContainer: ({ color, border }) => ({
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        backgroundColor: (theme) => theme.palette.background.paper,
        borderRadius: '15px',
        boxShadow: `4px 5px 6px rgba(0, 0, 0, .25)`,
        padding: { xs: '25px 20px', lg: '15px' },
        border: `2px ${border || 'dashed'} ${colors[color].main}`,
    }),
    totalValue: {
        color: (theme) => theme.palette.mode === 'light' ? colors.vizerCharcoal.main : 'inherit',
        minHeight: { md: '130px' },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
})

export default useStyles

