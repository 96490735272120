import { useEffect } from 'react'
import { reduceLoadingState, squashArr } from 'helpers'
import { useUpdateClientPortalUserCmd } from 'features/velocity/client_portal_users/queries'
import { useClientPortalUserShow } from 'features/velocity/client_portal_users/hooks/show'
import { useClientPortalUserForm } from 'features/velocity/client_portal_users/hooks/form'
import { useOfferPartners } from 'features/velocity/offer_partners/hooks/filterless_list'

export const useEditClientPortalUser = (id) => {
    const clientPortalUserFormReturn = useClientPortalUserForm()
    const clientPortalUserShowResult = useClientPortalUserShow(id)
    const offerPartnersResult = useOfferPartners()

    const isLoadError = clientPortalUserShowResult.isError
    const loadSuccess = clientPortalUserShowResult.success

    const updateResult = useUpdateClientPortalUserCmd(id)

    let offerPartners = offerPartnersResult?.success ? offerPartnersResult?.offerPartners : []
    offerPartners = offerPartners.map((p) => p.offer_partner)

    const connectionError = clientPortalUserShowResult.connectionError
    const warnings = squashArr([clientPortalUserShowResult.warnings, updateResult.data?.warnings])
    const notices = squashArr([clientPortalUserShowResult.notices, updateResult.data?.notices])
    const errors = squashArr([clientPortalUserShowResult.errors, updateResult.data?.errors, updateResult.error])

    const isLoadingState = reduceLoadingState([clientPortalUserShowResult]) || updateResult.isLoading
    const isDisabled = clientPortalUserShowResult.isLoading || updateResult.isLoading

    const clientPortalUser = loadSuccess ? clientPortalUserShowResult?.clientPortalUser : {}

    useEffect(() => {
        if (loadSuccess) {
            const offerPartners = clientPortalUser?.offer_partners?.map((op) => op?.offer_partner)

            clientPortalUserFormReturn.setForm({
                first_name: null,
                last_name: null,
                email: null,
                offer_partners: offerPartners,
            })
        }
    }, [loadSuccess])

    const onSubmit = () => {
        updateResult.mutate({ client_portal_user_id: id, form: clientPortalUserFormReturn.form })
    }

    return {
        loadSuccess,
        offerPartners,
        connectionError,
        errors,
        warnings,
        notices,
        isLoadError,
        isLoadingState,
        disabled: isDisabled,
        ...clientPortalUserFormReturn,
        onSubmit,
    }
}
