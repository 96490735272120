const LogOutButton = (props) => (
    <svg
        width={39}
        height={34}
        viewBox='0 0 39 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            // eslint-disable-next-line max-len
            d='M29.192 7.943a.87.87 0 00.192-1.251 16.5 16.5 0 10.177 20.39.87.87 0 00-.213-1.247c-.413-.284-.976-.177-1.285.218a14.685 14.685 0 11-.16-18.305c.317.39.881.486 1.29.195z'
            fill='#FF6969'
        />
        <path
            d='M17 16.75h21'
            stroke='#FF6969'
            strokeWidth={1.75}
            strokeLinecap='round'
        />
        <path
            d='M32.75 11.5L38 16.75 32.75 22'
            stroke='#FF6969'
            strokeWidth={1.75}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default LogOutButton
