
const useStyles = () => ({
    modalCover: {
        maxHeight: '85vh',
        width: 'auto',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        margin: 'auto',
    },
    closeButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        fontWeight: 900,
        minWidth: 30,
        zIndex: 999999,
    },
    graphDisplayWrapper: {
        position: 'relative',
        padding: '20px 10px 0 25px',
        width: '90vw',
        backgroundColor: (theme) => theme.palette.graphDashboard.main,
    },
    graphDataWrapper: {
        height: { xs: 200, xl: 350 },
        width: '100%',
        marginTop: '20px',
        position: 'relative',
    },
})

export default useStyles
