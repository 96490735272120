
const useStyles = () => ({
    closeButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        fontWeight: 900,
        minHeight: 30,
        maxHeight: 30,
        minWidth: 30,
        maxWidth: 30,
    },
})

export const customModalStyles = (theme) => {
    return {
        overlay: {
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '40px 20px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: theme.palette.background.paper,
            minHeight: 400,
            minWidth: 400,
        },
    }
}

export default useStyles
