import { Box, Typography } from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import { yMaxMulti, yTicksIntegerHelper } from 'helpers'
import { useTheme } from '@mui/material/styles'
import useStyles from './styles'

export const LineGraph = ({ graphData, lineColors, tickLabelSpacing, conversion }) => {
    const theme = useTheme()
    const styles = useStyles()

    const graphTheme = {
        axis: {
            ticks: {
                line: {
                    stroke: theme.palette.text.primary,
                },
                text: {
                    fill: theme.palette.text.primary,
                    fontSize: '12px',
                },
            },
            legend: {
                text: {
                    fill: theme.palette.text.primary,
                    fontSize: '15px',
                },
            },
        },
        grid: {
            line: {
                stroke: theme.palette.divider,
            },
        },
    }

    const max = yMaxMulti(graphData)
    const yTicks = yTicksIntegerHelper(max)
    const yFormat = conversion === true ? (v) => `${v}%` : undefined

    return (
        <Box sx={styles.graphContainer}>
            {graphData && <ResponsiveLine
                data={graphData}
                margin={{ top: 10, right: 60, bottom: 55, left: 60 }}
                layers={['grid', 'markers', LineLayer, 'axes', 'areas', 'crosshair', 'points', 'slices', 'mesh', 'legends']}
                xScale={{
                    type: 'time',
                    format: '%Y-%m-%d',
                    useUTC: false,
                }}
                xFormat="time:%m/%d/%Y"
                yScale={{
                    type: 'linear',
                    min: 0,
                    // NOTE(brianthomashammond): this graphData variable is null for an instant after closing the GraphModal
                    max,
                    stacked: false,
                    reverse: false,
                }}
                colors={({ color }) => color}
                curve='monotoneX'
                enableArea={true}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: tickLabelSpacing,
                    renderTick: BottomText,
                    format: (val) => tickLabelSpacing.find((tls) => tls === val) ? val : '',
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: yTicks,
                    format: yFormat,
                }}
                theme={graphTheme}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                enableSlices="x"
                sliceTooltip={({ slice }) => <SliceTooltip slice={slice} lineColors={lineColors} conversion={conversion} />}
                gridXValues={'every 1 days'}
                gridYValues={yTicks}
            />}
        </Box>
    )
}

const LineLayer = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }) => {
        return (
            <path
                key={id}
                d={lineGenerator(
                    data.map((d) => ({
                        x: xScale(d.data.x),
                        y: yScale(d.data.y),
                    })),
                )}
                fill="none"
                stroke={color}
                style={
                    id.toLowerCase().search('unique') === -1 ?
                        {
                            // Solid line for non-unique values
                            strokeWidth: 3,
                        } :
                        {
                            // Dashed line for unique values
                            strokeDasharray: '4, 8',
                            strokeWidth: 3,
                        }
                }
            />
        )
    })
}

const SliceTooltip = ({ slice, lineColors, conversion }) => {
    const styles = useStyles()

    slice.points.sort((a, b) => a.y - b.y)

    return (
        <Box sx={styles.tooltip}>
            <div>
                <Typography variant='h5'>{slice.points[0].data.xFormatted}</Typography>
            </div>
            {slice.points.map((point) => (
                <div
                    key={point.id}
                    style={{
                        color: lineColors[point.serieId],
                        padding: '3px 0',
                    }}
                >
                    {conversion ? (
                        <Typography variant='h5'>{point.data.yFormatted}% {point.serieId}</Typography>
                    ) : (
                        <Typography variant='h5'>{point.data.yFormatted} {point.serieId}</Typography>
                    )}
                </div>
            ))}
        </Box>
    )
}

const BottomText = ({
    value: _value,
    x,
    y,
    rotate,
    format,
    textX,
    textY,
    textBaseline,
    textAnchor,
}) => {
    const theme = useTheme()

    let value = _value
    if (format !== undefined) {
        value = format(value)
    }

    return (
        <g
            transform={`translate(${x},${y})`}
        >
            <text
                dominantBaseline={textBaseline}
                textAnchor={textAnchor}
                transform={`translate(${textX},${textY}) rotate(${rotate})`}
                style={{ fill: theme.palette.text.primary }}
            >
                {value instanceof Date ? value.toLocaleDateString() : ''}
            </text>
        </g>
    )
}

export default LineGraph
