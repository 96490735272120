import { TableCell } from '@mui/material'
import { isEmpty } from '../../helpers'
import useStyles from './styles'
import { CellLinkStyled } from 'theme/styled_components'

export const Cell = ({ row_path, path, children, noLink, ...props }) => {
    const { align, sx, component } = props
    const styles = useStyles()

    if (path && path.includes('null')) path = null

    return (
        <TableCell sx={isEmpty(path) ? null : styles.cell} padding='none' align={align} component={component} {...props}>
            {noLink ? (
                <div style={styles.block}>
                    {children}
                </div>
            ) : (
                <CellLinkStyled sx={sx} to={isEmpty(path) ? row_path : path}>
                    <div style={styles.block}>
                        {children}
                    </div>
                </CellLinkStyled>
            )}
        </TableCell>
    )
}

export default Cell
