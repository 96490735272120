import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '../selectors'
import { setGraphModal, dismissGraphModal } from '../reducers/graph_modal'

export const useGraphModal = () => {
    const dispatch = useDispatch()

    const graphTitle = useSelector(selectors.graphTitleSel)
    const graphData = useSelector(selectors.graphDataSel)
    const lineColors = useSelector(selectors.lineColorsSel)
    const visible = useSelector(selectors.graphVisibleSel)
    const tickLabelSpacing = useSelector(selectors.tickLabelSpacingSel)
    const conversion = useSelector(selectors.conversionSel)

    return {
        graphTitle,
        graphData,
        lineColors,
        visible,
        tickLabelSpacing,
        conversion,
        setGraphModal: (error) => dispatch(setGraphModal(error)),
        dismissGraphModal: () => {
            dispatch(dismissGraphModal())
        },
    }
}
