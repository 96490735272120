import { reduceQueries } from 'helpers'
import { useQueryBlockedEmailPrefixes } from 'features/velocity/email_containment/queries'
import { useDeleteBlockedEmailPrefix } from './delete_blocked_email_prefix'

export const useBlockedEmailPrefixes = ({ params, paginationQueryArgs }) => {
    const {
        payloads: [blockedEmailPrefixesPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryBlockedEmailPrefixes(params, paginationQueryArgs)],
    )

    const { onDeleteSubmit } = useDeleteBlockedEmailPrefix()

    const emailPrefixes = blockedEmailPrefixesPayload?.email_prefix_filters || []
    const pagination = blockedEmailPrefixesPayload?.pagination || {}

    return {
        connectionError,
        success,
        emailPrefixes,
        errors,
        warnings,
        notices,
        pagination,
        onDeleteSubmit,
        ...isResults,
    }
}
