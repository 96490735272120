import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const UPDATE_BARCODE_TYPE = 'UPDATE_BARCODE_TYPE'
export const UPDATE_BARCODE_DATA = 'UPDATE_BARCODE_DATA'

export const retailerFormInitialState = (state = {}) => {
    return {
        incomm_retailers: [],
        parent_id: null,
        logo_url: null,
        icon_original: null,
        icon_1x: null,
        icon_2x: null,
        icon_3x: null,
        name: null,
        status: 'active',
        redemption_instructions: null,
        print_status: 'inactive',
        barcode_type: {
            name: 'code128',
        },
        ...state,
    }
}

export const retailerFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state[`${action.field}`] = action.value

            break
        }
        case UPDATE_BARCODE_TYPE: {
            // remove the data key from barcode_type if code128
            if (action.value === 'code128') {
                delete state.barcode_type.data
            }

            state.barcode_type.name = action.value

            break
        }
        case UPDATE_BARCODE_DATA: {
            state.barcode_type.data = action.value

            break
        }
        case SET_FORM: {
            return action.value
        }
        case RESET_FORM: {
            return retailerFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useRetailerFormReducer = (state = {}) => {
    return useReducer(retailerFormReducer, retailerFormInitialState(state))
}
