import List from './List'
import Show from './Show'
import Edit from './Edit'
import New from './New'

const BrandsRoutes = {
    path: '/brands',
    children: [
        {
            path: '',
            element: <List />,
        },
        {
            path: 'new',
            element: <New />,
        },
        {
            path: ':id/edit',
            element: <Edit />,
        },
        {
            path: ':id',
            element: <Show />,
        },
    ],
}

export default BrandsRoutes
