import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import ConnectionError from 'components/ConnectionError'
import IsLoading from 'components/IsLoading'
import Loading from 'components/Loading'
import InCommShow from 'features/velocity/offers/components/InCommShow'
import { useOfferShow } from 'features/velocity/offers/hooks/show'
import { useOfferRetailersByOfferID } from 'features/velocity/offer_retailers/hooks/list_by_offer_id'
import useStyles from './styles'

const OfferShow = () => {
    const navigate = useNavigate()
    const styles = useStyles()
    const { id } = useParams()

    const {
        connectionError,
        offer,
        strategy,
        links,
        schedules,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        offerPublicLink,
        offerLandingPreviewLink,
        offerEngagePreviewLink,
        displayExpStr,
        refetchDisabled,
        offerMockApproval,
        refetchOffer,
        generateStripImages,
        generateStripImagesDisabled,
    } = useOfferShow(id)

    const { offerRetailers } = useOfferRetailersByOfferID(id)

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
            />

            {strategy === 'incomm' &&
                <InCommShow
                    offer={offer}
                    offerRetailers={offerRetailers}
                    schedules={schedules}
                    links={links}
                    offerPublicLink={offerPublicLink}
                    offerLandingPreviewLink={offerLandingPreviewLink}
                    offerEngagePreviewLink={offerEngagePreviewLink}
                    offerMockApproval={offerMockApproval}
                    refetchDisabled={refetchDisabled}
                    refetchOffer={refetchOffer}
                    generateStripImages={generateStripImages}
                    generateStripImagesDisabled={generateStripImagesDisabled}
                    displayExpStr={displayExpStr}
                />
            }
        </>
    )
}

export default OfferShow
