// import { url as _url, _get, _post } from 'utils'
import { roles } from 'features/oxidauth/users/components/List/fakeData'
import { users } from 'features/oxidauth/users/components/List/fakeData'
import { permissions } from 'features/oxidauth/users/components/List/fakeData'

export async function fetchPermissionsList() {
    // const url = await _url(`/permissions`)

    // return _get(url)

    return {
        success: true,
        payload: {
            permissions,
        },
        errors: null,
    }
}

export async function fetchUserPermissionsList({ userID }) {
    // const url = await _url(`/user_permissions/${userID}`)

    // return _get(url)
    const parentUser = users.find((user) => user.id === userID)
    const userPermissions = (parentUser.permissions).map((id) => {
        return permissions.find((el) => el.id === id)
    })

    return {
        success: true,
        payload: {
            permissions: userPermissions,
        },
        errors: null,
    }
}

export async function fetchRolePermissionsList({ roleID }) {
    // const url = await _url(`/role_permissions/${roleID}`)

    // return _get(url)

    const parentRole = roles.find((role) => role.id === roleID)
    const rolePermissions = (parentRole.permissions).map((id) => {
        return permissions.find((el) => el.id === id)
    })

    return {
        success: true,
        payload: {
            permissions: rolePermissions,
        },
        errors: null,
    }
}

export async function fetchPermissionShow(id) {
    // const url = await _url(`/permissions/${id}`)

    // return _get(url)

    const permission = permissions.find((el) => el.id === parseInt(id))

    return {
        success: true,
        payload: {
            permission: permission,
        },
        errors: null,
    }
}

export async function createNewPermission({
    realm_resource_action,
}) {
    // const url = await _url('/permissions')

    const body = {
        realm_resource_action,
    }

    // return _post(url, body)

    console.log('FORM:::', body) // eslint-disable-line no-console

    return {
        success: true,
        payload: {
            id: 22,
            realm_resource_action,
            created_at: '2021-03-20T08:01:41Z',
            updated_at: '2021-04-10T03:15:33Z',
        },
        errors: null,
    }
}

export async function createNewUserPermission(userID, {
    realm_resource_action,
}) {
    // const url = await _url(`/user_permissions/${userID}`)

    const body = {
        userID,
        realm_resource_action,
    }

    // return _post(url, body)

    console.log('FORM:::', body) // eslint-disable-line no-console

    return {
        success: true,
        payload: {
            id: 23,
        },
        errors: null,
    }
}

export async function createNewRolePermission(roleID, {
    realm_resource_action,
}) {
    // const url = await _url(`/role_permissions/${roleID}`)

    const body = {
        roleID,
        realm_resource_action,
    }

    // return _post(url, body)

    console.log('FORM:::', body) // eslint-disable-line no-console

    return {
        success: true,
        payload: {
            id: 23,
        },
        errors: null,
    }
}

export async function updateExistingPermission(id, {
    realm_resource_action,
}) {
    // const url = await _url(`/permissions/${id}`)

    const body = {
        realm_resource_action,
    }

    // return _post(url, body)

    console.log('EDIT:::', body) // eslint-disable-line no-console

    return {
        success: true,
        payload: {
            id,
            ...body,
            created_at: '2021-03-20T08:01:41Z',
            updated_at: '2021-04-10T03:15:33Z',
        },
        errors: null,
    }
}
