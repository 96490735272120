import useStyles from './styles'
import { Typography } from '@mui/material'
import BannerStoreSelector from '../BannerStoreSelector'
import { FormColumnStyled } from 'theme/forms'

export const RetailerSelector = ({ form, retailers, updateRetailer, selectAllRetailers, getAllIDs, checked, selectAllChecked }) => {
    const styles = useStyles()

    const half = Math.ceil(retailers.length / 2)

    return (
        <div style={styles.retailerContainer}>
            <Typography variant='h2' align='center'>Retailers</Typography>

            {retailers.length > 1 ? (
                <div style={styles.row}>
                    <FormColumnStyled>
                        {retailers.slice(0, half).map((retailer) => (
                            <BannerStoreSelector
                                key={retailer.id}
                                form={form}
                                retailer={retailer}
                                updateRetailer={updateRetailer}
                                selectAllRetailers={selectAllRetailers}
                                getAllIDs={getAllIDs}
                                checked={checked}
                                selectAllChecked={selectAllChecked}
                            />
                        ))}
                    </FormColumnStyled>
                    <FormColumnStyled>
                        {retailers.slice(half).map((retailer) => (
                            <BannerStoreSelector
                                key={retailer.id}
                                form={form}
                                retailer={retailer}
                                updateRetailer={updateRetailer}
                                selectAllRetailers={selectAllRetailers}
                                getAllIDs={getAllIDs}
                                checked={checked}
                                selectAllChecked={selectAllChecked}
                            />
                        ))}
                    </FormColumnStyled>
                </div>
            ) : (
                <FormColumnStyled>
                    {retailers.length === 0 ? (
                        <Typography variant='h3'>No retailers found</Typography>
                    ) : (
                        <>
                            {retailers.map((retailer) => (
                                <BannerStoreSelector
                                    key={retailer.id}
                                    form={form}
                                    retailer={retailer}
                                    updateRetailer={updateRetailer}
                                    selectAllRetailers={selectAllRetailers}
                                    getAllIDs={getAllIDs}
                                    checked={checked}
                                    selectAllChecked={selectAllChecked}
                                />
                            ))}
                        </>
                    )}
                </FormColumnStyled>
            )}
        </div>
    )
}

export default RetailerSelector
