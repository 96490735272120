import { colors } from 'theme'

const useStyles = () => {
    return {
        noResults: {
            padding: '20px 0',
        },
        count: {
            width: 250,
        },
        input: {
            width: 'auto',
            minWidth: 215,
            marginRight: { xs: 0, md: '10px' },
            marginBottom: '10px',
            '& .MuiInputLabelRoot': {
                textTransform: 'capitalize',
            },
        },
        btn: (type) => ({
            fontWeight: 700,
            color: type === 'pending' ? 'initial' : colors.white,
            background: calculateBG(type),
            '&:hover': {
                background: calculateBG(type, true),
            },
        }),
    }
}

const calculateBG = (type, hover) => {
    const variant = hover ? 'dark' : 'main'

    if (type === 'block') return colors.error[variant]
    if (type === 'allow') return colors.success[variant]
    return colors.warning[variant]
}

export default useStyles
