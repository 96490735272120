import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { isPresent, sanitizeDate } from 'helpers'
import {
    Box,
    Card,
    Divider,
    Paper,
    Typography,
    Tab,
    Tabs,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    AppBar,
} from '@mui/material'
import { DescriptionListStyled } from 'theme/styled_components'
import ExpandableText from 'components/ExpandableText'
import { TabPanel } from 'components/TabPanel'
import Accordion from 'components/Accordion'
import ShortLinks from 'features/velocity/offers/components/ShortLinks'
import OfferShowHeader from '../OfferShowHeader'
import OfferTemplating from '../OfferTemplating'
import RetailersList from '../RetailersList'
import OfferDashboard from 'features/velocity/analytics/components/OfferDashboard'
import useStyles from './styles'
import GraphContainer from 'features/velocity/analytics/components/GraphContainer'
import DomainsByOfferList from 'features/velocity/offers/components/DomainsByOfferList'

export const OfferShow = ({
    offer, offerRetailers, schedules, links, offerPublicLink, refetchDisabled,
    offerLandingPreviewLink, offerEngagePreviewLink, offerMockApproval, refetchOffer,
    generateStripImages, generateStripImagesDisabled, displayExpStr,
}) => {
    const styles = useStyles()
    const { id } = useParams()

    const [activeTab, setActiveTab] = useState(0)

    const {
        name,
        title,
        strategy,
        template_landing,
        template_engage,
    } = offer || {}

    const {
        description,
        verbiage,
        print_text,
        value,
        buy_quantity,
        offer_limit,
        customer_limit,
        terms,
        rules,
        offer_start,
        offer_end,
        type,
        primary_upcs,
        secondary_upcs,
        number,
        clip_limit,
        purse_name,
        purchase_limit,
        stack,
        max_unit,
        max_reward,
    } = strategy.incomm_data || {}

    return (
        <>
            <OfferShowHeader
                offer={offer}
                offerPublicLink={offerPublicLink}
                offerLandingPreviewLink={offerLandingPreviewLink}
                offerEngagePreviewLink={offerEngagePreviewLink}
                offerMockApproval={offerMockApproval}
                refetchDisabled={refetchDisabled}
                refetchOffer={refetchOffer}
                generateStripImages={generateStripImages}
                generateStripImagesDisabled={generateStripImagesDisabled}
            />

            <AppBar sx={styles.tabs} position='static'>
                <Tabs value={activeTab} classes={{ indicator: styles.indicator }}>
                    <Tab sx={styles.tab} label='Analytics' onClick={() => setActiveTab(0)} />
                    <Tab sx={styles.tab} label='Details' onClick={() => setActiveTab(1)} />
                    {offerRetailers?.length > 0 &&
                        <Tab sx={styles.tab} label='Short Links' onClick={() => setActiveTab(2)} />
                    }
                    <Tab sx={styles.tab} label='Retailers' onClick={() => setActiveTab(3)} />
                    <Tab sx={styles.tab} label='Public Templates' onClick={() => setActiveTab(4)} />
                    <Tab sx={styles.tab} label='Potential Fraud' onClick={() => setActiveTab(5)} />
                </Tabs>
            </AppBar>

            <Card sx={styles.detailsCard}>
                <TabPanel value={activeTab} index={0} noPadding>
                    <Box sx={styles.tabContainer}>
                        <OfferDashboard offerID={id} strategy={strategy?.strategy} />

                        <Divider sx={styles.divider} />

                        <GraphContainer offer={offer} links={links} />
                    </Box>
                </TabPanel>

                <TabPanel value={activeTab} index={1} noPadding>
                    <Box display='grid' sx={styles.detailsContainer}>
                        <Box gridColumn='span 8' sx={styles.detailsBlock}>
                            <DescriptionListStyled>
                                <ListRow label={['Name', 'ID']} value={[name, id]} columns={2} />

                                <ListRow label={['Title', 'Incomm Number']} value={[title, number]} columns={2} />

                                <dt>
                                    <p>Description</p>
                                </dt>
                                <dd>
                                    <p>{description}</p>
                                </dd>
                            </DescriptionListStyled>
                        </Box>

                        <Box gridColumn='span 4' sx={styles.detailsBlock}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>Print Text</p>
                                </dt>
                                <dd>
                                    <p>{print_text}</p>
                                </dd>
                                <dt>
                                    <p>Verbiage</p>
                                </dt>
                                <dd>
                                    <p>{verbiage}</p>
                                </dd>
                                <dt>
                                    <p>Terms</p>
                                </dt>
                                <dd>
                                    <ExpandableText text={terms} />
                                </dd>

                                <Divider sx={styles.miniDivider} />
                                <ListRow label={['Purse Name', 'Type']} value={[purse_name, type]} columns={2} />
                                <ListRow label={['Stack', 'Rules']} value={[stack, rules]} columns={2} />
                                <ListRow label={['Value', 'Buy Quantity']} value={[value, buy_quantity]} columns={2} />
                                <ListRow label={['Offer Limit', 'Customer Limit']} value={[offer_limit, customer_limit]} columns={2} />
                                <ListRow label={['Clip Limit', 'Purchase Limit']} value={[clip_limit, purchase_limit]} columns={2} />
                                <ListRow label={['Max Unit', 'Max Reward']} value={[max_unit, max_reward]} columns={2} />

                                <Divider sx={styles.miniDivider} />

                                <div style={styles.accordionContainer}>
                                    {primary_upcs?.length > 0 &&
                                        <Accordion
                                            title={'Primary UPCs'}
                                            label={'primary_upcs'}

                                        >
                                            <div style={styles.accordion}>
                                                {primary_upcs.map((upc) => <p key={upc} style={styles.upc}>{upc}</p>)}
                                            </div>
                                        </Accordion>
                                    }

                                    {secondary_upcs?.length > 0 &&
                                        <Accordion
                                            title={'Secondary UPCs'}
                                            label={'secondary_upcs'}
                                        >
                                            <div style={styles.accordion}>
                                                {secondary_upcs.map((upc) => <p key={upc} style={styles.upc}>{upc}</p>)}
                                            </div>
                                        </Accordion>
                                    }
                                </div>
                            </DescriptionListStyled>
                        </Box>

                        <Box gridColumn='span 8' sx={styles.detailsBlock}>
                            {displayExpStr &&
                                <DescriptionListStyled>
                                    <dt>
                                        <p>Expiration Display</p>
                                    </dt>
                                    <dd>
                                        <p>{displayExpStr}</p>
                                    </dd>
                                </DescriptionListStyled>
                            }
                            <DescriptionListStyled>
                                <ListRow
                                    label={['Offer Start', 'Offer End']}
                                    value={[(sanitizeDate(offer_start) || 'None'), (sanitizeDate(offer_end) || 'None')]}
                                    columns={2}
                                />
                            </DescriptionListStyled>

                            {schedules.length > 0 &&
                                <>
                                    <Divider sx={styles.miniDivider} />

                                    <Typography variant='h2'>Offer Schedules</Typography>

                                    <TableContainer component={Paper} sx={styles.table}>
                                        <Table aria-label='simple table' data-cy='offer-schedules-table'>
                                            <TableHead data-cy='offer-schedules-head'>
                                                <TableRow>
                                                    <TableCell>START</TableCell>
                                                    <TableCell>END</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {schedules.map((sched) => (
                                                    <TableRow key={sched.id}>
                                                        <TableCell>{sanitizeDate(sched.start_at) || 'None'}</TableCell>
                                                        <TableCell>{sanitizeDate(sched.end_at) || 'None'}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            }
                        </Box>
                    </Box>
                </TabPanel>

                <TabPanel value={activeTab} index={2}>
                    <ShortLinks
                        offer={offer}
                        links={links}
                    />
                </TabPanel>

                <TabPanel value={activeTab} index={3}>
                    <RetailersList
                        retailers={offerRetailers}
                        offerID={id}
                    />
                </TabPanel>

                <TabPanel value={activeTab} index={4}>
                    <div style={styles.templateContainer}>
                        <OfferTemplating
                            template={template_landing}
                            templateType='offer_landing'
                            readOnly
                        />

                        <OfferTemplating
                            template={template_engage}
                            templateType='offer_engage'
                            readOnly
                        />
                    </div>
                </TabPanel>

                <TabPanel value={activeTab} index={5}>
                    <DomainsByOfferList offerID={id} />
                </TabPanel>
            </Card>
        </>
    )
}

const ListRow = ({ label, value, columns }) => {
    const styles = useStyles()

    if (!value) return

    return (
        <Box sx={styles.listRow}>
            {columns > 1 ? (
                value.map((val, i) => (
                    isPresent(val) &&
                    <div key={label[i]}>
                        <dt>
                            <p>{label[i]}</p>
                        </dt>
                        <dd>
                            <p>{`${val}` || 'N/A'}</p>
                        </dd>
                    </div>
                ))
            ) : (
                <>
                    <dt>
                        <p>{label}</p>
                    </dt>
                    <dd>
                        <p>{value}</p>
                    </dd>
                </>
            )}
        </Box>
    )
}

export default OfferShow
