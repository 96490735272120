import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
    Button,
    Box,
    Card,
    CardContent,
    Typography,
    Tab,
    Tabs,
    AppBar,
} from '@mui/material'
import { sanitizeDate, isEmpty, lastXChar } from 'helpers'
import Accordion from 'components/Accordion'
import ConnectionError from 'components/ConnectionError'
import ImageWithModal from 'components/ImageWithModal'
import LabelWithLink from 'components/LabelWithLink'
import IsLoading from 'components/IsLoading'
import TabPanel from 'components/TabPanel'
import Loading from 'components/Loading'
import ImageModal from 'features/modals/components'
import RedemptionInstructions from 'features/velocity/retailers/components/RedemptionInstructions'
import BannerRetailersList from 'features/velocity/banner_retailers/components/List'
import { useBannerRetailers } from 'features/velocity/banner_retailers/hooks/list'
import { useRetailerShow } from 'features/velocity/retailers/hooks/show'
import useStyles from './styles'
import { DescriptionListStyled, StatusChipStyled } from 'theme/styled_components'

export const RetailerShow = () => {
    const [activeTab, setActiveTab] = useState(1)
    const styles = useStyles()
    const { id } = useParams()

    const {
        connectionError,
        retailer,
        barcodeType,
        barcodeData,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        onDeleteBannerRetailer,
        generateStripImages,
        generateStripImagesDisabled,
    } = useRetailerShow(id)

    const listBannerRetailersResult = useBannerRetailers(id)

    const { bannerRetailers } = listBannerRetailersResult

    const {
        incomm_retailers,
        status,
        name,
        logo_url,
        redemption_instructions,
        print_status,
        icon_original,
        parent_id,
        parent_name,
        created_at,
        updated_at,
    } = retailer || {}

    useEffect(() => {
        if (bannerRetailers?.length > 0 && isEmpty(parent_id)) {
            setActiveTab(0)

            return
        }

        setActiveTab(1)
    }, [bannerRetailers, retailer])

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <ImageModal />

            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
            />

            <Card sx={styles.pageHeader}>
                <CardContent>
                    <Box sx={styles.titleBar}>
                        <Typography variant='h1'>{name}</Typography>
                        <Box sx={styles.headerButtons}>
                            <Button
                                sx={styles.btn}
                                variant='contained'
                                color='secondary'
                                data-cy='edit-btn'
                                disabled={generateStripImagesDisabled}
                                onClick={generateStripImages}
                                title='Generate strip images for this retailers offer_retailers'
                            >
                                Generate Strip Images
                            </Button>
                            <Link style={styles.link} to={`/velocity/retailers/${id}/edit`}>
                                <Button
                                    sx={styles.btn}
                                    variant='contained'
                                    color='secondary'
                                    data-cy='edit-btn'
                                >
                                    Edit
                                </Button>
                            </Link>
                        </Box>
                    </Box>

                    <Box sx={styles.pageRow}>
                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>Logo</p>
                                </dt>
                                <dd>
                                    {/* NOTE(brianthomashammond): This image is named `logo` because that's
                                    what Apple calls it but the logo styling variant is not needed here. */}
                                    <ImageWithModal
                                        noSpacing
                                        image={{
                                            url: logo_url,
                                            alt: `${name}'s Logo`,
                                        }}
                                    />
                                </dd>
                            </DescriptionListStyled>
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <StatusChipStyled
                                active={status === 'active'}
                                label={<p>{status}</p>}
                            />

                            <DescriptionListStyled>
                                <dt>
                                    <p>Icon</p>
                                </dt>
                                <dd>
                                    <ImageWithModal
                                        noSpacing
                                        image={{
                                            url: icon_original,
                                            alt: `${name}'s Icon`,
                                            variant: 'icon',
                                        }}
                                    />
                                </dd>
                            </DescriptionListStyled>
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>ID</p>
                                </dt>
                                <dd>
                                    <p>{id}</p>
                                </dd>

                                <dt>
                                </dt>

                                {parent_id &&
                                    <>
                                        <dt>
                                            <p>Parent Retailer</p>
                                        </dt>
                                        <dd>
                                            <LabelWithLink
                                                label={parent_name}
                                                path={`/velocity/retailers/${parent_id}`}
                                                id={lastXChar(parent_id, 8)}
                                                emptyLabel={'None'}
                                            />
                                        </dd>
                                    </>
                                }

                                <dt>
                                    <p>Barcode Type</p>
                                </dt>
                                <dd>
                                    <p>{barcodeType}</p>
                                </dd>
                                {barcodeData &&
                                    <>
                                        <dt>
                                            <p>Barcode Prefix</p>
                                        </dt>
                                        <dd>
                                            <p>{barcodeData}</p>
                                        </dd>
                                    </>
                                }

                                <dt>
                                    <p>Print Screen Display</p>
                                </dt>
                                <dd>
                                    <p style={{ textTransform: 'capitalize' }}>{print_status}</p>
                                </dd>

                                <dd>
                                    {incomm_retailers?.length > 0 &&
                                        <Accordion
                                            title={'Incomm Retailers'}
                                            label={'incomm_retailers'}

                                        >
                                            <div style={styles.accordion}>
                                                {incomm_retailers.map(({ incomm_id, name }) => <p key={incomm_id}>({incomm_id}) {name}</p>)}
                                            </div>
                                        </Accordion>
                                    }
                                </dd>

                            </DescriptionListStyled>
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>Created</p>
                                </dt>
                                <dd>
                                    <p>{sanitizeDate(created_at)}</p>
                                </dd>
                                <dt>
                                    <p>Updated</p>
                                </dt>
                                <dd>
                                    <p>{sanitizeDate(updated_at)}</p>
                                </dd>
                            </DescriptionListStyled>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            <AppBar sx={styles.tabs} position="static">
                <Tabs value={activeTab}>
                    {isEmpty(parent_id) &&
                        <Tab sx={styles.tab} label="Banner Retailers" onClick={() => setActiveTab(0)} />
                    }
                    <Tab sx={styles.tab} label="Redemption Instructions" onClick={() => setActiveTab(1)} />
                </Tabs>
            </AppBar>

            <Card sx={styles.detailsCard}>
                <TabPanel value={activeTab} index={0}>
                    {isEmpty(parent_id) &&
                        <>
                            <div style={styles.titleBar}>
                                <Typography variant='h2'>Banner Retailers</Typography>

                                <div style={styles.headerButtons}>
                                    <Link
                                        style={styles.link}
                                        to='/velocity/retailers/new'
                                        state={{
                                            from: `/velocity/retailers/${id}`,
                                            parentRetailerID: { id },
                                        }}
                                    >
                                        <Button
                                            sx={styles.btn}
                                            variant="contained"
                                            color="secondary"
                                            data-cy='new-banner-retailer-btn'
                                        >
                                            {`Add New Banner Retailer`}
                                        </Button>
                                    </Link>
                                </div>
                            </div>

                            {bannerRetailers?.length > 0 &&
                                <div style={styles.table}>
                                    <BannerRetailersList
                                        onDeleteBannerRetailer={onDeleteBannerRetailer}
                                        parentID={id}
                                        {...listBannerRetailersResult}
                                    />
                                </div>
                            }
                        </>
                    }
                </TabPanel>

                <TabPanel value={activeTab} index={1}>
                    <RedemptionInstructions
                        activeTab={activeTab}
                        redemption_instructions={redemption_instructions}
                    />
                </TabPanel>
            </Card>
        </>
    )
}

export default RetailerShow
