const useStyles = () => ({
    subtitle: {
        alignSelf: { xs: 'center', md: 'flex-start' },
        margin: '20px',
    },
    cover: {
        minHeight: 100,
        width: 375,
        padding: '0 75px',
        marginBottom: '20px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    base64_cover: {
        width: 'auto',
        height: 'auto',
        maxHeight: 200,
        alignContent: 'center',
    },
    btn: {
        margin: '0 5px',
        width: 100,
    },
})

export default useStyles
