import { useContext } from 'react'
import { AppBar, Button, IconButton, Paper, Toolbar } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import NightsStayIcon from '@mui/icons-material/NightsStay'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import MenuIcon from '@mui/icons-material/Menu'

import { AppModeContext, AuthContext } from 'contexts'
import LogOutButton from 'components/LogOutButton'
import VizerLogoMark from 'components/VizerLogoMark'
import VizerLogoText from 'components/VizerLogoText'
import { useStyles } from './styles'

const Header = ({ handleDrawerClick, open }) => {
    const { darkMode, toggleDarkMode } = useContext(AppModeContext)
    const { authenticated, logout } = useContext(AuthContext)

    const styles = useStyles()

    const DarkModeIcon = darkMode ? WbSunnyIcon : NightsStayIcon

    if (!authenticated) {
        return (
            <AppBar
                position='fixed'
                sx={styles.appBar}
                component={Paper}
            >
                <Toolbar sx={styles.toolbar}>
                    <div style={styles.left}>
                    </div>
                    <div style={styles.center}>
                        <VizerLogoMark size={30} />
                        <div style={styles.spacer}></div>
                        <VizerLogoText />
                    </div>
                    <div style={styles.right}>
                        <DarkModeIcon sx={styles.icon} onClick={toggleDarkMode} />
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <AppBar
            position='fixed'
            sx={styles.appBar}
        >
            <Toolbar sx={styles.toolbar}>
                <div style={styles.left}>
                    <IconButton
                        aria-label='open drawer'
                        onClick={handleDrawerClick}
                        color={'secondary'}
                        sx={styles.menuButton}
                    >
                        {open ? <ChevronLeftIcon /> : <MenuIcon />}
                    </IconButton>
                    {/*
                    NOTE(adriennehein): comment out feature that is not functional
                    <div style={styles.search}>
                        <div style={styles.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder='Search…'
                            styles={{
                                root: styles.inputRoot,
                                input: styles.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div> */}
                </div>
                <div style={styles.center}>
                    <VizerLogoMark size={30} />
                    <div style={styles.spacer}></div>
                    <VizerLogoText />
                </div>
                <div style={styles.right}>
                    {darkMode ? (
                        <WbSunnyIcon sx={styles.icon} onClick={toggleDarkMode} />
                    ) : (
                        <NightsStayIcon sx={styles.icon} onClick={toggleDarkMode} />
                    )}
                    {/* <Typography sx={styles.userName}>{name}</Typography> */}
                    <Button
                        sx={styles.button}
                        onClick={logout}
                    >
                        <LogOutButton />
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default Header
