import { useParams } from 'react-router-dom'
import OfferPartnerForm from 'features/velocity/offer_partners/components/OfferPartnerForm'
import { useEditOfferPartner } from 'features/velocity/offer_partners/hooks/edit'

export const OfferPartnerEdit = () => {
    const { id } = useParams()
    const editOfferPartnerResult = useEditOfferPartner(id)

    return (
        <OfferPartnerForm
            edit={true}
            {...editOfferPartnerResult}
        />
    )
}

export default OfferPartnerEdit
