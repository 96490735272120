
const useStyles = () => ({
    hyperlink: {
        '&:link': {
            color: (theme) => theme.palette.link.main,
        },
        '&:visited': {
            color: (theme) => theme.palette.link.visited,
        },
    },
})

export default useStyles
