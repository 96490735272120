
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const GridStyled = styled(Box)(({ theme }) => ({
    margin: '0px 35px 30px',
    padding: '25px 0 35px',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr [col-start])',
    gap: 25,
    gridTemplateRows: 'repeat(2, 1fr [row-start])',
    gridTemplateAreas: `
            'uniqueClicks clicksToClips uniqueClips clipsToScans uniqueScans uniqueScanRefunds'
            'totalClicks clicksToClips totalClips clipsToScans totalScans totalScanRefunds'
        `,
    [theme.breakpoints.down(1200)]: {
        gridTemplateColumns: 'repeat(2, 1fr [col-start])',
        gridTemplateRows: 'repeat(6, 1fr [row-start])',
        gridTemplateAreas: `
            'uniqueClicks totalClicks'
            'clicksToClips clicksToClips'
            'uniqueClips totalClips'
            'clipsToScans clipsToScans'
            'uniqueScans totalScans'
            'uniqueScanRefunds totalScanRefunds'
        `,
    },
    [theme.breakpoints.up(1200)]: {
        minWidth: 950,
        '& > div': {
            aspectRatio: 1,
        },
        '& > div:nth-of-type(3)': {
            aspectRatio: 0,
        },
        '& > div:nth-of-type(6)': {
            aspectRatio: 0,
        },
    },
}))

const useStyles = () => ({
    totalClicks: {
        gridArea: 'totalClicks',
    },
    uniqueClicks: {
        gridArea: 'uniqueClicks',
    },
    clicksToClips: {
        gridArea: 'clicksToClips',
    },
    totalClips: {
        gridArea: 'totalClips',
    },
    uniqueClips: {
        gridArea: 'uniqueClips',
    },
    clipsToScans: {
        gridArea: 'clipsToScans',
    },
    totalScans: {
        gridArea: 'totalScans',
    },
    uniqueScans: {
        gridArea: 'uniqueScans',
    },
    totalScanRefunds: {
        gridArea: 'totalScanRefunds',
    },
    uniqueScanRefunds: {
        gridArea: 'uniqueScanRefunds',
    },
    exports: {
        margin: '30px 35px 0px',
    },
    exportBtn: {
        marginRight: '15px',
    }
})

export default useStyles
