import { createSlice } from '@reduxjs/toolkit'
import {
    ERROR,
    FETCHING,
    PENDING,
    SUCCESS,
} from 'features/status'

const name = 'rolesShow'

const initialState = {
    status: PENDING,
    id: null,
    hasLoaded: false,
    role: {},
    error: null,
}

const reducers = {
    fetchRole: (state, { payload }) => {
        return {
            ...initialState,
            id: payload,
            status: FETCHING,
        }
    },
    fetchRoleResult: (state, { payload }) => {
        const { role } = payload

        state.status = SUCCESS
        state.hasLoaded = true
        state.role = role
    },
    fetchRoleError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetRole: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    fetchRole,
    fetchRoleResult,
    fetchRoleError,
    resetRole,
} = slice.actions

export default slice.reducer
