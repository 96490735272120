import { useEffect, useState } from 'react'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import { useCreateAllowedEmail } from 'features/velocity/email_containment/hooks/create_allowed_email'
import { useAllowedEmails } from 'features/velocity/email_containment/hooks/allowed_emails'
import { EmailContainmentTable } from 'features/velocity/email_containment/components/Table'
import ConfirmationModal from '../ConfirmationModal'

export const AllowedEmails = (props) => {
    const [selectedEmail, setSelectedEmail] = useState()
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const { params, paginationQueryArgs, resetPage } = props

    const {
        emails,
        pagination,
        isFetching,
        invalidateListQueries,
        isLoading,
        connectionError,
        isInitialLoading,
        isError,
        errors,
        onDeleteSubmit,
    } = useAllowedEmails({ params, paginationQueryArgs })

    const createParams = useCreateAllowedEmail()

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isInitialLoading={isInitialLoading}
            />

            <ConfirmationModal
                showModal={showConfirmationModal}
                setShowModal={setShowConfirmationModal}
                selectedEmail={selectedEmail}
                setSelectedEmail={setSelectedEmail}
                onDeleteSubmit={onDeleteSubmit}
            />

            <EmailContainmentTable
                pagination={pagination}
                isFetching={isFetching}
                invalidateListQueries={invalidateListQueries}
                emails={emails}
                variant='email'
                createProps={createParams}
                selectedEmail={selectedEmail}
                setSelectedEmail={setSelectedEmail}
                showModal={showConfirmationModal}
                setShowModal={setShowConfirmationModal}
                {...props}
            />
        </>
    )
}

export default AllowedEmails
