import { reduceLoadingState, squashArr } from 'helpers'
import { useIncommRetailers } from 'features/velocity/retailers/hooks/incommRetailers'
import { useParentRetailers } from 'features/velocity/retailers/hooks/parentRetailers'
import { useRetailerForm } from 'features/velocity/retailers/hooks/form'
import { useCreateRetailerCmd } from 'features/velocity/retailers/queries'

export const useCreateRetailer = () => {
    const {
        form,
        updateStatusField,
        updatePrintStatusField,
        updateBarcodeTypeField,
        updateBarcodeDataField,
        updateField,
        updateParentRetailerField,
        updateWYSIWYG,
        updateLogoField,
        updateIconField,
        toggleIncommRetailer,
    } = useRetailerForm()

    const incommRetailersResult = useIncommRetailers()
    const parentRetailersResult = useParentRetailers()

    const isLoadError =
        incommRetailersResult?.isError ||
        parentRetailersResult?.isError

    const loadSuccess =
        incommRetailersResult?.success &&
        parentRetailersResult?.success

    const createResult = useCreateRetailerCmd()
    const onSubmit = () => createResult.mutate(form)

    const connectionError =
        incommRetailersResult.connectionError ||
        parentRetailersResult.connectionError

    const warnings = squashArr([
        incommRetailersResult.data?.warnings,
        parentRetailersResult.data?.warnings,
        createResult.data?.warnings,
    ])
    const notices = squashArr([
        incommRetailersResult.data?.notices,
        parentRetailersResult.data?.notices,
        createResult.data?.notices,
    ])
    const errors = squashArr([
        incommRetailersResult?.errors,
        parentRetailersResult?.errors,
        createResult.error,
    ])

    const isLoadingState = reduceLoadingState([incommRetailersResult, parentRetailersResult]) || createResult.isLoading
    const isDisabled = incommRetailersResult.isLoading || parentRetailersResult.isLoading || createResult.isLoading

    const incommRetailers = loadSuccess ? incommRetailersResult?.incommRetailers : []
    const retailerOptions = loadSuccess ? parentRetailersResult?.parentRetailers : []
    const parentRetailers = [{ name: 'None', id: null }, ...retailerOptions]

    return {
        loadSuccess,
        connectionError,
        errors,
        isLoadingState,
        warnings,
        notices,
        isLoadError,
        disabled: isDisabled,
        incommRetailers,
        parentRetailers,
        form,
        updateStatusField,
        updatePrintStatusField,
        updateBarcodeTypeField,
        updateBarcodeDataField,
        updateLogoField,
        updateIconField,
        updateField,
        updateParentRetailerField,
        updateWYSIWYG,
        toggleIncommRetailer,
        onSubmit,
    }
}
