import { useQueryOfferRetailersByOfferID } from 'features/velocity/offer_retailers/queries'
import { reduceQueries } from 'helpers'

export const useOfferRetailersByOfferID = (offerID, queryArgs = {}) => {
    const {
        payloads: [offerRetailersByOfferIDPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryOfferRetailersByOfferID(offerID, queryArgs)],
    )

    const offerRetailers = offerRetailersByOfferIDPayload?.offer_retailers || []

    return {
        connectionError,
        success,
        offerRetailers,
        errors,
        warnings,
        notices,
        ...isResults,
    }
}
