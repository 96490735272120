import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import {
    Box, Button, CardMedia,
    TextField, Tooltip, Typography,
    FormControlLabel, Checkbox,
} from '@mui/material'

import {
    AutocompleteStyled, FormColumnStyled,
    FormStyled, SubmitBlockStyled, TextFieldStyled,
} from 'theme/forms'
import { PageStyled } from 'theme/page'
import { BARCODE_TYPE_OPTIONS, STATUS_OPTIONS } from 'features/status'
import { checkImageNotNull, isEmpty } from 'helpers'
import InfoIcon from '@mui/icons-material/Info'
import BackButton from 'components/BackButton'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import ImageFieldWithTooltip from 'components/ImageFieldWithTooltip'
import RetailerIconTooltip from 'components/RetailerIconTooltip'
import FieldWithTooltip from 'components/FieldWithTooltip'
import useStyles from './styles'

export const RetailerForm = ({
    edit, loadSuccess, connectionError, errors, isLoadingState,
    isLoadError, disabled, incommRetailers, form, toggleIncommRetailer,
    updateStatusField, updateBarcodeTypeField, updateBarcodeDataField, updateField,
    updateWYSIWYG, onSubmit, updateLogoField, updateIconField, updatePrintStatusField,
    parentRetailers, updateParentRetailerField, parentRetailerID,
}) => {
    const styles = useStyles()

    const logo = checkImageNotNull(form.logo)
    const formLogo = checkImageNotNull(form.logo_url)

    const icon = checkImageNotNull(form.icon)
    const formIcon = checkImageNotNull(form.icon_original)

    if (parentRetailerID && !form.parent_id) {
        updateParentRetailerField(null, parentRetailerID)
    }

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isLoadError) return <ConnectionError err={errors} />

    if (!loadSuccess) return <Loading />

    return (
        <PageStyled>
            <Typography variant='h1'>{`${edit ? 'Edit' : 'New'} Retailer`}</Typography>

            <IsLoading isLoadingState={isLoadingState} />

            <FormStyled
                noValidate
                autoComplete='off'
                onSubmit={(e) => e.preventDefault()}
                data-testid='retailer-form'
            >
                <FormColumnStyled>
                    <TextFieldStyled
                        label='Name'
                        data-cy='name'
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                        value={form.name || ''}
                        onChange={updateField('name')}
                    />
                    {!edit &&
                        <>
                            {isEmpty(parentRetailerID) &&
                                <AutocompleteStyled
                                    autoComplete
                                    disableClearable
                                    data-cy='enter_parent_retailer'
                                    options={parentRetailers}
                                    getOptionLabel={(op) => op.name || 'None'}
                                    disabled={disabled}
                                    value={parentRetailers.find((op) => op.id === form.parent_id) || ''}
                                    onChange={updateParentRetailerField}
                                    renderInput={(params) => (
                                        <TextField {...params} label='Parent Retailer' />
                                    )}
                                />
                            }
                        </>
                    }
                    <AutocompleteStyled
                        autoComplete
                        disableClearable
                        data-cy='status'
                        options={STATUS_OPTIONS}
                        getOptionLabel={(value) => value.name || ' '}
                        disabled={disabled}
                        value={STATUS_OPTIONS.find((type) => type.value === form?.status) || ''}
                        onChange={updateStatusField}
                        renderInput={(params) => (
                            <TextField {...params} label='Status' />
                        )}
                    />

                    <FieldWithTooltip tooltip='Allow user to print barcode for all offers at this retailer.'>
                        <AutocompleteStyled
                            autoComplete
                            disableClearable
                            data-cy='print-status'
                            options={STATUS_OPTIONS}
                            getOptionLabel={(value) => value.name || ' '}
                            disabled={disabled}
                            value={STATUS_OPTIONS.find((type) => type.value === form?.print_status) || ''}
                            onChange={updatePrintStatusField}
                            renderInput={(params) => (
                                <TextField {...params} label='Print Status' />
                            )}
                        />
                    </FieldWithTooltip>

                    <AutocompleteStyled
                        autoComplete
                        disableClearable
                        data-cy='barcode_type'
                        options={BARCODE_TYPE_OPTIONS}
                        getOptionLabel={(op) => op.name}
                        disabled={disabled}
                        value={BARCODE_TYPE_OPTIONS.find((type) => type.value === form?.barcode_type.name) || ''}
                        onChange={updateBarcodeTypeField}
                        renderInput={(params) => (
                            <TextField {...params} label='Barcode Type' />
                        )}
                    />

                    {form?.barcode_type?.name === 'qr_code' &&
                        <TextFieldStyled
                            label='Barcode Prefix'
                            data-cy='barcode_data'
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            value={form.barcode_type.data || ''}
                            onChange={updateBarcodeDataField}
                        />
                    }
                </FormColumnStyled>

                <FormColumnStyled>
                    <ImageFieldWithTooltip>
                        <TextFieldStyled
                            label='Logo'
                            type='file'
                            disabled={disabled}
                            data-cy='logo'
                            InputLabelProps={{ shrink: true }}
                            onChange={updateLogoField}
                        />
                    </ImageFieldWithTooltip>

                    {(formLogo || logo) &&
                        <>
                            <Box style={styles.subtitle}>
                                <Typography variant='h3'>{`Current Logo:`}</Typography>
                            </Box>
                            <CardMedia
                                sx={styles.cover}
                                component='img'
                                alt='thumbnail_logo'
                                src={!formLogo ? `data:image/png;base64,${form.logo.data}` : form.logo_url}
                                title={`${form.name}'s Current Image`}
                            />
                        </>
                    }
                    {formLogo && logo &&
                        <>
                            <Box style={styles.subtitle}>
                                <Typography variant='h3'>{`New Logo:`}</Typography>
                            </Box>
                            <CardMedia
                                sx={styles.base64_cover}
                                component='img'
                                alt='thumbnail_logo'
                                src={`data:image/png;base64,${form.logo.data}`}
                                title={`${form.name}'s New Image`}
                            />
                        </>
                    }
                    <RetailerIconTooltip>
                        <TextFieldStyled
                            label='Icon'
                            type='file'
                            disabled={disabled}
                            data-cy='icon'
                            inputProps={{ accept: 'image/png' }}
                            InputLabelProps={{ shrink: true }}
                            onChange={updateIconField}
                        />
                    </RetailerIconTooltip>

                    {(formIcon || icon) &&
                        <>
                            <div style={styles.subtitle}>
                                <Typography variant='h3'>{`Current Icon:`}</Typography>
                            </div>
                            <CardMedia
                                sx={styles.cover}
                                component='img'
                                alt='thumbnail_icon'
                                src={!formIcon ? `data:image/png;base64,${form.icon.data}` : form.icon_original}
                                title={`${form.name}'s Current Image`}
                            />
                        </>
                    }
                    {formIcon && icon &&
                        <>
                            <div style={styles.subtitle}>
                                <Typography variant='h3'>{`New Icon:`}</Typography>
                            </div>
                            <CardMedia
                                sx={styles.base64_cover}
                                component='img'
                                alt='thumbnail_icon'
                                src={`data:image/png;base64,${form.icon.data}`}
                                title={`${form.name}'s New Image`}
                            />
                        </>
                    }
                </FormColumnStyled>

                <FormColumnStyled>
                    <Typography variant='h2' sx={styles.editorTitle}>
                        Select InComm Retailers
                        <Tooltip title="Names and IDs InComm Retailers. List only shows retailers not already connected." placement='right'>
                            <InfoIcon />
                        </Tooltip>
                    </Typography>

                    <Box sx={styles.retailerWrapper}>
                        {incommRetailers.map((r) => (
                            <Box key={r.incomm_id} sx={styles.retailerItem}>
                                <FormControlLabel
                                    label={r.displayName}
                                    control={
                                        <Checkbox
                                            sx={styles.checkbox}
                                            name={r.displayName}
                                            checked={form.incomm_retailers.map((r) => r.incomm_id).includes(r.incomm_id)}
                                            onChange={(e) => {
                                                toggleIncommRetailer(r, e.target.checked)
                                            }}
                                        />
                                    }
                                />
                            </Box>
                        ))}
                    </Box>
                </FormColumnStyled>

                <FormColumnStyled>
                    <Typography variant='h2' sx={styles.editorTitle}>
                        Add Redemption Instructions
                        <Tooltip title="Instructions specific to retailer will appear on offer redemption page" placement='right'>
                            <InfoIcon />
                        </Tooltip>
                    </Typography>

                    <SunEditor
                        height={250}
                        setDefaultStyle="font-family:  Avenir, Open-Sans, sans-serif;"
                        setOptions={{
                            buttonList: [
                                ['font', 'formatBlock', 'fontColor'],
                                ['bold', 'italic', 'underline', 'strike'],
                                ['align', 'list', 'horizontalRule'],
                                ['hiliteColor', 'lineHeight', 'paragraphStyle', 'textStyle'],
                                ['image', 'link'],
                                ['showBlocks', 'codeView'],
                            ],
                        }}
                        setContents={form?.redemption_instructions}
                        onChange={updateWYSIWYG}
                    />
                </FormColumnStyled>

                <SubmitBlockStyled>
                    <Button
                        type='submit'
                        data-cy='submit_retailer'
                        variant='contained'
                        color='secondary'
                        sx={styles.btn}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>

                    <BackButton />
                </SubmitBlockStyled>
            </FormStyled>
        </PageStyled>
    )
}

export default RetailerForm
