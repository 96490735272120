import { useEffect } from 'react'
import { useCreateShortLinkCmd } from '../queries'
import { isPresent, squashArr } from 'helpers'
import { useShortLinkForm } from './short_link_form'
import { velocityPublicWebHost } from 'utils'

export const useCreateShortLink = (offer) => {
    const createLinkResult = useCreateShortLinkCmd()

    const warnings = createLinkResult.data?.warnings
    const errors = squashArr([createLinkResult?.error, createLinkResult?.data?.errors])

    const isLoadingState = createLinkResult.isLoading

    const {
        shortLinkState,
        updateField,
        resetForm,
    } = useShortLinkForm({ offerID: offer?.id })

    useEffect(() => {
        if (createLinkResult?.isSuccess) resetForm(offer?.id)

        if (offer?.id) {
            velocityPublicWebHost()
                .then((url) => {
                    if (isPresent(url)) {
                        updateField('target')({ target: { value: `${url}/${offer?.id}` } })
                    }
                })
        }
    }, [createLinkResult.isSuccess])

    const onSubmit = () => {
        createLinkResult.mutate(shortLinkState)
    }

    return {
        errors,
        warnings,
        success: createLinkResult?.isSuccess,
        form: shortLinkState.form,
        disabled: isLoadingState,
        isLoadingState,
        updateField,
        onSubmit,
        resetForm,
    }
}
