import { reduceLoadingState, squashArr } from 'helpers'
import { useOfferPartnerForm } from 'features/velocity/offer_partners/hooks/form'
import { useOfferPartners } from 'features/velocity/offer_partners/hooks/filterless_list'
import { useCreateOfferPartnerCmd } from 'features/velocity/offer_partners/queries'
import { useBrands } from './brands'

export const useCreateOfferPartner = () => {
    const {
        form,
        updateField,
        updateStatusField,
        updateBrandField,
        updateImageField,
    } = useOfferPartnerForm()

    const brandsResult = useBrands()
    const offerPartnersResult = useOfferPartners()

    const isLoadError = brandsResult.isError || offerPartnersResult.isError
    const loadSuccess = brandsResult?.data?.success && offerPartnersResult?.success

    const createResult = useCreateOfferPartnerCmd()
    const onSubmit = () => createResult.mutate(form)

    const connectionError = brandsResult.error || offerPartnersResult.connectionError
    const warnings = squashArr([brandsResult.data?.warnings, offerPartnersResult.warnings, createResult.data?.warnings])
    const notices = squashArr([brandsResult.data?.notices, offerPartnersResult.notices, createResult.data?.notices])
    const errors = squashArr([brandsResult.data?.errors, offerPartnersResult.errors, createResult.data?.errors, createResult.error])

    const isLoadingState = reduceLoadingState([brandsResult, offerPartnersResult]) || createResult.isLoading
    const isDisabled = brandsResult.isLoading || offerPartnersResult.isLoading || createResult.isLoading

    const brands = loadSuccess ? brandsResult?.data?.payload?.brands : []
    const offerPartners = loadSuccess ? offerPartnersResult?.offerPartners : []

    const assignedBrandIds = offerPartners?.map((op) => op.brand.id)
    const assignableBrands = brands.filter((b) => !assignedBrandIds.includes(b.id))

    return {
        loadSuccess,
        connectionError,
        errors,
        isLoadingState,
        warnings,
        notices,
        isLoadError,
        disabled: isDisabled,
        brands: assignableBrands,
        form,
        updateField,
        updateStatusField,
        updateBrandField,
        updateImageField,
        onSubmit,
    }
}
