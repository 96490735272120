import { colors } from 'theme'

const useStyles = () => ({
    toolbar: {
        margin: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: '30px',
    },
    sectionTitle: {
        fontSize: 32,
        alignSelf: 'flex-start',
    },
    sectionDescription: {
        fontSize: 20,
        alignSelf: 'flex-start',
    },
    descriptionContainer: {
        display: 'flex',
    },
    jsonContainer: {
        display: 'flex',
        width: '100%',
        maxWidth: '94vw',
        flexDirection: 'column',
        background: colors.vizerSlateGray.light,
        padding: '20px',
        marginBottom: '50px',
        position: 'relative',
    },
    jsonCopy: {
        marginBottom: '30px',
    },
    json: {
        width: '100%',
        overflow: 'auto',
        margin: 0,
    },
    divider: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: colors.vizerSlateGray.light,
        marginBottom: '40px',
        width: '100%',
    },
    form: {
        alignItems: 'flex-end',
    },
    formLabel: {
        padding: '30px 0 10px',
        fontSize: 18,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 450,
        alignItems: 'center',
    },
    field: {
        maxWidth: 300,
        width: 300,
        marginRight: 0,
        marginBottom: 0,
        '& .MuiOutlinedInputRoot': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    searchBtn: {
        color: colors.white,
        height: 54,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    noResults: {
        fontSize: 18,
    },
    action: {
        display: 'flex',
        margin: '15px',
    },
    arrow: {
        marginTop: '-2px',
    },
    activityStatus: {
        width: '100%',
        overflow: 'auto',
        margin: '0px 0px 5px 0px',
    },
})

export default useStyles
