import { colors } from 'theme'

const useStyles = () => ({
    graphContainer: {
        height: { xs: 200, xl: 350 },
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 0,
    },
    tooltip: {
        display: 'flex',
        background: (theme) => theme.palette.tooltip.main,
        color: (theme) => theme.palette.text.primary,
        padding: '9px 12px',
        border: (theme) => `1px solid ${theme.palette.tooltip.border}`,
        flexDirection: 'column',
        alignItems: 'center',
    },
    expandButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: 40,
        width: 40,
        backgroundColor: colors.vizerIceGray.light,
        color: colors.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

export default useStyles
