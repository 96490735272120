import HelpFormRequestsList from './List'
import HelpFormRequestShow from './Show'

const HelpFormRequestsRoutes = {
    path: 'help_form_requests',
    children: [
        {
            path: '',
            element: <HelpFormRequestsList />,
        },
        {
            path: ':id',
            element: <HelpFormRequestShow />,
        },
    ],
}

export default HelpFormRequestsRoutes
