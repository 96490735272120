import { takeEvery, put, select } from 'redux-saga/effects'
import {
    updateExistingUser,
    fetchUserShow,
} from '../services'
import {
    editUser,
    editUserResult,
    editUserError,
    updateUser,
    updateUserResult,
    updateUserError,
} from '../reducers/edit'

export function* takeEditUser(action) {
    try {
        const { payload } = yield fetchUserShow(action.payload)

        yield put(editUserResult(payload))
    } catch (error) {
        yield put(editUserError(error.toString()))
    }
}

export function* watchEditUser() {
    yield takeEvery(editUser.toString(), takeEditUser)
}

export function* takeUpdateUser() {
    try {
        const id = yield select((state) => state.users.edit.id)
        const form = yield select((state) => state.users.edit.user)

        const { payload } = yield updateExistingUser(id, form)

        yield put(updateUserResult({ user: payload }))
    } catch (error) {
        yield put(updateUserError(error.toString()))
    }
}

export function* watchUpdateUser() {
    yield takeEvery(updateUser.toString(), takeUpdateUser)
}
