import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import RetailerEdit from 'features/velocity/retailers/components/Edit'
import useStyles from './styles'

export const Edit = () => {
    const styles = useStyles()
    const navigate = useNavigate()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <RetailerEdit />
        </>
    )
}

export default Edit
