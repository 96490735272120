import { useEffect, useState } from 'react'
import { useQueryOfferShow, useQueryOfferMockApproval, useQueryOfferRefetch, useGenerateStripImagesCmd } from 'features/velocity/offers/queries'
import { useQueryConstants } from 'features/velocity/constants/queries'
import { squashArr, reduceQueries, isEmpty, sanitizeDateOnly } from 'helpers'
import { useQueryBrands, useQueryOfferPartners } from 'features/velocity/offer_partners/queries'

export const useOfferShow = (offerID) => {
    const [refetchDisabled, setRefetchDisabled] = useState(false)
    const [generatingImages, setGeneratingImages] = useState(false)

    const {
        payloads: [offerPayload, offerPartnersPayload, brandsPayload, constantsPayload],
        success, connectionError,
        queryNotices, queryWarnings, queryErrors,
        isResults,
    } = reduceQueries(
        [
            useQueryOfferShow(offerID),
            useQueryOfferPartners(),
            useQueryBrands(),
            useQueryConstants(),
        ],
    )

    const offer = offerPayload?.offer
    const links = offerPayload?.short_links
    const schedules = offerPayload?.offer_schedules
    const strategy = offer?.strategy?.strategy
    const offerPublicLink = `${constantsPayload?.VELOCITY_PUBLIC_WEB_HOST}/${offer?.id}?tmpl=${offer?.template_landing?.id}`
    const offerLandingPreviewLink = `${constantsPayload?.VELOCITY_PUBLIC_WEB_HOST}/${offer?.id}/preview?tmpl=${offer?.template_landing?.id}`
    const offerEngagePreviewLink = `${constantsPayload?.VELOCITY_PUBLIC_WEB_HOST}/engage/${offer?.id}/preview?tmpl=${offer?.template_engage?.id}`
    const calcDisplayExpStr = (displayExpiration) => {
        if (isEmpty(displayExpiration)) {
            return null
        }

        if (displayExpiration.name === 'static') {
            return `Static: ${sanitizeDateOnly(displayExpiration.data)}`
        }

        return `Dynamic: ${displayExpiration.data} days rolling`
    }
    const displayExpStr = calcDisplayExpStr(offer?.display_expiration)

    if (offer) {
        offer.brand = brandsPayload?.brands.find((brand) => brand.id === offer?.brand_id)
        offer.offer_partner = offerPartnersPayload?.offer_partners.find((op) => offer.offer_partner_id === op.id)
    }

    const offerMockApproval = useQueryOfferMockApproval(offerID)
    const refetchOffer = useQueryOfferRefetch(offerID)
    const generateResult = useGenerateStripImagesCmd(setGeneratingImages, offerID)
    const warnings = squashArr([offerMockApproval?.data?.warnings, queryWarnings])
    const notices = squashArr([offerMockApproval?.data?.notices, queryNotices])
    const errors = squashArr([offerMockApproval?.data?.errors, queryErrors])

    useEffect(() => {
        if (refetchOffer.isLoading) setRefetchDisabled(true)

        if (refetchOffer.isSuccess || refetchOffer.isError) {
            setTimeout(() => {
                setRefetchDisabled(false)
            }, 3500)
        }
    }, [refetchOffer])

    return {
        connectionError,
        success,
        offer,
        links,
        schedules,
        strategy,
        errors,
        warnings,
        notices,
        offerPublicLink,
        offerLandingPreviewLink,
        offerEngagePreviewLink,
        refetchDisabled,
        displayExpStr,
        offerMockApproval: () => offerMockApproval.mutate(offerID),
        refetchOffer: () => refetchOffer.mutate(offerID),
        generateStripImages: () => {
            setGeneratingImages(true)
            generateResult.mutate(offerID)
        },
        generateStripImagesDisabled: generatingImages,
        ...isResults,
    }
}
