import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { lastXChar } from 'helpers'
import Cell from 'components/Cell'
import Row from 'components/Row'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import { useClientPortalUsers } from 'features/velocity/client_portal_users/hooks/list'
import useStyles from './styles'

export const ClientPortalUsersList = () => {
    const styles = useStyles()

    const {
        connectionError,
        clientPortalUsers,
        pagination,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        setPage,
        pageState,
    } = useClientPortalUsers()

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Client Portal Users</Typography>

            <Link style={styles.link} to={'/velocity/client_portal/users/new'}>
                <Button
                    sx={styles.btn}
                    variant="contained"
                    color="secondary"
                    data-cy='new-btn'
                >
                    New
                </Button>
            </Link>

            <Filter
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align='center'>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        {clientPortalUsers.map(({ id, name }) => (
                            <Row
                                key={id}
                                path={`/velocity/client_portal/users/${id}`}
                                sx={styles.row}
                            >
                                <Cell scope='client portal user' align='center'>{lastXChar(id, 8)}</Cell>
                                <Cell align='center'>{name}</Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {clientPortalUsers.length === 0 &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No client portal users found.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default ClientPortalUsersList
