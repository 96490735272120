import { Link } from 'react-router-dom'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
} from '@mui/material'
import { lastXChar } from 'helpers'
import Cell from 'components/Cell'
import Row from 'components/Row'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import { useOfferPartners } from 'features/velocity/offer_partners/hooks/list'
import useStyles from './styles'

export const OfferPartnersList = () => {
    const styles = useStyles()
    const {
        connectionError,
        offerPartners,
        pagination,
        filters,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        setPage,
        updateFilter,
        setSearch,
        search,
        resetFilters,
        pageState,
        invalidateListQueries,
        resetFiltersDisabled,
    } = useOfferPartners()

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Offer Partners</Typography>
            <Link to={{ pathname: `/velocity/offer_partners/new` }}>
                <Button
                    sx={styles.btn}
                    variant="contained"
                    color="secondary"
                    data-cy='new-offer-partner-btn'
                >
                    New
                </Button>
            </Link>
            <Filter
                pageState={pageState}
                filters={filters}
                pagination={pagination}
                setPage={setPage}
                updateFilter={updateFilter}
                resetFilters={resetFilters}
                disabled={isFetching}
                resetFiltersDisabled={resetFiltersDisabled}
                refresh={invalidateListQueries}
                search={search}
                setSearch={setSearch}
            />
            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align='center'>Name</TableCell>
                            <TableCell align='center'>Brand</TableCell>
                            <TableCell align='center'>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    {offerPartners.length > 0 &&
                        <TableBody data-cy='body'>
                            {offerPartners.map(({ offer_partner, brand }) => {
                                const { id, name, status } = offer_partner

                                return (
                                    <Row
                                        key={id}
                                        path={`/velocity/offer_partners/${id}`}
                                    >
                                        <Cell scope='offer-partner' align='center'>{lastXChar(id, 8)}</Cell>
                                        <Cell align='center'>{name}</Cell>
                                        <Cell
                                            align='center'
                                            sx={styles.cellLink}
                                            path={`/brands/${brand?.id}`}
                                        >
                                            {`${brand?.name} (${brand?.id})`}
                                        </Cell>
                                        <Cell align='center'>{status}</Cell>
                                    </Row>
                                )
                            })}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            {offerPartners.length === 0 &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No offer partners found matching your selection.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default OfferPartnersList
