import { useEffect } from 'react'
import { reduceLoadingState, squashArr } from 'helpers'
import { useParentRetailers } from 'features/velocity/retailers/hooks/parentRetailers'
import { useUpdateRetailerCmd } from 'features/velocity/retailers/queries'
import { useIncommRetailers } from 'features/velocity/retailers/hooks/incommRetailers'
import { useRetailerShow } from 'features/velocity/retailers/hooks/show'
import { useRetailerForm } from 'features/velocity/retailers/hooks/form'

export const useEditRetailer = (id) => {
    const {
        form,
        updateStatusField,
        updateBarcodeTypeField,
        updateBarcodeDataField,
        updateField,
        updatePrintStatusField,
        updateLogoField,
        updateIconField,
        updateParentRetailerField,
        updateWYSIWYG,
        toggleIncommRetailer,
        setForm,
    } = useRetailerForm()

    const retailerShowResult = useRetailerShow(id)
    const incommRetailersResult = useIncommRetailers()
    const parentRetailersResult = useParentRetailers()

    const isLoadError = retailerShowResult.isError || incommRetailersResult.isError || parentRetailersResult?.isError
    const loadSuccess = retailerShowResult.success && incommRetailersResult.success && parentRetailersResult?.success

    const updateResult = useUpdateRetailerCmd()
    const onSubmit = () => updateResult.mutate(form)

    const connectionError = retailerShowResult.connectionError
    const warnings = squashArr([retailerShowResult.warnings, incommRetailersResult.warnings, updateResult.data?.warnings])
    const notices = squashArr([retailerShowResult.notices, incommRetailersResult.notices, updateResult.data?.notices])
    const errors = squashArr([retailerShowResult.errors, incommRetailersResult.errors, updateResult.error])

    const isLoadingState = reduceLoadingState([retailerShowResult, incommRetailersResult, parentRetailersResult]) || updateResult.isLoading
    const isDisabled = retailerShowResult.isLoading || incommRetailersResult.isLoading || parentRetailersResult.isLoading || updateResult.isLoading

    const retailer = loadSuccess ? retailerShowResult?.retailer : {}
    const incommRetailers = loadSuccess ? incommRetailersResult?.incommRetailers : []
    const parentRetailers = loadSuccess ? parentRetailersResult?.parentRetailers : []

    useEffect(() => {
        if (loadSuccess) {
            setForm(retailer)
        }
    }, [loadSuccess, retailer])

    return {
        loadSuccess,
        connectionError,
        errors,
        warnings,
        notices,
        isLoadError,
        isLoadingState,
        disabled: isDisabled,
        incommRetailers,
        parentRetailers,
        form,
        updateField,
        updatePrintStatusField,
        updateStatusField,
        updateBarcodeTypeField,
        updateBarcodeDataField,
        updateLogoField,
        updateIconField,
        updateParentRetailerField,
        updateWYSIWYG,
        toggleIncommRetailer,
        onSubmit,
    }
}
