import {
    Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField, Paper, Typography,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

import { DOMAIN_ATTEMPT_THRESHOLD_OPTIONS } from 'features/status'
import Row from 'components/Row'
import Cell from 'components/Cell'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import useStyles from './styles'

export const DomainsTable = ({
    domains, buttonType, setShowModal, setSelectedDomain,
    filters, search, setSearch, refresh, updateFilter, disabled,
    pageState, pagination, setPage, isFetching, invalidateListQueries,
}) => {
    const styles = useStyles()

    const buttonText = buttonType === 'pending' ? 'Confirm' : buttonType

    return (
        <>
            <Filter
                pageState={pageState}
                filters={filters}
                pagination={pagination}
                setPage={setPage}
                updateFilter={updateFilter}
                search={search}
                setSearch={setSearch}
                resetFilters={invalidateListQueries}
                disabled={disabled}
                refresh={refresh}
            >
                <Autocomplete
                    autoComplete
                    freeSolo
                    clearOnBlur
                    selectOnFocus
                    data-cy='attempt-threshold'
                    sx={styles.input}
                    options={DOMAIN_ATTEMPT_THRESHOLD_OPTIONS}
                    getOptionLabel={(value) => value}
                    disabled={disabled}
                    value={
                        DOMAIN_ATTEMPT_THRESHOLD_OPTIONS.find((f) => f === pageState.filters.attempt_threshold) ||
                        pageState.filters.attempt_threshold ||
                        null
                    }
                    onChange={(e, value) => updateFilter(e, value, 'attempt_threshold')}
                    renderInput={(params) => (
                        <TextField {...params} label={'Minimum Attempts'} />
                    )}
                />
            </Filter>

            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>Attempts</TableCell>
                            <TableCell align='center'>Domain</TableCell>
                            <TableCell align='center'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        {domains?.map(({ domain, id, count }) => (
                            <Row key={domain}>
                                <Cell align='center' sx={styles.count} noLink>{count}</Cell>
                                <Cell scope='domain' align='left' noLink>{domain}</Cell>
                                <Cell scope='action' align='right' noLink>
                                    <Button
                                        type='button'
                                        data-cy=''
                                        variant='contained'
                                        sx={styles.btn(buttonType)}
                                        onClick={() => {
                                            setSelectedDomain({ domain, id, count, pendingFlow: buttonType === 'pending' })
                                            setShowModal(buttonType)
                                        }}
                                    >
                                        {buttonText}
                                    </Button>
                                </Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {domains?.length === 0 && !isFetching &&
                <Typography sx={styles.noResults} variant='h3' align='center'>No domains found.</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default DomainsTable
