import { _getWithoutJWT, _postWithoutJWT, velocityUrl as _url } from '../../utils'

export async function fetchUserByInvitationID(invitationID) {
    try {
        const url = await _url(`/invitations/${invitationID}`)

        return await _getWithoutJWT(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function acceptInvitation({
    form: { first_name, last_name, email, password, password_confirmation }, invitationID,
}) {
    try {
        const url = await _url(`/invitations/${invitationID}/accept`)

        const body = {
            first_name,
            last_name,
            email,
            password,
            password_confirmation,
        }

        return await _postWithoutJWT(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
