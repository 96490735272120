import { takeEvery, put, select } from '@redux-saga/core/effects'
import {
    fetchPermissionsList,
    fetchUserPermissionsList,
    fetchRolePermissionsList,
} from '../services'
import {
    fetchPermissions,
    fetchPermissionsResult,
    fetchPermissionsError,
} from '../reducers/list'

export function* takeFetchPermissions() {
    try {
        const userID = yield select((state) => state.permissions.list.userID)
        const roleID = yield select((state) => state.permissions.list.roleID)

        let payload
        if (userID) {
            ({ payload } = yield fetchUserPermissionsList({ userID }))
        } else if (roleID) {
            ({ payload } = yield fetchRolePermissionsList({ roleID }))
        } else {
            ({ payload } = yield fetchPermissionsList())
        }

        const { permissions } = payload

        yield put(fetchPermissionsResult({ permissions }))
    } catch (error) {
        yield put(fetchPermissionsError(error.toString()))
    }
}

export function* watchFetchPermissions() {
    yield takeEvery(fetchPermissions.toString(), takeFetchPermissions)
}
