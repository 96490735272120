import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'
import { useOfferPartnerFormReducer } from 'features/velocity/offer_partners/reducers'
import { bundleImageData, emptyToNull } from 'helpers'

export const useOfferPartnerForm = (state) => {
    const [form, dispatch] = useOfferPartnerFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })
    const updateStatusField = (_, status) => dispatch({ type: UPDATE_FIELD, field: 'status', value: status.value })
    const updateBrandField = (_, brand) => dispatch({ type: UPDATE_FIELD, field: 'brand_id', value: brand.id })
    const updateImageField = async (e, _) => {
        const file = e.target.files[0]
        const image = await bundleImageData(file)

        dispatch({ type: UPDATE_FIELD, field: 'image', value: image })
    }
    const resetForm = () => dispatch({ type: RESET_FORM })
    const setForm = (offer) => dispatch({ type: SET_FORM, value: offer })

    return {
        form,
        updateField,
        updateStatusField,
        updateBrandField,
        updateImageField,
        setForm,
        resetForm,
    }
}
