import { useDeleteCatalogCmd } from 'features/velocity/catalogs/queries'
import { squashArr } from 'helpers'

export const useCatalogDelete = (catalogID) => {
    const deleteResult = useDeleteCatalogCmd()

    const onDeleteCatalog = () => deleteResult.mutate(catalogID)

    const errors = squashArr([deleteResult.data?.errors, deleteResult.error])

    const isLoadingState = deleteResult.isLoading

    return {
        deleteErrors: errors,
        deleteIsLoading: isLoadingState,
        onDeleteCatalog,
    }
}
