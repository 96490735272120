import { useState } from 'react'
import { useQueryOfferShow } from 'features/velocity/offers/queries'
import { useQueryRetailerShow } from 'features/velocity/retailers/queries'
import { useQueryOfferRetailerShow, useGenerateStripImagesCmd } from 'features/velocity/offer_retailers/queries'
import { reduceQueries } from 'helpers'

export const useOfferRetailerShow = (offerID, retailerID) => {
    const {
        payloads: [offerPayload, retailerPayload, offerRetailerPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [
            useQueryOfferShow(offerID),
            useQueryRetailerShow(retailerID),
            useQueryOfferRetailerShow(offerID, retailerID),
        ],
    )

    const offer = offerPayload?.offer
    const retailer = retailerPayload?.retailer
    const offerRetailer = offerRetailerPayload?.offer_retailer

    const [generatingImages, setGeneratingImages] = useState(false)

    const generateResult = useGenerateStripImagesCmd(setGeneratingImages, offerRetailer?.id)

    return {
        connectionError,
        success,
        offer,
        retailer,
        offerRetailer,
        generateStripImages: () => {
            setGeneratingImages(true)
            generateResult.mutate(offerRetailer?.id)
        },
        disabled: generatingImages,
        errors,
        warnings,
        notices,
        ...isResults,
    }
}
