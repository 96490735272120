import BrandForm from 'features/brands/components/Form'
import { useCreateBrand } from 'features/brands/hooks/create'

export const BrandNewForm = () => {
    const createBrandResult = useCreateBrand()

    return (
        <BrandForm
            edit={false}
            {...createBrandResult}
        />
    )
}

export default BrandNewForm
