import { useMutation, useQuery } from '@tanstack/react-query'
import { useMutationDefaults, useQueryDefaults } from 'queryHelpers'
import { useClientPortalUserForm } from 'features/velocity/client_portal_users/hooks/form'
import {
    createNewClientPortalUser,
    fetchClientPortalUsers,
    fetchClientPortalUser,
    updateExistingClientPortalUser,
} from 'features/velocity/client_portal_users/services'

export const useCreateClientPortalUserCmd = () => {
    const { resetForm } = useClientPortalUserForm()

    return useMutation({
        mutationFn: (form) => createNewClientPortalUser(form),
        ...useMutationDefaults({
            queryKeys: [['client_portal_users', 'list']],
            queryType: ['client_portal_users', 'create'],
            navigationPath: '/velocity/client_portal/users',
            reset: resetForm,
        }),
    })
}

export const useUpdateClientPortalUserCmd = (id) => {
    const { resetForm } = useClientPortalUserForm()

    return useMutation({
        mutationFn: (params) => updateExistingClientPortalUser(params),
        ...useMutationDefaults({
            queryKeys: [
                ['client_portal_users', 'list'],
                ['client_portal_users', 'show', 'id'],
            ],
            queryType: ['client_portal_users', 'update'],
            navigationPath: '/velocity/client_portal/users',
            navID: id,
            reset: resetForm,
        }),
    })
}

export const useQueryClientPortalUsers = (pageQuery, queryArgs = {}) => {
    const queryKey = ['client_portal_users', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchClientPortalUsers(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryClientPortalUserShow = (clientPortalUserID) => {
    const queryKey = ['client_portal_users', 'show', clientPortalUserID]

    return useQuery({
        queryKey,
        queryFn: () => fetchClientPortalUser(clientPortalUserID),
        ...useQueryDefaults(queryKey),
    })
}
