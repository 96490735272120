import { useNavigate, useLocation } from 'react-router-dom'
import {
    Button,
} from '@mui/material'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import IncommOfferBaseForm from 'features/velocity/offers/components/InCommForm'
import { useCreateOffer } from 'features/velocity/offers/hooks/create'
import { useStyles } from './styles'

export const OfferNewTabs = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const styles = useStyles()

    const createOfferResult = useCreateOffer()

    const offerPartnerParams = location?.state?.offerPartnerParams

    if (createOfferResult.isInitialLoading) return <Loading />

    if (createOfferResult.connectionError) return <ConnectionError err={createOfferResult.connectionError} />

    if (createOfferResult.isError) return <ConnectionError err={createOfferResult.errors} />

    return (
        <>
            <div style={styles.pageTop}>
                <Button variant={'outlined'} sx={styles.btn} onClick={() => navigate(-1)}>
                    Back
                </Button>
            </div>

            <IsLoading
                isLoading={createOfferResult.isLoading}
                isFetching={createOfferResult.isFetching}
                isRefetching={createOfferResult.isRefetching}
            />

            <IncommOfferBaseForm
                edit={false}
                {...createOfferResult}
                offerPartner={offerPartnerParams}
            />
        </>
    )
}

export default OfferNewTabs
