import { colors } from 'theme'

const styles = {
    error: {
        fontSize: 12,
        color: colors.error.main,
    },
}

export default styles
