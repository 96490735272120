import { colors } from 'theme'

const useStyles = () => ({
    toolbar: {
        margin: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: '30px',
    },
    sectionTitle: {
        fontSize: 32,
        alignSelf: 'flex-start',
    },
    sectionDescription: {
        fontSize: 20,
        alignSelf: 'flex-start',
    },
    form: {
        alignItems: 'flex-end',
    },
    field: {
        maxWidth: 300,
        width: 300,
        marginRight: 0,
        marginBottom: 0,
        '& .MuiOutlinedInputRoot': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    searchBtn: {
        color: colors.white,
        height: 54,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    searchResults: {
        fontSize: 32,
        display: 'flex',
        alignSelf: 'flex-start',
        background: colors.vizerSlateGray.light,
        padding: '20px',
        borderRadius: '4px',
        '& > span': {
            marginRight: '20px',
            display: 'flex',
            alignItems: 'center',
        },
    },
    noResults: {
        fontSize: 18,
    },
})

export default useStyles
