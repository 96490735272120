import { velocityUrl, _get } from 'utils'

export async function fetchOfferTemplate(templateType, templateID) {
    try {
        const url = await velocityUrl(`/templates/queries/fetch_template?name=${templateID}&template_type=${templateType}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchOfferTemplateNames(templateType) {
    try {
        const url = await velocityUrl(`/templates/queries/list_template_names_by_type?template_type=${templateType}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
