import { useQueryBrands, useQueryOfferPartners } from 'features/velocity/offer_partners/queries'
import { reduceQueries } from 'helpers'

export const useOfferPartners = () => {
    const {
        payloads: [offerPartnersPayload, brandsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [
            useQueryOfferPartners({}),
            useQueryBrands(),
        ],
    )

    const offerPartnersArr = offerPartnersPayload?.offer_partners || []

    const offerPartners = offerPartnersArr.map((offerPartner) => {
        return {
            offer_partner: offerPartner,
            brand: brandsPayload?.brands.find((brand) => brand.id === offerPartner?.brand_id),
        }
    })

    return {
        connectionError,
        success,
        offerPartners,
        errors,
        warnings,
        notices,
        ...isResults,
    }
}
