import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
    Box,
    Card,
    CardContent,
    Fade,
    TextField,
    Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { lastXChar, sanitizeDate } from 'helpers'
import { AutocompleteStyled, FormColumnStyled, FormStyled } from 'theme/forms'
import { DescriptionListStyled } from 'theme/styled_components'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import CustomAccordion from 'components/Accordion'
import LabelWithLink from 'components/LabelWithLink'
import ConnectionError from 'components/ConnectionError'
import { HELP_REQUEST_STATUSES } from 'features/status'
import { useHelpFormRequestShow } from 'features/velocity/help_form_requests/hooks/show'
import { useEditHelpFormRequestStatus } from 'features/velocity/help_form_requests/hooks/edit'
import HelpFormRequestStatus from '../HelpFormRequestStatus'
import HelpFormRequestDomainStatus from '../HelpFormRequestDomainStatus'
import useStyles from './styles'

export const HelpFormRequestShow = () => {
    const [visible, setVisible] = useState(null)
    const styles = useStyles()
    const { id } = useParams()

    const {
        connectionError,
        helpFormRequest,
        helpFormOffer,
        helpFormRetailer,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
    } = useHelpFormRequestShow(id)

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    const {
        status,
        name,
        email,
        message,
        user_offer_id,
        card_number,
        domain_status,
        created_at,
        updated_at,
    } = helpFormRequest || {}

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
            />

            <Card sx={styles.pageHeader}>
                <CardContent>
                    <Box sx={styles.titleBar}>
                        <Typography variant='h1'>
                            Help Request from {name}
                        </Typography>
                    </Box>

                    <Box sx={styles.pageRow}>
                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <Box sx={styles.messageContainer}>
                                    <dt>
                                        <p>Name</p>
                                    </dt>
                                    <dd>
                                        <p>{name}</p>
                                    </dd>
                                    <dt>
                                        <p>Email</p>
                                    </dt>
                                    <dd>
                                        <p
                                            style={styles.copy}
                                            onClick={() => {
                                                navigator.clipboard.writeText(email)
                                                setVisible(email)
                                                setTimeout(() => setVisible(null), 2000)
                                            }}
                                        >
                                            <FileCopyIcon />
                                            {email}

                                            <Fade
                                                in={visible === email}
                                                style={{ transitionDelay: '150ms' }}
                                            >
                                                <span style={styles.copyText}>
                                                    {'Copied!'}
                                                </span>
                                            </Fade>

                                        </p>

                                        <HelpFormRequestDomainStatus domain_status={domain_status} />
                                    </dd>
                                    <dt>
                                        <p>Message</p>
                                    </dt>
                                    <dd>
                                        <p style={styles.message}>{message}</p>
                                    </dd>
                                </Box>
                            </DescriptionListStyled>
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <DescriptionListStyled>
                                <dt>
                                    <p>ID</p>
                                </dt>
                                <dd>
                                    <p>{id}</p>
                                </dd>
                                <dt>
                                    <p>Offer</p>
                                </dt>
                                <dd>
                                    <LabelWithLink
                                        label={helpFormOffer?.name}
                                        path={`/velocity/offers/${helpFormOffer?.id}`}
                                        id={lastXChar(helpFormOffer?.id, 4)}
                                        emptyLabel={'None'}
                                    />
                                </dd>
                                <dt>
                                    <p>Retailer</p>
                                </dt>
                                <dd>
                                    <p>{
                                        helpFormRetailer ? (
                                            <LabelWithLink
                                                label={helpFormRetailer?.name}
                                                path={`/velocity/retailers/${helpFormRetailer?.id}`}
                                                id={lastXChar(helpFormRetailer?.id, 4)}
                                                emptyLabel={'None'}
                                            />
                                        ) : ('N/A')
                                    }</p>
                                </dd>
                                <dt>
                                    <p>User Offer ID</p>
                                </dt>
                                <dd>
                                    <p>{user_offer_id || 'N/A'}</p>
                                </dd>
                                <dt>
                                    <p>Card Number</p>
                                </dt>
                                <dd>
                                    <p>{card_number || 'N/A'}</p>
                                </dd>
                                <dt>
                                    <p>Submitted</p>
                                </dt>
                                <dd>
                                    <p>{sanitizeDate(created_at)}</p>
                                </dd>
                                <dt>
                                    <p>Last Updated</p>
                                </dt>
                                <dd>
                                    <p>{sanitizeDate(updated_at)}</p>
                                </dd>
                            </DescriptionListStyled>
                        </Box>

                        <Box sx={styles.pageColumn}>
                            <CustomAccordion
                                title={
                                    <HelpFormRequestStatus status={status} variant='large' />
                                }
                                expandIcon={<EditIcon />}
                                label='help-request-status'
                            >
                                <EditStatusForm helpFormRequest={helpFormRequest} />
                            </CustomAccordion>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

const EditStatusForm = ({ helpFormRequest }) => {
    const styles = useStyles()

    const { id, status } = helpFormRequest

    const {
        formStatus,
        disabled,
        submitStatus,
    } = useEditHelpFormRequestStatus(id, status)

    return (
        <FormStyled
            noValidate
            autoComplete='off'
            style={styles.form}
            onSubmit={(e) => e.preventDefault()}
        >
            <FormColumnStyled>
                <AutocompleteStyled
                    autoComplete
                    style={styles.field}
                    disableClearable
                    data-cy='status'
                    options={HELP_REQUEST_STATUSES}
                    getOptionLabel={(value) => (value?.name).replaceAll('_', ' ') || ' '}
                    disabled={disabled}
                    value={HELP_REQUEST_STATUSES.find((type) => type.value === formStatus || '')}
                    onChange={submitStatus}
                    renderInput={(params) => (
                        <TextField {...params} label='Status' />
                    )}
                />
            </FormColumnStyled>
        </FormStyled>
    )
}

export default HelpFormRequestShow
