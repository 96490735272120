import { useEffect, useState } from 'react'
import { QueryCache, QueryClient } from '@tanstack/react-query'
import { queryCacheDefaults, queryOptionsDefaults } from 'queryHelpers'
import reportWebVitals from 'reportWebVitals'
import { useThemeSelector } from 'theme'
import { bootstrap } from 'utils'

const useStartup = (darkMode) => {
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(null)

    const { theme } = useThemeSelector(darkMode)

    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            ...queryCacheDefaults(),
        }),
        defaultOptions: {
            queries: {
                ...queryOptionsDefaults(),
            },
        },
    })

    useEffect(() => {
        bootstrap()
            .then(() => {
                setLoaded(true)
            })
            .catch((error) => {
                setError(error)
            })
            .finally(() => {
                setLoaded(true)

                // If you want to start measuring performance in your app, pass a function
                // to log results (for example: reportWebVitals(console.log))
                // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
                reportWebVitals()
            })
    }, [])

    return {
        loaded,
        error,
        theme,
        queryClient,
    }
}

export default useStartup
