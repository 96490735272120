
const useStyles = () => ({
    pageHeader: {
        marginBottom: '30px',
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '20px',
        '& h1': {
            margin: 0,
        },
        flexWrap: { xs: 'wrap', md: 'nowrap' },
    },
    pageRow: {
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
    },
    pageColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 15px',
        '&:nth-of-type(1)': {
            paddingLeft: 0,
        },
        '&:nth-of-type(4)': {
            flexGrow: 1,
            paddingRight: 0,
        },
    },
    btn: {
        width: 'auto',
    },
    cellLink: {
        textDecoration: 'none',
        color: (theme) => theme.palette.link.main,
        fontWeight: 600,
        '&:visited': {
            color: (theme) => theme.palette.link.visited,
        },
        cursor: 'pointer',
    },
    noResults: {
        padding: '20px 0',
    },
})

export default useStyles
