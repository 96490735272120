import {
    Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Paper, Typography,
} from '@mui/material'
import { lastXChar } from 'helpers'
import Row from 'components/Row'
import Cell from 'components/Cell'
import Filter from 'features/pagination_filtering/components/Filter'
import Pagination from 'features/pagination_filtering/components/Pagination'
import useStyles from './styles'
import CreateEmailContainmentForm from '../CreateEmailContainmentForm'

export const EmailContainmentTable = ({
    emails, variant, setShowModal, setSelectedEmail, createProps,
    filters, search, setSearch, refresh, updateFilter, disabled,
    pageState, pagination, setPage, isFetching, invalidateListQueries,
}) => {
    const styles = useStyles()

    return (
        <>
            <Filter
                pageState={pageState}
                filters={filters}
                pagination={pagination}
                setPage={setPage}
                updateFilter={updateFilter}
                // search={search}
                // setSearch={setSearch}
                resetFilters={invalidateListQueries}
                disabled={disabled}
                refresh={refresh}
            />
            <TableContainer component={Paper}>
                <Table aria-label='simple table' data-cy='table'>
                    <TableHead data-cy='head'>
                        <TableRow>
                            <TableCell align='center'>ID</TableCell>
                            <TableCell align='center' sx={{ textTransform: 'capitalize' }}>{variant}</TableCell>
                            <TableCell align='center'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy='body'>
                        <CreateEmailContainmentForm
                            variant={variant}
                            {...createProps}
                        />
                        {emails?.map(({ email, filter, id }) => (
                            <Row key={email || filter}>
                                <Cell align='center' noLink>{lastXChar(id, 8)}</Cell>
                                <Cell scope='email' align='left' noLink>{email || filter}</Cell>
                                <Cell scope='action' align='right' noLink>
                                    <Button
                                        type='button'
                                        data-cy=''
                                        variant='contained'
                                        sx={styles.btn('block')}
                                        onClick={() => {
                                            setSelectedEmail({ email, filter, id })
                                            setShowModal(true)
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {emails?.length === 0 && !isFetching &&
                <Typography sx={styles.noResults} variant='h3' align='center'>{`No email${variant === 'email' ? 's' : ' prefixes'} found.`}</Typography>
            }

            <Pagination
                pageState={pageState}
                pagination={pagination}
                setPage={setPage}
                disabled={isFetching}
            />
        </>
    )
}

export default EmailContainmentTable
