import { call, all, put, takeLatest } from 'redux-saga/effects'
import * as services from './services'
import {
    checkHealth,
    checkHealthResult,
    checkHealthError,
} from './reducers'

export default function* healthCheck() {
    yield all([
        watchCheckVersion(),
    ])
}

export function* watchCheckVersion() {
    yield takeLatest(checkHealth.toString(), takeCheckVersion)
}

export function* takeCheckVersion() {
    try {
        const result = yield call(services.fetchHealth)

        if (!result.success) {
            yield put(checkHealth())
        }

        yield put(checkHealthResult(result))
    } catch (error) {
        yield put(checkHealthError(error))
    }
}
