import { squashArr } from 'helpers'
import { useDeleteBannerRetailerCmd } from 'features/velocity/banner_retailers/queries'

export const useDeleteBannerRetailer = (parentID) => {
    const deleteResult = useDeleteBannerRetailerCmd()

    const onDeleteBannerRetailer = (bannerStoreID) => deleteResult.mutate({ parentID, bannerStoreID })

    const warnings = deleteResult.data?.warnings
    const notices = deleteResult.data?.notices
    const errors = squashArr([deleteResult.data?.errors, deleteResult?.error])

    const isLoadingState = deleteResult.isLoading

    return {
        deleteBannerRetailerWarnings: warnings,
        deleteBannerRetailerNotices: notices,
        deleteBannerRetailerErrors: errors,
        deleteBannerRetailerIsLoading: isLoadingState,
        onDeleteBannerRetailer,
    }
}
