import { LinkStyled } from 'theme/styled_components'
import useStyles from './style'

export const Hyperlink = ({ path, text }) => {
    const styles = useStyles()

    return (
        <LinkStyled style={styles.hyperlink} to={path}>{text}</LinkStyled>
    )
}

export default Hyperlink
