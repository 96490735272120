import React from 'react'
import { TableRow } from '@mui/material'
import { isEmpty } from 'helpers'

export const Row = ({ path, children, ...props }) => {
    const childrenWithRowPath = React.Children.toArray(children).map((child, index) =>
        index === 0 ? (
            React.cloneElement(child, {
                row_path: path,
                sx: isEmpty(child.props.sx) ? props.sx : child.props.sx,
                component: 'th',
            })
        ) : (
            React.cloneElement(child, {
                row_path: path,
                sx: isEmpty(child.props.sx) ? props.sx : child.props.sx,
            })
        ),
    )

    return (
        <TableRow {...props}>
            {childrenWithRowPath}
        </TableRow>
    )
}

export default Row
