import { useQueryOffers } from 'features/velocity/offers/queries'
import { reduceQueries } from 'helpers'

export const useOffers = () => {
    const {
        payloads: [offersPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [
            useQueryOffers({}, {}),
        ],
    )

    const offers = offersPayload?.offers || []

    return {
        connectionError,
        success,
        offers,
        errors,
        warnings,
        notices,
        ...isResults,
    }
}
