import { createSlice } from '@reduxjs/toolkit'

const name = 'image_modal'

const initialState = {
    image: {
        alt: null,
        url: null,
        title: null,
    },
    visible: false,
}

const reducers = {
    setImageModal: (state, { payload }) => {
        state.image = payload
        state.visible = true
    },
    dismissImageModal: () => initialState,
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    setImageModal,
    dismissImageModal,
} = slice.actions

export default slice.reducer
