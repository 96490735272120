import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import {
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material'
import {
    Visibility,
    VisibilityOff,
} from '@mui/icons-material'
import { useAuthenticate } from '../../hooks/authenticate'
import { LoginFormStyled, SubmitStyled, classesFn } from '../styles'
// import GoogleLogin from 'features/auth/components/GoogleAuth'

const LoginForm = () => {
    const classes = classesFn()
    // const [checked, setChecked] = useState(false)
    const [displayPassword, setDisplayPassword] = useState(false)
    const location = useLocation()

    const {
        form, disabled, authenticated,
        setUsername, setPassword, authenticate,
    } = useAuthenticate()

    const { username, password } = form

    // const handleCheckboxChange = (event) => setChecked(event.target.checked)
    if (authenticated) return <Navigate to={location?.state?.from || '/'} />

    return (
        <LoginFormStyled onSubmit={(e) => e.preventDefault()}>
            <TextField
                data-cy='email'
                required
                id='login-email-input'
                label='Email'
                value={username || ''}
                onChange={setUsername}
                disabled={disabled}
            />
            <TextField
                data-cy='password'
                required
                id='login-password-input'
                label='Password'
                type={displayPassword ? 'text' : 'password'}
                autoComplete='current-password'
                value={password || ''}
                onChange={setPassword}
                disabled={disabled}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                disableRipple={true}
                                size='small'
                                disabled={disabled}
                                onClick={() => setDisplayPassword(!displayPassword)}
                                onMouseDown={() => setDisplayPassword(!displayPassword)}
                            >
                                {displayPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <SubmitStyled
                data-cy='login-submit'
                type='submit'
                onClick={authenticate}
                variant='contained'
                disabled={disabled || !username || !password}
            >
                {disabled ? '...' : 'LOGIN'}
            </SubmitStyled>
            <div style={classes.actions}>
                <div style={classes.rememberMe}>
                    {/* <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        sx={classes.checkbox}
                    />
                    <Typography sx={classes.actionableItem}>{`Remember me`}</Typography> */}
                </div>
                {/*
                <Link to='/register' sx={classes.linkReset}>
                    <Typography sx={classes.actionableItem}>{'Register'}</Typography>
                </Link>
                <Link to='/forgot-password' sx={classes.linkReset}>
                    <Typography sx={classes.actionableItem}>{'Forgot your password?'}</Typography>
                </Link>
                */}
            </div>
            {/* <GoogleLogin /> */}
        </LoginFormStyled>
    )
}

export default LoginForm
