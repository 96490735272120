import BrandsList from 'features/brands/components/List'
import { useBrands } from 'features/brands/hooks/list'

const List = () => {
    const listBrandsResult = useBrands()

    return (
        <BrandsList
            {...listBrandsResult}
        />
    )
}

export default List
