import { colors } from 'theme'

const useStyles = () => ({
    livetrue: {
        background: (theme) => theme.palette.success.light,
    },
    livefalse: {
        background: colors.vizerCharcoal.light,
        color: colors.vizerCharcoal.dark,
    },
    status: {
        borderRadius: '6px',
        textAlign: 'center',
        fontSize: 16,
        padding: '8px',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        color: (theme) => theme.palette.mode === 'light' ? colors.vizerCharcoal.main : colors.white,
    },
})

export default useStyles
