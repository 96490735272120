import BacklogDeadletterCountsByDate from './BacklogDeadletterCountsByDate'
import Dashboard from './Dashboard'
import EventCounts from './EventCounts'
import EventCountsByDate from './EventCountsByDate'
import EventDeadletterCountsByDate from './EventDeadletterCountsByDate'

const EventsRoutes = {
    path: 'events',
    children: [
        {
            path: '',
            element: <Dashboard />,
        },
        {
            path: 'backlog_deadletter/counts_by_date',
            element: <BacklogDeadletterCountsByDate />,
        },
        {
            path: 'event_deadletter/counts_by_date',
            element: <EventDeadletterCountsByDate />,
        },
        {
            path: 'event_counts/by_date',
            element: <EventCountsByDate />,
        },
        {
            path: 'event_counts',
            element: <EventCounts />,
        },
    ],
}

export default EventsRoutes
