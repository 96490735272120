import { useEffect, useState } from 'react'

export const TOGGLED = 'TOGGLED'
export const DARK_MODE_ON = 'DARK_MODE_ON'

const useDarkMode = (darkModePreferred) => {
    const storedDarkModeSetting = localStorage.getItem(DARK_MODE_ON) === 'true' ? true : false
    const toggled = localStorage.getItem(TOGGLED)
    const [darkMode, setDarkMode] = useState(storedDarkModeSetting)

    const toggleDarkMode = () => {
        localStorage.setItem(TOGGLED, true)
        localStorage.setItem(DARK_MODE_ON, !darkMode)
        setDarkMode(!darkMode)
    }

    useEffect(() => {
        if (!toggled) {
            setDarkMode(darkModePreferred)
        } else {
            setDarkMode(storedDarkModeSetting)
        }
    }, [darkModePreferred, storedDarkModeSetting])

    return {
        darkMode,
        toggleDarkMode,
    }
}

export default useDarkMode
