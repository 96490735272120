import { UPDATE_FIELD, RESET_FORM } from 'features/status'
import { useEmailContainmentFormReducer } from 'features/velocity/email_containment/reducers'
import { emptyToNull } from 'helpers'

export const useEmailContainmentForm = (state) => {
    const [form, dispatch] = useEmailContainmentFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })
    const updateStatusField = (_, status) => dispatch({ type: UPDATE_FIELD, field: 'status', value: status.value })
    const resetForm = () => dispatch({ type: RESET_FORM })

    return {
        form,
        updateField,
        updateStatusField,
        resetForm,
    }
}
