import { Box } from '@mui/material'
import useStyles, { TypographyStyled } from './styles'

const DataTile = ({ total, subject, suffix, color, border, percentage }) => {
    const styles = useStyles()

    const localizedTotal = total?.toLocaleString()
    const totalDisplay = (percentage && localizedTotal) ? `${localizedTotal}%` : (localizedTotal || '-')

    return (
        <Box sx={styles.totalValueContainer({ color, border })}>
            <Box sx={styles.totalValue}>
                <TypographyStyled variant='h2' type='value' data-testid='total'>
                    {totalDisplay}
                </TypographyStyled>
                <TypographyStyled variant='h2' type='subject' data-testid='subject'>
                    {subject}
                </TypographyStyled>
                <TypographyStyled variant='h2' type='suffix' data-testid='suffix'>
                    {suffix}
                </TypographyStyled>
            </Box>
        </Box>
    )
}

export default DataTile
