import { UPDATE_FIELD, UPDATE_STATE, RESET_FORM } from 'features/status'
import { useReducer } from 'reducerHelpers'

export const sendEmailsFormInitialState = (state = {}) => {
    return {
        form: {
            csv_data: null,
            offer_id: null,
        },
        originalForm: null,
        warning: {},
        ...state,
    }
}

export const sendEmailsFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state.form[`${action.field}`] = action.value

            break
        }
        case UPDATE_STATE: {
            state[`${action.field}`] = action.value

            break
        }
        case RESET_FORM: {
            return sendEmailsFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useSendEmailsFormReducer = (state = {}) => {
    return useReducer(sendEmailsFormReducer, sendEmailsFormInitialState(state))
}
