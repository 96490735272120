import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useMutationDefaults, useQueryDefaults } from 'queryHelpers'
import { useAdminUserForm } from 'features/admin_users/hooks/form'
import { createNewAdminUser, fetchAdminUsers } from 'features/admin_users/services'

export const useCreateAdminUserCmd = () => {
    const navigate = useNavigate()
    const { resetForm } = useAdminUserForm()

    return useMutation({
        mutationFn: (form) => createNewAdminUser(form),
        ...useMutationDefaults({
            queryKeys: [['admin_users', 'list']],
            queryType: ['admin_users', 'create'],
            reset: resetForm,
        }),
        onSuccess: (data) => {
            if (data?.success) navigate('/admin/users')
        },
    })
}

export const useQueryAdminUsers = (pageQuery, queryArgs = {}) => {
    const queryKey = ['admin_users', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchAdminUsers(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}
