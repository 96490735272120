import { Divider, Typography } from '@mui/material'
import InCommDebug from 'features/velocity/incomm_debug/components/InCommDebug'
import CustomerLookup from 'features/velocity/incomm_debug/components/CustomerLookup'
import { useCustomerActivity } from 'features/velocity/incomm_debug/hooks/customer_activity'
import { useCustomerLookup } from 'features/velocity/incomm_debug/hooks/customer_lookup'
import useStyles from './styles'

const InCommDebugPage = () => {
    const styles = useStyles()
    const customerActivityResult = useCustomerActivity()
    const customerLookupResult = useCustomerLookup()

    return (
        <>
            <Typography variant='h1' align='center'>InComm Data for Debug</Typography>

            <CustomerLookup {...customerLookupResult} />

            <Divider sx={styles.divider}></Divider>

            <InCommDebug {...customerActivityResult} />
        </>
    )
}

export default InCommDebugPage
