import { useQuery, useMutation } from '@tanstack/react-query'
import { useMutationDefaults, useQueryDefaults } from 'queryHelpers'
import {
    fetchAllowedDomains,
    fetchBlockedDomains,
    fetchPendingDomains,
    blockDomain,
    allowDomain,
    createNewDomain,
} from '../services'
import { useDomainForm } from '../hooks/form'

export const useQueryAllowedDomains = (pageQuery, queryArgs = {}) => {
    const queryKey = ['domain_filters', 'allowed', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchAllowedDomains(pageQuery),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryBlockedDomains = (pageQuery, queryArgs = {}) => {
    const queryKey = ['domain_filters', 'blocked', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchBlockedDomains(pageQuery),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryPendingDomains = (pageQuery, queryArgs = {}) => {
    const queryKey = ['domain_filters', 'pending', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchPendingDomains(pageQuery),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useBlockDomainCmd = () => (
    useMutation({
        mutationFn: (domainID) => blockDomain(domainID),
        ...useMutationDefaults({
            queryKeys: [
                ['domain_filters'],
            ],
            queryType: ['domain_filters', 'block'],
        }),
    })
)

export const useAllowDomainCmd = () => (
    useMutation({
        mutationFn: (domainID) => allowDomain(domainID),
        ...useMutationDefaults({
            queryKeys: [
                ['domain_filters'],
            ],
            queryType: ['domain_filters', 'allow'],
        }),
    })
)

export const useCreateDomainCmd = () => {
    const { resetForm } = useDomainForm()

    return useMutation({
        mutationFn: (form) => createNewDomain(form),
        ...useMutationDefaults({
            queryKeys: [
                ['domain_filters'],
            ],
            queryType: ['domain_filters', 'create'],
            reset: resetForm,
        }),
    })
}
