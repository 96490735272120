import { Button, Typography } from '@mui/material'
import { FormColumnStyled, FormStyled, SubmitBlockStyled, TextFieldStyled } from 'theme/forms'
import { PageStyled } from 'theme/page'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import MultiSelect from 'components/MultiSelect'
import useStyles from './styles'

export const ClientPortalUserForm = ({
    edit, offerPartners, loadSuccess, connectionError, errors, isLoadingState,
    isLoadError, disabled, form, updateField, updateOfferPartnersField, onSubmit,
}) => {
    const styles = useStyles()

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isLoadError) return <ConnectionError err={errors} />

    if (!loadSuccess) return <Loading />

    return (
        <PageStyled>
            <Typography variant='h1'>{edit ? 'Edit' : 'Create'} Client Portal User</Typography>

            <IsLoading isLoadingState={isLoadingState} />

            <FormStyled
                noValidate
                autoComplete='off'
                onSubmit={(e) => e.preventDefault()}
                data-testid='client-portal-users-form'
            >
                <FormColumnStyled>
                    {!edit &&
                        <>
                            <TextFieldStyled
                                label='First Name'
                                type='text'
                                data-cy='enter-first-name'
                                style={styles.field}
                                disabled={disabled}
                                value={form?.first_name || ''}
                                onChange={updateField('first_name')}
                            />
                            <TextFieldStyled
                                label='Last Name'
                                type='text'
                                data-cy='enter-last-name'
                                style={styles.field}
                                disabled={disabled}
                                value={form?.last_name || ''}
                                onChange={updateField('last_name')}
                            />
                            <TextFieldStyled
                                label='Email'
                                type='email'
                                data-cy='enter-email'
                                style={styles.field}
                                disabled={disabled}
                                value={form?.email || ''}
                                onChange={updateField('email')}
                            />
                        </>
                    }
                    <MultiSelect
                        filterOptions={(options, _) => (
                            options.filter((option) => !form?.offer_partners.map((op) => op.id).includes(option.id))
                        )}
                        options={offerPartners}
                        value={form?.offer_partners}
                        label='Offer Partners'
                        onChange={updateOfferPartnersField}
                        disabled={disabled}
                        style={styles.field}
                    />
                </FormColumnStyled >

                <SubmitBlockStyled style={styles.formSubmit}>
                    <Button
                        type='submit'
                        data-cy='submit-user'
                        variant='contained'
                        color='secondary'
                        sx={styles.btn}
                        disabled={disabled}
                        onClick={onSubmit}
                    >
                        {edit ? 'Update User' : 'Invite User'}
                    </Button>
                </SubmitBlockStyled>
            </FormStyled >
        </PageStyled >
    )
}

export default ClientPortalUserForm
