import { useEffect } from 'react'
import { reduceQueries, useInvalidateQuery } from 'helpers'
import { useQueryDomainsByOffer } from 'features/velocity/offers/queries'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'

export const useDomainsByOffer = (offerID, state = {}) => {
    const {
        setPage,
        resetPage,
        params,
        pageState,
        paginationQueryArgs,
        updateFilter,
    } = usePaginationFilters({ state, debounceSkipKeys: ['attempt_threshold'] })

    const {
        payloads: [domainsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [
            useQueryDomainsByOffer(offerID, params, paginationQueryArgs),
        ],
    )

    const invalidateListQueries = useInvalidateQuery(['domains_by_offer', 'list', offerID])

    const domains = domainsPayload?.domains || []
    const pagination = domainsPayload?.pagination || {}

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    return {
        connectionError,
        success,
        domains,
        errors,
        warnings,
        notices,
        pagination,
        setPage,
        pageState,
        invalidateListQueries,
        updateFilter,
        ...isResults,
    }
}
