import { createSlice } from '@reduxjs/toolkit'
import {
    UPDATING,
    UPDATED,
    ERROR,
    PENDING,
    LOADED,
} from 'features/status'

const name = 'permissionEdit'

const initialState = {
    status: PENDING,
    id: null,
    hasLoaded: false,
    permission: {
        realm_resource_action: null,
    },
    error: null,
}

const reducers = {
    setFormField: (state, { payload }) => {
        const form = state.permission
        const { field, value } = payload

        const permission = {
            ...form,
            [field]: value,
        }

        return { ...state, permission }
    },
    editPermission: (state, { payload }) => {
        state.id = payload
    },
    editPermissionResult: (state, { payload }) => {
        const { permission } = payload

        state.statue = LOADED
        state.hasLoaded = true
        state.permission = permission
    },
    editPermissionError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    updatePermission: (state) => {
        state.status = UPDATING
    },
    updatePermissionResult: (state, { payload }) => {
        const { permission } = payload

        state.status = UPDATED
        state.permission = permission
    },
    updatePermissionError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetForm: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    setFormField,
    editPermission,
    editPermissionResult,
    editPermissionError,
    updatePermission,
    updatePermissionResult,
    updatePermissionError,
    resetForm,
} = slice.actions

export default slice.reducer
