const useStyles = () => ({
    btn: {
        width: 'auto',
        marginBottom: '20px',
    },
    row: {
        textDecoration: 'none',
        color: (theme) => theme.palette.text.primary,
    },
    noResults: {
        padding: '20px 0',
    },
    input: {
        width: 'auto',
        minWidth: 215,
        marginRight: { xs: 0, sm: '10px' },
        marginBottom: '10px',
        '& .MuiInputLabel-root': {
            textTransform: 'capitalize',
        },
    },
})

export default useStyles
