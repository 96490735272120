import { reduceQueries } from 'helpers'
import { useQueryAllowedDomains } from 'features/velocity/domains/queries'

export const useAllowedDomains = ({ params, paginationQueryArgs }) => {
    const {
        payloads: [allowedDomainsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryAllowedDomains(params, paginationQueryArgs)],
    )

    const domains = allowedDomainsPayload?.allowed_domains || []
    const pagination = allowedDomainsPayload?.pagination || {}

    return {
        connectionError,
        success,
        domains,
        errors,
        warnings,
        notices,
        pagination,
        ...isResults,
    }
}
