import { useReducer } from 'reducerHelpers'
import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'

export const clientPortalUserFormInitialState = (state = {}) => {
    return {
        first_name: null,
        last_name: null,
        email: null,
        offer_partners: [],
        ...state,
    }
}

export const clientPortalUserFormReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FIELD: {
            state[`${action.field}`] = action.value

            break
        }
        case SET_FORM: {
            return action.value
        }
        case RESET_FORM: {
            return clientPortalUserFormInitialState()
        }
        default: {
            return state
        }
    }
}

export const useClientPortalUserFormReducer = (state = {}) => {
    return useReducer(clientPortalUserFormReducer, clientPortalUserFormInitialState(state))
}
