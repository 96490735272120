import { velocityUrl, _get, _post } from 'utils'

export async function fetchOfferRetailersByOfferID(offerID) {
    try {
        const url = await velocityUrl(`/retailers/queries/list_retailers_by_offer_id/${offerID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchOfferRetailerShow(offerID, retailerID) {
    try {
        const url = await velocityUrl(`/retailers/queries/fetch_offer_retailer/${offerID}/${retailerID}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function generateStripImages(offerRetailerId) {
    try {
        const url = await velocityUrl(`/offer_retailers/commands/generate_strip_images/${offerRetailerId}`)

        return await _post(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
