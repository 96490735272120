import { useQueryEventDashboardCounts } from 'features/velocity/events/queries'
import { reduceQueries } from 'helpers'

export const useEventDashboard = () => {
    const {
        payloads: [eventsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryEventDashboardCounts()],
    )

    console.log(eventsPayload)

    const eventCounts = {
        ...eventsPayload,
    }

    return {
        connectionError,
        success,
        eventCounts,
        errors,
        warnings,
        notices,
        ...isResults,
    }
}
