
const useStyles = () => ({
    toolbar: {
        margin: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    btnGroup: {
        margin: 0,
        width: 400,
        display: 'flex',
        flexDirection: 'column',
    },
    btn: {
        width: 'auto',
    },
    formLabel: {
        marginBottom: '15px',
    },
})

export default useStyles
