import { useState } from 'react'
import {
    AppBar, Box, Card, Button,
    Tab, Tabs, Typography,
} from '@mui/material'
import { TabPanel } from 'components/TabPanel'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'
import { Allowed } from 'features/velocity/domains/components/TableContainers/Allowed'
import { Blocked } from 'features/velocity/domains/components/TableContainers/Blocked'
import { Pending } from 'features/velocity/domains/components/TableContainers/Pending'
import ConfirmationModal from '../ConfirmationModal'
import CreateFormModal from '../CreateFormModal'
import { useCreateDomain } from '../../hooks/create'
import useStyles from './styles'

export const Domains = () => {
    const styles = useStyles()
    const [selectedDomain, setSelectedDomain] = useState()
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showCreateFormModal, setShowCreateFormModal] = useState(false)
    const [activeTab, setActiveTab] = useState(0)

    const paginationFilters = usePaginationFilters({ debounceSkipKeys: ['attempt_threshold'] })

    const switchTabs = (i) => {
        paginationFilters.setPreventDebounce()
        paginationFilters.resetFilters()
        setActiveTab(i)
    }

    const createParams = useCreateDomain({ setShowCreateFormModal, switchTabs })

    return (
        <Box sx={styles.page}>
            <ConfirmationModal
                showModal={showConfirmationModal}
                setShowModal={setShowConfirmationModal}
                selectedDomain={selectedDomain}
                setSelectedDomain={setSelectedDomain}
            />

            <CreateFormModal
                showModal={showCreateFormModal}
                setShowModal={setShowCreateFormModal}
                {...createParams}
            />

            <Typography variant='h1' align='center'>
                Domains
            </Typography>

            <Button
                sx={styles.btn}
                variant="contained"
                color="secondary"
                data-cy='new-domain-btn'
                onClick={() => setShowCreateFormModal(true)}
            >
                New
            </Button>

            <AppBar sx={styles.tabs} position="static">
                <Tabs value={activeTab} classes={{ indicator: styles.indicator }}>
                    <Tab sx={styles.tab} label="Pending" onClick={() => switchTabs(0)} />
                    <Tab sx={styles.tab} label="Blocked" onClick={() => switchTabs(1)} />
                    <Tab sx={styles.tab} label="Allowed" onClick={() => switchTabs(2)} />
                </Tabs>
            </AppBar>

            <Card sx={styles.detailsCard}>
                <TabPanel value={activeTab} index={0} noPadding>
                    <Box sx={styles.tabContainer}>
                        <Typography variant='h2' align='center'>
                            Pending Domains
                        </Typography>
                        <Pending
                            setShowModal={setShowConfirmationModal}
                            setSelectedDomain={setSelectedDomain}
                            {...paginationFilters}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value={activeTab} index={1} noPadding>
                    <Box sx={styles.tabContainer}>
                        <Typography variant='h2' align='center'>
                            Blocked Domains
                        </Typography>
                        <Blocked
                            setShowModal={setShowConfirmationModal}
                            setSelectedDomain={setSelectedDomain}
                            {...paginationFilters}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value={activeTab} index={2} noPadding>
                    <Box sx={styles.tabContainer}>
                        <Typography variant='h2' align='center'>
                            Allowed Domains
                        </Typography>
                        <Allowed
                            setShowModal={setShowConfirmationModal}
                            setSelectedDomain={setSelectedDomain}
                            {...paginationFilters}
                        />
                    </Box>
                </TabPanel>
            </Card>
        </Box>
    )
}

export default Domains
