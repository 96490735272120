
const useStyles = () => ({
    btn: {
        fontWeight: 500,
        '& svg': {
            marginLeft: '10px',
        },
    },
})

export default useStyles
