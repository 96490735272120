import { useEffect } from 'react'
import { squashArr, reduceQueries } from 'helpers'
import { useUpdateBrandCmd } from 'features/brands/queries'
import { useQueryBrandShow } from 'features/brands/queries'
import { useBrandForm } from 'features/brands/hooks/form'

export const useEditBrand = (id) => {
    const {
        form,
        updateField,
        updateImageField,
        updateWYSIWYG,
        setForm,
    } = useBrandForm()

    const {
        payloads: [brandPayload],
        success, connectionError,
        brandNotices, brandWarnings, brandErrors,
        brandIsResults,
    } = reduceQueries(
        [useQueryBrandShow(id)],
    )

    const isLoadError = brandIsResults?.isError
    const loadSuccess = success

    const updateResult = useUpdateBrandCmd()
    const onSubmit = () => updateResult.mutate({ id, form })

    const warnings = squashArr([brandWarnings, updateResult.data?.warnings])
    const notices = squashArr([brandNotices, updateResult.data?.notices])
    const errors = squashArr([brandErrors, updateResult.error])

    const isLoadingState = brandIsResults?.isLoading ||
        brandIsResults?.isFetching ||
        brandIsResults?.isRefetching ||
        brandIsResults?.isInitialLoading ||
        updateResult?.isLoading
    const isDisabled = brandIsResults?.isLoading || updateResult?.isLoading

    useEffect(() => {
        if (loadSuccess) {
            setForm(brandPayload.brand)
        }
    }, [loadSuccess, brandPayload])

    return {
        loadSuccess,
        connectionError,
        errors,
        warnings,
        notices,
        isLoadError,
        isLoadingState,
        disabled: isDisabled,
        form,
        updateField,
        updateImageField,
        updateWYSIWYG,
        onSubmit,
    }
}
