import { colors } from 'theme'

const useStyles = () => ({
    text: {
        display: 'block',
        maxHeight: 50,
        overflow: 'hidden',
        fontSize: '.85rem',
        margin: 0,
    },
    textExpanded: {
        display: 'block',
        fontSize: '.85rem',
        maxHeight: '100%',
        overflow: 'visible',
        margin: 0,
        '& + div': {
            display: 'none',
        },
    },
    expandBtn: {
        fontSize: '.85rem',
        display: 'block',
        color: colors.vizerCoral.main,
        cursor: 'pointer',
        zIndex: 2,
        position: 'relative',
    },
    expandContainer: {
        position: 'relative',
    },
    expandBG: {
        background: (theme) => `linear-gradient(0deg, ${theme.palette.background.dark} 35%, rgba(255, 255, 255, 0) 60%)`,
        position: 'absolute',
        left: 0,
        bottom: 0,
        zIndex: 1,
        width: '100%',
        display: 'block',
        height: '100%',
    },
})

export default useStyles
