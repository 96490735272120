import { createSlice } from '@reduxjs/toolkit'
import {
    PENDING,
    FETCHING,
    SUCCESS,
    ERROR,
} from 'features/status'

const name = 'usersList'

const initialState = {
    status: PENDING,
    hasLoaded: false,
    users: [],
    error: null,
}

const reducers = {
    fetchUsers: (state) => {
        state.status = FETCHING
    },
    fetchUsersResult: (state, { payload }) => {
        const { users } = payload

        state.status = SUCCESS
        state.hasLoaded = true
        state.users = users
    },
    fetchUsersError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetUsers: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    fetchUsers,
    fetchUsersResult,
    fetchUsersError,
    resetUsers,
} = slice.actions

export default slice.reducer
