import { useEffect } from 'react'
import { reduceQueries, useInvalidateQuery } from 'helpers'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'
import { useQueryHelpFormRequests } from 'features/velocity/help_form_requests/queries'

export const useHelpFormRequests = () => {
    const debounceSkipKeys = ['help_form_request_status']

    const {
        setPage,
        resetPage,
        updateFilter,
        resetFilters,
        params,
        pageState,
        paginationQueryArgs,
        resetFiltersDisabled: disabled,
    } = usePaginationFilters({
        state: { filters: { help_form_request_status: 'pending' } },
        debounceSkipKeys,
    })

    const {
        payloads: [helpFormRequestsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [useQueryHelpFormRequests(params, paginationQueryArgs)],
    )

    const invalidateListQueries = useInvalidateQuery(['help_form_requests', 'list'])

    const resetFiltersDisabled = isResults.isFetching || disabled

    const helpFormRequests = helpFormRequestsPayload?.help_form_requests || []
    const filters = helpFormRequestsPayload?.filters || {}
    const pagination = helpFormRequestsPayload?.pagination || {}

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    return {
        connectionError,
        success,
        helpFormRequests,
        errors,
        warnings,
        notices,
        filters,
        pagination,
        setPage,
        updateFilter,
        resetFilters,
        pageState,
        invalidateListQueries,
        resetFiltersDisabled,
        ...isResults,
    }
}
