const useStyles = () => ({
    formLabel: {
        marginTop: '15px',
        marginBottom: '10px',
    },
    field: {
        width: '100%',
        minWidth: 0,
    },
    btn: {
        margin: '0 5px',
        width: 100,
    },
})

export default useStyles
