import { squashArr } from 'helpers'
import { useDeleteBlockedEmailPrefixCmd } from '../queries'

export const useDeleteBlockedEmailPrefix = () => {
    const deleteResult = useDeleteBlockedEmailPrefixCmd()
    const onDeleteSubmit = (emailID) => deleteResult.mutate(emailID)

    const warnings = squashArr([deleteResult.data?.warnings])
    const notices = squashArr([deleteResult.data?.notices])
    const errors = squashArr([deleteResult.error, deleteResult.data?.errors])

    const isLoadingState = deleteResult.isPending

    return {
        isLoadingState,
        errors,
        warnings,
        notices,
        onDeleteSubmit,
    }
}
