import Modal from 'react-modal'
import { Box, Button, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useDisablerTimeout } from 'helpers'
import { DescriptionListStyled } from 'theme/styled_components'
import Progress from 'components/ProgressCircle'
import { useBlockDomain } from '../../hooks/block_domain'
import { useAllowDomain } from '../../hooks/allow_domain'
import useStyles, { customModalStyles } from './styles'

export const ConfirmationModal = ({ showModal, setShowModal, selectedDomain, setSelectedDomain }) => {
    const styles = useStyles()
    const { domain, id, count, pendingFlow } = selectedDomain || {}
    const sharedProps = { styles, domain, id, count, setShowModal, setSelectedDomain }

    Modal.setAppElement('#root')

    return (
        <Modal
            isOpen={!!showModal}
            onRequestClose={() => setShowModal(false)}
            style={customModalStyles}
            contentLabel="Confirm Modal"
        >
            <Button
                variant='contained'
                onClick={() => setShowModal(false)}
                sx={styles.closeButton}
            >
                <ClearIcon />
            </Button>
            <div style={styles.page}>
                <Box sx={styles.container}>
                    {showModal === 'pending' &&
                        <PendingContent {...sharedProps} />
                    }
                    {showModal === 'block' &&
                        <BlockContent {...sharedProps} />
                    }
                    {showModal === 'allow' &&
                        <AllowContent
                            pendingFlow={pendingFlow}
                            {...sharedProps}
                        />
                    }
                </Box>
            </div>
        </Modal>
    )
}

const PendingContent = ({ styles, domain, count, setShowModal }) => (
    <>
        <Typography variant='h2' align='center'>How would you like to handle this domain?</Typography>

        <DescriptionListStyled style={{ marginRight: 'auto' }}>
            <dt>
                <p>Domain</p>
            </dt>
            <dd>
                <p>{domain}</p>
            </dd>
            <dt>
                <p>Attempts</p>
            </dt>
            <dd>
                <p>{count}</p>
            </dd>
        </DescriptionListStyled>
        <Box sx={styles.buttonContainer}>
            <Button
                type='button'
                data-cy=''
                variant='contained'
                color='secondary'
                sx={styles.btn('block')}
                onClick={() => setShowModal('block')}
            >
                Block
            </Button>
            <Button
                type='button'
                data-cy=''
                variant='contained'
                color='secondary'
                sx={styles.btn('allow')}
                onClick={() => setShowModal('allow')}
            >
                Allow
            </Button>
        </Box>
    </>
)

const BlockContent = ({ styles, domain, id, count, setShowModal, setSelectedDomain }) => {
    const disabled = useDisablerTimeout(3000)

    const { onBlockSubmit } = useBlockDomain()

    return (
        <>
            <div>
                <Typography variant='h2' align='center'>
                    Are you sure you want to block <span style={{ ...styles.span, ...styles.blockSpan }}>{domain}</span>?
                </Typography>
                <Typography variant='h2' align='center'>
                    This affects <span style={{ ...styles.span, ...styles.blockSpan }}>{count}</span> attempts.
                </Typography>
            </div>
            <Box sx={styles.buttonContainer}>
                <Button
                    variant='contained'
                    color='secondary'
                    sx={styles.btn()}
                    onClick={() => setShowModal(false)}
                >
                    No, Cancel
                </Button>
                <Button
                    disabled={disabled}
                    variant='contained'
                    color='secondary'
                    sx={styles.btn('block')}
                    onClick={() => {
                        onBlockSubmit(id)
                        setSelectedDomain()
                        setShowModal(false)
                    }}
                >
                    {disabled ? <Progress interval={60} /> : 'Yes, Block'}
                </Button>
            </Box>
        </>
    )
}

const AllowContent = ({ styles, pendingFlow, domain, id, count, setShowModal, setSelectedDomain }) => {
    const disabled = useDisablerTimeout(3000)

    const { onAllowSubmit } = useAllowDomain()

    return (
        <>
            <div>
                <Typography variant='h2' align='center'>
                    Are you sure you want to allow <span style={{ ...styles.span, ...styles.allowSpan }}>{domain}</span>?
                </Typography>
                <Typography variant='h2' align='center'>
                    {pendingFlow ? (
                        <>
                            <span style={{ ...styles.span, ...styles.allowSpan }}>
                                {count}
                            </span> attempts associated with this domain will receive emailed offers.
                        </>
                    ) : (
                        <>
                            This affects <span style={{ ...styles.span, ...styles.allowSpan }}>{count}</span> attempts.
                        </>

                    )}
                </Typography>
            </div>

            <Box sx={styles.buttonContainer}>
                <Button
                    variant='contained'
                    color='secondary'
                    sx={styles.btn()}
                    onClick={() => setShowModal(false)}
                >
                    No, Cancel
                </Button>
                <Button
                    disabled={disabled}
                    variant='contained'
                    color='secondary'
                    sx={styles.btn('allow')}
                    onClick={() => {
                        onAllowSubmit(id)
                        setSelectedDomain()
                        setShowModal(false)
                    }}
                >
                    {disabled ? <Progress interval={60} /> : 'Yes, Allow'}
                </Button>
            </Box>
        </>
    )
}

export default ConfirmationModal
