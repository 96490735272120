import { takeEvery, put, select } from 'redux-saga/effects'
import {
    updateExistingRole,
    fetchRoleShow,
} from '../services'
import {
    editRole,
    editRoleResult,
    editRoleError,
    updateRole,
    updateRoleResult,
    updateRoleError,
} from '../reducers/edit'

export function* takeEditRole(action) {
    try {
        const { payload } = yield fetchRoleShow(action.payload)

        yield put(editRoleResult(payload))
    } catch (error) {
        yield put(editRoleError(error.toString()))
    }
}

export function* watchEditRole() {
    yield takeEvery(editRole.toString(), takeEditRole)
}

export function* takeUpdateRole() {
    try {
        const id = yield select((state) => state.roles.edit.id)
        const form = yield select((state) => state.roles.edit.role)

        const { payload } = yield updateExistingRole(id, form)

        yield put(updateRoleResult({ role: payload }))
    } catch (error) {
        yield put(updateRoleError(error.toString()))
    }
}

export function* watchUpdateRole() {
    yield takeEvery(updateRole.toString(), takeUpdateRole)
}
