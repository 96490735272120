import { useContext } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { AuthContext } from 'contexts'
import Loading from 'components/Loading'
import { LOGGED_IN, LOGGED_OUT } from 'features/auth/states'

const ProtectedRoute = () => {
    const { status } = useContext(AuthContext)

    if (status === LOGGED_OUT) return <Navigate to='/login' />

    if (status === LOGGED_IN) return <Outlet />

    return <Loading absolute />
}

export default ProtectedRoute
