import { Box, Card, Typography } from '@mui/material'
import useStyles from './styles'

export const ClientPortalContent = ({ client_portal_resource_content, client_portal_success_center_content }) => {
    const styles = useStyles()

    return (
        <Box sx={styles.content}>
            {client_portal_resource_content &&
                <Box sx={styles.exampleContainer}>
                    <Typography variant='h2'>Resources</Typography>

                    <Card sx={styles.contentCard}>
                        <div id='iframe' style={styles.contentContainer}>
                            <iframe
                                style={styles.iframe}
                                srcDoc={`
                                <html style="font-family: Avenir, Open-Sans, sans-serif; scrollbar-color: #ff6969 rgba(255, 105, 105, 0.15)">
                                    <body>${client_portal_resource_content}</body>
                                    <style>
                                        html ::-webkit-scrollbar { width: 11px; }
                                        body { padding: 0 10px; }
                                        ::-webkit-scrollbar-thumb { background: #ff6969; border-radius: 10px; }
                                        ::-webkit-scrollbar-track-piece { background: rgba(255, 105, 105, 0.15) }
                                        a:link { color: #ff6969 }
                                        a:visited { color: #ff6969 }
                                    </style>
                                </html>
                            `}
                            />
                        </div>
                    </Card>
                </Box>
            }

            {client_portal_success_center_content &&
                <Box sx={styles.exampleContainer}>
                    <Typography variant='h2'>Success Center</Typography>

                    <Card sx={styles.contentCard}>
                        <div id='iframe' style={styles.contentContainer}>
                            <iframe
                                style={styles.iframe}
                                srcDoc={`
                                <html style="font-family: Avenir, Open-Sans, sans-serif; scrollbar-color: #ff6969 rgba(255, 105, 105, 0.15)">
                                    <body>${client_portal_success_center_content}</body>
                                    <style>
                                        html ::-webkit-scrollbar { width: 11px; }
                                        body { padding: 0 10px; }
                                        ::-webkit-scrollbar-thumb { background: #ff6969; border-radius: 10px; }
                                        ::-webkit-scrollbar-track-piece { background: rgba(255, 105, 105, 0.15) }
                                        a:link { color: #ff6969 }
                                        a:visited { color: #ff6969 }
                                    </style>
                                </html>
                            `}
                            />
                        </div>
                    </Card>
                </Box>
            }
        </Box>
    )
}

export default ClientPortalContent
