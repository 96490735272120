import qs from 'qs'
import { velocityUrl, _get, _post, _delete } from 'utils'

export async function fetchAllowedEmails(pageQuery) {
    try {
        const url = await velocityUrl(`/allowed_emails?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function createAllowedEmail({ email }) {
    try {
        const url = await velocityUrl('/allowed_emails')

        const body = { email }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function deleteAllowedEmail(id) {
    try {
        const url = await velocityUrl(`/allowed_emails/${id}`)

        return await _delete(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function fetchBlockedEmailPrefixes(pageQuery) {
    try {
        const url = await velocityUrl(`/email_prefixes?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function createBlockedEmailPrefix({ email_prefix }) {
    try {
        const url = await velocityUrl('/email_prefixes')

        const body = { filter: email_prefix }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function deleteBlockedEmailPrefix(id) {
    try {
        const url = await velocityUrl(`/email_prefixes/${id}`)

        return await _delete(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
