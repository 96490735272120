import { colors } from 'theme'

const useStyles = () => ({
    status: ({ status, variant }) => ({
        borderRadius: '6px',
        textAlign: 'center',
        width: '100%',
        maxWidth: 250,
        minWidth: 200,
        margin: '0 10px 0',
        fontSize: variant === 'large' ? 24 : 'inherit',
        padding: variant === 'large' ? '13px 15px 9px' : '5px 15px',
        fontWeight: 600,
        display: 'flex',
        background: getBG(status),
        color: status === 'pending' ? colors.vizerCharcoal.dark : colors.white,
    }),
    centered: {
        margin: 'auto',
    },
})

const getBG = (status) => {
    if (status === 'pending') return colors.warning.main
    if (status === 'viewed') return colors.vizerOrange.main
    if (status === 'in_progress') return colors.vizerPeacock.main
    if (status === 'resolved') return colors.info.main
    if (status === 'duplicate') return colors.vizerOrchid.main
}

export default useStyles
