import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { useBacklogDeadletterCountsByDate } from 'features/velocity/events/hooks/backlog_deadletters'
import useStyles from './styles'

const BacklogDeadletterCountsByDate = () => {
    const styles = useStyles()
    const navigate = useNavigate()
    const params = useBacklogDeadletterCountsByDate()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <Typography variant='h2'>Backlog Deadletter Count By Date</Typography>
        </>
    )
}

export default BacklogDeadletterCountsByDate
