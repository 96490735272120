import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import * as buttons from './buttons'
import * as colors from './colors'
import * as inputs from './inputs'
import * as typography from './typography'

const useThemeSelector = (darkMode = false) => {
    let theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1700,
                xxl: 1920,
            },
        },
        palette: {
            mode: darkMode ? 'dark' : 'light',
            error: {
                main: colors.error.main,
                dark: colors.error.dark,
                light: colors.error.light,
            },
            info: {
                main: colors.info.main,
                dark: colors.info.dark,
                light: colors.info.light,
            },
            primary: {
                main: colors.vizerCoral.main,
                dark: colors.vizerCoral.dark,
                light: colors.vizerCoral.light,
            },
            default: {
                main: darkMode ? '#212121' : '#f5f5f5',
                dark: colors.vizerCoral.dark,
                light: colors.vizerCoral.light,
            },
            secondary: {
                main: darkMode ? colors.vizerCoral.main : colors.vizerCharcoal.main,
                dark: darkMode ? colors.vizerCoral.dark : colors.vizerCharcoal.dark,
                light: colors.vizerCharcoal.light,
            },
            success: {
                main: colors.success.main,
                dark: colors.success.dark,
                light: darkMode ? colors.success.light : colors.success.lighter,
            },
            text: {
                primary: darkMode ? colors.white : colors.vizerCharcoal.dark,
                secondary: colors.vizerSlateGray.dark,
                disabled: colors.disabled,
            },
            warning: {
                main: colors.warning.main,
                dark: colors.warning.dark,
                light: darkMode ? colors.warning.light : colors.warning.lighter,
            },
            hover: {
                main: darkMode ? colors.vizerCharcoal.main : colors.vizerIceGray.main,
            },
            selected: {
                main: darkMode ? colors.vizerCharcoal.light : colors.lightGray.main,
            },
            overlay: {
                main: darkMode ? colors.vizerCharcoal.light : colors.vizerSlateGray.light,
            },
            background: {
                default: darkMode ? colors.vizerCharcoal.main : colors.lightGray.lighter,
                dark: darkMode ? colors.vizerCharcoal.dark : colors.vizerCharcoal.main,
                paper: darkMode ? colors.vizerCharcoal.default : colors.white,
            },
            icons: {
                main: darkMode ? colors.white : colors.vizerCharcoal.main,
                dark: darkMode ? colors.white : colors.vizerCharcoal.dark,
                hover: colors.vizerCoral.main,
            },
            iconText: {
                main: darkMode ? colors.white : colors.vizerCharcoal.main,
                dark: darkMode ? colors.white : colors.vizerSlateGray.dark,
                light: darkMode ? colors.white : colors.vizerSlateGray.main,
            },
            link: {
                main: darkMode ? colors.vizerCoral.main : colors.vizerIndigo.dark,
                visited: darkMode ? colors.vizerOrange.main : colors.vizerOrchid.dark,
            },
            searchBar: {
                main: darkMode ? colors.vizerCharcoal.main : colors.vizerIceGray.light,
            },
            searchIcon: {
                main: darkMode ? colors.white : colors.vizerCharcoal.dark,
            },
            footerBorderTop: {
                main: darkMode ? colors.vizerSlateGray.dark : colors.vizerIceGray.main,
            },
            tooltip: {
                main: darkMode ? colors.vizerSlateGray.dark : colors.white,
                border: darkMode ? colors.vizerIceGray.light : colors.vizerIceGray.dark,
            },
            divider: darkMode ? colors.vizerIceGray.light : colors.vizerIceGray.dark,
            graphDashboard: {
                main: darkMode ? colors.vizerSlateGray.light : colors.vizerIceGray.light,
            },
            nav: {
                main: darkMode ? colors.vizerSlateGray.light : colors.vizerIceGray.main,
            },
        },
        typography: {
            h1: typography.h1,
            h2: typography.h2,
            h3: typography.h3,
            h4: typography.h4,
            h5: typography.h5,
            body1: typography.p,
            a: typography.p,
            // fontFamily: [
            //     'Open Sans',
            //     'Avenir',
            //     'Helvetica',
            //     'sans-serif',
            // ].join(','),
        },
        buttons: {
            large: {
                primary: buttons.large.primary,
            },
            medium: {
                secondary: buttons.medium.secondary,
            },
        },
        inputs: {
            helperText: inputs.helperText,
        },
        components: {
            MuiPaper: {
                styleOverrides: { root: { backgroundImage: 'unset' } },
            },
            MuiInputBase: {
                defaultProps: {
                    disableInjectingGlobalStyles: true,
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: darkMode ? colors.white : colors.vizerSlateGray.main,
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: darkMode ? colors.white : colors.vizerSlateGray.main,
                        '&.Mui-focused': {
                            color: darkMode ? colors.white : colors.vizerSlateGray.main,
                        },
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: 14,
                    },
                },
            },
            MuiSnackbar: {
                styleOverrides: {
                    anchorOriginBottomRight: {
                        zIndex: 999999,
                        bottom: '90px !important',
                    },
                },
            },
            MuiAutocomplete: {
                popper: {
                    zIndex: 9999,
                },
            },
        },
    })

    theme = responsiveFontSizes(theme)

    return { theme }
}

export {
    useThemeSelector,
    colors,
    typography,
}
