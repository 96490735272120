import { useState, useEffect, useContext } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import {
    Accordion, AccordionDetails, AccordionSummary,
    AppBar, Box,
    List, ListItem, ListItemIcon, ListItemText,
    Tab, Tabs,
} from '@mui/material'

import { AuthContext } from 'contexts'
import Header from 'components/Header'
import Footer from 'components/Footer'
import VizerLogoMark from 'components/VizerLogoMark'
import ErrorModal from 'features/errors/components/ErrorModal'
import velocityNavList from './velocity_navlist'
import { AppContentStyled, DrawerStyled, NavLinkStyled, useStyles } from './styles'

const Navbar = () => {
    const { authenticated } = useContext(AuthContext)
    const [open, setOpen] = useState(JSON.parse(localStorage.getItem('drawer-state')) || false)

    const styles = useStyles({ drawerOpen: open })

    const handleDrawerClick = () => {
        setOpen(!open)
        localStorage.setItem('drawer-state', JSON.stringify(!open))
    }

    if (!authenticated) return <Outlet />

    return (
        <>
            <Header
                handleDrawerClick={handleDrawerClick}
                open={open}
            />
            <DrawerStyled
                variant='permanent'
                open={open}
            >
                <NavTabs drawerOpen={open} styles={styles} />
            </DrawerStyled>

            <AppContentStyled navOpen={open} authenticated={authenticated}>
                <main>
                    <Box sx={styles.page}>
                        <ErrorModal />
                        <Outlet />
                    </Box>
                </main>
            </AppContentStyled>

            <Footer />
        </>
    )
}

const NavTabs = ({ drawerOpen, styles }) => {
    const [value, setValue] = useState(JSON.parse(localStorage.getItem('navigation-tab-state')) || 0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
        localStorage.setItem('navigation-tab-state', JSON.stringify(newValue))
    }

    const { state } = useLocation()
    const navTabState = state?.navTabState

    useEffect(() => {
        if (navTabState) {
            setValue(navTabState)
            localStorage.setItem('navigation-tab-state', JSON.stringify(navTabState))
        }
    }, [navTabState])

    return (
        <>
            <AppBar position='static' sx={{ background: (theme) => theme.palette.default.main }}>
                <Tabs
                    aria-label='navigation-tabs'
                    value={value}
                    orientation={drawerOpen ? 'horizontal' : 'vertical'}
                    onChange={handleChange}
                    TabIndicatorProps={{ style: { background: 'none' } }}
                >
                    <Tab
                        id='vizer-0'
                        sx={styles.tab}
                        label={drawerOpen ? 'Vizer' : ''}
                        aria-controls='vizer-tabpanel'
                        icon={<VizerLogoMark size={20} />}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <NavList navList={velocityNavList} styles={styles} />
            </TabPanel>
        </>
    )
}

const TabPanel = ({ children, value, index }) => (
    <div
        role='tabpanel'
        hidden={value !== index}
        id={`${value}-${index}`}
        aria-labelledby={`${value}-tabpanel`}
        style={{ width: '100%', height: '100%' }}
    >
        {value === index && (
            <>
                {children}
            </>
        )}
    </div>
)

const NavList = ({ navList, styles }) => (
    <List sx={styles.list}>
        {navList.map(({ icon, path, text, children }) => {
            if (children) {
                return (
                    <Accordion key={text} sx={styles.accordion}>
                        <AccordionSummary
                            aria-controls='nav-item-with-children'
                            id={text}
                            sx={styles.accordionHeader}
                        >
                            <ListItem button>
                                <ListItemIcon sx={styles.listItemIcon}>
                                    {icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={text}
                                    primaryTypographyProps={{ style: styles.navLink }}
                                />
                            </ListItem>
                        </AccordionSummary>

                        <AccordionDetails sx={styles.subNavContainer}>
                            {children.map((child) => (
                                <NavLinkStyled
                                    to={child.path}
                                    key={child.text}
                                >
                                    <ListItem button>
                                        <ListItemIcon sx={styles.listItemIcon}>
                                            {child.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={child.text}
                                            primaryTypographyProps={{ style: styles.navLink }}
                                        />
                                    </ListItem>
                                </NavLinkStyled>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                )
            }

            return (
                <NavLinkStyled
                    to={path}
                    key={text}
                >
                    <ListItem button>
                        <ListItemIcon sx={styles.listItemIcon}>
                            {icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={text}
                            primaryTypographyProps={{ style: styles.navLink }}
                        />
                    </ListItem>
                </NavLinkStyled>
            )
        })}
    </List>
)

export default Navbar
