import { Navigate, Link } from 'react-router-dom'
import { SUCCESS } from 'features/status'
import {
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material'
import {
    Visibility,
    VisibilityOff,
} from '@mui/icons-material'

import { useRegister } from '../../hooks/register'
import { classesFn } from '../styles'
import FormErrors from 'components/FormErrors/index'

const RegisterForm = () => {
    const classes = classesFn()
    const {
        status,
        show_password,
        form_errors,
        disabled,
        setFirstName,
        setLastName,
        setEmail,
        setPassword,
        setConfirmedPassword,
        setShowPassword,
        onRegister,
    } = useRegister()

    if (status === SUCCESS) return <Navigate to='/' />

    return (
        <form style={classes.form} onSubmit={(e) => e.preventDefault()}>
            <TextField
                required
                id='register-first-name-input'
                label='First Name'
                onChange={setFirstName}
            />
            <TextField
                required
                id='register-last-name-input'
                label='Last Name'
                onChange={setLastName}
            />
            {form_errors && form_errors.includes('invalid_email') &&
                <FormErrors error={'Please enter a valid email'} />
            }
            <TextField
                required
                id='register-email-input'
                label='Email'
                onChange={setEmail}
            />
            {form_errors && form_errors.includes('invalid_password') &&
                <FormErrors
                    error={'Password must be 6-20 characters long, contain at least one numeric digit, one uppercase and one lowercase letter'}
                />
            }
            <TextField
                required
                id='register-password-input'
                label='Password'
                type={show_password ? 'text' : 'password'}
                autoComplete='new-password'
                onChange={setPassword}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                color='primary'
                                onClick={setShowPassword}
                            >
                                {show_password ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {form_errors && form_errors.includes('mismatched_passwords') &&
                <FormErrors error={'Passwords must match'} />
            }
            <TextField
                required
                id='register-confirm-password-input'
                label='Confirm Password'
                type={show_password ? 'text' : 'password'}
                autoComplete='new-password'
                onChange={setConfirmedPassword}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                color='primary'
                                onClick={setShowPassword}
                            >
                                {show_password ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                type='submit'
                disabled={disabled}
                onClick={onRegister}
                variant='contained'
                sx={classes.registerButton}
            >
                {'Register'}
            </Button>
            <div style={classes.actions}>
                <Link to='/login' style={classes.linkReset}>
                    <Typography sx={classes.actionableItem}>{'Already have an account?'} <u>{'Login'}</u></Typography>
                </Link>
            </div>
        </form>
    )
}

export default RegisterForm
