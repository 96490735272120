import Typography from '@mui/material/Typography'
import styles from './styles'

export const FormErrors = ({ error }) => (
    <>
        <Typography variant='body1' style={styles.error}>
            {error}
        </Typography>
    </>
)

export default FormErrors
