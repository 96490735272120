import { useQuery } from '@tanstack/react-query'
import { useQueryDefaults } from 'queryHelpers'
import { fetchOfferTemplate, fetchOfferTemplateNames } from '../services'

export const useQueryOfferTemplate = (templateType, templateID, queryArgs = {}) => {
    const queryKey = ['offers', 'template', templateType, templateID]

    return useQuery({
        queryKey,
        queryFn: () => fetchOfferTemplate(templateType, templateID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryOfferTemplateNames = (templateType, queryArgs = {}) => {
    const queryKey = ['offers', 'templateNames', templateType]

    return useQuery({
        queryKey,
        queryFn: () => fetchOfferTemplateNames(templateType),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}
