import { useMutation } from '@tanstack/react-query'
import { fetchCustomerActivity, fetchCustomerByCardNumber, fetchCustomerClips, fetchCustomerOfferClip, fetchCustomerStatus } from '../services'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'

export const useQueryCustomerStatus = () => {
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (form) => fetchCustomerStatus(form),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `custStatus-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `custStatus-${warn}` }))

                return
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `custStatus-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `custStatus-${data.toString()}` })
        },
    })
}

export const useQueryCustomerActivity = () => {
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (form) => fetchCustomerActivity(form),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `custActivity-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `custActivity-${warn}` }))

                return
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `custActivity-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `custActivity-${data.toString()}` })
        },
    })
}

export const useQueryCustomerClips = () => {
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (form) => fetchCustomerClips(form),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `custClips-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `custClips-${warn}` }))

                return
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `custClips-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `custClips-${data.toString()}` })
        },
    })
}

export const useQueryCustomerOfferHistory = () => {
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (form) => fetchCustomerOfferClip(form),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `custOffer-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `custOffer-${warn}` }))

                return
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `custOffer-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `custOffer-${data.toString()}` })
        },
    })
}

export const useQueryCustomerByCardNumber = () => {
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (cardNumber) => fetchCustomerByCardNumber(cardNumber),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `custCard-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `custCard-${warn}` }))

                return
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `custCard-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `custCard-${data.toString()}` })
        },
    })
}
