import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'

import {
    TextField,
    Typography,
} from '@mui/material'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import { useOfferTemplateNames } from 'features/velocity/templates/hooks/template_names'
import LandingAcacia from './LandingAcacia'
import LandingPalm from './LandingPalm'
import LandingPine from './LandingPine'
import LandingElm from './LandingElm'
import LandingMaple from './LandingMaple'
import EngageAcacia from './EngageAcacia'
import EngagePalm from './EngagePalm'
import EngagePine from './EngagePine'
import EngageElm from './EngageElm'
import EngageMaple from './EngageMaple'
import useStyles from './styles'

const Components = {
    landing_acacia: LandingAcacia,
    landing_palm: LandingPalm,
    landing_pine: LandingPine,
    landing_elm: LandingElm,
    landing_maple: LandingMaple,
    engage_acacia: EngageAcacia,
    engage_palm: EngagePalm,
    engage_pine: EngagePine,
    engage_elm: EngageElm,
    engage_maple: EngageMaple,
}

export const footerVariants = [
    {
        name: 'Default',
        value: 'default',
    },
    {
        name: 'Basic',
        value: 'basic',
    },
    {
        name: 'Vizer',
        value: 'vizer',
    },
]

export const OfferTemplating = ({
    readOnly, templateType, disabled, template, displayName,
    setTemplate = () => { }, updateTemplate = () => { }, updateTemplateCheckbox = () => { }, updateTemplateDropdown = () => { },
}) => {
    const [templateField, setTemplateField] = useState()
    const styles = useStyles()

    const offerTemplateNamesResult = useOfferTemplateNames(templateType)
    const { templateNames } = offerTemplateNamesResult

    useEffect(() => {
        if (template && Object.keys(template).length !== 0) {
            setTemplateField({ id: template.id })
        }
    }, [template?.id])

    if (offerTemplateNamesResult.isInitialLoading) return <Loading />

    if (offerTemplateNamesResult.connectionError) return <ConnectionError err={offerTemplateNamesResult.connectionError} />

    if (offerTemplateNamesResult.isError) return <ConnectionError err={offerTemplateNamesResult.errors} />

    return (
        <>
            <div style={styles.templateContainer}>
                {!readOnly &&
                    <>
                        <Typography variant='h2'>{`Select ${displayName} Page Template`}</Typography>

                        <Autocomplete
                            autoComplete
                            disableClearable
                            sx={styles.field}
                            data-cy='select_template'
                            options={templateNames || {}}
                            getOptionLabel={(op) => op?.name || 'None'}
                            disabled={disabled}
                            value={templateNames?.find((op) => op?.id === templateField?.id) || ' '}
                            onChange={(_, val) => setTemplateField(val)}
                            renderInput={(params) => (
                                <TextField {...params} label='Template' />
                            )}
                        />
                    </>
                }

                {templateField &&
                    calculateTemplate({
                        template,
                        templateField,
                        templateType,
                        disabled: disabled || readOnly,
                        setTemplate,
                        updateTemplate,
                        updateTemplateCheckbox,
                        updateTemplateDropdown,
                    })
                }
            </div>

            <IsLoading
                isLoading={offerTemplateNamesResult.isLoading}
                isFetching={offerTemplateNamesResult.isFetching}
                isRefetching={offerTemplateNamesResult.isRefetching}
            />

        </>
    )
}

const calculateTemplate = (props) => {
    const { templateField } = props

    if (typeof Components[templateField.id] !== 'undefined') {
        return React.createElement(Components[templateField.id], {
            ...props,
        })
    }
}

export default OfferTemplating
