const useStyles = () => ({
    copyItem: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
        padding: '15px 10px',
        marginRight: 'auto',
        lineHeight: 1,
    },
    copyIcon: {
        padding: '0 10px 0 0',
        display: 'flex',
        alignItems: 'center',
        marginTop: '-4px',
        position: 'relative',
    },
})

export default useStyles
