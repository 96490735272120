import CatalogsList from 'features/velocity/catalogs/components/CatalogList'
import { useCatalogs } from 'features/velocity/catalogs/hooks/list'

const CatalogListPage = () => {
    const listCatalogsResult = useCatalogs()

    return (
        <CatalogsList
            {...listCatalogsResult}
        />
    )
}

export default CatalogListPage
