import { colors } from 'theme'

const useStyles = () => ({
    tabHeader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '15px',
        alignItems: 'center',
        '& h2': {
            margin: 0,
        },
    },
    accordionBtn: {
        width: 'auto',
        marginLeft: 'auto',
    },
    accordion: {
        boxShadow: `none`,
        background: `rgba(255, 255, 255, .05)`,
        '&.Mui-expanded': {
            marginTop: 0,
        },
    },
    accordionHeader: {
        display: 'flex',
        justifyContent: 'center',
        '&.MuiAccordionSummary-root:hover:not(.Mui-disabled)': {
            cursor: 'default',
        },
        '& > .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
        },
    },
    linkParams: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        textDecoration: 'none',
        display: 'flex',
    },
    linkIcon: {
        color: (theme) => theme.palette.icons.dark,
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    linkContainer: {
        flex: { xs: '1 1 100%', sm: '0 1 49%' },
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        border: (theme) => `1px solid ${theme.palette.iconText.dark}`,
        borderRadius: '6px',
        marginBottom: '15px',
    },
    linkName: {
        padding: '5px 10px',
        borderBottom: (theme) => `1px solid ${theme.palette.iconText.dark}`,
    },
    linkItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        fontSize: '1.2rem',
        margin: '15px 0',
    },
    copyIcon: {
        cursor: 'pointer',
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginTop: '-3px',
        position: 'relative',
    },
    copyText: {
        background: colors.vizerCoral.main,
        padding: '2.5px 5px',
        borderRadius: '6px',
        position: 'absolute',
        top: -37,
        left: -18,
        margin: 'auto',
        width: 'auto',
    },
    iconBlock: {
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.2rem',
        marginTop: '-3px',
    },
    btn: {
        width: 'auto',
        margin: '10px 5px 10px 0',
    },
    listRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& > div': {
            flex: '0 1 33.33%',
        },
    },
    descriptionList: {
        width: '100%',
        marginBottom: 'auto',
        '& dt': {
            opacity: '50%',
            textTransform: 'uppercase',
        },
        '& dd': {
            fontSize: '1.25rem',
            marginLeft: 0,
            padding: '2px 0 12px',
        },
    },
})

export default useStyles
