import { useMutation, useQuery } from '@tanstack/react-query'
import { useQueryDefaults } from 'queryHelpers'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'
import {
    fetchOfferRetailersByOfferID,
    fetchOfferRetailerShow,
    generateStripImages,
} from '../services'

export const useQueryOfferRetailersByOfferID = (offerID, queryArgs = {}) => {
    const queryKey = ['offer_retailers', 'list_by_offer_id', offerID]

    return useQuery({
        queryKey,
        queryFn: () => fetchOfferRetailersByOfferID(offerID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryOfferRetailerShow = (offerID, retailerID, queryArgs = {}) => {
    const queryKey = ['offer_retailers', 'show', (offerID, retailerID)]

    return useQuery({
        queryKey,
        queryFn: () => fetchOfferRetailerShow(offerID, retailerID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useGenerateStripImagesCmd = (setGeneratingImages, offerRetailerID) => {
    const { addNotification } = usePageNotifications()

    return useMutation({
        mutationFn: (offerRetailerId) => generateStripImages(offerRetailerId),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data

                setGeneratingImages(false)

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `${offerRetailerID}-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `${offerRetailerID}-${warn}` }))
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `offer_retailers-${err}` })
                })
            }
        },
        onError: (data) => {
            setGeneratingImages(false)

            addNotification({ message: data.toString(), type: 'error', id: `offer_retailers-${data.toString()}` })
        },
    })
}
