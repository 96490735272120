import Modal from 'react-modal'
import { Box, Button, Typography } from '@mui/material'
import useStyles, { customModalStyles } from './styles'

export const ConfirmModal = (props) => {
    const styles = useStyles()

    Modal.setAppElement('#root')

    return (
        <Modal
            isOpen={props.visible}
            onRequestClose={() => props.dismissModal()}
            style={customModalStyles}
            contentLabel="Confirm Modal"
        >
            <div style={styles.page}>
                <Box sx={styles.container}>
                    <div>
                        <Typography sx={styles.title}>{props.title || 'Warning'}</Typography>
                    </div>
                    <Typography sx={styles.messageText}>
                        {props.message || 'Are you sure?'}
                    </Typography>
                    <div>
                        <Button
                            variant='contained'
                            color='secondary'
                            sx={styles.btn}
                            onClick={() => props.dismissModal()}
                        >
                            {props.cancelButtonText || 'Cancel'}
                        </Button>
                        <Button
                            variant='contained'
                            color='secondary'
                            sx={styles.btn}
                            onClick={() => {
                                props.accept()
                                props.dismissModal()
                            }}
                        >
                            {props.confirmButtonText || 'Yes'}
                        </Button>
                    </div>
                </Box>
            </div>
        </Modal>
    )
}

export default ConfirmModal
