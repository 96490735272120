import { colors } from 'theme'

const useStyles = () => ({
    divider: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: colors.vizerSlateGray.light,
        marginBottom: '40px',
        width: '100%',
    },
})

export default useStyles
