import { Link } from 'react-router-dom'
import {
    Button,
    Typography,
} from '@mui/material'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import OffersList from 'features/velocity/offers/components/OfferList'
import { useOffers } from 'features/velocity/offers/hooks/list'
import useStyles from './styles'

const OffersListPage = () => {
    const styles = useStyles()

    const {
        connectionError,
        offers,
        pagination,
        filters,
        errors,
        isError,
        isLoading,
        isFetching,
        isRefetching,
        isInitialLoading,
        setPage,
        updateFilter,
        setSearch,
        search,
        resetFilters,
        pageState,
        invalidateListQueries,
        resetFiltersDisabled,
    } = useOffers()

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isFetching={isFetching}
                isRefetching={isRefetching}
                isInitialLoading={isInitialLoading}
            />

            <Typography variant='h1' align='center'>Offers</Typography>

            <div style={styles.toolbar}>
                <Link to={{ pathname: `/velocity/offers/new` }}>
                    <Button
                        sx={styles.btn}
                        variant="contained"
                        color="secondary"
                        data-cy='new-offer-btn'
                    >
                        New
                    </Button>
                </Link>
            </div>

            <OffersList
                offers={offers}
                pageState={pageState}
                filters={filters}
                pagination={pagination}
                setPage={setPage}
                updateFilter={updateFilter}
                search={search}
                setSearch={setSearch}
                resetFilters={resetFilters}
                disabled={isFetching}
                resetFiltersDisabled={resetFiltersDisabled}
                refresh={invalidateListQueries}
            />
        </>
    )
}

export default OffersListPage
