const useStyles = () => ({
    retailerContainer: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'start',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
})

export default useStyles
