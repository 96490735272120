import { emptyToNull } from 'helpers'
import { validateEmail, validatePassword } from 'utilities/user_validations'
import { useRegisterFormReducer } from '../reducers/register'

export const useRegister = (state = {}) => {
    const [form, dispatch] = useRegisterFormReducer(state)

    const { show_password, form_errors } = form

    const handleField = (field) => (event) => {
        const raw = event.target.value
        const value = emptyToNull(raw)

        dispatch({ type: 'UPDATE_FORM_FIELD', field, value })
    }

    const setFormErrors = (value) => {
        dispatch({ type: 'UPDATE_FORM_ERRORS', value })
    }

    const filledOutForm = form.first_name && form.last_name && form.email && form.password && form.confirmed_password

    const validate = () => {
        let errors = false

        if (!validateEmail(form.email)) {
            errors = true

            setFormErrors('invalid_email')
        }

        if (form.password !== form.confirmed_password) {
            errors = true
            setFormErrors('mismatched_passwords')
        }

        if (!validatePassword(form.password)) {
            errors = true
            setFormErrors('invalid_password')
        }

        if (errors) {
            return false
        } else {
            return true
        }
    }

    return {
        form,
        status,
        show_password,
        form_errors,
        disabled: !filledOutForm,
        setFirstName: handleField('first_name'),
        setLastName: handleField('last_name'),
        setEmail: handleField('email'),
        setPassword: handleField('password'),
        setConfirmedPassword: handleField('confirmed_password'),
        setShowPassword: () => dispatch({ type: 'UPDATE_FIELD', field: 'show_password', value: !show_password }),
        onRegister: async () => {
            dispatch({ type: 'CLEAR_FORM_ERRORS' })
            const valid = await validate()

            if (valid) {
                // dispatch(register(form))
            } else {
                console.log('FAILURE') // eslint-disable-line no-console
            }
        },
    }
}
