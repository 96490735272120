const TwitterBlack = ({ size }, props) => (
    <svg
        width={size}
        height={size}
        viewBox='0 0 22 18'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <title>{'Fill 43'}</title>
        <path
            // eslint-disable-next-line max-len
            d='M21.877 2.187a.464.464 0 00-.548-.092 4.49 4.49 0 01-.685.242c.359-.461.65-.986.774-1.456a.447.447 0 00-.183-.484.468.468 0 00-.526.004c-.27.188-1.515.73-2.319.905C17.37.405 16.354 0 15.126 0c-.921 0-1.775.258-2.767.836-1.73 1.008-2.03 3.187-1.9 4.523-4.696-.439-7.33-3.12-8.265-4.288A.49.49 0 001.8.901a.46.46 0 00-.364.222c-1.119 1.884-.66 3.822.2 5.127a5.781 5.781 0 01-.428-.316.464.464 0 00-.488-.06.448.448 0 00-.263.406c0 1.978 1.18 3.39 2.331 4.193a8.583 8.583 0 01-.36-.103.465.465 0 00-.48.13.443.443 0 00-.076.483c.736 1.624 2.2 2.836 3.84 3.257-1.41.843-3.36 1.276-5.202 1.063a.457.457 0 00-.488.307.447.447 0 00.208.53C2.462 17.393 4.781 18 7.318 18h.016c3.466-.003 6.721-1.483 9.165-4.169 2.359-2.59 3.595-5.91 3.342-8.93.606-.445 1.524-1.277 2.09-2.17a.443.443 0 00-.054-.544z'
            fill='#000'
            fillRule='evenodd'
        />
    </svg>
)

export default TwitterBlack
