import { useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CardContent,
    Typography,
} from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import UPCForm from 'features/velocity/catalogs/components/UPCForm'
import ConfirmModal from 'features/modals/components/ConfirmModal'
import useStyles from './styles'

export const UPCs = ({ catalogID, items, onDeleteCatalogItem }) => {
    const styles = useStyles()

    const [visible, setVisible] = useState(false)
    const [selectedUPC, setSelectedUPC] = useState(null)
    return (
        <>
            <CardContent sx={styles.itemsColumn}>
                <Typography variant='h2'>Catalog Items</Typography>

                <ConfirmModal
                    visible={visible}
                    message={`Are you sure you wish to delete this catalog item ${selectedUPC}? This cannot be undone.`}
                    dismissModal={() => setVisible(false)}
                    accept={() => {
                        onDeleteCatalogItem(selectedUPC)
                        setVisible(false)
                    }}
                />

                <Box style={styles.itemsContainer}>
                    {items?.length > 0 &&
                        items.map(({ upc, description }) => (
                            <div key={upc} style={styles.itemContainer}>
                                <p style={styles.item}>
                                    {description}

                                    <span>{upc}</span>
                                </p>
                                <div
                                    style={styles.deleteBtn}
                                    onClick={() => {
                                        setVisible(true)
                                        setSelectedUPC(upc)
                                    }}
                                >
                                    <HighlightOffIcon />
                                </div>
                            </div>
                        ))}
                </Box>
            </CardContent>

            <CardContent sx={styles.accordionColumn}>
                <Accordion sx={styles.accordion}>
                    <AccordionSummary
                        aria-controls="catalog-item-form-content"
                        id="catalog-item-form-header"
                        sx={styles.accordionHeader}
                    >
                        <Button
                            sx={styles.accordionBtn}
                            variant='contained'
                            color='secondary'
                            data-cy='edit-btn'
                        >
                            Add Item to Catalog
                        </Button>
                    </AccordionSummary>

                    <AccordionDetails>
                        <UPCForm catalogID={catalogID} />
                    </AccordionDetails>
                </Accordion>
            </CardContent>
        </>
    )
}

export default UPCs
