import Hyperlink from '../Hyperlink'
import { isEmpty } from '../../helpers'

export const LabelWithLink = ({ label, path, id, emptyLabel }) => {
    if (isEmpty(id)) return (emptyLabel)

    return (
        <>
            {label} (<Hyperlink path={path} text={id} />)
        </>
    )
}

export default LabelWithLink
