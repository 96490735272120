import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useQueryDefaults } from 'queryHelpers'
import { fetchUserByInvitationID, acceptInvitation } from '../services'

export const useQueryUserByInvitationID = (invitationID, queryArgs = {}) => {
    const queryKey = ['users', 'by_invitation_id', invitationID]

    return useQuery({
        queryKey,
        queryFn: () => fetchUserByInvitationID(invitationID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useAcceptInvitationCmd = () => {
    const navigate = useNavigate()

    return useMutation({
        mutationFn: (params) => acceptInvitation(params),
        onSuccess: (data) => {
            if (data?.success) navigate('/login')
        },
    })
}
