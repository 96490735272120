import { all } from 'redux-saga/effects'
import csv_uploader from 'features/csv_upload/saga'
import healthCheck from 'features/health_check/sagas'
import permissions from 'features/oxidauth/permissions/sagas'
import roles from 'features/oxidauth/roles/sagas'
import users from 'features/oxidauth/users/sagas'

export default function* rootSaga() {
    yield all([
        csv_uploader(),
        healthCheck(),
        permissions(),
        roles(),
        users(),
    ])
}
