import { createSlice } from '@reduxjs/toolkit'
import {
    FETCHING,
    SUCCESS,
    ERROR,
    PENDING,
} from 'features/status'

const name = 'rolesList'

const initialState = {
    status: PENDING,
    hasLoaded: false,
    roles: [],
    error: null,
    userID: null,
    roleID: null,
}

const reducers = {
    fetchRoles: (state, { payload }) => {
        const { id, parent } = payload

        if (id) state[`${parent}ID`] = parseInt(id)

        state.status = FETCHING
    },
    fetchRolesResult: (state, { payload }) => {
        const { roles } = payload

        state.status = SUCCESS
        state.hasLoaded = true
        state.roles = roles
    },
    fetchRolesError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetRoles: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    fetchRoles,
    fetchRolesResult,
    fetchRolesError,
    resetRoles,
} = slice.actions

export default slice.reducer
