import { useEffect } from 'react'
import { isPresent, reduceQueries, useInvalidateQuery } from 'helpers'
import { useQueryAdminUsers } from 'features/admin_users/queries'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'

export const useAdminUsers = (state = {}) => {
    const {
        setPage,
        resetPage,
        params,
        pageState,
        paginationQueryArgs,
    } = usePaginationFilters({ state })

    const {
        payloads: [adminUsersPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries([useQueryAdminUsers(params, paginationQueryArgs)])

    const invalidateListQueries = useInvalidateQuery(['admin_users', 'list'])

    const adminUsers = adminUsersPayload?.admin_users?.users || []
    const adminUserIDsNotFound = adminUsersPayload?.admin_users?.user_ids_not_found || []
    const pagination = adminUsersPayload?.pagination || []

    if (isPresent(adminUserIDsNotFound) && adminUserIDsNotFound?.length > 0) {
        console.log('Failed to fetch some users from oxidauth: \n', adminUserIDsNotFound) // eslint-disable-line no-console
    }

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    return {
        connectionError,
        success,
        adminUsers,
        errors,
        warnings,
        notices,
        pagination,
        setPage,
        pageState,
        invalidateListQueries,
        ...isResults,
    }
}
