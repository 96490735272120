import { colors } from 'theme'

const useStyles = () => ({
    itemsColumn: {
        width: { xs: '100%', sm: `calc(100% - 500px)` },
    },
    accordionColumn: {
        width: 500,
    },
    accordionBtn: {
        width: '100%',
    },
    accordion: {
        boxShadow: `none`,
        background: `rgba(255, 255, 255, .05)`,
    },
    accordionHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    itemsContainer: {
        display: 'grid',
        gridTemplateColumns: { xs: '1fr 1fr', md: '1fr 1fr 1fr' },
        gridGap: 10,
    },
    itemContainer: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        minWidth: '50%',
        border: `1px solid ${colors.vizerSlateGray.main}`,
        borderRadius: '6px',
        position: 'relative',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px 10px',
        fontSize: '.8rem',
        margin: 0,
        '& span': {
            fontSize: '1.2rem',
            marginTop: '5px',
        },
    },
    deleteBtn: {
        position: 'absolute',
        right: 5,
        top: 5,
        cursor: 'pointer',
        '& :hover': {
            color: colors.vizerCoral.main,
        },
    },
    btn: {
        width: 'auto',
        margin: '10px 5px 10px 0',
    },
})

export default useStyles
