import { NavLink } from 'react-router-dom'
import { Box, Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'theme'

const drawerWidth = 300

export const DrawerStyled = styled(Drawer)(({ open, theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: drawerWidth,
    },
    flexShrink: 0,
    whiteSpace: 'nowrap',
    paddingTop: '64px',
    ...(open && {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
        },
        transition: theme.transitions.create(['width', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& .MuiListItemButton-root': {
            padding: '5px 30px',
        },
        '& > .MuiPaper-root': {
            height: '100vh',
            flexShrink: 0,
            paddingTop: '64px',
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
            },
            transition: theme.transitions.create(['width', 'padding'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    }),
    ...(!open && {
        transition: theme.transitions.create(['width', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(7),
        },
        scrollbarWidth: 'none', // Hide the scrollbar for firefox
        '&::-webkit-scrollbar': {
            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
        },
        '&-ms-overflow-style:': {
            display: 'none', // Hide the scrollbar for IE
        },
        '& > .MuiPaper-root': {
            transition: theme.transitions.create(['width', 'padding'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            height: '100vh',
            flexShrink: 0,
            paddingTop: '64px',
            width: theme.spacing(7),
            [theme.breakpoints.up('md')]: {
                width: theme.spacing(7),
            },
        },
    }),
    '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflowX: 'hidden',
    },
}))

export const AppContentStyled = styled(Box, {
    shouldForwardProp: (props) => props !== 'authenticated' && props !== 'navOpen',
})(({ authenticated, navOpen, theme }) => ({
    transition: theme.transitions.create('padding', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    padding: '0 0 0 57px',
    [theme.breakpoints.up('md')]: {
        padding: authenticated ? `0 0 0 ${navOpen ? `${drawerWidth}px` : '57px'}` : '100px 0 0',
    },
}))

export const NavLinkStyled = styled(NavLink)(({ theme }) => ({
    color: theme.palette.iconText.light,
    textDecoration: 'none',
    fontSize: '1.325rem',
    '& svg': {
        fill: theme.palette.iconText.light,
        height: '24px',
        width: '24px',
    },
    '&:hover': {
        color: colors.vizerCoral.dark,
        '& svg': {
            fill: colors.vizerCoral.dark,
        },
    },
    '&.active': {
        color: colors.vizerCoral.main,
        position: 'relative',
        display: 'block',
        '& svg': {
            fill: colors.vizerCoral.main,
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            height: '100%',
            width: 3,
            background: colors.vizerCoral.main,
            left: 0,
            top: 0,
        },
        '&:hover': {
            color: colors.vizerCoral.dark,
            '& svg': {
                fill: colors.vizerCoral.dark,
            },
        },
    },
}))

export const useStyles = () => ({
    page: {
        padding: (theme) => ({
            xs: `${theme.spacing(5)} 0px 120px`,
            sm: `${theme.spacing(2)} ${theme.spacing(3)} 120px`,
        }),
    },
    tab: {
        width: 'auto',
        flex: 1,
        minWidth: '50%',
        maxWidth: '100%',
        filter: 'grayscale(1)',
        justifyContent: 'space-around',
        '&.Mui-selected': {
            filter: 'grayscale(0)',
        },
    },
    navLink: {
        fontFamily: 'Open Sans, Avenir, sans-serif',
        fontWeight: 400,
        fontSize: 15,
        textTransform: 'initial',
        letterSpacing: 0,
    },
    list: {
        paddingBottom: '64px',
    },
    listItemIcon: {
        minWidth: 40,
    },
    accordion: {
        boxShadow: 'none',
        color: (theme) => theme.palette.iconText.main,
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            height: 46.5,
            minHeight: 46.5,
        },
        '& .MuiAccordionSummary-root': {
            height: 46.5,
            minHeight: 46.5,
            padding: 0,
            justifyContent: 'flex-start',
        },
        '&::before': {
            display: 'none',
        },
        '&:hover': {
            color: colors.vizerCoral.dark,
            '& svg': {
                fill: colors.vizerCoral.dark,
            },
        },
    },
    subNavContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: ({ drawerOpen }) => drawerOpen ? '5px 8px' : '5px 0',
        backgroundColor: (theme) => theme.palette.nav.main,
    },
})
