import Modal from 'react-modal'
import { CardMedia, Button } from '@mui/material'
import { useImageModal } from '../hooks/image_modal'
import useStyles from './styles'

export const ImageModal = () => {
    Modal.setAppElement('#root')
    const { image, visible, dismissImageModal } = useImageModal()

    const customStyles = {
        overlay: {
            zIndex: 999999999999,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    }

    return (
        <ImageModalView
            visible={visible}
            dismissImageModal={dismissImageModal}
            customStyles={customStyles}
            image={image}
        />
    )
}

export const ImageModalView = ({ visible, dismissImageModal, customStyles, image }) => {
    const styles = useStyles()

    return (
        <Modal
            isOpen={visible}
            onRequestClose={() => dismissImageModal()}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <Button
                variant='contained'
                color='secondary'
                onClick={() => dismissImageModal()}
                sx={styles.closeButton}
            >
                X
            </Button>
            <CardMedia
                sx={styles.modalCover}
                component='img'
                alt={image?.alt}
                image={image?.url}
                title={image?.title}
            />
        </Modal>
    )
}

export default ImageModal
