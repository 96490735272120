/**
 * @param {Object} original
 *   the unedited original object
 * @param {Object} updated
 *   a copy of original, but has had changes made to its values
 *
 * returns a copy of updated with an array containing changes.
 * @return {Object}
 * { ...updated, changes: [{ field: 'field_name', prev: previous_value, curr: updated_value }, ...] }
 *
 * WARNING: Will return bad changes if the compared values are objects. Or arrays that contain objects or arrays.
 * This only works with simple value types: Strings, numbers, bools, etc.
 * A deepDiff could be written...but deep comparing has giant piles of gotchas and edge cases.
 */
import { ignoreOrderCompare } from '../helpers'

export const shallowDiff = (original, updated) => {
    try {
        const fields = Object.keys(original)

        const diffs = []

        fields.forEach((f) => {
            // Object.getPrototypeOf is going to fail if we pass it null. Check that
            // we've got a non-null value before we check if it's an object, etc.
            // There are other escape hatches we can add, but these are the common cases
            if (
                original[f] != null && (
                    Object.getPrototypeOf(original[f]) === Object.prototype ||
                    original[f] instanceof Map ||
                    original[f] instanceof Set
                )
            ) {
                const e = new ShallowDiffError({ error: `shallowDiff called on an object that contains something that can't be shallow compared` })
                throw e
            }

            if (
                original[f] != null && original[f] instanceof Array
            ) {
                if (!ignoreOrderCompare(original[f], updated[f])) {
                    diffs.push({ field: f, prev: original[f], curr: updated[f] })
                }
            } else if (updated[f] != original[f]) { // eslint-disable-line
                diffs.push({ field: f, prev: original[f], curr: updated[f] })
            }
        })

        const updated_w_changes = { ...updated, diffs }

        return updated_w_changes
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
    }
}

export class ShallowDiffError extends Error {
    constructor(params) {
        super(params)
        const { error } = params

        this.name = 'ShallowDiffError'
        this.message = `Encountered a problem producing a shallowDiff: ${error}`
    }

    toJSON() {
        return this.message
    }
}
