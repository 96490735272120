import ClientPortalUsersEdit from './Edit'
import ClientPortalUsersList from './List'
import ClientPortalUsersNew from './New'
import ClientPortalUsersShow from './Show'

const ClientPortalUsersRoutes = {
    path: 'client_portal',
    children: [
        {
            path: 'users',
            element: <ClientPortalUsersList />,
        },
        {
            path: 'users/new',
            element: <ClientPortalUsersNew />,
        },
        {
            path: 'users/:id',
            element: <ClientPortalUsersShow />,
        },
        {
            path: 'users/:id/edit',
            element: <ClientPortalUsersEdit />,
        },
    ],
}

export default ClientPortalUsersRoutes
