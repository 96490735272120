import { useEffect } from 'react'
import { reduceQueries, useInvalidateQuery } from 'helpers'
import { usePaginationFilters } from 'features/pagination_filtering/hooks'
import { useQueryOffers } from 'features/velocity/offers/queries'
import { useQueryBrands, useQueryOfferPartners } from 'features/velocity/offer_partners/queries'

export const useOffers = () => {
    const debounceSkipKeys = ['offer_type', 'offer_status', 'incomm_status', 'currently_live']

    const {
        setPage,
        resetPage,
        updateFilter,
        setSearch,
        search,
        resetFilters,
        params,
        pageState,
        paginationQueryArgs,
        resetFiltersDisabled: disabled,
    } = usePaginationFilters({
        state: { filters: { offer_type: 'incomm' } },
        debounceSkipKeys,
    })

    const {
        payloads: [offersPayload, offerPartnersPayload, brandsPayload],
        success, connectionError,
        notices, warnings, errors,
        isResults,
    } = reduceQueries(
        [
            useQueryOffers(params, paginationQueryArgs),
            useQueryOfferPartners(null, paginationQueryArgs),
            useQueryBrands(paginationQueryArgs),
        ],
    )

    const invalidateListQueries = useInvalidateQuery(['offers', 'list'])

    const resetFiltersDisabled = isResults.isFetching || disabled

    const offers = offersPayload?.offers || []
    const pagination = offersPayload?.pagination || {}
    const filters = offersPayload?.filters || {}
    delete filters.offer_type

    offers.map((offer) => {
        offer.brand = brandsPayload?.brands.find((brand) => brand.id === offer?.brand_id)
        offer.offer_partner = offerPartnersPayload?.offer_partners.find((op) => offer.offer_partner_id === op.id)
    })

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    useEffect(() => {
        updateFilter(null, 'incomm', 'offer_type')
    }, [pageState])

    return {
        connectionError,
        success,
        offers,
        errors,
        warnings,
        notices,
        filters,
        pagination,
        setPage,
        updateFilter,
        setSearch,
        search,
        resetFilters,
        pageState,
        invalidateListQueries,
        resetFiltersDisabled,
        ...isResults,
    }
}
