import { createSlice } from '@reduxjs/toolkit'
import {
    PENDING,
    LOADED,
    CREATING,
    CREATED,
    ERROR,
} from 'features/status'

const name = 'usersCreate'

const initialState = {
    status: PENDING,
    id: null,
    hasLoaded: false,
    user: {
        username: null,
        email: null,
        first_name: null,
        last_name: null,
        profile: null,
        status: null,
        kind: null,
    },
    error: null,
}

const reducers = {
    setFormField: (state, { payload }) => {
        const form = state.user
        const { field, value } = payload

        const user = {
            ...form,
            [field]: value,
        }

        return { ...state, user }
    },
    setStatus: (state, { payload }) => {state.user.status = payload},
    setKind: (state, { payload }) => {state.user.kind = payload},
    newUser: (state, { payload }) => {
        state.staus = PENDING
    },
    newUserResult: (state, { payload }) => {
        state.status = LOADED
        state.hasLoaded = true
    },
    newUserError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    createUser: (state) => {
        state.status = CREATING
    },
    createUserResult: (state, { payload }) => {
        const { user } = payload

        state.status = CREATED
        state.user = user
    },
    createUserError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetForm: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    setFormField,
    setStatus,
    setKind,
    newUser,
    newUserResult,
    newUserError,
    createUser,
    createUserResult,
    createUserError,
    resetForm,
} = slice.actions

export default slice.reducer
