import { createSlice } from '@reduxjs/toolkit'
import {
    PENDING,
    LOADED,
    CREATING,
    CREATED,
    ERROR,
} from 'features/status'

const name = 'permissionCreate'

const initialState = {
    status: PENDING,
    id: null,
    hasLoaded: false,
    permission: {
        realm_resource_action: null,
        user_id: null,
        role_id: null,
    },
    error: null,
}

const reducers = {
    setFormField: (state, { payload }) => {
        const form = state.permission
        const { field, value } = payload

        const permission = {
            ...form,
            [field]: value,
        }

        return { ...state, permission }
    },
    newPermission: (state, { payload }) => {
        const { id, parent } = payload
        if (id) state.permission[`${parent}_id`] = parseInt(id)
    },
    newPermissionResult: (state) => {
        state.status = LOADED
        state.hasLoaded = true
    },
    newPermissionError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    createPermission: (state) => {
        state.status = CREATING
    },
    createPermissionResult: (state, { payload }) => {
        const { permission } = payload

        state.status = CREATED
        state.permission = permission
    },
    createPermissionError: (state, { payload }) => {
        state.status = ERROR
        state.error = payload
    },
    resetForm: () => (initialState),
}

const slice = createSlice({
    name,
    initialState,
    reducers,
})

export const {
    setFormField,
    newPermission,
    newPermissionResult,
    newPermissionError,
    createPermission,
    createPermissionResult,
    createPermissionError,
    resetForm,
} = slice.actions

export default slice.reducer
