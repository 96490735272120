import { useQuery } from '@tanstack/react-query'
import { useQueryDefaults } from 'queryHelpers'
import { fetchConstants } from '../services'

export const useQueryConstants = () => {
    const queryKey = ['constants', 'list']

    return useQuery({
        queryKey,
        queryFn: () => fetchConstants(),
        ...useQueryDefaults(queryKey),
    })
}
