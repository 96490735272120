import { combineReducers } from 'redux'
import list from './list'
import show from './show'
import create from './create'
import edit from './edit'

const permissions = combineReducers({
    list,
    show,
    create,
    edit,
})

export default permissions
