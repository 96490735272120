import { UPDATE_FIELD, RESET_FORM, SET_FORM } from 'features/status'
import { useCatalogFormReducer } from 'features/velocity/catalogs/reducers/form'
import { emptyToNull } from 'helpers'

export const useCatalogForm = (state = {}) => {
    const [form, dispatch] = useCatalogFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })
    const updateActiveField = (_, active) => dispatch({ type: UPDATE_FIELD, field: 'active', value: active.value })
    const resetForm = () => dispatch({ type: RESET_FORM })
    const setForm = (catalog) => dispatch({ type: SET_FORM, value: catalog })

    return {
        form,
        updateField,
        updateActiveField,
        setForm,
        resetForm,
    }
}
