import { Typography } from '@mui/material'
import styles from './styles'

const VizerLogoText = () => (
    <Typography style={styles.logo}>
        VIZER
    </Typography>
)

export default VizerLogoText
