import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { useEventCounts } from 'features/velocity/events/hooks/events'
import useStyles from './styles'

const EventCounts = () => {
    const styles = useStyles()
    const navigate = useNavigate()
    const params = useEventCounts()

    return (
        <>
            <Button sx={styles.btn} variant='outlined' onClick={() => navigate(-1)}>
                Back
            </Button>

            <Typography variant='h2'>Event Counts</Typography>
        </>
    )
}

export default EventCounts
