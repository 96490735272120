import qs from 'qs'
import { velocityUrl, _get, _post } from 'utils'

export async function fetchHelpFormRequests(pageQuery) {
    try {
        const url = await velocityUrl(`/help_form_requests/queries/list_help_form_requests?${qs.stringify(pageQuery)}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console
        throw error
    }
}

export async function fetchHelpFormRequestShow(id) {
    try {
        const url = await velocityUrl(`/help_form_requests/queries/fetch_help_form_request_by_id/${id}`)

        return await _get(url)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}

export async function updateExistingHelpFormRequest({ id, status }) {
    try {
        const url = await velocityUrl(`/help_form_requests/commands/update_help_form_request`)

        const body = {
            help_form_request: {
                id,
                status,
            },
        }

        return await _post(url, body)
    } catch (error) {
        console.error(error) // eslint-disable-line no-console

        throw error
    }
}
