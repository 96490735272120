import RetailersList from './List'
import RetailerShow from './Show'
import RetailerEdit from './Edit'
import RetailerNew from './New'

const RetailersRoutes = {
    path: 'retailers',
    children: [
        {
            path: '',
            element: <RetailersList />,
        },
        {
            path: 'new',
            element: <RetailerNew />,
        },
        {
            path: ':id/edit',
            element: <RetailerEdit />,
        },
        {
            path: ':id',
            element: <RetailerShow />,
        },
    ],
}

export default RetailersRoutes
