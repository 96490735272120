import { useContext } from 'react'
import { oxidauthUsernamePasswordAuthority } from 'oxidauth'
import { emptyToNull } from 'helpers'
import { AuthContext } from 'contexts'
import { PENDING, REQUESTING, UPDATE_FIELD } from 'features/status'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'
import { useAuthFormReducer } from '../reducers/authenticate'
import { LOGGED_IN } from '../states'

export const useAuthenticate = (state = {}) => {
    const { status, setStatus } = useContext(AuthContext)
    const { addNotification } = usePageNotifications()

    const [authState, dispatch] = useAuthFormReducer(state)

    const { form } = authState

    const setField = (field) => (event) => {
        const raw = event.target.value
        const value = emptyToNull(raw)

        dispatch({ type: UPDATE_FIELD, field, value })
    }

    const authenticate = () => {
        setStatus(REQUESTING)
        oxidauthUsernamePasswordAuthority.authenticate(form.username, form.password)
            .then(() => {
                setStatus(LOGGED_IN)
            })
            .catch((error) => {
                addNotification({ type: 'error', message: 'Unable to log in. Please try again', id: 'authError' })
                setStatus(PENDING)

                console.error('AUTH ERROR', error) // eslint-disable-line
            })
    }

    return {
        form,
        status,
        authenticated: status === LOGGED_IN,
        disabled: status === REQUESTING,
        authenticate,
        setUsername: setField('username'),
        setPassword: setField('password'),
    }
}
