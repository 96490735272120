import { CardMedia, Typography } from '@mui/material'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import { useImageModal } from 'features/modals/hooks/image_modal'
import useStyles from './styles'

const ImageWithModal = ({ noSpacing, image }) => {
    const styles = useStyles({ noSpacing })
    const { setImageModal } = useImageModal()

    return (
        <>
            {image.title &&
                <Typography variant='h5'>{image.title}</Typography>
            }
            <div style={image.variant ? styles[image.variant] : styles.banner}>
                <CardMedia
                    sx={styles.cover}
                    component='img'
                    alt={image.alt}
                    image={image.url}
                    title={image.title}
                />
                <div
                    style={styles.expandButton}
                    onClick={() => setImageModal(image)}
                >
                    <ZoomInIcon />
                </div>
            </div>

        </>
    )
}

export default ImageWithModal
