import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useStyles from './styles'

export const CustomAccordion = ({ title, label, expandIcon, children }) => {
    const styles = useStyles()

    return (
        <Accordion sx={styles.accordion}>
            <AccordionSummary
                id={label}
                expandIcon={expandIcon || <ExpandMoreIcon />}
                aria-controls={`${label}-content`}
                sx={styles.accordionHeader}
            >
                {title}
            </AccordionSummary>

            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default CustomAccordion
