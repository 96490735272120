import { colors } from 'theme'

const useStyles = () => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    card: {
        display: 'flex',
    },
    link: {
        textDecoration: 'none',
        color: colors.black,
    },
    descriptionList: {
        margin: 0,
    },
    content: {
        width: '50%',
    },
    imageColumn: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
})

export default useStyles
