import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useBrandForm } from 'features/brands/hooks/form'
import { useQueryDefaults } from 'queryHelpers'
import {
    fetchBrandsList,
    fetchBrandShow,
    createNewBrand,
    updateExistingBrand,
} from '../services'
import { usePageNotifications } from 'features/page_notifications/hooks/page_notifications'

export const useQueryBrands = (pageQuery, queryArgs = {}) => {
    const queryKey = ['brands', 'list', pageQuery]

    return useQuery({
        queryKey,
        queryFn: () => fetchBrandsList(pageQuery),
        keepPreviousData: true,
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useQueryBrandShow = (brandID, queryArgs = {}) => {
    const queryKey = ['brands', 'show', brandID]

    return useQuery({
        queryKey,
        queryFn: () => fetchBrandShow(brandID),
        ...useQueryDefaults(queryKey),
        ...queryArgs,
    })
}

export const useCreateBrandCmd = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { addNotification } = usePageNotifications()
    const { resetForm } = useBrandForm()

    return useMutation({
        mutationFn: (form) => createNewBrand(form),
        onSuccess: (data) => {
            if (data?.success) {
                const { notices, warnings } = data
                const brandID = data?.payload?.brand?.id

                queryClient.invalidateQueries({ queryKey: ['brands', 'list'] })

                resetForm()

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `${brandID}-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `${brandID}-${warn}` }))

                navigate(`/brands/${brandID}`, { replace: true })
            }

            if (data?.errors?.length > 0) {
                data?.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `brand-create-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `brandCreate-${data.toString()}` })
        },
    })
}

export const useUpdateBrandCmd = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { addNotification } = usePageNotifications()
    const { resetForm } = useBrandForm()

    return useMutation({
        mutationFn: ({ id, form }) => updateExistingBrand(id, form),
        onSuccess: (data) => {
            if (data?.success) {
                const brandID = data?.payload?.brand?.id
                const { notices, warnings } = data

                queryClient.invalidateQueries({ queryKey: ['brands', 'list'] })
                queryClient.invalidateQueries({ queryKey: ['brands', 'show', brandID] })

                resetForm()

                if (notices) notices.map((note) => addNotification({ message: note, type: 'success', id: `${brandID}-${note}` }))
                if (warnings) warnings.map((warn) => addNotification({ message: warn, type: 'warning', id: `${brandID}-${warn}` }))

                navigate(`/brands/${brandID}`, { replace: true })
            }

            if (data?.errors?.length > 0) {
                data.errors.map((err) => {
                    addNotification({ message: err, type: 'error', id: `brand-edit-${err}` })
                })
            }
        },
        onError: (data) => {
            addNotification({ message: data.toString(), type: 'error', id: `brandEdit-${data.toString()}` })
        },
    })
}
