const useStyles = () => ({
    titleBar: {
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        textAlign: { xs: 'center', sm: 'left' },
        marginBottom: '15px',
    },
    title: {
        margin: { xs: '0 auto 20px', sm: '0 auto 0 0' },
    },
    btn: {
        width: { xs: '100%', sm: 'auto' },
    },
    errorCounts: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px',
    },
    otherEventButtons: {
        height: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'start',
    },
})

export default useStyles
