import { useEffect } from 'react'
import Loading from 'components/Loading'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import { DomainsTable } from 'features/velocity/domains/components/Table'
import { useBlockedDomains } from 'features/velocity/domains/hooks/blocked'

export const Blocked = (props) => {
    const { params, paginationQueryArgs, resetPage } = props

    const {
        domains,
        pagination,
        isFetching,
        invalidateListQueries,
        isLoading,
        connectionError,
        isInitialLoading,
        isError,
        errors,
    } = useBlockedDomains({ params, paginationQueryArgs })

    useEffect(() => {
        resetPage(pagination.total_pages)
    }, [pagination.total_pages])

    if (isInitialLoading) return <Loading absolute />

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={isLoading}
                isInitialLoading={isInitialLoading}
            />

            <DomainsTable
                pagination={pagination}
                isFetching={isFetching}
                invalidateListQueries={invalidateListQueries}
                domains={domains}
                buttonType='allow'
                {...props}
            />
        </>
    )
}

export default Blocked
