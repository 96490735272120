import { useEffect, useState } from 'react'
import {
    Autocomplete, Box, Button, CssBaseline,
    IconButton, TextField, Tooltip, Typography,
} from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import RefreshIcon from '@mui/icons-material/Refresh'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InfoIcon from '@mui/icons-material/Info'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import { addTimezoneOffsetToDate, isPresent } from 'helpers'
import IsLoading from 'components/IsLoading'
import ConnectionError from 'components/ConnectionError'
import GraphDashboard from 'features/velocity/analytics/components/GraphDashboard'
import GraphModal from 'features/modals/components/GraphModal'
import { useOfferGraphAnalytics } from '../../hooks/graph'
import { AccordionStyled, classesFn } from './styles'

const GraphContainer = ({ offer, links }) => {
    const classes = classesFn()

    const [resetFilters, setResetFilters] = useState(0)
    const [refreshFilters, setRefreshFilters] = useState(false)

    const {
        connectionError,
        clicks,
        clips,
        scansAndRefunds,
        conversionRates,
        lineColors,
        clicksSpacing,
        clipsSpacing,
        scansSpacing,
        conversionsSpacing,
        errors,
        filterState,
        filterStartDate,
        filterEndDate,
        updateUtmCode,
        updateStrategy,
        updateDateField,
        updateUTMFieldsByLinkField,
        resetFilterValues,
        exportGraph,
        isError,
    } = useOfferGraphAnalytics(offer?.id, offer?.strategy?.incomm_data?.offer_start)

    const { strategy, incomm_data } = offer?.strategy
    const presentData = [isPresent(clicks), isPresent(clips), isPresent(scansAndRefunds), isPresent(conversionRates)]
    const gridValue = presentData.filter(Boolean).length <= 1 ? 12 : 6

    const [open, setOpen] = useState(JSON.parse(localStorage.getItem('graph-drawer-state')) || false)

    const handleDrawerClick = () => {
        setOpen(!open)
        localStorage.setItem('graph-drawer-state', JSON.stringify(!open))
    }

    const onFilterRefresh = () => {
        setRefreshFilters(true)

        setTimeout(() => setRefreshFilters(false), 1500)
    }

    const onFilterReset = () => {
        setResetFilters(resetFilters + 1)
        resetFilterValues()
    }

    useEffect(() => {
        if (offer?.id && filterStartDate && filterEndDate) {
            const startDate = addTimezoneOffsetToDate(new Date(filterStartDate))
            const endDate = addTimezoneOffsetToDate(new Date(filterEndDate))

            updateStrategy(strategy)
            updateDateField({ field: 'start_date', value: startDate })
            updateDateField({ field: 'end_date', value: endDate })
        }
    }, [offer?.id, resetFilters, filterStartDate, filterEndDate])

    if (connectionError) return <ConnectionError err={connectionError} />

    if (isError) return <ConnectionError err={errors} />

    return (
        <>
            <IsLoading
                isLoading={refreshFilters}
                withOverlay={refreshFilters}
            />

            <GraphModal />

            <Box sx={classes.graphContainerWrapper}>
                <div style={classes.tooltipHeader}>
                    <Tooltip
                        title={'All dates are normalized to the UTC timezone'}
                        placement={'left'}
                        sx={classes.tooltip}
                    >
                        <InfoIcon
                            sx={classes.infoIcon}
                        />
                    </Tooltip>
                </div>
                <Box sx={classes.graphContainer}>
                    <Box sx={classes.root}>
                        <CssBaseline />
                        <IconButton
                            aria-label='open drawer'
                            onClick={handleDrawerClick}
                            color={'primary'}
                            sx={classes.menuButton}
                        >
                            {open ? (
                                <div style={classes.filterTitle}>
                                    <Typography sx={classes.label}>
                                        Filters
                                    </Typography>
                                    <ChevronLeftIcon sx={classes.chevronLeft} />
                                </div>
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                        <AccordionStyled open={open} variant='elevation'>
                            <FilterList
                                classes={classes}
                                drawerOpen={open}
                                links={links}
                                filterState={filterState}
                                updateUtmCode={updateUtmCode}
                                updateDateField={updateDateField}
                                updateUTMFieldsByLinkField={updateUTMFieldsByLinkField}
                                resetFilterValues={onFilterReset}
                                exportGraph={exportGraph}
                                onFilterRefresh={onFilterRefresh}
                                minStartDate={incomm_data?.offer_start}
                            />
                        </AccordionStyled>
                    </Box>
                    <GraphDashboard
                        presentData={presentData}
                        clicks={clicks}
                        clips={clips}
                        scansAndRefunds={scansAndRefunds}
                        conversionRates={conversionRates}
                        lineColors={lineColors}
                        gridValue={gridValue}
                        exportGraph={exportGraph}
                        clicksSpacing={clicksSpacing}
                        clipsSpacing={clipsSpacing}
                        scansSpacing={scansSpacing}
                        conversionsSpacing={conversionsSpacing}
                    />
                </Box>
            </Box>
        </>
    )
}

const FilterList = ({
    classes, drawerOpen, links, filterState, updateDateField, updateUTMFieldsByLinkField,
    updateUtmCode, resetFilterValues, onFilterRefresh, exportGraph,
}) => (
    <>
        {drawerOpen &&
            <div style={classes.filterList}>
                <DatePicker
                    sx={classes.field}
                    invalidLabel={'Invalid Date'}
                    placeholder='mm/dd/yyyy'
                    label={'Start'}
                    value={dayjs(filterState?.start_date)}
                    defaultValue={dayjs(filterState?.start_date)}
                    onChange={(datetime, value) => updateDateField({ field: 'start_date', datetime, value })}
                />
                <DatePicker
                    sx={classes.field}
                    invalidLabel={'Invalid Date'}
                    placeholder='mm/dd/yyyy'
                    label={'End'}
                    value={dayjs(filterState?.end_date)}
                    defaultValue={dayjs(filterState?.end_date)}
                    onChange={(datetime, value) => updateDateField({ field: 'end_date', datetime, value })}
                />
                <Autocomplete
                    autoComplete
                    sx={classes.field}
                    data-cy='link'
                    options={links}
                    getOptionLabel={(value) => value?.offer_short_link?.name || ''}
                    isOptionEqualToValue={(option) => option.offer_short_link?.id === filterState?.shortlink_id}
                    value={links.find((type) => type?.offer_short_link?.id === filterState?.shortlink_id) || null}
                    onChange={updateUTMFieldsByLinkField}
                    renderInput={(params) => (
                        <TextField {...params} label='Link' />
                    )}
                />

                <Typography sx={classes.label}>
                    UTM Codes
                </Typography>
                <TextField
                    label='Source'
                    type='text'
                    data-cy='source'
                    sx={classes.field}
                    InputLabelProps={{ shrink: true }}
                    value={filterState?.utm_source || filterState?.link_utm_source || ''}
                    onChange={updateUtmCode('utm_source')}
                />
                <TextField
                    label='Medium'
                    type='text'
                    data-cy='medium'
                    sx={classes.field}
                    InputLabelProps={{ shrink: true }}
                    value={filterState?.utm_medium || filterState?.link_utm_medium || ''}
                    onChange={updateUtmCode('utm_medium')}
                />
                <TextField
                    label='Campaign'
                    type='text'
                    data-cy='campaign'
                    sx={classes.field}
                    InputLabelProps={{ shrink: true }}
                    value={filterState?.utm_campaign || filterState?.link_utm_campaign || ''}
                    onChange={updateUtmCode('utm_campaign')}
                />
                <TextField
                    label='Content'
                    type='text'
                    data-cy='content'
                    sx={classes.field}
                    InputLabelProps={{ shrink: true }}
                    value={filterState?.utm_content || filterState?.link_utm_content || ''}
                    onChange={updateUtmCode('utm_content')}
                />
                <TextField
                    label='Term'
                    type='text'
                    data-cy='term'
                    sx={classes.field}
                    InputLabelProps={{ shrink: true }}
                    value={filterState?.utm_term || filterState?.link_utm_term || ''}
                    onChange={updateUtmCode('utm_term')}
                />
                <div style={classes.buttonContainer}>
                    <Button
                        sx={classes.btn}
                        variant="contained"
                        color="secondary"
                        data-cy='reset-filter-btn'
                        onClick={resetFilterValues}
                    >
                        Reset Filters
                    </Button>
                    <Button
                        sx={classes.btn}
                        variant="contained"
                        color="secondary"
                        data-cy='refresh-filter-btn'
                        onClick={onFilterRefresh}
                    >
                        <RefreshIcon />
                    </Button>
                </div>
                <div style={classes.exportButtonContainer}>
                    <Button
                        sx={classes.exportBtn}
                        variant='contained'
                        color='secondary'
                        data-cy='export-graphs-btn'
                        onClick={exportGraph}
                        title='Download graph data as CSV'
                    >
                        Export
                        <GetAppIcon />
                    </Button>
                </div>
            </div>
        }
    </>
)

export default GraphContainer
