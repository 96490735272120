import AlarmIcon from '@mui/icons-material/Alarm'
import AlarmOffIcon from '@mui/icons-material/AlarmOff'
import Row from 'components/Row'
import Cell from 'components/Cell'
import { lastXChar, isEmpty } from 'helpers'
import { useOfferDashboardAnalytics } from 'features/velocity/analytics/hooks/offer_analytics'
import useStyles from './styles'

export const OfferListAnalyticsRow = ({ id, name, strategy_type, currently_live }) => {
    const styles = useStyles()

    const { analytics } = useOfferDashboardAnalytics(id, strategy_type)

    const {
        totalClicks,
        totalClips,
        totalScans,
        totalScanRefunds,
    } = analytics || {}

    return (
        <Row
            key={id}
            path={`/velocity/offers/${id}`}
        >
            <Cell align='center'>{`${name} (${lastXChar(id, 8)})`}</Cell>
            <Cell align='center'>
                {currently_live ? (
                    <span style={{ ...styles[`live${currently_live}`], ...styles.status }}>
                        <AlarmIcon />
                    </span>
                ) : (
                    <span style={{ ...styles[`live${currently_live}`], ...styles.status }}>
                        <AlarmOffIcon />
                    </span>
                )}
            </Cell>
            <Cell align='center'>{isEmpty(totalClicks) ? 'N/A' : `${totalClicks}`}</Cell>
            <Cell align='center'>{isEmpty(totalClips) ? 'N/A' : `${totalClips}`}</Cell>
            <Cell align='center'>{isEmpty(totalScans) ? 'N/A' : `${totalScans}`}</Cell>
            <Cell align='center'>{isEmpty(totalScanRefunds) ? 'N/A' : `${totalScanRefunds}`}</Cell>
        </Row>
    )
}

export default OfferListAnalyticsRow
