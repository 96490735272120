import { UPDATE_FIELD, RESET_FORM, UPDATE_STATE, RESET_FIELD } from 'features/status'
import { useCustomerFormReducer } from 'features/velocity/incomm_debug/reducers/form'
import { emptyToNull } from 'helpers'

export const useCustomerForm = (state = {}) => {
    const [customerState, dispatch] = useCustomerFormReducer(state)

    const updateField = (field) => (event) => dispatch({ type: UPDATE_FIELD, field, value: emptyToNull(event.target.value) })
    const saveID = (field, value) => dispatch({ type: UPDATE_STATE, field, value })
    const resetField = (field) => dispatch({ type: RESET_FIELD, field })
    const resetForm = () => dispatch({ type: RESET_FORM })

    return {
        form: customerState.form,
        customerState,
        updateField,
        saveID,
        resetField,
        resetForm,
    }
}
